import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {CommonModule} from "@angular/common";
import {IonicModule} from "@ionic/angular";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {InputTextComponent} from "@shared/components/custom-property/inputs/text/input-text.component";
import {
  WinterhalterServiceCallCategoryPreviewDto,
  WinterhalterServiceCallIssuePreviewDto,
  WinterhalterServiceCallProblemPreviewDto
} from "@server-models";
import {InputMultiTextComponent} from "@shared/components/custom-property/inputs/multi-text/input-multi-text.component";
import {InputEmailComponent} from "@shared/components/custom-property/inputs/email/input-email.component";
import {InputDropdownComponent} from "@shared/components/custom-property/inputs/dropdown/input-dropdown.component";

@Component({
  selector: 'app-issue-custom-form-winterhalter-service-call',
  templateUrl: './issue-custom-winterhalter-service-call-form.component.html',
  styleUrls: ['./issue-custom-winterhalter-service-call-form.component.scss'],
  standalone: true,
  imports: [
    IonicModule,
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    InputTextComponent,
    InputMultiTextComponent,
    InputEmailComponent,
    InputDropdownComponent
  ]
})
export class IssueCustomWinterhalterServiceCallFormComponent implements OnInit, OnChanges {
  @Input() serviceCalLData: WinterhalterServiceCallIssuePreviewDto;
  @Output() inputChange = new EventEmitter<FormGroup>();

  categoryList: WinterhalterServiceCallCategoryPreviewDto[];
  selectedCategoryProblems: WinterhalterServiceCallProblemPreviewDto[] = [];
  categoryForm: FormGroup;


  constructor(
    private _translate: TranslateService,
    private _fb: FormBuilder
  ) {
    this.serviceCalLData = {};
    this.categoryList = [];
    this.categoryForm = this._fb.group({
      category: ['', Validators.required],
      problem: ['', Validators.required],
      message: ['', Validators.required],
      contactFirstName: ['', Validators.required],
      contactLastName: ['', Validators.required]
    });
  }

  ngOnInit() {
    this.categoryList = this.serviceCalLData.categories!;
  }

  getControl(controlName: string) {
    return this.categoryForm.get(controlName) as FormControl;
  }

  isRequired(controlName: string): boolean {
    const control = this.categoryForm.get(controlName);
    if (control) {
      const validator = control.validator ? control.validator({} as any) : null;
      return !!(validator && validator['required']);
    }
    return false;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['serviceCalLData']) {
      this.formModified();
    }
  }

  formModified(): void {
    this.inputChange.emit(this.categoryForm);
  }

  onCategoryChange(event: any) {
    const selectedCategoryKey = event.detail.value;

    const selectedCategory = this.categoryList.find(category => category.key === selectedCategoryKey);

    if (selectedCategory) {
      this.selectedCategoryProblems = selectedCategory.problems!;
      this.categoryForm.patchValue({problem: ''});
    } else {
      this.selectedCategoryProblems = [];
    }
  }
}
