import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';
import {select, Store,} from "@ngrx/store";
import {filter, Observable} from "rxjs";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {
  SharedShowHidePasswordComponent
} from "@shared/components/show-hide-password/shared-show-hide-password.component";
import {TranslateModule} from "@ngx-translate/core";
import {EAppType} from "@shared/models/AppType.enum";
import {LinkLoginActions} from "@link/pages/login/store/link-login.actions-type";
import {LinkLoginSelectors} from "@link/pages/login/store/link-login.selector-type";
import {
  LinkLoginMessageCardComponent
} from "@link/pages/login/components/message-card/link-login-message-card.component";
import {TokenDto} from '@server-models';

@Component({
  selector: 'app-link-login',
  templateUrl: './link-login.page.html',
  styleUrls: ['./link-login.page.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, FormsModule, SharedShowHidePasswordComponent, TranslateModule, LinkLoginMessageCardComponent]
})
export class LinkLoginPage implements OnInit {

  @Output() isPasswordEnabledOut: EventEmitter<any>;
  public isLoading$: Observable<boolean> = this.store.select(LinkLoginSelectors.selectIsLoading);
  public isLogged$: Observable<boolean> = this.store.select(LinkLoginSelectors.selectIsLogged);
  public resourceId$: Observable<number | undefined> = this.store.pipe(select(LinkLoginSelectors.selectLinkId));
  public token$: Observable<TokenDto | undefined> = this.store.select(LinkLoginSelectors.selectToken);

  public username?: string;
  public password?: string;

  private redirectRequest?: string;

  resourceIdStored: number;

  constructor(
    private store: Store,
    private _router: Router,
    private _activatedRoute: ActivatedRoute
  ) {
    this.isPasswordEnabledOut = new EventEmitter<any>();
    this.resourceIdStored = NaN;
  }

  ngOnInit() {
    this.resourceId$.subscribe((linkId) => {
      this.resourceIdStored = linkId!;
    })
    this.redirectRequest = this.getRedirectRequest();
    const routeSnapshot = this._activatedRoute.snapshot;

    const args: { key?: string, lang?: string } = {
      key: routeSnapshot.queryParamMap.get('key') ?? undefined,
      lang: routeSnapshot.queryParamMap.get('lang') ?? undefined
    }

    this.token$.pipe(
      filter(token => !!token)
    ).subscribe(async () => {
      if (this.redirectRequest !== undefined) {
        await this.startApp(EAppType.Link);
      }
    });

    this.isLogged$.subscribe((isLogged) => {
      if (isLogged && this.redirectRequest === undefined) {
        this._router.navigate([`link/${ this.resourceIdStored }/home`])
      }
    });


    if (!!args.key) {
      this.store.dispatch(LinkLoginActions.byResource({ key: args.key, lang: args.lang }));
    } else {
      this.store.dispatch(LinkLoginActions.init());
    }
  }

  private getRedirectRequest(): string | undefined {
    const queryParams = new URLSearchParams(window.location.search);
    const url = queryParams?.get('redirect');

    return url || undefined;
  };

  private async findRedirectUrl(appType: EAppType, request?: string) {
    switch (appType) {
      case EAppType.Link:
        return request?.startsWith('/link/') ? request : ('/link/' + this.resourceIdStored);
      default:
        return null;
    }
  }

  private async startApp(action: EAppType) {
    const redirect = await this.findRedirectUrl(action, this.redirectRequest);
    const url = new URL(redirect ?? '', window.location.origin);
    const path = url.pathname;

    const queryParams: Params = {};
    url.searchParams.forEach((value, key) => {
      queryParams[key] = value;
    });

    await this._router.navigate([path], { queryParams });
  }
}
