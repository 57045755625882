import {Component, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {IonicModule, NavController} from '@ionic/angular';
import {ActivatedRoute} from "@angular/router";
import {select, Store} from "@ngrx/store";
import {KnowledgeListComponent} from "@features/knowledge/knowledge-list/knowledge-list.component";
import {selectKnowledgeDetail} from "../../store/link.selector";
import {KnowledgeDetailComponent} from "@features/knowledge/knowledge-detail/knowledge-detail.component";
import {TranslateModule} from "@ngx-translate/core";
import {Observable, of} from "rxjs";
import {ResourceProfileFeatureDto} from "@server-models";
import {LinkLoginSelectors} from "@link/pages/login/store/link-login.selector-type";
import {LinkActions} from "@link/store/link.actions-type";

@Component({
  selector: 'app-link-knowledge-article',
  templateUrl: './link-knowledge-article.page.html',
  styleUrls: ['./link-knowledge-article.page.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, FormsModule, KnowledgeListComponent, KnowledgeDetailComponent, TranslateModule]
})
export class LinkKnowledgeArticlePage implements OnInit {

  public knowledge$ = this._store.select(selectKnowledgeDetail);
  features$: Observable<ResourceProfileFeatureDto | undefined>;

  constructor(
    private route: ActivatedRoute,
    private _store: Store,
    private nav: NavController
  ) {
    this.features$ = of();
  }

  ngOnInit() {
    this.features$ = this._store.pipe(select(LinkLoginSelectors.selectFeatures));

    const routeSnapshot = this.route.snapshot;
    const knowledgeArticleId = routeSnapshot.paramMap.get('id');

    this._store.dispatch(LinkActions.getKnowledgeArticle({ knowledgeArticleId: +knowledgeArticleId! }));
  }

  back() {
    this.nav.back();
  }
}
