import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {switchMap} from 'rxjs';
import {exhaustMap, map} from 'rxjs/operators';
import {Store} from "@ngrx/store";
import {LoginBaseStorageService} from "../services/login-base-storage.service";
import {Router} from "@angular/router";
import {generateColor} from "@core/helpers/color/parse-css";
import {ColorVariable} from "@core/helpers/color/color-variables";
import {TenantInfoDto, TokenDto} from "@server-models";
import {TranslateService} from "@ngx-translate/core";
import {ModalControllerService} from "@shared/services/modal-controller.service";
import {LoginBaseActions} from "@shared/stores/login-base/store/login-base.actions-type";
import {AlertBaseControllerService} from "@shared/services/alert-controller.service";
import {LanguageService} from "@shared/services/language.service";

@Injectable({
  providedIn: 'root'
})
export class LoginBaseEffects {

  constructor(
    protected _store: Store,
    protected actions$: Actions,
    protected loginStorage: LoginBaseStorageService,
    protected _alertControllerService: AlertBaseControllerService,
    protected _translationService: TranslateService,
    protected _modalControllerService: ModalControllerService,
    protected _router: Router,
    protected _languageService: LanguageService
  ) {
  }

  loginFetchTenantSettingsFail$ = createEffect(() => this.actions$.pipe(
    ofType(LoginBaseActions.fetchTenantSettingsFail),
    exhaustMap(() => this.loginStorage.remove()
    )), {dispatch: false});

  loginLogout$ = createEffect(() => this.actions$.pipe(
    ofType(LoginBaseActions.initLogout),
    switchMap(() => this.loginStorage.clear().pipe(
      map(() => {
        this._router.navigate(['']).then(() => window.location.reload());
      })
    ))
  ), {dispatch: false});

  /**
   * @name _setTenantColor
   * @description
   * set the default color of a tenant
   * @memberof LoginBaseEffects
   * @param colorHexString
   * @private
   */
  protected _setTenantColor(colorHexString: string): void {
    const isSemcoColor = colorHexString.toUpperCase() == '#F1B143';
    if (isSemcoColor) {
      const app = (window.location?.pathname?.split("/") || [])[1]?.toLowerCase();
      switch (app) {
        case "tech":
          colorHexString = "#0052a1";
          break;
        case "team":
          colorHexString = "#e73a23";
          break;
        case "link":
          colorHexString = "#02a14d";
          break;
      }
    }

    if (colorHexString !== undefined) {
      const color: ColorVariable = generateColor("primary", "--ion-color-primary", colorHexString);
      const attrMap: ColorVariable = {
        value: '',
        valueRgb: '-rgb',
        contrast: '-contrast',
        contrastRgb: '-contrast-rgb',
        shade: '-shade',
        tint: '-tint',
      };

      //force semco contrast
      if (isSemcoColor) {
        color.contrast = '#ffffff';
        color.contrastRgb = '255,255,255;';
      }

      Object.keys(attrMap).forEach(key => {
        document.documentElement.style.setProperty('--ion-color-primary' + attrMap[key], color[key]!);
      });

      document.querySelector('meta[name="theme-color"]')?.setAttribute('content', colorHexString);
    }
  }

  /**
   * @name _selectedTenantDisplayName
   * @description
   * return the displayName of a tenantId
   * @memberof LoginBaseEffects
   * @param tenants
   * @param tenantId
   * @private
   */
  protected _selectedTenantDisplayName(tenants: TenantInfoDto[], tenantId: number): string | null | undefined {
    return tenants?.find(tenant => tenant.tenantId === tenantId)!?.displayName;
  }

  /**
   * @name _stripJwt
   * @description
   * remove Jwt key from TokenDto Object
   * @param tokenDto
   * @protected
   * @returns {TokenDto} (-token)
   */
  protected _stripJwt(tokenDto: TokenDto): TokenDto {
    let strippedTokenDto = tokenDto!;
    const {token, ...newTokenDto} = strippedTokenDto;
    return newTokenDto;
  }
}
