<ion-list
  *ngIf="!formControl.value?.ownValue || !formControl.value?.ownFileValue; else existingUri"
  class="ion-no-padding">
  <ion-item [lines]="checkLines(propertyId)" class="ion-no-padding">
    <ion-input
      [label]="isRequired ? label + ' *': label"
      labelPlacement="stacked"
      type="hidden"
      [formControl]="formControl"
    ></ion-input>
    <ion-button *ngIf="!hasCameraButtonState(propertyId)" slot="end" (click)="pictureTakeDispatch()">
      <ion-icon name="camera-outline"></ion-icon>
    </ion-button>
  </ion-item>
  <ng-container *ngFor="let photoValue of photoValues;let photoIndex = index">
    <ion-item class="ion-no-padding" *ngIf="photoValue?.pictureId === propertyId && photoValue?.isCameraButtonShown">
      <ng-container *ngIf="(checkLoading() | async)">
        <div class="spinner-container">
          <ion-spinner></ion-spinner>
        </div>
      </ng-container>

      <ng-container *ngIf="!(checkLoading() | async)">
        <div style="width: 100%; padding-top: 0.5em">
          <img *ngIf="photoValue?.picture?.webPath" alt="image-attached" style="padding-right: 0"
               [src]="photoValue?.picture?.webPath | sharedSafe: 'url'"/>

          <div style="text-align: right">
            <ion-button *ngIf="photoValue?.picture?.webPath" style="--padding-end: 0" fill="clear"
                        (click)="clear(propertyId)">
              {{ "COMPONENTS.INPUTS.PICTURE.BUTTONS.CLEAR" | translate }}
            </ion-button>
          </div>
        </div>
      </ng-container>

    </ion-item>
  </ng-container>

  <div class="input-error" *ngIf="formControl.errors && (formControl.dirty || formControl.touched)">
    <p *ngIf="formControl.errors['required']">{{ 'COMPONENTS.INPUTS.ERRORS.REQUIRED' | translate }}</p>
  </div>
</ion-list>
<ng-template #existingUri>
  <ion-list *ngIf="formControl.value?.ownFileValue">
    <ion-item class="ion-no-padding">
      <ion-label class="set-color">
        <h3 [innerText]="label"></h3>
        <img *ngIf="formControl.value?.ownFileValue.uri" alt="picture-attached-read"
             [src]="formControl.value?.ownFileValue.uri | sharedSafe: 'url'"/>
      </ion-label>
    </ion-item>
    <ion-item *ngIf="!(checkLoading() | async)" class="ion-no-padding" lines="none" style="float: right">
      <ng-container>
        <ion-button
          (click)="clearImage()">{{ "COMPONENTS.INPUTS.PICTURE.BUTTONS.CLEAR" | translate }}
        </ion-button>
      </ng-container>
    </ion-item>
  </ion-list>

</ng-template>
