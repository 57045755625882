import {Injectable} from "@angular/core";
import {IOrgaAttachmentDtoExtend} from "@shared/components/attachments/interfaces/orga-attachment-dto-extend.interface";
import {AttachmentTypes} from "@server-models";
import {AttachmentsActions} from "@shared/components/attachments/store/attachments.actions-type";
import {select, Store} from "@ngrx/store";
import {Observable, withLatestFrom} from "rxjs";
import {map} from "rxjs/operators";
import {
  selectAttachmentsIsLoading,
  selectAttachmentsIsLoadingId
} from "@shared/components/attachments/store/attachments.selectors";

@Injectable({
  providedIn: 'root',
})
export class SharedAttachmentsService {

  isLoading$: Observable<boolean>;
  isLoadingId$: Observable<number>;

  constructor(
    private _store: Store
  ) {
    this.isLoading$ = this._store.pipe(select(selectAttachmentsIsLoading));
    this.isLoadingId$ = this._store.pipe(select(selectAttachmentsIsLoadingId));
  }

  /**
   * @name checkLoadingButton
   * @description
   * Check if it is isLoading base on an id
   * @memberof SharedAttachmentsService
   * @param attachmentId
   * @returns {Observable<boolean>}
   */
  checkLoadingButton(attachmentId: number | undefined): Observable<boolean> {
    return this.isLoadingId$.pipe(
      withLatestFrom(this.isLoading$),
      map(([id, isLoading]) => (attachmentId! === id) && isLoading)
    );
  }

  /**
   * @name dispatchAction
   * @description
   * dispatch the action checking the attachment types
   * @memberof SharedAttachmentsService
   * @param attachment
   */
  dispatchAction(attachment: IOrgaAttachmentDtoExtend): void {
    switch (attachment.type) {
      case AttachmentTypes.File:
        this._store.dispatch(AttachmentsActions.getFile(
          { path: attachment.url!, name: attachment.name!, id: attachment.attachmentId!, mineType: attachment.mineType }
        ));
        break;
      case AttachmentTypes.Link:
        this._store.dispatch(AttachmentsActions.openLink({
          id: attachment.attachmentId!,
          url: attachment.url!
        }))
        break;
      case AttachmentTypes.Knowledge:
        this._store.dispatch(AttachmentsActions.getKnowledgeArticle({
          id: attachment.attachmentId!,
          referenceId: attachment.referenceId!
        }));
        break;
    }
  }
}
