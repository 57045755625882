import {Component, EventEmitter, Output} from '@angular/core';
import {CommonModule} from "@angular/common";
import {IonicModule} from "@ionic/angular";
import {TranslateModule} from "@ngx-translate/core";
import {SafePipe} from "@shared/pipes/safe.pipe";
import {UserHtmlComponent} from "@features/user-html/user-html.component";
import {AttachmentsComponent} from "@shared/components/attachments/attachments.component";
import {RouterLink} from "@angular/router";
import {CustomPropertyComponent} from "@shared/components/custom-property/custom-property.component";
import {FormBuilder, FormGroup, ReactiveFormsModule} from "@angular/forms";
import {InputTextComponent} from "@shared/components/custom-property/inputs/text/input-text.component";
import {
  IssueContentType, WinterhalterProductCatalogDto, WinterhalterProductOrderIssuePreviewDto
} from "@server-models";
import {
  IssueCustomPropertyFormComponent
} from "@shared/components/issues/components/custom/form/properties/issue-custom-property-form.component";
import {Observable, of} from "rxjs";
import {select, Store} from "@ngrx/store";
import {BaseIssuesSelectors} from "@shared/components/issues/store/base-issues.selectors-type";
import {TemplatePreviewDtoType} from "@shared/components/issues/types/template-preview-dto.type";
import {
  IssueCustomWinterhalterProductOrderFormComponent
} from "@shared/components/issues/components/custom/form/winterhalter/product-order/issue-custom-winterhalter-product-order-form.component";

@Component({
  selector: 'app-base-issue-winterhalter-product-order-template-preview',
  templateUrl: './product-order-template-preview.component.html',
  styleUrls: ['./product-order-template-preview.component.scss'],
  standalone: true,
  imports: [
    IonicModule,
    CommonModule,
    TranslateModule,
    SafePipe,
    UserHtmlComponent,
    AttachmentsComponent,
    RouterLink,
    InputTextComponent,
    ReactiveFormsModule,
    CustomPropertyComponent,
    IssueCustomPropertyFormComponent,
    IssueCustomWinterhalterProductOrderFormComponent
  ]
})
export class BaseIssueWinterhalterProductOrderTemplatePreviewComponent {

  @Output() formChange = new EventEmitter<FormGroup>();
  parentForm: FormGroup;
  selectedTemplatePreview$: Observable<{
    id: number,
    templateIssueType: IssueContentType,
    data: TemplatePreviewDtoType
  }>;
  currentTemplatePreview: {
    issueContentType: IssueContentType,
    data: WinterhalterProductOrderIssuePreviewDto
  };
  currentProductOrderCatalog$: Observable<WinterhalterProductCatalogDto>;
  currentProductOrderCatalog: WinterhalterProductCatalogDto;

  constructor(
    private _fb: FormBuilder,
    private _store: Store
  ) {
    this.parentForm = this._fb.group({});
    this.selectedTemplatePreview$ = of(<{
      id: number,
      templateIssueType: IssueContentType,
      data: TemplatePreviewDtoType
    }>{});
    this.currentTemplatePreview = <{
      issueContentType: IssueContentType,
      data: WinterhalterProductOrderIssuePreviewDto
    }>{};
    this.currentProductOrderCatalog$ = of({});
    this.currentProductOrderCatalog = {};
  }

  // the best idea is make the request here, is we make the request to catalog here, we send the productList to the form, and the form only
  // have to build it

  ngOnInit() {
    this.selectedTemplatePreview$ = this._store.pipe(select(BaseIssuesSelectors.selectSelectedTemplate));
    this.selectedTemplatePreview$.subscribe((templatePreview) => {
      this.currentTemplatePreview = {
        issueContentType: templatePreview.templateIssueType,
        data: templatePreview.data as WinterhalterProductOrderIssuePreviewDto
      };
    })
    this.currentProductOrderCatalog$ = this._store.pipe(select(BaseIssuesSelectors.selectCurrentProductOrderCatalog));
    this.currentProductOrderCatalog$.subscribe((catalog) => {
      if (catalog) {
        this.currentProductOrderCatalog = catalog;

      }
    });
  }

  formInnerModified(ev: any) {
    this.parentForm = ev;
    this.formChange.emit(this.parentForm);
  }

}
