import {Injectable} from "@angular/core";
import {Actions} from "@ngrx/effects";
import {select, Store} from "@ngrx/store";
import {mergeMap, Observable, of, withLatestFrom} from "rxjs";
import {AttachmentsEffectsBase} from "@shared/components/attachments/store/attachments.effects";
import {SharedFileService} from "@shared/services/file/shared-file.service";
import {SharedViewerPdfComponent} from "@shared/components/viewers/pdf/shared-viewer-pdf.component";
import {SharedModalControllerService} from "@shared/services/shared-modal-controller.service";
import {selectAttachments} from "@shared/components/attachments/store/attachments.selectors";
import {catchError, map, tap} from "rxjs/operators";
import {AttachmentsActions} from "@shared/components/attachments/store/attachments.actions-type";
import {SharedViewerImageComponent} from "@shared/components/viewers/image/shared-viewer-image.component";
import {IAttachmentsFileAction} from "@shared/components/attachments/interfaces/attachments-file-action.interface";
import {
  IAttachmentsFileRequestAction
} from "@shared/components/attachments/interfaces/attachments-file-request-action.interface";
import {SharedCapacitorBrowserService} from "@shared/services/capacitor/browser/shared-capacitor-browser.service";
import {
  SharedViewerKnowledgeArticleComponent
} from "@shared/components/viewers/knowledge-article/shared-viewer-knowledge-article.component";
import {
  IAttachmentKnowledgeArticle
} from "@shared/components/attachments/interfaces/attachment-knowledge-article.interface";
import {
  IAttachmentKnowledgeArticleResponse
} from "@shared/components/attachments/interfaces/attachment-knowledge-article-response.interface";
import {IViewerBlobSrc} from '@shared/components/viewers/interfaces/viewer-src.interface';
import {SharedKnowledgeService} from "@shared/services/shared-knowledge.service";

@Injectable({
  providedIn: 'root',
})
export class AttachmentsApiEffects extends AttachmentsEffectsBase {
  constructor(
    actions$: Actions,
    store: Store,
    private _fileService: SharedFileService,
    private _modalControllerService: SharedModalControllerService,
    private _cBrowser: SharedCapacitorBrowserService,
    private _sharedKnowledgeService: SharedKnowledgeService
  ) {
    super(store, actions$);
  }

  /**
   * @name getFile
   * @description
   * trigger get file action and forward to requestFile
   * @memberof AttachmentsApiEffects
   * @param action$
   * @returns {Observable<IAttachmentsFileRequestAction>}
   */
  getFile(
    action$: Observable<IAttachmentsFileRequestAction>
  ): Observable<IAttachmentsFileRequestAction> {
    return action$.pipe(
      withLatestFrom(this.store.pipe(select(selectAttachments))),
      mergeMap(([action]) => this._requestFile(action))
    );
  }

  /**
   * @name _requestFile
   * @description
   * Request to the file service the file
   * @memberof AttachmentsApiEffects
   * @param action
   * @private
   * @returns {Observable<IAttachmentsFileRequestAction | any>}
   */
  private _requestFile(
    action: IAttachmentsFileRequestAction
  ): Observable<IAttachmentsFileRequestAction | any> {
    return this._fileService.get(action.path).pipe(
      map((data) =>
        AttachmentsActions.getFileSuccess({
          file: data,
          name: action.name,
          id: action.id,
          mineType: action.mineType,
        })
      ),
      catchError((error) => of(AttachmentsActions.getFileFail({ error })))
    );
  }

  /**
   * @name getFileSuccess
   * @description
   * trigger file success action and check the file type to open the correct modal
   * @memberof AttachmentsApiEffects
   * @param action$
   * @returns {Observable<IAttachmentsFileAction>}
   */
  getFileSuccess(
    action$: Observable<IAttachmentsFileAction>
  ): Observable<IAttachmentsFileAction> {
    return action$.pipe(
      tap((action) => {
        const finalType = action.mineType ? action.mineType : action.file.type;

        switch (finalType) {
          case 'application/pdf':
            this._openDocumentViewer(
              SharedViewerPdfComponent,
              action.file,
              action.name
            );
            break;
          case 'image/png':
          case 'image/jpg':
          case 'image/jpeg':
            this._openDocumentViewer(
              SharedViewerImageComponent,
              action.file,
              action.name
            );
            break;
        }
      })
    );
  }

  /**
   * @name _openDocumentViewer
   * @description
   * Call the modal controller service to open the modal
   * @memberof AttachmentsApiEffects
   * @param component
   * @param blobFile
   * @param name
   * @private
   */
  private _openDocumentViewer(
    component: any,
    blobFile: Blob,
    name: string
  ): void {
    this._modalControllerService.showModal(component, 'modal-fullscreen', {
      file: { file: blobFile } as IViewerBlobSrc,
      name,
    });
  }

  /**
   * @name openLink
   * @description
   * trigger the open link action and forward to success or fail action
   * @memberof AttachmentsApiEffects
   * @param action$
   * @returns {Observable<{id: number, options: OpenOptions} | any>}
   */
  openLink(
    action$: Observable<{ id: number; url: string }>
  ): Observable<
    | {
    id: number;
    url: string;
  }
    | any
  > {
    return action$.pipe(
      map((action) => AttachmentsActions.openLinkSuccess(action)),
      catchError((error) => of(AttachmentsActions.openLinkFail({ error })))
    );
  }

  /**
   * @name openLinkSuccess
   * @description
   * trigger the open link success action and launch the open browser action
   * @memberof AttachmentsApiEffects
   * @param action$
   * @returns {Observable<OpenOptions>}
   */
  openLinkSuccess(
    action$: Observable<{ id: number; url: string }>
  ): Observable<{
    id: number;
    url: string;
  }> {
    return action$.pipe(
      tap((action) => {
        this._cBrowser.open(action.url);
      })
    );
  }

  /**
   * @name getKnowledgeArticle
   * @description
   * trigger get KnowledgeArticle action and forward to requestKnowledgeArticle
   * @memberof
   * @param action$
   * @returns {Observable<IAttachmentKnowledgeArticle>}
   */
  getKnowledgeArticle(
    action$: Observable<IAttachmentKnowledgeArticle>
  ): Observable<IAttachmentKnowledgeArticle> {
    return action$.pipe(
      withLatestFrom(this.store.pipe(select(selectAttachments))),
      mergeMap(([action]) => this._requestKnowledgeArticle(action))
    );
  }

  /**
   * @name _requestKnowledgeArticle
   * @description
   * Request to the _sharedKnowledgeService service the Article
   * @memberof
   * @param action
   * @private
   * @returns {Observable<IAttachmentKnowledgeArticle>}
   */
  private _requestKnowledgeArticle(
    action: IAttachmentKnowledgeArticle
  ): Observable<IAttachmentKnowledgeArticle | any> {
    return this._sharedKnowledgeService.getArticle(action.referenceId!).pipe(
      map((data) =>
        AttachmentsActions.getKnowledgeArticleSuccess({
          id: action.id,
          data: data,
        })
      ),
      catchError((error) =>
        of(AttachmentsActions.getKnowledgeArticleFail({ error }))
      )
    );
  }

  /**
   * @name getKnowledgeArticleSuccess
   * @description
   * trigger knowledge article success action to open the modal
   * @memberof
   * @param action$
   * @returns {Observable<IAttachmentKnowledgeArticleResponse>}
   */
  getKnowledgeArticleSuccess(
    action$: Observable<IAttachmentKnowledgeArticleResponse>
  ): Observable<IAttachmentKnowledgeArticleResponse> {
    return action$.pipe(
      tap((action) => {
        this._modalControllerService.showModal(
          SharedViewerKnowledgeArticleComponent,
          'modal-fullscreen',
          {
            name: action.data.title,
            htmlContent: action.data.content,
            attachmentsInc: action.data.attachments,
          }
        );
      })
    );
  }
}
