import {Component, Input, OnInit} from "@angular/core";
import {IonicModule} from "@ionic/angular";
import {CommonModule} from "@angular/common";
import {TranslateModule} from "@ngx-translate/core";
import {IAttachmentInternalDto} from "@shared/components/attachments/interfaces/attachment-internal-dto.interface";
import {SharedAttachmentsService} from "@shared/components/attachments/services/shared-attachments.service";
import {AttachmentTypes} from "@server-models";

@Component({
  selector: 'app-shared-attachments-row-item',
  templateUrl: './shared-attachments-row-item.component.html',
  styleUrls: ['./shared-attachments-row-item.component.scss'],
  imports: [
    IonicModule,
    CommonModule,
    TranslateModule
  ],
  standalone: true
})
export class SharedAttachmentsRowItemComponent implements OnInit {
  @Input() mappedAttachments: IAttachmentInternalDto[];
  @Input() hasHeader: boolean;
  @Input() isIconFolderEnabled: boolean;

  attachmentType: typeof AttachmentTypes = AttachmentTypes;

  constructor(
    public attachmentsService: SharedAttachmentsService
  ) {
    this.hasHeader = true;
    this.isIconFolderEnabled = false;
    this.mappedAttachments = [];
  }

  ngOnInit() {

  }
}
