import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {mergeMap, switchMap, withLatestFrom} from 'rxjs';
import {catchError, exhaustMap, map} from 'rxjs/operators';
import {LinkLoginApiService} from "../services/link-login-api.service";
import {select, Store} from "@ngrx/store";
import {Router} from "@angular/router";
import {LinkLoginActions} from "@link/pages/login/store/link-login.actions-type";
import {EAppType} from "@shared/models/AppType.enum";
import {TranslateService} from "@ngx-translate/core";
import {SharedLoginBaseEffects} from "@shared/stores/login-base/store/shared-login-base.effects";
import {SharedModalControllerService} from "@shared/services/shared-modal-controller.service";
import {SharedLoginStorageBaseService} from "@shared/stores/login-base/services/shared-login-storage-base.service";
import {AlertBaseControllerService} from "@shared/services/shared-alert-controller.service";
import {LinkLoginSelectors} from "@link/pages/login/store/link-login.selector-type";
import {Clients} from "@server-models";
import {TokenBaseHelperService} from "@shared/services/token-base-helper.service";
import {LinkLoginStorageService} from "@link/pages/login/services/link-login-storage.service";
import {LinkLoginService} from "@link/pages/login/services/link-login.service";
import {SharedLanguageService} from "@shared/services/shared-language.service";
import {CoreEnvironmentService} from "@core/services/environment/core-environment.service";

@Injectable({
  providedIn: 'root'
})
export class LinkLoginEffects extends SharedLoginBaseEffects {

  constructor(
    _store: Store,
    actions$: Actions,
    private _loginLinkApiService: LinkLoginApiService,
    private _tokenHelperService: TokenBaseHelperService,
    private _linkLoginStorage: LinkLoginStorageService,
    loginStorage: SharedLoginStorageBaseService,
    _alertControllerService: AlertBaseControllerService,
    _translateService: TranslateService,
    _modalControllerService: SharedModalControllerService,
    _router: Router,
    _languageService: SharedLanguageService,
    private _linkLoginService: LinkLoginService,
  ) {
    super(
      _store,
      actions$,
      loginStorage,
      _alertControllerService,
      _translateService,
      _modalControllerService,
      _router,
      _languageService
    )
  }

  loginByResource$ = createEffect(() => this.actions$.pipe(
    ofType(LinkLoginActions.byResource),
    exhaustMap(action => this._loginLinkApiService.loginByResource(action.key)
      .pipe(
        switchMap(data => {
            return [
              LinkLoginActions.byResourceSuccess({ tokenDto: data, linkId: data.resourceId!, lang: action.lang }),
            ]
          }
        ),
        catchError(error => [LinkLoginActions.byResourceFail({ error })])
      ))
  ));

  loginByResourceSuccess$ = createEffect(() => this.actions$.pipe(
      ofType(LinkLoginActions.byResourceSuccess),
      exhaustMap(action => {
        return [LinkLoginActions.initSuccess({ tokenDto: action.tokenDto, app: EAppType.Link, lang: action.lang })]
      }),
      catchError((error) => [LinkLoginActions.initFail({ error })])
    )
  );

  loginSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(LinkLoginActions.initSuccess),
    map((action) => {
      const env = CoreEnvironmentService.getEnvironment();
      const info = action.tokenDto!?.info!;

      this._languageService.setLanguageByPriority(action.lang, info.userCulture!, env);

      const tenant = info?.tenants![0];
      const tenantId = tenant?.tenantId!;

      return LinkLoginActions.successLink({
        app: action.app,
        token: action.tokenDto,
        currentTenant: {
          tenantId,
          tenantLanguage: info?.userCulture,
          tenantDisplayName: tenant?.displayName,
          tenantSettings: action.currentTenant?.tenantSettings
        }
      });

    })
  ));

  successLink$ = createEffect(() => this.actions$.pipe(
    ofType(LinkLoginActions.successLink),
    exhaustMap(() => {
        return [LinkLoginActions.loginRefresh({ clientType: Clients.Link })]
      }
    )
  ));

  loginFetchTenantSettings$ = createEffect(() => this.actions$.pipe(
    ofType(LinkLoginActions.fetchTenantSettings),
    withLatestFrom(this._store.select(LinkLoginSelectors.selectTenantId)),
    withLatestFrom(this._store.select(LinkLoginSelectors.selectToken)),
    mergeMap(([[_, tenantId], tokenDto]) => {
      const tokenInfo = tokenDto!?.info!;
      return this._loginLinkApiService.getSettings(tenantId!).pipe(
        map((settings) => {
          return LinkLoginActions.fetchTenantSettingsSuccess({
            currentTenant: {
              tenantId,
              tenantLanguage: tokenInfo?.userCulture,
              tenantDisplayName: this._selectedTenantDisplayName(tokenInfo?.tenants!, tenantId!),
              tenantSettings: settings
            }
          })
        }),
        catchError(error => [LinkLoginActions.fetchTenantSettingsFail({ error })])
      )
    })
  ));

  getProfile$ = createEffect(() => this.actions$.pipe(
    ofType(LinkLoginActions.getProfile),
    exhaustMap(action => this._loginLinkApiService.getProfile(action.resourceId).pipe(
        map(response => {
          return LinkLoginActions.getProfileSuccess({
            profile: response
          })
        })
      )
    )
  ));

  getProfileSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(LinkLoginActions.getProfileSuccess),
    exhaustMap((action) => {
        return this._linkLoginStorage.merge({ profile: action.profile }).pipe(
          mergeMap(() => [LinkLoginActions.isLoginByResourceNavigation()])
        )
      }
    )));

  checkFeatures$ = createEffect(() => this.actions$.pipe(
      ofType(LinkLoginActions.checkFeatures),
      withLatestFrom(this._store.pipe(select(LinkLoginSelectors.selectFeatures))),
      exhaustMap(([_, features]) => {
          return [LinkLoginActions.checkFeaturesSuccess({
            tabNavigation: {
              issues: this._linkLoginService.checkIssuesNav(features!),
              knowledges: this._linkLoginService.checkKnowledgeNav(features!)
            }
          })]
        }
      )
    )
  );

  checkFeaturesSuccess$ = createEffect(() => this.actions$.pipe(
      ofType(LinkLoginActions.checkFeaturesSuccess),
      withLatestFrom(this._store.pipe(select(LinkLoginSelectors.selectLinkId))),
      map(([_, linkId]) =>
        LinkLoginActions.navigationDependsFeature({ linkId: linkId! })
      )
    )
  );

  navigationDependsFeature$ = createEffect(() => this.actions$.pipe(
      ofType(LinkLoginActions.navigationDependsFeature),
      withLatestFrom(this._store.pipe(select(LinkLoginSelectors.selectFeatures))),
      withLatestFrom(this._store.pipe(select(LinkLoginSelectors.selectTabNavigation))),
      exhaustMap(([[action, features], tabNavigation]) => {
          if (features) {
            const firstTabKey = this._linkLoginService.findFirstTrueTab(features!);
            this._linkLoginService.redirectToPage(firstTabKey as string, action.linkId, tabNavigation, features.issueTemplateShortcut!);
          }
          return [LinkLoginActions.navigationDependsFeatureDone()];
        }
      )
    )
  );

  loginFetchTenantSettingsSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(LinkLoginActions.fetchTenantSettingsSuccess),
    withLatestFrom(this._store.select(LinkLoginSelectors.selectCurrentApp)),
    withLatestFrom(this._store.select(LinkLoginSelectors.selectToken)),
    withLatestFrom(this._store.select(LinkLoginSelectors.selectLinkId)),
    withLatestFrom(this._store.select(LinkLoginSelectors.selectLinkKey)),
    withLatestFrom(this._store.select(LinkLoginSelectors.selectProfile)),
    mergeMap(([[[[[action, appType], tokenDto], linkId], linkKey], profile]) => {
      if (action?.currentTenant?.tenantSettings?.primaryColor?.value) {
        this._setTenantColor(action.currentTenant.tenantSettings!.primaryColor!.value!);
      }

      const currentLanguage = this._languageService.getCurrentLanguage();
      const updatedTenant = {
        ...action.currentTenant,
        tenantLanguage: currentLanguage
      };

      this._linkLoginStorage.set({
        app: appType,
        token: this._stripJwt(tokenDto!)!,
        linkId: linkId!,
        linkKey,
        currentTenant: updatedTenant,
        profile
      })

      return [LinkLoginActions.isLoginByResourceNavigation()]
    }),
  ));

  isLoginByResource$ = createEffect(() => this.actions$.pipe(
    ofType(LinkLoginActions.isLoginByResourceNavigation),
    withLatestFrom(this._store.select(LinkLoginSelectors.selectIsByResource)),
    exhaustMap(([_, isByResource]) => {
      if (isByResource) {
        return [LinkLoginActions.isLoginByResourceNavigationDone()]
      } else {
        return [LinkLoginActions.isLoginByResourceNavigationCanceled()]
      }
    })
  ));

  isLoginByResourceNavigationDone$ = createEffect(() => this.actions$.pipe(
    ofType(LinkLoginActions.isLoginByResourceNavigationDone),
    withLatestFrom(this._store.select(LinkLoginSelectors.selectLinkId)),
    exhaustMap(([_, linkId]) => [LinkLoginActions.getProfile({ resourceId: linkId! })])
  ));

  isLoginByResourceNavigationCanceled$ = createEffect(() => this.actions$.pipe(
    ofType(LinkLoginActions.isLoginByResourceNavigationCanceled),
    withLatestFrom(this._store.select(LinkLoginSelectors.selectLinkId)),
    exhaustMap((_) => {
      return [LinkLoginActions.checkFeatures()]
    })
  ));

  loadStorage$ = createEffect(() => this.actions$.pipe(
    ofType(LinkLoginActions.loadStorage),
    exhaustMap(() => {
      return this._linkLoginStorage.getSt().pipe(
        mergeMap((data) => {
          let tenantDisplayName: string;
          if (data) {
            if (data.token) {
              if (data.currentTenant?.tenantId) {
                tenantDisplayName = this._selectedTenantDisplayName(data.token!.info!.tenants!, data.currentTenant.tenantId!)!;
              }
            }

            return [LinkLoginActions.loadStorageSuccess(
              {
                tenantSettings: data.currentTenant?.tenantSettings,
                tenantId: data.currentTenant?.tenantId!,
                tenantLanguage: data.currentTenant?.tenantLanguage!,
                tenantDisplayName: tenantDisplayName!,
                tokenDto: data.token!,
                app: data.app!,
                linkId: data.linkId!,
                linkKey: data.linkKey!,
                profile: data.profile!
              }
            )]
          }
          return [LinkLoginActions.loadStorageFail({
            error: 'There is not Storage data'
          })]
        })
      )
    })
  ));

  loadStorageSuccess$ = createEffect((() => this.actions$.pipe(
    ofType(LinkLoginActions.loadStorageSuccess),
    exhaustMap((action) => {
      if (action?.tenantSettings?.primaryColor?.value) {
        this._setTenantColor(action.tenantSettings?.primaryColor!.value!);
      }

      return [LinkLoginActions.initSuccess({
        tokenDto: action.tokenDto,
        app: EAppType.Link,
        lang: action.tenantLanguage
      })]

    })
  )));

  loginInit$ = createEffect(() => this.actions$.pipe(
    ofType(LinkLoginActions.init),
    exhaustMap(() => {
      return [LinkLoginActions.loadStorage()]
    })
  ));

  loginRefreshStart$ = createEffect(() => this.actions$.pipe(
      ofType(LinkLoginActions.loginRefresh),
      withLatestFrom(this._store.select(LinkLoginSelectors.selectToken)),
      withLatestFrom(this._store.select(LinkLoginSelectors.selectTenantId)),
      exhaustMap(([[_, token], tenantId]) => {
          return this._loginLinkApiService.loginRefresh(token?.refreshToken!, tenantId!).pipe(
            map(tokenDto => {
              return LinkLoginActions.loginRefreshSuccess({
                tokenDto,
                tenantId: tenantId!,
                clientType: Clients.Link
              })
            }),
            catchError((error) => [LinkLoginActions.loginRefreshFail({ error })])
          )
        }
      )
    )
  );

  loginRefreshSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(LinkLoginActions.loginRefreshSuccess),
    withLatestFrom(this._store.select(LinkLoginSelectors.selectToken)),
    withLatestFrom(this._store.select(LinkLoginSelectors.selectCurrentApp)),
    withLatestFrom(this._store.select(LinkLoginSelectors.selectLinkId)),
    withLatestFrom(this._store.select(LinkLoginSelectors.selectLinkKey)),
    withLatestFrom(this._store.select(LinkLoginSelectors.selectProfile)),
    exhaustMap(([[[[[action], appType], linkId], linkKey], profile]) => {
        this._linkLoginStorage.set({
          app: appType,
          token: this._stripJwt(action.tokenDto!),
          linkId,
          linkKey,
          currentTenant: {
            tenantId: action.tenantId,
          },
          profile
        });
        this._tokenHelperService.tokenRefreshSubject.complete();
        return [LinkLoginActions.fetchTenantSettings()]
      }
    )
  ));

  loginRefreshFail$ = createEffect(() => this.actions$.pipe(
    ofType(LinkLoginActions.loginRefreshFail), exhaustMap(() =>
      this._alertControllerService.observableRefreshToken(Clients.Link))
  ), { dispatch: false });
}
