import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {
  FormBuilder, FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import {SharedCustomPropertyFormInputTextComponent} from '@shared/components/custom-property-form/components/input/components/text/shared-custom-property-form-input-text.component';
import {
  WinterhalterProductCatalogDto,
  WinterhalterProductDetailDto,
  WinterhalterProductOrderIssuePreviewDto,
} from '@server-models';
import {SharedCustomPropertyReadOnlyInputTextComponent} from '@shared/components/custom-property-read-only/components/input-text/shared-custom-property-read-only-input-text.component';
import {SharedImageService} from "@shared/services/shared-image.service";
import {
  SharedCustomPropertyFormInputMultiTextComponent
} from "@shared/components/custom-property-form/components/input/components/multi-text/shared-custom-property-form-input-multi-text.component";
import {
  SharedCustomPropertyFormInputEmailComponent
} from "@shared/components/custom-property-form/components/input/components/email/shared-custom-property-form-input-email.component";
import {
  SharedCustomPropertyFormInputDropdownComponent
} from "@shared/components/custom-property-form/components/input/components/dropdown/shared-custom-property-form-input-dropdown.component";

@Component({
  selector: 'app-shared-issue-custom-form-winterhalter-product-order',
  templateUrl: './shared-issue-custom-form-winterhalter-product-order.component.html',
  styleUrls: ['./shared-issue-custom-form-winterhalter-product-order.component.scss'],
  standalone: true,
  imports: [
    IonicModule,
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    SharedCustomPropertyFormInputTextComponent,
    SharedCustomPropertyFormInputMultiTextComponent,
    SharedCustomPropertyFormInputEmailComponent,
    SharedCustomPropertyFormInputDropdownComponent,
    SharedCustomPropertyReadOnlyInputTextComponent,
  ],
})
export class SharedIssueCustomFormWinterhalterProductOrderComponent implements OnInit {
  @Input() productOrderData: WinterhalterProductOrderIssuePreviewDto;
  @Input() catalogData: WinterhalterProductCatalogDto;
  @Output() inputChange = new EventEmitter<FormGroup>();

  productOrderForm: FormGroup;
  innerFormGroup: FormGroup;
  products: any;

  constructor(
    public imageService: SharedImageService,
    private _translateService: TranslateService,
    private _fb: FormBuilder
  ) {
    this.productOrderData = {};
    this.catalogData = {};
    this.products = [];

    this.productOrderForm = this._fb.group({});
    this.innerFormGroup = this._fb.group({
      productId: [''],
      quantity: [''],
      containerSize: [''],
      productCode: [''],
      urlSafetyFile: [''],
      urlImage: [''],
    });
  }

  ngOnInit() {
    this.products = this.catalogData?.products || [];

    if (this.products) {
      this.products.forEach(
        (product: WinterhalterProductDetailDto, index: number) => {
          const productKey = `product_${ index }`;
          this.productOrderForm.addControl(
            productKey,
            this.createProductForm(product)
          );
        }
      );
      this.formModified();
    }
  }


  getControl(productKey: string, controlName: string): FormControl {
    return this.productOrderForm.get(productKey)?.get(controlName) as FormControl;
  }

  getControlValue(productKey: string, controlName: string): string {
    return this.getControl(productKey, controlName)?.value!;
  }

  getFormGroupKeys(): string[] {
    return Object.keys(this.productOrderForm.controls);
  }

  /**
   * @name createProductForm
   * @description
   * create a formGroup base of inc product
   * @memberof SharedIssueCustomFormWinterhalterProductOrderComponent
   * @param product
   */
  createProductForm(product: WinterhalterProductDetailDto): FormGroup {
    return this._fb.group({
      productId: [product.productId, Validators.required],
      quantity: [0],
      title: [product.title],
      containerSize: [product.containerSize],
      productCode: [product.productCode],
      urlSafetyFile: [product.urlSafetyFile],
      urlImage: [product.urlImage],
    });
  }

  quantitySelected(productKey: string, ev: CustomEvent) {
    this.getControl(productKey, 'quantity').setValue(ev.detail.value);
    this.formModified();
  }

  getQuantitySelectOptions(productId: string): { label: string; value: number }[] {
    const unit = this._translateService.instant('COMPONENTS.ISSUES.SERVICES.WINTERHALTER.PRODUCT_ORDER.FORM.SELECT.QUANTITY.PIECES');
    const product = this.catalogData.products?.find(
      (product) => product.productId == +productId
    );

    const options = [{ value: 0, label: `${ 0 } ${ unit }` }];
    for (
      let i = product?.minimumQuantity ?? 1;
      i <= (product?.maxQuantity ?? 5);
      i = i + (product?.salesQuantity ?? 1)
    ) {
      options.push({ value: i, label: `${ i } ${ unit }` });
    }

    return options;
  }

  formModified(): void {
    this.inputChange.emit(this.productOrderForm);
  }
}
