import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { ReadInputTextComponent } from '@shared/components/custom-property-read/inputs/text/read-input-text.component';
import { WinterhalterProductOrderDto } from '@server-models';
import { ModalControllerService } from '@shared/services/modal-controller.service';
import { ViewerImageComponent } from '@shared/components/viewers/viewer-image/viewer-image.component';
import { ViewerUrlSrc } from '@shared/components/viewers/interfaces/viewer-src.interface';

@Component({
  selector: 'app-issue-custom-read-winterhalter-product-order',
  templateUrl: './product-order-read.component.html',
  styleUrls: ['./product-order-read.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, TranslateModule, ReadInputTextComponent],
})
export class IssueCustomWinterhalterProductOrderReadComponent
  implements OnInit
{
  @Input() headerTitle: string;
  @Input() productList: WinterhalterProductOrderDto[];

  constructor(private _modalControllerService: ModalControllerService) {
    this.headerTitle = '';
    this.productList = [];
  }

  ngOnInit() {}

  openImage(url: string, name?: string) {
    this._modalControllerService.showModal(
      ViewerImageComponent,
      'modal-fullscreen',
      {
        file: { url } as ViewerUrlSrc,
        name,
      }
    );
  }
}
