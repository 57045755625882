import {environment} from "@env-link/environment.local";
import {createReducer, on} from "@ngrx/store";
import {BaseIssuesState} from "@shared/components/issues/store/base-issues.state";
import {BaseIssuesActions} from "@shared/components/issues/store/base-issues.action-type";
import {IssueContentType, IssueType} from "@server-models";
import {IssueFormattedDetailData} from "@shared/components/issues/interfaces/issue-formatted-detail-data.interface";

export const initialIssuesState: BaseIssuesState = {
  page: {},
  items: [],
  itemsTemplate: [],
  issueItem: {},
  selectedIssueItem: {
    id: NaN,
    issueType: <IssueType>{}
  },
  selectedTemplate: {
    id: NaN,
    templateIssueType: <IssueContentType>{},
    data: {}
  },
  shortCutTemplate: {
    issueTemplateId: NaN,
    contentType: <IssueContentType>{}
  },
  selectedProductOrderCatalog: {},
  selectedStereotype: {},
  selectedStereotypeEntityType: {},
  selectedPicture: {},
  selectedPictureIsLoading: false,
  selectedPictureIsLoadingId: NaN,
  selectedPictureAsBlob: new Blob(),
  savedFile: {
    pictureId: NaN,
    fileId: NaN,
    path: ''
  },
  selectedSignatureAsBlob: new Blob(),
  selectedSignatureIsLoading: false,
  selectedSignatureIsLoadingId: NaN,
  savedSignatureId: NaN,
  detailDataFormatted: <IssueFormattedDetailData>{},
  paging: {
    totalItems: 0,
    pageNumber: 0,
    pageSize: environment?.apiUrl?.pageDefaultSize || 1,
    totalPages: 1
  },
  isLoading: true,
  isLoadingId: NaN
};

export const baseIssuesStore = createReducer(
  initialIssuesState,
  on(BaseIssuesActions.getItemsPaginated, (state) => ({
    ...state,
    isLoading: true
  })),
  on(BaseIssuesActions.getItemsPaginatedSuccess, (state, {data}) => ({
    ...state,
    // Important! include the current state.items and the data.items to create a new array with the
    // existing ones and the new one that is requested
    items: [...state.items, ...data.items],
    paging: data.paging,
    isLoading: false
  })),
  on(BaseIssuesActions.getItemsPaginatedRefresh, (state, {data}) => ({
    ...state,
    items: [...data.items],
    paging: data.paging,
    isLoading: false
  })),
  on(BaseIssuesActions.getItemsPaginatedCancel, (state) => ({
    ...state,
    isLoading: false
  })),
  on(BaseIssuesActions.getItemsPaginatedFail, (state) => ({
    ...state,
    isLoading: false
  })),
  on(BaseIssuesActions.getItemsTemplate, (state) => ({
    ...state,
    isLoading: true
  })),
  on(BaseIssuesActions.getItemsTemplateSuccess, (state, {data}) => ({
    ...state,
    itemsTemplate: [...data.items],
    isLoading: false
  })),
  on(BaseIssuesActions.getItemsTemplateFail, (state) => ({
    ...state,
    isLoading: false
  })),
  on(BaseIssuesActions.getItemsTemplatePreviewByIssueType, (state, {id, issueType}) => ({
    ...state,
    shortCutTemplate: {
      issueTemplateId: id,
      contentType: issueType
    },
    selectedTemplate: {
      ...state.selectedTemplate,
      id,
      templateIssueType: issueType
    },
    isLoading: true
  })),
  on(BaseIssuesActions.getItemsTemplatePreview, (state) => ({
    ...state,
    isLoading: true
  })),
  on(BaseIssuesActions.getItemsTemplatePreviewSuccess, (state, {data}) => ({
    ...state,
    selectedTemplate: {
      ...state.selectedTemplate,
      data
    },
    isLoading: true
  })),
  on(BaseIssuesActions.getItemsResourceSuccess, (state, {data}) => ({
    ...state,
    isLoading: true
  })),
  on(BaseIssuesActions.getItemsTemplatePreviewDone, (state) => ({
    ...state,
    isLoading: false
  })),
  on(BaseIssuesActions.getItemsTemplatePreviewFail, (state) => ({
    ...state,
    isLoading: false
  })),
  on(BaseIssuesActions.getById, (state) => ({
    ...state,
    isLoading: true
  })),
  on(BaseIssuesActions.getIssueByIdByIssueType, (state, {id, issueType}) => ({
    ...state,
    selectedIssueItem: {
      id,
      issueType
    },
    isLoading: true
  })),
  on(BaseIssuesActions.getByIdSuccess, (state, {data}) => ({
    ...state,
    issueItem: data,
    isLoading: false
  })),
  on(BaseIssuesActions.getByIdFail, (state) => ({
    ...state,
    isLoading: false
  })),
  on(BaseIssuesActions.prepareFormsToSendTemplatePreview, (state) => ({
    ...state,
    isLoading: true
  })),
  on(BaseIssuesActions.sendTemplatePreview, (state) => ({
    ...state,
    isLoading: true
  })),
  on(BaseIssuesActions.sendTemplatePreviewSuccess, (state) => ({
    ...state,
    savedFile: initialIssuesState.savedFile,
    selectedPicture: initialIssuesState.selectedPicture,
    selectedPictureAsBlob: initialIssuesState.selectedPictureAsBlob,
    isLoading: false
  })),
  on(BaseIssuesActions.thanksPageNavigationBack, (state) => ({
    ...state,
    isLoading: true
  })),
  on(BaseIssuesActions.navigateToNewSpecificTemplate, (state, {templateShortCut}) => ({
    ...state,
    isLoading: true,
    shortCutTemplate: templateShortCut
  })),
  on(BaseIssuesActions.thanksPageNavigationBackDone, (state) => ({
    ...state,
    isLoading: false
  })),
  on(BaseIssuesActions.sendTemplatePreviewFail, (state) => ({
    ...state,
    isLoading: false
  })),
  on(BaseIssuesActions.getStereotypeById, (state, {issueId}) => ({
    ...state,
    selectedIssueId: issueId,
    isLoading: true
  })),
  on(BaseIssuesActions.navigateToTemplatePreview, (state, {templateId, templateIssueType}) => ({
    ...state,
    selectedTemplate: {
      ...state.selectedTemplate,
      id: templateId,
      templateIssueType
    },
    isLoading: false
  })),
  on(BaseIssuesActions.navigateToDetail, (state, {issueId, issueType}) => ({
    ...state,
    selectedIssueItem: {
      id: issueId,
      issueType
    },
    isLoading: true
  })),
  on(BaseIssuesActions.getStereotypeByIdSuccess, (state, {stereotype}) => ({
    ...state,
    selectedStereotype: stereotype,
    isLoading: true
  })),
  on(BaseIssuesActions.getStereotypeByIdFail, (state) => ({
    ...state,
    isLoading: false
  })),
  on(BaseIssuesActions.getStereotypeByEntityType, (state) => ({
    ...state,
    isLoading: true
  })),
  on(BaseIssuesActions.getStereotypeByEntityTypeSuccess, (state) => ({
    ...state,
    isLoading: true
  })),
  on(BaseIssuesActions.getStereotypeByEntityTypeFail, (state) => ({
    ...state,
    isLoading: false
  })),
  on(BaseIssuesActions.formatReadDetailDataFinish, (state, {detailDataFormatted}) => ({
    ...state,
    detailDataFormatted,
    isLoading: false
  })),
  on(BaseIssuesActions.askPictureInput, (state, {isLoadingId}) => ({
    ...state,
    selectedPictureIsLoading: true,
    selectedPictureIsLoadingId: isLoadingId
  })),
  on(BaseIssuesActions.askPictureInputSuccess, (state, {picture, pictureId}) => ({
    ...state,
    selectedPicture: {
      picture,
      pictureId
    },
  })),
  on(BaseIssuesActions.askPictureInputFail, (state) => ({
    ...state,
    selectedPictureIsLoading: false
  })),
  on(BaseIssuesActions.reducePictureSuccess, (state, {blob}) => ({
    ...state,
    selectedPictureAsBlob: blob
  })),
  on(BaseIssuesActions.reducePictureError, (state,) => ({
    ...state,
    selectedPictureIsLoading: false
  })),
  on(BaseIssuesActions.savePictureSuccess, (state, {pictureId, fileId, path}) => ({
    ...state,
    savedFile: {
      pictureId,
      fileId,
      path
    },
    selectedPictureIsLoading: false
  })),
  on(BaseIssuesActions.savePictureFail, (state) => ({
    ...state,
    selectedPictureIsLoading: false
  })),
  on(BaseIssuesActions.convertDateURLToBlob, (state, {isLoadingId}) => ({
    ...state,
    selectedSignatureIsLoading: true,
    selectedSignatureIsLoadingId: isLoadingId
  })),
  on(BaseIssuesActions.convertDateURLToBlobSuccess, (state, {blob}) => ({
    ...state,
    selectedSignatureAsBlob: blob,
  })),
  on(BaseIssuesActions.convertDateURLToBlobFail, (state,) => ({
    ...state,
    selectedSignatureIsLoading: false
  })),
  on(BaseIssuesActions.saveSignatureSuccess, (state, {signatureId}) => ({
    ...state,
    savedSignatureId: signatureId,
    selectedSignatureIsLoading: false
  })),
  on(BaseIssuesActions.saveSignatureFail, (state) => ({
    ...state,
    selectedSignatureIsLoading: false
  })),
  on(BaseIssuesActions.getWinterhalterProductOrderCatalog, (state) => ({
    ...state,
    isLoading: true
  })),
  on(BaseIssuesActions.getWinterhalterProductOrderCatalogSuccess, (state, {data}) => ({
    ...state,
    selectedProductOrderCatalog: data,
    isLoading: true
  })),
  on(BaseIssuesActions.getWinterhalterProductOrderCatalogFail, (state) => ({
    ...state,
    isLoading: true
  }))
);
