import {Injectable} from "@angular/core";
import {LinkIssuesApiService} from "@link/pages/issues/services/link-issues-api.service";
import {Observable} from "rxjs";
import {
  SubmitWinterhalterServiceCallIssueDto,
  WinterhalterServiceCallIssueDto,
  WinterhalterServiceCallIssuePreviewDto
} from "@server-models";

@Injectable({
  providedIn: 'root',
})
export class LinkIssuesWinterhalterServiceCallApiService extends LinkIssuesApiService {

  override getIssueTemplatePreviewListById(id: number): Observable<WinterhalterServiceCallIssuePreviewDto> {
    return super.get<WinterhalterServiceCallIssuePreviewDto>(`/tech/v2/issue/winterhalterservicecall/template/${id}/preview`, undefined);
  }

  override getIssueById(issueId: number): Observable<WinterhalterServiceCallIssueDto> {
    return super.get<WinterhalterServiceCallIssueDto>(`/tech/v2/issue/winterhalterservicecall/${issueId}/recent`);
  }

  override sendIssueTemplatePreviewById(templateId: number, issue: SubmitWinterhalterServiceCallIssueDto): Observable<void> {
    return super.post<void>(`/tech/v2/issue/winterhalterservicecall/template/${templateId}/submitIssue`, undefined, issue)
  }
}
