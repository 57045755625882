import {Component, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {TranslateModule} from "@ngx-translate/core";

@Component({
  selector: 'app-link-error',
  templateUrl: './link-error.page.html',
  styleUrls: ['./link-error.page.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, TranslateModule]
})
export class LinkErrorPage implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }

}
