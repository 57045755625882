import {Injectable} from "@angular/core";
import {CoreBaseEffects} from "@core/core-base.effects";
import {Store} from "@ngrx/store";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {Observable} from "rxjs";
import {AttachmentsActions} from "@shared/components/attachments/store/attachments.actions-type";
import {IAttachmentsFileAction} from "@shared/components/attachments/interfaces/attachments-file-action.interface";
import {
  IAttachmentsFileRequestAction
} from "@shared/components/attachments/interfaces/attachments-file-request-action.interface";
import {
  IAttachmentKnowledgeArticle
} from "@shared/components/attachments/interfaces/attachment-knowledge-article.interface";
import {
  IAttachmentKnowledgeArticleResponse
} from "@shared/components/attachments/interfaces/attachment-knowledge-article-response.interface";

@Injectable({
  providedIn: 'root'
})
export abstract class AttachmentsEffectsBase extends CoreBaseEffects {

  protected constructor(store: Store, rawActions$: Actions) {
    super(store, rawActions$);
  }

  abstract getFile(action$: Observable<IAttachmentsFileRequestAction>): Observable<IAttachmentsFileRequestAction | any>;

  abstract getFileSuccess(action$: Observable<IAttachmentsFileAction>): Observable<IAttachmentsFileAction>;

  abstract openLink(action$: Observable<{ id: number, url: string }>): Observable<{
    id: number,
    url: string
  } | any>;

  abstract openLinkSuccess(action$: Observable<{ id: number, url: string }>): Observable<{
    id: number,
    url: string
  }>;

  abstract getKnowledgeArticle(action$: Observable<IAttachmentKnowledgeArticle>): Observable<IAttachmentKnowledgeArticle | any>;

  abstract getKnowledgeArticleSuccess(action$: Observable<IAttachmentKnowledgeArticleResponse>): Observable<IAttachmentKnowledgeArticleResponse>;

  getPath$ = createEffect(() => this.actions().pipe(
    ofType(AttachmentsActions.getFile),
    action => this.getFile(action)
  ));

  getPathSuccess$ = createEffect(() => this.actions().pipe(
    ofType(AttachmentsActions.getFileSuccess),
    action => this.getFileSuccess(action)
  ), { dispatch: false });

  openLink$ = createEffect(() => this.actions().pipe(
    ofType(AttachmentsActions.openLink),
    action => this.openLink(action)
  ));

  openLinkSuccess$ = createEffect(() => this.actions().pipe(
    ofType(AttachmentsActions.openLinkSuccess),
    action => this.openLinkSuccess(action)
  ), { dispatch: false });

  getKnowledgeArticle$ = createEffect(() => this.actions().pipe(
    ofType(AttachmentsActions.getKnowledgeArticle),
    action => this.getKnowledgeArticle(action)
  ));

  getKnowledgeArticleSuccess$ = createEffect(() => this.actions().pipe(
    ofType(AttachmentsActions.getKnowledgeArticleSuccess),
    action => this.getKnowledgeArticleSuccess(action)
  ), { dispatch: false });


}
