/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum SettingType {
    Base = '0',
    General = 1,
    Ticket = 2,
    Mission = 3,
}
