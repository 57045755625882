import {CoreAppState} from "./core-app.state";
import {createReducer, on} from "@ngrx/store";
import {appInit} from "./core-app.actions";

export const initialState: CoreAppState = <CoreAppState>{};

export const appStore = createReducer(
  initialState,
  on(appInit, (state) => ({
    isLoaded: true
  }))
);
