import {Injectable} from "@angular/core";
import {
  CustomPropertyDto,
  CustomSetReferenceDto,
  FilledCustomValueDto,
  IssueResourceSelectorKind,
  PrioritySimpleDto,
  ResourceProfileDto,
  StereotypeUsageDto,
  SubmitTicketIssueDto,
  TicketIssueDto,
  TicketIssuePreviewDto
} from "@server-models";
import {IssueFormattedDetailData} from "@shared/components/issues/interfaces/issue-formatted-detail-data.interface";
import {FormGroup} from "@angular/forms";
import {IssueBaseStereotypeService} from "@shared/components/issues/services/stereotype/stereotype.service";

@Injectable({
  providedIn: 'root',
})
export class IssuesBaseTicketService extends IssueBaseStereotypeService {

  prepareFormIssueDto(formGroup: FormGroup, templatePreviewDto: TicketIssuePreviewDto, resourceDto?: ResourceProfileDto): SubmitTicketIssueDto {
    const priorityFormGroup = formGroup.get('Priority') as FormGroup;
    const priority = this.findPriorityValue(priorityFormGroup, templatePreviewDto.priorities!);

    const customPropertyValues = this.prepareCustomValues(formGroup); // to build the customValues
    const issueStereotype: StereotypeUsageDto = templatePreviewDto.issueStereotype!;

    const sortedCustomPropertySets: CustomSetReferenceDto[] = this._sortCustomPropertySets(issueStereotype);
    const stereotypeCustomProperties: CustomPropertyDto[] = this._flattenCustomProperties(sortedCustomPropertySets);
    const mutateCustomPropertyValues: FilledCustomValueDto[] = this._prepareCustomPropertyValues(customPropertyValues, issueStereotype, stereotypeCustomProperties);

    const resourceKind = this._checkResourceKind(templatePreviewDto.resourceSelectorKind!) ? resourceDto : undefined;

    return {
      title: templatePreviewDto.name,
      tenantId: issueStereotype?.tenantId,
      stereotypeId: issueStereotype?.stereotypeId,
      stereotypeRowVersion: issueStereotype?.rowVersion,
      customPropertyValues: mutateCustomPropertyValues,
      priority,
      resource: resourceKind
    };
  }

  _checkResourceKind(resourceOption: IssueResourceSelectorKind): boolean {
    let displayResource = true;
    if (resourceOption === IssueResourceSelectorKind.Selection) {
      displayResource = true;
    } else if (resourceOption === IssueResourceSelectorKind.None) {
      displayResource = false;
    }
    return displayResource;
  }

  findPriorityValue(priorityForm: FormGroup, priorities: PrioritySimpleDto[]): PrioritySimpleDto {
    const valueToFind = priorityForm.value;
    return priorities.find(p => p?.priorityId === valueToFind)!
  }

  getAdditionalFormattedData(issueDto: TicketIssueDto): Partial<IssueFormattedDetailData> {
    const ticketDetail = issueDto.ticket;
    return {
      ticket: ticketDetail
    };
  }
}
