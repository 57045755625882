import {Injectable} from "@angular/core";
import {
  AppEntityType,
  CustomDefaultCompleteDto,
  CustomPictureValueDto,
  CustomPropertyCompleteDto,
  CustomPropertyDto,
  CustomPropertyType,
  CustomSetReferenceDto,
  CustomSetReferenceListDto,
  CustomSignatureValueDto,
  CustomValueCompleteDto,
  FileDto,
  FilledCustomValueDto,
  IssueType,
  StereotypeDto
} from "@server-models";
import {TIssueDto} from "@shared/components/issues/types/issue-dto.type";
import {
  ICustomPropertyStructureFormatted
} from "@shared/components/custom-property-form/interfaces/custom-property-structure-formatted.interface";
import {FormBuilder, FormControl, FormGroup, ValidatorFn, Validators} from "@angular/forms";
import {TranslateService} from "@ngx-translate/core";
import {SharedDateHelperService} from "@shared/services/shared-date-helper.service";
import {
  SharedCustomPropertyService
} from "@shared/services/stereotype/custom-properties/shared-custom-property.service";
import {
  IAdditionalDetails
} from "@shared/components/custom-property-form/interfaces/structure-formatted-additional-details.interface";
import {
  ICustomPropertySetFormatted
} from "@shared/components/custom-property-form/interfaces/custom-property-set-formatted.interface";
import {
  ICustomPropertyFormatted
} from "@shared/components/custom-property-form/interfaces/custom-property-formatted.interface";
import {invalidZeroTimeValidator} from "@shared/services/stereotype/shared-time-of-day-zero.validator";
import {
  signaturePictureRequiredValidator
} from "@shared/services/stereotype/shared-signature-picture-required.validator";

@Injectable({
  providedIn: 'root',
})
export class SharedStereotypeService {

  constructor(
    protected _fb: FormBuilder,
    protected _translateService: TranslateService,
    protected _dateHelperService: SharedDateHelperService,
    protected _customPropertyService: SharedCustomPropertyService
  ) {
  }

  customPropertiesFormattedFromStereotype(
    id: number,
    headerTitle: string,
    type: IssueType | AppEntityType | any,
    customPropertyValues: FilledCustomValueDto[],
    stereotypeDto: StereotypeDto,
    issueDtoType?: TIssueDto,
    additionalDataFn?: (issueDtoType: TIssueDto) => Partial<IAdditionalDetails>
  ): ICustomPropertyStructureFormatted {
    let customPropertySetsFormatted: ICustomPropertySetFormatted[] = [];

    for (const customPropertySet of stereotypeDto!.customPropertySets!) {
      const customPropertySetName = customPropertySet.customPropertySet?.name;
      const customPropertySetId = customPropertySet.customPropertySet?.customPropertySetId;
      const customPropertySetPosition = customPropertySet.position;
      const customPropertiesFormatted: ICustomPropertyFormatted[] = [];

      for (const customPropertyValue of customPropertyValues) {
        const propertyId: number | undefined = customPropertyValue.propertyId;

        const properties: CustomPropertyDto[] = customPropertySet.customPropertySet!.properties!;
        const correspondingProperty: CustomPropertyCompleteDto | undefined = properties.find((property: CustomPropertyDto) => property.customPropertyId === propertyId);
        const customValue: CustomValueCompleteDto = customPropertyValue!.customValues![0].customValue!;

        if (correspondingProperty) {
          switch (correspondingProperty.type) {
            case CustomPropertyType.Info: {
              const formattedValueInfo: ICustomPropertyFormatted = {
                title: correspondingProperty.name!,
                value: correspondingProperty.content,
                type: correspondingProperty.type!,
                propertyId: correspondingProperty.customPropertyId!,
                position: correspondingProperty.position!,
                kind: correspondingProperty.infoKind
              }
              customPropertiesFormatted.push(formattedValueInfo);
              break;
            }
            case CustomPropertyType.Dropdown: {
              const formattedValueInfo: ICustomPropertyFormatted = {
                title: correspondingProperty.name!,
                value: this.getDropdownContent(customValue.ownValue, correspondingProperty.defaultValues!),
                propertyId: correspondingProperty.customPropertyId!,
                type: correspondingProperty.type!,
                position: correspondingProperty.position!,
              }
              customPropertiesFormatted.push(formattedValueInfo);
              break;
            }
            case CustomPropertyType.Signature:
            case CustomPropertyType.Picture: {
              const formattedValueInfo: ICustomPropertyFormatted = {
                title: correspondingProperty.name!,
                fileValue: (customValue as CustomSignatureValueDto | CustomPictureValueDto).ownValueFile,
                value: customValue.ownValue,
                propertyId: correspondingProperty.customPropertyId!,
                type: correspondingProperty.type!,
                position: correspondingProperty.position!,
              }
              customPropertiesFormatted.push(formattedValueInfo);
              break;
            }
            default: {
              const formattedValue: ICustomPropertyFormatted = {
                title: correspondingProperty.name!,
                value: customValue.ownValue,
                position: correspondingProperty.position!,
                propertyId: correspondingProperty.customPropertyId!,
                type: correspondingProperty.type!,
              };
              customPropertiesFormatted.push(formattedValue);
            }
          }
        }
      }

      customPropertiesFormatted.sort((a, b) => a.position - b.position);

      customPropertySetsFormatted.push({
        name: customPropertySetName!,
        propertySetId: customPropertySetId!,
        position: customPropertySetPosition!,
        customPropertiesFormatted: customPropertiesFormatted
      });

      customPropertySetsFormatted.sort((a, b) => a.position - b.position);
    }

    const baseResult: ICustomPropertyStructureFormatted = {
      structureId: id,
      headerTitle: headerTitle,
      tenantId: stereotypeDto.tenantId!,
      type: type,
      lastModified: issueDtoType?.lastModified,
      customStereotypePropertySets: stereotypeDto.customPropertySets!,
      customPropertySets: customPropertySetsFormatted
    };

    const additionalData = additionalDataFn ? additionalDataFn(issueDtoType!) : {};

    return {
      ...baseResult,
      additionalDetails: { ...additionalData },
    };
  }

  prepareCustomValues(constructedForm: FormGroup): CustomValueCompleteDto[] {
    const customPropertyValues: FilledCustomValueDto[] = [];

    Object.keys(constructedForm.controls).forEach(groupName => {
      const group = constructedForm.controls[groupName] as FormGroup;
      const customValuesComplete: CustomValueCompleteDto[] = [];

      Object.keys(group.controls).forEach(key => {
        const customValue: CustomValueCompleteDto = {
          type: this._customPropertyService.getTypeProperty(key),
          ownValue: group.controls[key!].value,
        };

        if (customValue.type === CustomPropertyType.TimeOfDay) {
          if (customValue.ownValue === undefined) {
            customValue.ownValue = null;
          } else {
            customValue.ownValue = this._dateHelperService.convertISOToMinutes(customValue.ownValue)
          }
        }

        customValuesComplete.push(customValue);
      });

      customPropertyValues.push({
        customValues: customValuesComplete
      });
    });

    this._customPropertyService.resetCustomTypes();
    return customPropertyValues.flatMap(value => value.customValues!);
  }

  /**
   * @name getDropdownContent
   * @description
   * find the dropdown value
   * @memberof SharedStereotypeService
   * @param selectedId
   * @param options
   */
  getDropdownContent(selectedId: number, options: CustomDefaultCompleteDto[]): string {
    const result = options.find((props: any) => props.customValueId === selectedId)!;
    return result?.value;
  }

  buildFormGroupsCustomProperties(customPropertySets: CustomSetReferenceDto[],
                                  customFilledValues?: FilledCustomValueDto[]): FormGroup {
    const parentForm = this._fb.group({});

    customPropertySets.forEach((propertySet: any) => {
      const properties = propertySet.customPropertySet?.properties || [];

      properties.sort((a: any, b: any) => a.position! - b.position!);

      const customValueMap = new Map<any, { ownValue: number; ownValueFile?: FileDto }>();

      const group = this._fb.group({});

      if (customFilledValues && customFilledValues?.length > 0) {
        customFilledValues.forEach(item => {
          item.customValues!.forEach(custom => {
            const customValue = custom.customValue as CustomValueCompleteDto;
            const valueObject = {
              ownValue: customValue.ownValue,
              ownValueFile: (customValue as CustomSignatureValueDto | CustomPictureValueDto).ownValueFile
            };

            customValueMap.set(item.propertyId!, valueObject);
          });
        });
      }

      properties.forEach((value: CustomPropertyDto) => {
        const propertyId = value.customPropertyId;
        const controlName = value.name! + propertyId!;
        const validators = this.checkValidators(value);
        const defaultValue: CustomDefaultCompleteDto = value.defaultValues![0];
        let newValue: { ownValue: any; ownValueFile?: FileDto } | undefined;

        if (value.type === CustomPropertyType.Checkbox) {
          if (value.isRequired && !defaultValue.value) {
            defaultValue.value = null;
          }
        }

        newValue = customValueMap.get(propertyId!);

        if (value.type === CustomPropertyType.Signature || value.type === CustomPropertyType.Picture) {
          const valueSignatureToAdd = {
            ownValue: newValue?.ownValue ? newValue.ownValue : defaultValue?.value,
            ownFileValue: newValue?.ownValueFile ? newValue.ownValueFile : undefined
          }
          group.addControl(controlName, new FormControl(valueSignatureToAdd, validators));
        } else if (value.type === CustomPropertyType.Dropdown) {
          const dropDownDefaultValues = value.defaultValues as CustomDefaultCompleteDto[];
          const dropDownSelected = dropDownDefaultValues?.find(defaultValue => defaultValue.isSelected);
          const valueToAdd = newValue?.ownValue
            ?? dropDownSelected?.customValueId
            ?? undefined;
          group.addControl(controlName, new FormControl(valueToAdd, validators));
        } else {
          const valueToAdd = newValue?.ownValue ? newValue.ownValue : defaultValue?.value;
          group.addControl(controlName, new FormControl(valueToAdd, validators));
        }

        this._customPropertyService.addTypeProperty(controlName, value.type!);
      });

      parentForm.addControl(propertySet.customPropertySet?.name!, group);
    });

    return parentForm;
  }

  /**
   * @name checkValidators
   * @description
   * check validators for building form with validators
   * @memberof SharedStereotypeService
   * @param properties
   */
  checkValidators(properties: CustomPropertyCompleteDto): ValidatorFn[] {
    let validators: ValidatorFn[] = [];

    if (properties?.maxLength) {
      validators.push(Validators.maxLength(properties?.maxLength));
    }
    if (properties?.minLength) {
      validators.push(Validators.minLength(properties?.minLength));
    }
    if (properties?.isRequired) {
      validators.push(Validators.required);
      if (properties?.type === CustomPropertyType.TimeOfDay) {
        validators.push(invalidZeroTimeValidator(this._dateHelperService));
      }
      if (properties?.type === CustomPropertyType.Signature || properties?.type === CustomPropertyType.Picture) {
        validators.push(signaturePictureRequiredValidator());
      }
    }
    if (properties?.type === CustomPropertyType.Email) {
      validators.push(Validators.email);
    }
    if (properties?.maxValue) {
      validators.push(Validators.max(properties.maxValue));
    }
    if (properties?.minValue) {
      validators.push(Validators.min(properties.minValue));
    }

    return validators;
  }

  sortCustomPropertyListSets(customPropertySets: CustomSetReferenceListDto[]): CustomSetReferenceListDto[] {
    return customPropertySets.map(customPropertySet => {
      customPropertySet.customPropertySet?.properties?.sort((a, b) => a.position! - b.position!);
      return customPropertySet;
    });
  }

  /**
   * @name sortCustomPropertySets
   * @description
   * sort customPropertySets
   * @memberof SharedStereotypeService
   * @param customPropertySets
   */
  sortCustomPropertySets(customPropertySets: CustomSetReferenceDto[]): CustomSetReferenceDto[] {
    return customPropertySets.map(customPropertySet => {
      customPropertySet.customPropertySet?.properties?.sort((a, b) => a.position! - b.position!);
      return customPropertySet;
    });
  }

  /**
   * @name flattenCustomProperties
   * @description
   * flat custom property sets
   * @memberof SharedStereotypeService
   * @param sortedCustomPropertySets
   */
  flattenCustomProperties(sortedCustomPropertySets: CustomSetReferenceDto[]): CustomPropertyDto[] {
    return sortedCustomPropertySets.flatMap(propertySet => {
      return propertySet.customPropertySet?.properties || []
    });

  }

  /**
   * @name prepareCustomPropertyValues
   * @description
   * format custom values
   * @memberof SharedStereotypeService
   * @param customValues
   * @param tenantId
   * @param stereotypeCustomProperties
   */
  prepareCustomPropertyValues(customValues: any[], tenantId: number,
                              stereotypeCustomProperties: CustomPropertyDto[]): FilledCustomValueDto[] {
    return customValues.map((customValue, index) => {
      if (customValue.type === CustomPropertyType.Signature || customValue.type === CustomPropertyType.Picture) {

        customValue.ownValue = customValue.ownValue ? customValue.ownValue.ownValue : customValue.ownValue;
      }
      return ({
        customValues: [{ customValue }],
        tenantId: tenantId,
        propertyId: stereotypeCustomProperties[index].customPropertyId
      })
    });
  }
}
