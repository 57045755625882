import {Injectable} from "@angular/core";
import {AlertController} from "@ionic/angular";
import {from, Observable} from "rxjs";
import {Store} from "@ngrx/store";
import {TranslateService} from "@ngx-translate/core";
import {IAlertControllerCreate} from "@shared/interfaces/alert-controller-create.interface";
import {initLogout} from "@shared/stores/login-base/store/shared-login-base.actions";
import {SharedLoginBaseActions} from "@shared/stores/login-base/store/shared-login-base.actions-type";
import {Clients} from "@server-models";

@Injectable({
  providedIn: 'root',
})
export class AlertBaseControllerService {
  constructor(
    private _alertController: AlertController,
    private _store: Store,
    private _translateService: TranslateService) {
  }

  /**
   * @name _presentRefreshToken
   * @description
   * Return the promise alert for isRefresh token
   * @memberof AlertBaseControllerService
   * @return {Promise<void>}
   * @private
   */
  private async _presentRefreshToken(clientType: Clients): Promise<void> {
    const alert = await this._alertController.create({
      header: this._translateService.instant("GENERAL.ALERT.HEADER"),
      subHeader: this._translateService.instant("GENERAL.ALERT.SUBHEADER"),
      message: this._translateService.instant("GENERAL.ALERT.MESSAGE"),
      buttons: [
        {
          text: this._translateService.instant("GENERAL.ALERT.BUTTONS.LOGOUT"),
          role: 'cancel',
          handler: () => {
            this._store.dispatch(initLogout());
          }
        },
        {
          text: this._translateService.instant("GENERAL.ALERT.BUTTONS.RETRY"),
          role: 'retry',
          handler: () => {
            this._store.dispatch(SharedLoginBaseActions.loginRefresh({ clientType }));
          }
        }
      ],
    });

    await alert.present();
  }

  /**
   * @name _present
   * @description
   * async function to create the alert
   * @memberof AlertBaseControllerService
   * @param alertCreate
   * @private
   * @returns {Promise<void>}
   */
  private async _present(alertCreate: IAlertControllerCreate): Promise<void> {
    const alert = await this._alertController.create({
      header: alertCreate.header,
      subHeader: alertCreate.subHeader,
      message: alertCreate.message,
      buttons: alertCreate.buttons,
    });

    await alert.present();
  }

  /**
   * @name observableAlert
   * @description
   * Return the promise alert as Observable by param
   * @memberof AlertBaseControllerService
   * @param alertCreate
   */
  observableAlert(alertCreate: IAlertControllerCreate): Observable<void> {
    return from(this._present(alertCreate));
  }

  /**
   * @name observableRefreshToken
   * @description
   * Return the promise alert as Observable
   * @memberof AlertBaseControllerService
   * @return {Observable<void>}
   */
  observableRefreshToken(clientType: Clients): Observable<void> {
    return from(this._presentRefreshToken(clientType));
  }
}
