<form [formGroup]="customValues" (ngSubmit)="formModified()" (change)="formModified()">
  <ng-container *ngIf="customPropertySets" formGroupName="CustomValues">
    <ng-container *ngFor="let groupSortedIndex of getSortedIndexes()">
      <ion-card [ngClass]="{'margin-remove': hasNoMargin}" [formGroupName]="getGroupNameByIndex(groupSortedIndex)">
        <ion-card-header>
          <ion-text color="dark">
            <h4 class="ion-no-margin">
              {{ getPropertyName(groupSortedIndex) }}
            </h4>
          </ion-text>
        </ion-card-header>
        <ion-card-content class="space-reduce"
                          *ngFor="let controlName of getControlNames(getGroupNameByIndex(groupSortedIndex)); let controlIndex = index">
          <app-shared-custom-property-form-input
            [inputProperty]="getProperty(groupSortedIndex, controlName)"
            [formControl]="getControlFromGroup(getGroupNameByIndex(groupSortedIndex), controlName)"
            [index]="controlIndex">
          </app-shared-custom-property-form-input>
        </ion-card-content>
      </ion-card>
    </ng-container>
  </ng-container>
</form>
