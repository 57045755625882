import {Component, Input, OnInit} from "@angular/core";
import {IonicModule} from "@ionic/angular";
import {CommonModule} from "@angular/common";
import {TranslateModule} from "@ngx-translate/core";
import {AttachmentInternalDto} from "@shared/components/attachments/interfaces/attachment-internal-dto.interface";
import {AttachmentsService} from "@shared/components/attachments/services/attachments.service";
import {AttachmentTypes} from "@server-models";

@Component({
  selector: 'app-attachments-row',
  templateUrl: './attachments-row.component.html',
  styleUrls: ['./attachments-row.component.scss'],
  imports: [
    IonicModule,
    CommonModule,
    TranslateModule
  ],
  standalone: true
})
export class AttachmentsRowComponent implements OnInit{
  @Input() mappedAttachments: AttachmentInternalDto[];
  @Input() showHeader: boolean;

  attachmentType: typeof AttachmentTypes = AttachmentTypes;

  constructor(
    public attachmentsService: AttachmentsService
  ) {
    this.showHeader = true;
    this.mappedAttachments = [];
  }
  ngOnInit() {

  }
}
