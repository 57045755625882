import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {CommonModule} from "@angular/common";
import {IonicModule} from "@ionic/angular";
import {TranslateModule} from "@ngx-translate/core";
import {FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {SharedCustomPropertyFormInputTextComponent} from "@shared/components/custom-property-form/components/input/components/text/shared-custom-property-form-input-text.component";
import {
  WinterhalterServiceCallCategoryPreviewDto,
  WinterhalterServiceCallIssuePreviewDto,
  WinterhalterServiceCallProblemPreviewDto
} from "@server-models";
import {
  SharedCustomPropertyFormInputMultiTextComponent
} from "@shared/components/custom-property-form/components/input/components/multi-text/shared-custom-property-form-input-multi-text.component";
import {
  SharedCustomPropertyFormInputEmailComponent
} from "@shared/components/custom-property-form/components/input/components/email/shared-custom-property-form-input-email.component";
import {
  SharedCustomPropertyFormInputDropdownComponent
} from "@shared/components/custom-property-form/components/input/components/dropdown/shared-custom-property-form-input-dropdown.component";

@Component({
  selector: 'app-shared-issue-custom-form-winterhalter-service-call',
  templateUrl: './shared-issue-custom-form-winterhalter-service-call.component.html',
  styleUrls: ['./shared-issue-custom-form-winterhalter-service-call.component.scss'],
  standalone: true,
  imports: [
    IonicModule,
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    SharedCustomPropertyFormInputTextComponent,
    SharedCustomPropertyFormInputMultiTextComponent,
    SharedCustomPropertyFormInputEmailComponent,
    SharedCustomPropertyFormInputDropdownComponent
  ]
})
export class SharedIssueCustomFormWinterhalterServiceCallComponent implements OnInit, OnChanges {
  @Input() serviceCalLData: WinterhalterServiceCallIssuePreviewDto;
  @Output() inputChange = new EventEmitter<FormGroup>();

  categoryList: WinterhalterServiceCallCategoryPreviewDto[];
  selectedCategoryProblems: WinterhalterServiceCallProblemPreviewDto[] = [];
  categoryForm: FormGroup;


  constructor(
    private _fb: FormBuilder
  ) {
    this.serviceCalLData = {};
    this.categoryList = [];
    this.categoryForm = this._fb.group({
      category: ['', Validators.required],
      problem: ['', Validators.required],
      message: ['', Validators.required],
      contactFirstName: ['', Validators.required],
      contactLastName: ['', Validators.required]
    });
  }

  ngOnInit() {
    this.categoryList = this.serviceCalLData.categories!;
  }

  getControl(controlName: string) {
    return this.categoryForm.get(controlName) as FormControl;
  }

  isRequired(controlName: string): boolean {
    const control = this.categoryForm.get(controlName);
    if (control) {
      const validator = control.validator ? control.validator({} as any) : null;
      return !!(validator && validator['required']);
    }
    return false;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['serviceCalLData']) {
      this.formModified();
    }
  }

  formModified(): void {
    this.inputChange.emit(this.categoryForm);
  }

  onCategoryChange(event: any) {
    const selectedCategoryKey = event.detail.value;

    const selectedCategory = this.categoryList.find(category => category.key === selectedCategoryKey);

    if (selectedCategory) {
      this.selectedCategoryProblems = selectedCategory.problems!;
      this.categoryForm.patchValue({ problem: '' });
    } else {
      this.selectedCategoryProblems = [];
    }
  }
}
