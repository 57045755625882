<ng-container *ngIf="(detailDataFormatted$ | async) as detailDataFormatted">
  <ion-card *ngIf="detailDataFormatted.additionalDetails!.controlMessage! as controlMessage" class="right-padding">
    <ng-container *ngIf="controlMessage.reason" [ngSwitch]="controlMessage.reason.type!">
      <ng-container *ngSwitchCase="IssueReasonType.ReachedConsumption">
        <ng-container *ngIf="checkReasonType(controlMessage, IssueReasonType.ReachedConsumption)">
          <ng-container *ngIf="reasonConsumption" [ngSwitch]="reasonConsumption.triggerType">
            <ng-container *ngSwitchCase="ControlTriggerType.OutOfRange">
              <ion-card-header>
                <ion-text color="dark">
                  <h4 class="ion-no-margin">
                    {{ 'COMPONENTS.ISSUES.SERVICES.CONTROL_MESSAGE.REASON_TYPE.CONSUMPTION.TITLE' | translate }}:
                  </h4>
                </ion-text>
                <ion-text color="dark">
                  <h4 class="ion-no-margin">
                    {{ 'COMPONENTS.ISSUES.SERVICES.CONTROL_MESSAGE.REASON_TYPE.CONSUMPTION.TRIGGER_TYPE.RANGE.TITLE' | translate }}
                  </h4>
                </ion-text>
              </ion-card-header>
              <ion-item>
                <ion-label class="set-color">
                  <h3
                    [innerText]="'COMPONENTS.ISSUES.SERVICES.CONTROL_MESSAGE.REASON_TYPE.CONSUMPTION.TRIGGER_TYPE.RANGE.PROPERTIES.NAME' | translate"></h3>
                  <p>{{ reasonConsumption.consumptionCode?.displayName }}</p>
                </ion-label>
              </ion-item>
              <ion-item>
                <ion-label class="set-color">
                  <h3
                    [innerText]="'COMPONENTS.ISSUES.SERVICES.CONTROL_MESSAGE.REASON_TYPE.CONSUMPTION.TRIGGER_TYPE.RANGE.PROPERTIES.EXTERNAL_ID' | translate"></h3>
                  <p>{{ reasonConsumption.consumptionCode?.externalId }}</p>
                </ion-label>
              </ion-item>
              <ion-item>
                <ion-label class="set-color">
                  <h3
                    [innerText]="'COMPONENTS.ISSUES.SERVICES.CONTROL_MESSAGE.REASON_TYPE.CONSUMPTION.TRIGGER_TYPE.RANGE.PROPERTIES.ALLOWED_RANGE' | translate"></h3>
                  <p>{{ reasonConsumption.lowerLimit }}
                    - {{ reasonConsumption.upperLimit }} {{ reasonConsumption.consumptionCode?.unit }}</p>
                </ion-label>
              </ion-item>
              <ion-item>
                <ion-label class="set-color">
                  <h3
                    [innerText]="'COMPONENTS.ISSUES.SERVICES.CONTROL_MESSAGE.REASON_TYPE.CONSUMPTION.TRIGGER_TYPE.RANGE.PROPERTIES.TRIGGERED_VALUE' | translate"></h3>
                  <p>{{ reasonConsumption.triggerValue }} {{ reasonConsumption.consumptionCode?.unit }}</p>
                </ion-label>
              </ion-item>

            </ng-container>
            <ng-container *ngSwitchCase="ControlTriggerType.Stepped">
              <ion-card-header>
                <ion-text color="dark">
                  <h4 class="ion-no-margin">
                    {{ 'COMPONENTS.ISSUES.SERVICES.CONTROL_MESSAGE.REASON_TYPE.CONSUMPTION.TITLE' | translate }}:
                  </h4>
                </ion-text>
                <ion-text color="dark">
                  <h4 class="ion-no-margin">
                    {{ 'COMPONENTS.ISSUES.SERVICES.CONTROL_MESSAGE.REASON_TYPE.CONSUMPTION.TRIGGER_TYPE.STEPPED.TITLE' | translate }}
                  </h4>
                </ion-text>
              </ion-card-header>
              <ion-item>
                <ion-label class="set-color">
                  <h3
                    [innerText]="'COMPONENTS.ISSUES.SERVICES.CONTROL_MESSAGE.REASON_TYPE.CONSUMPTION.TRIGGER_TYPE.STEPPED.PROPERTIES.NAME' | translate"></h3>
                  <p>{{ reasonConsumption.consumptionCode?.displayName }}</p>
                </ion-label>
              </ion-item>
              <ion-item>
                <ion-label class="set-color">
                  <h3
                    [innerText]="'COMPONENTS.ISSUES.SERVICES.CONTROL_MESSAGE.REASON_TYPE.CONSUMPTION.TRIGGER_TYPE.STEPPED.PROPERTIES.EXTERNAL_ID' | translate"></h3>
                  <p>{{ reasonConsumption.consumptionCode?.externalId }}</p>
                </ion-label>
              </ion-item>
              <ion-item>
                <ion-label class="set-color">
                  <h3
                    [innerText]="'COMPONENTS.ISSUES.SERVICES.CONTROL_MESSAGE.REASON_TYPE.CONSUMPTION.TRIGGER_TYPE.STEPPED.PROPERTIES.LIMIT' | translate"></h3>
                  <p>{{ reasonConsumption.limit }} {{ reasonConsumption.consumptionCode?.unit }}</p>
                </ion-label>
              </ion-item>
              <ion-item>
                <ion-label class="set-color">
                  <h3
                    [innerText]="'COMPONENTS.ISSUES.SERVICES.CONTROL_MESSAGE.REASON_TYPE.CONSUMPTION.TRIGGER_TYPE.STEPPED.PROPERTIES.TRIGGERED_VALUE' | translate"></h3>
                  <p>{{ reasonConsumption.triggerValue }} {{ reasonConsumption.consumptionCode?.unit }}</p>
                </ion-label>
              </ion-item>
            </ng-container>
          </ng-container>
        </ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="IssueReasonType.ReachedError">
        <ng-container *ngIf="checkReasonType(controlMessage, IssueReasonType.ReachedError)">
          <ng-container *ngIf="reasonErrorCode">
            <ion-card-header>
              <ion-text color="dark">
                <h4 class="ion-no-margin">
                  {{ 'COMPONENTS.ISSUES.SERVICES.CONTROL_MESSAGE.REASON_TYPE.ERROR.TITLE' | translate }}
                </h4>
              </ion-text>
            </ion-card-header>
            <ion-item>
              <ion-label class="set-color">
                <h3
                  [innerText]="'COMPONENTS.ISSUES.SERVICES.CONTROL_MESSAGE.REASON_TYPE.ERROR.PROPERTIES.NAME' | translate"></h3>
                <p>{{ reasonErrorCode.errorCode?.displayName }}</p>
              </ion-label>
            </ion-item>
            <ion-item>
              <ion-label class="set-color">
                <h3
                  [innerText]="'COMPONENTS.ISSUES.SERVICES.CONTROL_MESSAGE.REASON_TYPE.ERROR.PROPERTIES.EXTERNAL_ID' | translate"></h3>
                <p>{{ reasonErrorCode.errorCode?.externalId }}</p>
              </ion-label>
            </ion-item>
          </ng-container>

        </ng-container>

      </ng-container>
      <ng-container *ngSwitchCase="IssueReasonType.ReachedFixedTime">
        <ng-container *ngIf="checkReasonType(controlMessage, IssueReasonType.ReachedFixedTime!)">
          <ng-container *ngIf="reasonFixedTime">
            <ion-card-header>
              <ion-text color="dark">
                <h4 class="ion-no-margin">
                  {{ 'COMPONENTS.ISSUES.SERVICES.CONTROL_MESSAGE.REASON_TYPE.FIXED_TIME.TITLE' | translate }}
                </h4>
              </ion-text>
            </ion-card-header>
            <ion-item *ngIf="convertFrequency(reasonFixedTime.frequency!) as frequency">
              <ion-label class="set-color">
                <h3
                  [innerText]="'COMPONENTS.ISSUES.SERVICES.CONTROL_MESSAGE.REASON_TYPE.FIXED_TIME.PROPERTIES.FREQUENCY.NAME' | translate"></h3>
                <p class="frequency-container">
              <span *ngIf="frequency.years > 0">
                {{ frequency.years }} {{ formatFrequencyAmount('COMPONENTS.ISSUES.SERVICES.CONTROL_MESSAGE.REASON_TYPE.FIXED_TIME.PROPERTIES.FREQUENCY.TIMES.YEARS' | translate, frequency.years) }}
              </span>
                  <span *ngIf="frequency.weeks > 0">
                {{ frequency.weeks }} {{ formatFrequencyAmount('COMPONENTS.ISSUES.SERVICES.CONTROL_MESSAGE.REASON_TYPE.FIXED_TIME.PROPERTIES.FREQUENCY.TIMES.WEEKS' | translate, frequency.weeks) }}
              </span>
                  <span *ngIf="frequency.days > 0">
                {{ frequency.days }} {{ formatFrequencyAmount('COMPONENTS.ISSUES.SERVICES.CONTROL_MESSAGE.REASON_TYPE.FIXED_TIME.PROPERTIES.FREQUENCY.TIMES.DAYS' | translate, frequency.days) }}
              </span>
                </p>
              </ion-label>
            </ion-item>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
  </ion-card>
</ng-container>
