import {Injectable} from "@angular/core";
import {Observable, of} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class SharedCapacitorBrowserService {

  constructor() {
  }

  open(url: string): Observable<void> {
    return of(void window.open(url, '_system'));
  }

}
