import {Injectable} from "@angular/core";
import {SharedIssueBaseService} from "@shared/components/issues/services/shared-issue-base.service";
import {TIssueDtoStereotype, TIssueDto} from "@shared/components/issues/types/issue-dto.type";

@Injectable({
  providedIn: 'root',
})
export abstract class SharedIssueStereotypeBaseService extends SharedIssueBaseService {

  isIssueDtoStereotypeType(data: TIssueDto): data is TIssueDtoStereotype {
    return (
      'stereotypeId' in data &&
      'customPropertyValues' in data
    );
  }
}
