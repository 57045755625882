<ng-container *ngIf="winterhalterOrder$ | async as orderInfo">
  <ion-row>
    <ion-col size-xs="12" size-sm="4" size-md="4" size-lg="4">
      <app-shared-custom-property-read-only-input-text
        [title]="'COMPONENTS.ISSUES.SERVICES.WINTERHALTER.ORDER_NR' | translate"
        [value]="orderInfo.orderId ?? ('COMPONENTS.MISSIONS.TREE_MENU.EMPTY' | translate)">
      </app-shared-custom-property-read-only-input-text>
    </ion-col>
    <ion-col size-xs="12" size-sm="4" size-md="4" size-lg="4">
      <app-shared-custom-property-read-only-input-text *ngIf="orderInfo.orderRefreshAt"
                                                       [title]="'COMPONENTS.ISSUES.SERVICES.WINTERHALTER.LAST_UPDATE' | translate"
                                                       [value]="orderInfo.orderRefreshAt">
      </app-shared-custom-property-read-only-input-text>
      <app-shared-custom-property-read-only-input-text *ngIf="!orderInfo.orderRefreshAt"
                                                       [title]="'COMPONENTS.ISSUES.SERVICES.WINTERHALTER.LAST_UPDATE' | translate"
                                                       [value]="'COMPONENTS.MISSIONS.TREE_MENU.EMPTY' | translate">
      </app-shared-custom-property-read-only-input-text>
    </ion-col>
    <ion-col size-xs="12" size-sm="4" size-md="4" size-lg="4">
      <app-shared-custom-property-read-only-input-text
        [title]="'COMPONENTS.ISSUES.SERVICES.WINTERHALTER.STATE' | translate"
        [value]="((winterhalterStateValue$ | async)  ?? 'COMPONENTS.MISSIONS.TREE_MENU.EMPTY') | translate">
      </app-shared-custom-property-read-only-input-text>
    </ion-col>
  </ion-row>
</ng-container>
