import {createAction, props} from "@ngrx/store";
import {Clients, ResetPasswordDto, TokenDto} from "@server-models";

export const initLogout = createAction(
  '[Login-base] Logout'
);

export const resetPassword = createAction(
  '[Login-base] Reset Password start',
  props<{ resetData: ResetPasswordDto, isLoadingId: number | string }>()
);

export const resetPasswordSuccess = createAction(
  '[Login-base] Reset Password success',
  props<{ email: string, password: string }>()
);

export const resetPasswordFail = createAction(
  '[Login-base] Reset Password fail',
  props<{ error: any }>()
);

export const fetchTenantSettingsCancel = createAction(
  '[Login-base] Fetch Tenant Settings Cancel'
);

export const fetchTenantSettingsFail = createAction(
  '[Login-base] Fetch Tenant Settings Fail',
  props<{ error: any }>()
);

export const loginRefresh = createAction(
  '[Login-base] Login Refresh start',
  props<({ clientType: Clients, isLogging?: boolean })>()
);

export const loginRefreshSuccess = createAction(
  '[Login-base] Login Refresh success',
  props<{ tokenDto: TokenDto, tenantId: number, clientType: Clients, isLogging?: boolean }>()
);

export const loginRefreshCancel = createAction(
  '[Login-base] Login Refresh Cancel'
);

export const loginRefreshFail = createAction(
  '[Login-base] Login Refresh fail',
  props<{ error: any }>()
);
