<form [formGroup]="productOrderForm">
  <ion-card>
    <ion-card-header *ngIf="productOrderData">
      <ion-text color="dark">
        <h4 class="ion-no-margin">
          {{ productOrderData.name }}
        </h4>
      </ion-text>
    </ion-card-header>

    <ion-card-content *ngIf="productOrderForm">
      <ng-container *ngFor="let productKey of getFormGroupKeys(); let i = index" [formGroupName]="productKey">
        <ion-list>
          <ion-item class="ion-no-padding product" lines="none">
            <ion-avatar slot="start"
                        (click)="imageService.openModalImage(getControlValue(productKey,'urlImage'), getControlValue(productKey, 'title'))">
              <img [src]="getControlValue(productKey,'urlImage')" alt="product image"/>
            </ion-avatar>
            <ion-label class="ion-text-sm-nowrap ion-text-wrap">
              <ion-text>
                <p [innerText]="getControlValue(productKey, 'title')"></p>
              </ion-text>
              <ion-text color="medium">
                <p
                  [innerText]="getControlValue(productKey, 'containerSize') + ' (' + getControlValue(productKey, 'productCode') + ')'"></p>
              </ion-text>
              <ion-text color="primary" *ngIf="getControlValue(productKey,'urlSafetyFile')">
                <small>
                  <a class="safety-link"
                     [href]="getControlValue(productKey,'urlSafetyFile')"
                     target="_blank">
                    <ion-icon name="alert-circle-outline"></ion-icon>
                    <div>{{ 'COMPONENTS.ISSUES.SERVICES.WINTERHALTER.PRODUCT_ORDER.FORM.SAFETY_LINK' | translate }}</div>
                  </a>
                </small>
              </ion-text>
            </ion-label>
            <ion-select interface="popover"
                        [interfaceOptions]="{subHeader: ('COMPONENTS.ISSUES.SERVICES.WINTERHALTER.PRODUCT_ORDER.FORM.SELECT.SUBHEADER' | translate) + ': ' + getControlValue(productKey, 'containerSize')}"
                        [value]="getControlValue(productKey, 'quantity')"
                        (ionChange)="quantitySelected(productKey, $event)"
                        legacy="legacy"
                        aria-label="quantity"
                        class="ion-no-padding"
                        slot="end">
              <ion-select-option
                *ngFor="let option of getQuantitySelectOptions(getControlValue(productKey, 'productId'))"
                [value]="option.value">
                {{ option.label }}
              </ion-select-option>
            </ion-select>
          </ion-item>
        </ion-list>

        <!-- Hidden Form Controls for Product ID and Quantity -->
        <ion-input type="hidden" formControlName="productId" style="display: none;"></ion-input>
        <ion-input type="hidden" formControlName="quantity" style="display: none;"></ion-input>
      </ng-container>
    </ion-card-content>
  </ion-card>
</form>
