import {Component, Input, OnChanges} from '@angular/core';
import {
  FileOrgaAttachmentDto,
  KnowledgeArticleOrgaAttachmentDto,
  OrgaAttachmentListDto
} from "@server-models";
import {TranslateModule} from "@ngx-translate/core";
import {IonicModule} from "@ionic/angular";
import {CommonModule} from "@angular/common";
import {AttachmentInternalDto} from "@shared/components/attachments/interfaces/attachment-internal-dto.interface";
import {DesignTypeEnum} from "@shared/components/attachments/enums/design-type.enum";
import {
  AttachmentsBoxComponent
} from "@shared/components/attachments/components/attachments-box/attachments-box.component";
import {
  AttachmentsRowComponent
} from "@shared/components/attachments/components/attachments-row/attachments-row.component";

@Component({
  selector: 'app-attachments',
  templateUrl: './attachments.component.html',
  styleUrls: ['./attachments.component.scss'],
  imports: [
    IonicModule,
    CommonModule,
    TranslateModule,
    AttachmentsBoxComponent,
    AttachmentsRowComponent
  ],
  standalone: true
})
export class AttachmentsComponent implements OnChanges {

  @Input() attachmentsInc: OrgaAttachmentListDto[] | FileOrgaAttachmentDto[] | null | any;
  @Input() showHeader: boolean;
  @Input() designType?: DesignTypeEnum;

  mappedAttachments: AttachmentInternalDto[];

  protected readonly DesignTypeEnum = DesignTypeEnum;


  constructor(
  ) {
    this.mappedAttachments = [];
    this.showHeader = true;
    this.designType = DesignTypeEnum.Row
  }

  ngOnChanges() {
    this.mappedAttachments = this._mapToAttachmentInternalDto(this.attachmentsInc);
  }

  /**
   * @name _mapToAttachmentInternalDto
   * @description
   * map the incoming attachments to AttachmentInternalDto[]
   * @memberof AttachmentsComponent
   * @param attachments
   * @private
   * @returns {AttachmentInternalDto[]}
   */
  _mapToAttachmentInternalDto(attachments: OrgaAttachmentListDto[] | FileOrgaAttachmentDto[] | null | any): AttachmentInternalDto[] {
    if (!attachments || attachments.length === 0) {
      return [];
    }

    return attachments.map((attachment: OrgaAttachmentListDto | FileOrgaAttachmentDto | KnowledgeArticleOrgaAttachmentDto) => {
      let attachmentInternal: AttachmentInternalDto = <AttachmentInternalDto>{};

      if (this._isKnowledgeArticle(attachment)) {
        attachmentInternal = {
          attachmentId: attachment.attachmentId!,
          name: attachment.knowledgeArticle?.title!,
          url: attachment.knowledgeArticle?.externalId!,
          type: attachment.type!,
          referenceId: attachment.knowledgeArticle?.knowledgeArticleId!
        }
      } else if (this._isOrgaAttachmentListDto(attachment)) {
        attachmentInternal = {
          attachmentId: attachment.attachmentId!,
          name: attachment?.name!,
          url: attachment?.url! || attachment?.link!,
          type: attachment.type!,
          referenceId: attachment.referenceId!
        }
      } else if (this._isFileOrgaAttachmentDto(attachment)) {
        attachmentInternal = {
          attachmentId: attachment.attachmentId! || attachment.file?.fileId!,
          name: attachment.file?.name!,
          url: attachment.file?.uri!,
          type: attachment.type!,
          mineType: attachment?.file?.mimeType!
        }
      }

      return attachmentInternal;
    });
  }

  private _isKnowledgeArticle(object: any): object is KnowledgeArticleOrgaAttachmentDto {
    return object.knowledgeArticle !== undefined;
  }

  /**
   * @name _isOrgaAttachmentListDto
   * @description
   * Check if the incoming object is OrgaAttachmentListDto
   * @memberof
   * @param object
   * @returns {object is OrgaAttachmentListDto}
   */
  private _isOrgaAttachmentListDto(object: any): object is OrgaAttachmentListDto {
    return object.file === undefined;
  }

  /**
   * @name _isFileOrgaAttachmentDto
   * @description
   * Check if the incoming object is FileOrgaAttachmentDto
   * @memberof
   * @param object
   * @returns {object is FileOrgaAttachmentDto}
   */
  private _isFileOrgaAttachmentDto(object: any): object is FileOrgaAttachmentDto {
    return object.file !== undefined;
  }

}
