import {mapToCanActivate, Route, RouterStateSnapshot} from "@angular/router";
import {LinkHomePage} from "./pages/home/link-home.page";
import {LinkKnowledgePage} from "./pages/knowledge/link-knowledge.page";
import {LinkMainPage} from "./pages/link-main.page";
import {LinkPropertiesPage} from "./pages/properties/link-properties.page";
import {LinkKnowledgeArticlePage} from "./pages/knowledge-article/link-knowledge-article.page";
import {LinkLoginPage} from "@link/pages/login/link-login.page";
import {inject} from "@angular/core";
import {EAppType} from "@shared/models/AppType.enum";
import {LinkLoginGuard} from "@link/guards/link-login.guard";
import {LinkIssueGuard} from "@link/guards/link-issue.guard";
import {LinkIssuesTemplateGuard} from "@link/guards/link-issue-teamplate.guard";
import {LinkHomeGuard} from "@link/guards/link-home.guard";
import {LinkKnowledgeGuard} from "@link/guards/link-knowledge.guard";
import {LinkLoginFeaturesGuard} from "@link/guards/link-login-features.guard";
import {LinkIssuePage} from "@link/pages/issues/link-issue.page";
import {linkIssueTemplatePreviewResolver} from "@link/pages/issues/store/link-issue-template-preview.resolver";
import {LinkIssueReadGuard} from "@link/guards/link-issue-read.guards";
import {SharedIssueReadOnlyPage} from "@shared/components/issues/pages/read-only/shared-issue-read-only.page";
import {
  SharedIssueNewTemplateDetailPage
} from "@shared/components/issues/pages/new-template-detail/shared-issue-new-template-detail.page";
import {LinkErrorPage} from "@link/pages/error/link-error.page";
import {linkIssueNewTemplateResolver} from "@link/pages/issues/store/link-issue-new-template.resolver";
import {linkKnowledgeResolver} from "@link/pages/knowledge/resolvers/link-knowledge.resolver";
import {
  SharedIssueSuccessPage
} from "@shared/components/issues/pages/success/shared-issue-success.page";
import {
  SharedIssueNewTemplateListPage
} from "@shared/components/issues/pages/new-template-list/shared-issue-new-template-list.page";

export default [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'link'
  },
  {
    path: 'link',
    children: [
      {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full'
      },
      {
        path: 'login',
        component: LinkLoginPage
      },
      {
        path: 'error',
        component: LinkErrorPage
      },
      {
        path: ':id',
        component: LinkMainPage,
        canActivate: [
          (_: any, state: RouterStateSnapshot) => inject(LinkLoginGuard).canActivate((EAppType.Link), state)
        ],
        children: [
          {
            path: 'home',
            component: LinkHomePage,
            canActivate: mapToCanActivate([LinkHomeGuard])
          },
          {
            path: 'knowledge',
            component: LinkKnowledgePage,
            canActivate: mapToCanActivate([LinkKnowledgeGuard]),
            resolve: { knowledge: linkKnowledgeResolver },
            data: { isBackButtonEnable: true }
          },
          { path: 'knowledge/:id', component: LinkKnowledgeArticlePage },
          {
            path: 'properties',
            component: LinkPropertiesPage,
            canActivate: mapToCanActivate([LinkLoginFeaturesGuard])
          },
          {
            path: 'issues',
            component: LinkIssuePage,
            canActivate: mapToCanActivate([LinkIssueGuard])
          },
          {
            path: 'issues/new',
            component: SharedIssueNewTemplateListPage,
            canActivate: mapToCanActivate([LinkIssuesTemplateGuard]),
            resolve: { newTemplate: linkIssueNewTemplateResolver },
            data: { isBackButtonEnable: true }
          },
          {
            path: 'issues/new/:id',
            component: SharedIssueNewTemplateDetailPage,
            resolve: { templatePreview: linkIssueTemplatePreviewResolver },
            pathMatch: 'full',
            data: { isBackButtonEnable: true }
          },
          {
            path: 'issues/success',
            component: SharedIssueSuccessPage,
            pathMatch: 'full'
          },
          {
            path: 'issues/:id',
            component: SharedIssueReadOnlyPage,
            canActivate: mapToCanActivate([LinkIssueReadGuard]),
            pathMatch: 'full',
          },
        ],
      }
    ]
  },
] as Route[];
