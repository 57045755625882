<ng-container *ngIf="(detailDataFormatted$ | async) as detailDataFormatted">
  <ion-card
    *ngIf="detailDataFormatted.additionalDetails!.winterhalter?.productOrder!.formData.productList as productList">
    <ion-card-content *ngIf="productList">
      <ion-list>
        <ion-item class="ion-no-padding product" lines="none" *ngFor="let product of productList; let i = index">
          <ion-avatar slot="start"
                      (click)="imageService.openModalImage(product?.urlImage ?? '', product?.title ?? '')">
            <img [src]="product?.urlImage" alt="product image"/>
          </ion-avatar>
          <ion-label class="ion-text-sm-nowrap ion-text-wrap">
            <ion-text>
              <p [innerText]="product.title"></p>
            </ion-text>
            <ion-text color="medium">
              <p
                [innerText]="product.containerSize + ' (' + product.productCode + ')'"></p>
            </ion-text>
          </ion-label>
          <ion-input
            [value]="(product.quantity ?? 0) + ' ' + ('COMPONENTS.ISSUES.SERVICES.WINTERHALTER.PRODUCT_ORDER.FORM.SELECT.QUANTITY.PIECES' | translate)"
            class="ion-text-end"
            type="text"
            aria-label="quantity"
            readonly>
          </ion-input>
        </ion-item>
      </ion-list>
    </ion-card-content>
  </ion-card>

</ng-container>
