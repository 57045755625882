import {Component, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';
import {KnowledgeListComponent} from '@features/knowledge/knowledge-list/knowledge-list.component';
import {CoreChildAppService} from '@core/child-app/core-child-app.service';
import {Store} from "@ngrx/store";
import {selectIsLoading, selectOccurredConsumptions} from "../../store/link.selector";
import {filter, mergeMap, Observable} from "rxjs";
import {map} from "rxjs/operators";
import {
  CustomDropDownDefaultValueDto,
  CustomDropDownDto,
  CustomNumericPropertyDto,
  CustomPropertyDto,
  CustomPropertyType
} from "@server-models";
import {TranslateModule} from "@ngx-translate/core";
import {CoreClockService} from "@core/services/core-clock.service";
import {SharedPropComponent} from "@shared/components/prop/shared-prop.component";
import {LinkLoginSelectors} from "@link/pages/login/store/link-login.selector-type";

@Component({
  selector: 'app-link-properties',
  templateUrl: './link-properties.page.html',
  styleUrls: ['./link-properties.page.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, FormsModule, KnowledgeListComponent, TranslateModule, SharedPropComponent]
})
export class LinkPropertiesPage implements OnInit {

  public isChildApp: boolean;
  public properties$ = this.store.select(LinkLoginSelectors.selectProperties);
  public stereotype$ = this.store.select(LinkLoginSelectors.selectStereotype);
  public isLoading$ = this.store.select(selectIsLoading)
  public consumptions$ = this.store.select(selectOccurredConsumptions);

  constructor(
    private childAppService: CoreChildAppService,
    private store: Store,
    private clock: CoreClockService
  ) {
    this.isChildApp = this.childAppService.isChildApp('link');
  }

  ngOnInit() {

  }

  close = async () => await this.childAppService.close();

  findValue(property: CustomPropertyDto, customPropertyId: number | undefined): Observable<string> {
    return this.properties$.pipe(
      mergeMap(x => x),
      filter(x => x.propertyId === customPropertyId),
      map(x => <any>x.customValues![0]?.customValue),
      map(x => !!x.isInherited ? x?.inheritedValue : x?.ownValue),
      map(x => this.parseValue(property, x || ''))
    );
  }

  private parseValue(property: CustomPropertyDto, value: string) {
    switch (property.type) {
      case CustomPropertyType.Numeric:
        return (<CustomNumericPropertyDto>property).unit ? `${ value } ${ (<CustomNumericPropertyDto>property).unit }` : `${ value }`
      case CustomPropertyType.Date:
        return !value ? '' : this.clock.format(this.clock.parse(value), false);
      case CustomPropertyType.Dropdown: {
        const dropdownValue = <CustomDropDownDefaultValueDto>
          (<CustomDropDownDto>property).defaultValues?.find(x => x.customValueId == +value);
        return dropdownValue?.value || '';
      }
      default:
        return value;
    }
  }
}
