import {Injectable} from "@angular/core";
import {Router} from "@angular/router";
import {ResourceProfileFeatureDto, ResourceProfileIssueTemplateShortCutDto} from "@server-models";
import {LinkTabNavigation} from "@link/store/link.state";
import {LinkIssuesActions} from "@link/pages/issues/store/link-issues.actions-type";
import {Store} from "@ngrx/store";


@Injectable({
  providedIn: 'root'
})
export class LinkLoginService {

  constructor(
    private _router: Router,
    private _store: Store
  ) {
  }


  private _tabKeys: any[] = [
    'isDashboardVisible',
    'isKnowledgeVisible',
    'isShowingProperties',
    'isIssueTemplateVisible',
    'isIssueHistoryVisible'
  ];

  /**
   * @name findFirstTrueTab
   * @description
   * return the first one find with true value
   * isDashboardVisible is always set as a first value because is the prefer load page
   * @memberof LinkLoginService
   * @param features
   */
  findFirstTrueTab(features: ResourceProfileFeatureDto): string | null {
    for (const key of this._tabKeys) {
      if (features[key as keyof ResourceProfileFeatureDto]) {
        return key;
      }
    }

    return null;
  }

  /**
   * @name redirectToPage
   * @description
   * check the keys to redirect to the corresponding page
   * @memberof LinkLoginService
   * @param featureKey
   * @param linkId
   * @param tabNavigation
   * @param templateShortCut
   */
  redirectToPage(featureKey: string, linkId: number, tabNavigation: LinkTabNavigation, templateShortCut: ResourceProfileIssueTemplateShortCutDto): void {
    switch (featureKey) {
      case 'isShowingProperties':
        this._router.navigateByUrl(`link/${linkId}/properties`);
        break;
      case 'isIssueTemplateVisible': {
        if (templateShortCut?.issueTemplateId) {
          this._store.dispatch(LinkIssuesActions.getItemsTemplatePreviewByIssueType({
            id: templateShortCut.issueTemplateId!,
            issueType: templateShortCut.contentType!
          }));
        } else {
          this._router.navigateByUrl(`link/${linkId}/${tabNavigation.issues}`);
        }
        break;
      }
      case 'isIssueHistoryVisible':
        this._router.navigateByUrl(`link/${linkId}/${tabNavigation.issues}`);
        break;
      case 'isKnowledgeVisible':
        this._router.navigateByUrl(`link/${linkId}/${tabNavigation.knowledges}`);
        break;
      case 'isDashboardVisible':
        this._router.navigateByUrl(`link/${linkId}/home`);
        break;
      default:
        this._router.navigateByUrl(`link/error`);
    }
  }

  /**
   * @name checkIssuesNav
   * @description
   * check what navigation must have the app tab for issues
   * @memberof LinkLoginService
   * @param features
   */
  checkIssuesNav(features: ResourceProfileFeatureDto): string {
    if (typeof features?.issueTemplateShortcut?.issueTemplateId == 'number' && features?.isIssueHistoryVisible) {
      return `issues`
    } else if (typeof features?.issueTemplateShortcut?.issueTemplateId == 'number') {
      return `issues/new/${features.issueTemplateShortcut.issueTemplateId}`;
    } else if (!features?.isIssueHistoryVisible) {
      return `issues/new`;
    } else {
      return `issues`;
    }
  }

  /**
   * @name checkKnowledgeNav
   * @description
   * check what navigation must have the app tab for knowledge
   * @memberof LinkLoginService
   * @param features
   */
  checkKnowledgeNav(features: ResourceProfileFeatureDto): string {
    if (typeof features?.knowledgeShortcutId == 'number') {
      return `knowledge/${features.knowledgeShortcutId}`;
    } else {
      return 'knowledge'
    }
  }
}
