import {Injectable} from "@angular/core";

@Injectable({
  providedIn: 'root',
})
export class SharedDateHelperService {

  convertMinutesToISO(minutes: number): string {
    const milliseconds = minutes * 60 * 1000;
    const date: Date = new Date(milliseconds);

    return date.toISOString();
  }

  convertISOToMinutes(isoString: string): number {
    const date = new Date(isoString.endsWith('Z') ? isoString : isoString + 'Z');
    const hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();

    return hours * 60 + minutes;
  }

  splitTimeStringToYearsWeeksDays(timeString: string): { years: number; weeks: number; days: number } {
    const [daysStr, timeStr] = timeString.split('.');
    const days = parseInt(daysStr, 10);

    const years = Math.floor(days / 365);
    const remainingDays = days % 365;
    const weeks = Math.floor(remainingDays / 7);
    const finalDays = remainingDays % 7;

    return { years, weeks, days: finalDays };
  }
}
