import {Component, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';
import {CoreChildAppService} from "@core/child-app/core-child-app.service";
import {select, Store} from "@ngrx/store";
import {
  selectIsLoading, selectOccurredErrors,
  selectOccurredHints,
  selectOccurredHintsLoading
} from "../../store/link.selector";
import {CoreClockService} from "@core/services/core-clock.service";
import {TranslateModule} from "@ngx-translate/core";
import {SharedPropComponent} from "@shared/components/prop/shared-prop.component";
import {LinkLoginSelectors} from "@link/pages/login/store/link-login.selector-type";
import {LinkActions} from "@link/store/link.actions-type";
import {withLatestFrom} from "rxjs";
import {map} from "rxjs/operators";
import {Router} from "@angular/router";

@Component({
  selector: 'app-link-home',
  templateUrl: './link-home.page.html',
  styleUrls: ['./link-home.page.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, FormsModule, TranslateModule, SharedPropComponent]
})
export class LinkHomePage implements OnInit {
  public isChildApp: boolean;
  public isLoading$ = this.store.select(selectIsLoading);

  public resource$ = this.store.select(LinkLoginSelectors.selectResource);
  public hints$ = this.store.select(selectOccurredHints);
  public errors$ = this.store.select(selectOccurredErrors);
  public hintsIsLoading$ = this.store.select(selectOccurredHintsLoading);

  constructor(
    private childAppService: CoreChildAppService,
    private store: Store,
    private clock: CoreClockService,
    private router: Router
  ) {
    this.isChildApp = this.childAppService.isChildApp('link');
  }

  ngOnInit() {

    this.isLoading$.subscribe(isLoading => {
      if (!isLoading) {
        this.store.dispatch(LinkActions.getProfileDashboard())
      }
    });
  }

  //todo add home screen values
  close = async () => await this.childAppService.close();
  protected readonly location = location;


  parseDatetime(date: string | null | undefined): Date | null {
    return date ? this.clock.parse(date) : null;
  }

  formatDatetime(date: Date | null, withTime = true): string {
    return date ? this.clock.format(date, withTime) : "";
  }

  formatDatetimeTo(date: null | Date) {
    return this.clock.to(date);
  }

  openKnowledgeArticle(knowledgeArticleId: any) {
    this.store.pipe(
      withLatestFrom(this.store.pipe(select(LinkLoginSelectors.selectLinkId))),
      map(([_, linkId]) => {
        return this.router.navigate([`link/${ linkId }/knowledge/${ knowledgeArticleId }`]);
      })
    ).subscribe();
  }
}
