import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {TranslateModule} from "@ngx-translate/core";
import {FileDto} from "@server-models";
import {SharedSafePipe} from "@shared/pipes/shared-safe.pipe";
import {SharedImageService} from "@shared/services/shared-image.service";

@Component({
  selector: 'app-shared-custom-property-read-only-input-picture',
  templateUrl: './shared-custom-property-read-only-input-picture.component.html',
  styleUrls: ['./shared-custom-property-read-only-input-picture.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, TranslateModule, SharedSafePipe],
  encapsulation: ViewEncapsulation.None
})
export class SharedCustomPropertyReadOnlyInputPictureComponent implements OnInit {

  @Input() title: string;
  @Input() value: FileDto;

  defaultAlt: string;

  constructor(
    public imageService: SharedImageService
  ) {
    this.defaultAlt = 'picture-attached-read';
    this.title = "";
    this.value = {};
  }

  ngOnInit() {
  }
}
