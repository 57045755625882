import {Component, OnInit} from "@angular/core";
import {IonicModule} from "@ionic/angular";
import {select, Store} from "@ngrx/store";
import {ActivatedRoute} from "@angular/router";
import {CoreChildAppService} from "@core/child-app/core-child-app.service";
import {AsyncPipe, NgIf} from "@angular/common";
import {TranslateModule} from "@ngx-translate/core";
import {ResourceProfileFeatureDto} from "@server-models";
import {Observable, of} from "rxjs";
import {ILinkTabNavigation} from "@link/store/link.state";
import {LinkLoginSelectors} from "@link/pages/login/store/link-login.selector-type";

@Component({
  selector: 'app-link-main',
  templateUrl: 'link-main.page.html',
  styleUrls: [],
  standalone: true,
  imports: [IonicModule, NgIf, TranslateModule, AsyncPipe],
})
export class LinkMainPage implements OnInit {
  public isChildApp = false;
  features$: Observable<ResourceProfileFeatureDto | undefined>;
  currentTabNavigation$: Observable<ILinkTabNavigation>;
  resourceId: string;

  constructor(
    private store: Store,
    private route: ActivatedRoute,
    private childAppService: CoreChildAppService,
  ) {
    this.features$ = of();
    this.currentTabNavigation$ = of();
    this.resourceId = "";
  }

  ngOnInit(): void {
    this.features$ = this.store.pipe(select(LinkLoginSelectors.selectFeatures));
    this.currentTabNavigation$ = this.store.pipe(select(LinkLoginSelectors.selectTabNavigation));
    const routeSnapshot = this.route.snapshot;
    this.resourceId = routeSnapshot.paramMap.get('id')!;
    this.isChildApp = this.childAppService.isChildApp('link');
  }
}

