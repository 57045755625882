import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  ViewEncapsulation
} from '@angular/core';
import {SharedSafePipe} from "@shared/pipes/shared-safe.pipe";
import {TranslateService} from "@ngx-translate/core";
import {SharedImageService} from "@shared/services/shared-image.service";

@Component({
  selector: 'app-shared-user-html',
  templateUrl: './shared-user-html.component.html',
  styleUrls: ['./shared-user-html.component.scss'],
  imports: [
    SharedSafePipe
  ],
  standalone: true,
  encapsulation: ViewEncapsulation.None
})
export class SharedUserHtmlComponent implements AfterViewInit {

  @Input() html: string | null | undefined;

  constructor(
    private imageService: SharedImageService,
    private el: ElementRef,
    private _translateService: TranslateService
  ) {
  }

  ngAfterViewInit() {
    this._processImages();
  }

  private _processImages() {
    const images = this.el.nativeElement.querySelectorAll('img');
    images.forEach((img: HTMLElement) => {
      img.addEventListener('click', (event: Event) => {
        const target = event.target as HTMLImageElement;
        const imageName = target.getAttribute('alt')
          || target.getAttribute('title')
          || this._translateService.instant('GENERAL.DEFAULT_IMAGE');
        this.imageService.openModalImage(target.getAttribute('src') || '', imageName);
      });
    });
  }
}
