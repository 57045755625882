import {Component, OnInit} from '@angular/core';
import {select, Store} from "@ngrx/store";
import {IonicModule, ViewWillEnter} from "@ionic/angular";
import {TranslateModule} from "@ngx-translate/core";
import {AsyncPipe, NgForOf, NgIf} from "@angular/common";
import {Observable} from "rxjs";

import {ActivatedRoute, Router} from "@angular/router";
import {IssueTemplateListDto} from "@server-models";
import {BaseIssuesSelectors} from "@shared/components/issues/store/base-issues.selectors-type";
import {BaseIssuesActions} from "@shared/components/issues/store/base-issues.action-type";
import {
  BaseIssueTemplateCardComponent
} from "@shared/components/issues/components/base-issue-template/components/base-issue-template-card/base-issue-template-card.component";
import {IssuesBaseService} from "@shared/components/issues/services/issues-base.service";

@Component({
  selector: 'app-base-issue-template',
  templateUrl: './base-issue-template.component.html',
  styleUrls: ['./base-issue-template.component.scss'],
  imports: [
    IonicModule,
    TranslateModule,
    AsyncPipe,
    NgIf,
    NgForOf,
    BaseIssueTemplateCardComponent
  ],
  standalone: true
})
export class BaseIssueTemplateComponent implements OnInit, ViewWillEnter {
  isLoading$!: Observable<boolean>;
  issuesTemplateList$!: Observable<IssueTemplateListDto[]>;
  isBackButtonEnable: boolean;

  issueTemplateList!: IssueTemplateListDto[];

  constructor(
    private _store: Store,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _issueBaseService: IssuesBaseService
  ) {
    this.isBackButtonEnable = true;
  }

  ngOnInit() {
    this.isLoading$ = this._store.pipe(select(BaseIssuesSelectors.selectIsLoading));
    this.issuesTemplateList$ = this._store.pipe(select(BaseIssuesSelectors.selectPageTemplate));
    this.issuesTemplateList$.subscribe((templateList => {
      this.issueTemplateList = this._issueBaseService.filterValidIssueContentTypes(templateList);
    }));
  }

  ionViewWillEnter() {
    const activatedRouterData = this._activatedRoute?.snapshot?.data['newTemplate'];
    if (activatedRouterData && 'isBackButtonEnable' in activatedRouterData) {
      this.isBackButtonEnable = activatedRouterData?.isBackButtonEnable;
    }

    this.loadTemplates();
  }

  /**
   * @name loadTemplates
   * @description
   * dispatch the action to request get items template
   * @memberof BaseIssueTemplateComponent
   */
  loadTemplates(): void {
    this._store.dispatch(BaseIssuesActions.getItemsTemplate());
  }

  /**
   * @name back
   * @description
   * navigate to issues previous page
   * @memberof BaseIssueTemplateComponent
   */
  back(): void {
    this._store.dispatch(BaseIssuesActions.navigateToIssues())
  }

}
