import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Observable} from 'rxjs';
import {Store} from "@ngrx/store";
import {CoreBaseEffects} from '@core/core-base.effects';
import {Injectable} from "@angular/core";
import {LinkActions} from "@link/store/link.actions-type";

@Injectable({
  providedIn: 'root'
})
export abstract class LinkBaseEffects extends CoreBaseEffects {

  protected constructor(store: Store, rawActions$: Actions) {
    super(store, rawActions$);
  }

  abstract getOccurredHints(action$: Observable<{}>): Observable<any>;

  abstract getKnowledge(action$: Observable<{}>): Observable<any>;

  abstract getKnowledgeArticle(action$: Observable<{ knowledgeArticleId: number }>): Observable<any>;

  getOccurredHints$ = createEffect(() => this.actions().pipe(
    ofType(LinkActions.getProfileDashboard),
    action => this.getOccurredHints(action)
  ));

  getKnowledge$ = createEffect(() => this.actions().pipe(
    ofType(LinkActions.getKnowledge),
    action => this.getKnowledge(action)
  ));

  getKnowledgeArticle$ = createEffect(() => this.actions().pipe(
    ofType(LinkActions.getKnowledgeArticle),
    action => this.getKnowledgeArticle(action)
  ));

}
