import {Component, EventEmitter, Input, Output, SimpleChanges} from '@angular/core';
import {CommonModule} from "@angular/common";
import {IonicModule} from "@ionic/angular";
import {TranslateModule} from "@ngx-translate/core";
import {SharedSafePipe} from "@shared/pipes/shared-safe.pipe";
import {SharedUserHtmlComponent} from "@shared/components/user-html/shared-user-html.component";
import {SharedAttachmentsComponent} from "@shared/components/attachments/shared-attachments.component";
import {RouterLink} from "@angular/router";
import {SharedCustomPropertyFormInputComponent} from "@shared/components/custom-property-form/components/input/shared-custom-property-form-input.component";
import {FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {SharedCustomPropertyFormInputTextComponent} from "@shared/components/custom-property-form/components/input/components/text/shared-custom-property-form-input-text.component";
import {PrioritySimpleDto} from "@server-models";

@Component({
  selector: 'app-shared-issue-custom-form-priority',
  templateUrl: './shared-issue-custom-form-priority.component.html',
  styleUrls: ['./shared-issue-custom-form-priority.component.scss'],
  standalone: true,
  imports: [
    IonicModule,
    CommonModule,
    TranslateModule,
    SharedSafePipe,
    SharedUserHtmlComponent,
    SharedAttachmentsComponent,
    RouterLink,
    SharedCustomPropertyFormInputTextComponent,
    ReactiveFormsModule,
    SharedCustomPropertyFormInputComponent
  ]
})
export class SharedIssueCustomFormPriorityComponent {
  @Input() priorityList: PrioritySimpleDto[];
  @Output() inputChange = new EventEmitter<FormGroup>();

  priorityForm: FormGroup;

  constructor(
    private _fb: FormBuilder
  ) {
    this.priorityList = [];
    this.priorityForm = this._fb.group({
      Priority: ['', Validators.required]
    });
  }

  getControl(): FormControl {
    return this.priorityForm.get('Priority') as FormControl
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['priorityList']) {
      this.formModified();
    }
  }

  formModified(): void {
    this.inputChange.emit(this.priorityForm);
  }

  onPriorityChange(event: any) {
    const selectedValue = event.detail.value;
  }
}
