/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum IssueContentType {
    Message = 1,
    Ticket = 2,
    Mission = 3,
    WinterhalterServiceCall = 4,
    WinterhalterProductOrder = 5,
}
