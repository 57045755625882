import {Injectable} from "@angular/core";
import {
  CustomPropertyDto,
  CustomSetReferenceDto,
  FilledCustomValueDto,
  IssueResourceSelectorKind, IssueType,
  PrioritySimpleDto,
  ResourceProfileDto, StereotypeDto,
  StereotypeUsageDto,
  SubmitTicketIssueDto,
  TicketIssueDto,
  TicketIssuePreviewDto
} from "@server-models";
import {
  ICustomPropertyStructureFormatted
} from "@shared/components/custom-property-form/interfaces/custom-property-structure-formatted.interface";
import {FormGroup} from "@angular/forms";
import {
  SharedIssueStereotypeBaseService
} from "@shared/components/issues/services/stereotype/shared-issue-stereotype-base.service";
import {TIssueDto} from "@shared/components/issues/types/issue-dto.type";
import {
  IAdditionalDetails
} from "@shared/components/custom-property-form/interfaces/structure-formatted-additional-details.interface";

@Injectable({
  providedIn: 'root',
})
export class SharedIssueTicketService extends SharedIssueStereotypeBaseService {

  override formatReadDetailData(issueDtoType: TIssueDto, issueType: IssueType,
                                stereotypeDto?: StereotypeDto | undefined): ICustomPropertyStructureFormatted {
    const issueDtoTypeTicket = issueDtoType as TicketIssueDto;
    return this._stereotypeBaseService.customPropertiesFormattedFromStereotype(
      issueDtoTypeTicket.issueId!,
      issueDtoTypeTicket.title!,
      issueType,
      issueDtoTypeTicket.customPropertyValues!,
      stereotypeDto!,
      issueDtoTypeTicket,
      (dto) => this.getAdditionalFormattedData(dto),
    );
  }

  prepareFormIssueDto(formGroup: FormGroup, templatePreviewDto: TicketIssuePreviewDto,
                      resourceDto?: ResourceProfileDto): SubmitTicketIssueDto {
    const priorityFormGroup = formGroup.get('Priority') as FormGroup;
    const customValuesFromGroup = formGroup.get('CustomValues') as FormGroup;

    const priority = priorityFormGroup ? this.findPriorityValue(priorityFormGroup, templatePreviewDto.priorities!) : templatePreviewDto.priorities?.[0];

    const customPropertyValues = this._stereotypeBaseService.prepareCustomValues(customValuesFromGroup); // to build the customValues
    const issueStereotype: StereotypeUsageDto = templatePreviewDto.issueStereotype!;
    const customPropertySets: CustomSetReferenceDto[] = issueStereotype.customPropertySets!;

    const sortedCustomPropertySets: CustomSetReferenceDto[] = this._stereotypeBaseService.sortCustomPropertySets(customPropertySets);
    const stereotypeCustomProperties: CustomPropertyDto[] = this._stereotypeBaseService.flattenCustomProperties(sortedCustomPropertySets);
    const mutateCustomPropertyValues: FilledCustomValueDto[] = this._stereotypeBaseService.prepareCustomPropertyValues(customPropertyValues, issueStereotype.tenantId!, stereotypeCustomProperties);

    const resourceKind = this._isResourceSelectorKindSelection(templatePreviewDto.resourceSelectorKind!) ? resourceDto : undefined;

    return {
      title: templatePreviewDto.name,
      tenantId: issueStereotype?.tenantId,
      stereotypeId: issueStereotype?.stereotypeId,
      stereotypeRowVersion: issueStereotype?.rowVersion,
      customPropertyValues: mutateCustomPropertyValues,
      priority,
      resource: resourceKind
    };
  }

  _isResourceSelectorKindSelection(resourceOption: IssueResourceSelectorKind): boolean {
    let displayResource = true;
    if (resourceOption === IssueResourceSelectorKind.Selection) {
      displayResource = true;
    } else if (resourceOption === IssueResourceSelectorKind.None) {
      displayResource = false;
    }
    return displayResource;
  }

  findPriorityValue(priorityForm: FormGroup, priorities: PrioritySimpleDto[]): PrioritySimpleDto {
    const valueToFind = priorityForm.value;
    return priorities.find(p => p?.priorityId === valueToFind)!
  }

  getAdditionalFormattedData(issueDto: TicketIssueDto): Partial<IAdditionalDetails> {
    const ticketDetail = issueDto.ticket;
    return {
      ticket: ticketDetail
    };
  }
}
