/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum AppPermissions {
    ReadTicket = 10,
    CreateTicket = 11,
    UpdateTicket = 12,
    DeleteTicket = 13,
    ForceTicketState = 14,
    AssignTicketEditor = 15,
    AssignTicketToMe = 16,
    AssignTicketResource = 17,
    ReadResource = 20,
    CreateResource = 21,
    UpdateResource = 22,
    DeleteResource = 23,
    ReadCustomProperty = 40,
    CreateCustomProperty = 41,
    UpdateCustomProperty = 42,
    DeleteCustomProperty = 43,
    ReadCustomPropertySet = 50,
    CreateCustomPropertySet = 51,
    UpdateCustomPropertySet = 52,
    DeleteCustomPropertySet = 53,
    ReadStereotype = 60,
    CreateStereotype = 61,
    UpdateStereotype = 62,
    DeleteStereotype = 63,
    ReadSetting = 70,
    UpdateSetting = 71,
    ReadCompany = 80,
    CreateCompany = 81,
    UpdateCompany = 82,
    DeleteCompany = 83,
    ReadLocation = 90,
    CreateLocation = 91,
    UpdateLocation = 92,
    DeleteLocation = 93,
    ReadContact = 100,
    CreateContact = 101,
    UpdateContact = 102,
    DeleteContact = 103,
    CreateSystemContact = 104,
    ReadSystemContact = 105,
    UpdateSystemContact = 106,
    DeleteSystemContact = 107,
    ReadFunction = 110,
    CreateFunction = 111,
    UpdateFunction = 112,
    DeleteFunction = 113,
    ReadAddress = 120,
    CreateAddress = 121,
    UpdateAddress = 123,
    DeleteAddress = 124,
    ReadNote = 130,
    CreateNote = 131,
    UpdateNote = 132,
    DeleteNote = 133,
    ReadRole = 140,
    CreateRole = 141,
    UpdateRole = 142,
    DeleteRole = 143,
    ReadAppPermissions = 150,
    ReadTenant = 160,
    CreateTenant = 161,
    UpdateTenant = 162,
    DeleteTenant = 163,
    ReadTenantUsage = 164,
    ReadLoginProvider = 170,
    CreateLoginProvider = 171,
    UpdateLoginProvider = 172,
    DeleteLoginProvider = 173,
    ReadDevice = 190,
    CreateDevice = 191,
    UpdateDevice = 192,
    DeleteDevice = 193,
    UsePinLogin = 200,
    UsePushRegister = 201,
    UseDeviceRegister = 202,
    UseExternalAppLogin = 203,
    UseBadgeLogin = 204,
    ReadLocationGroup = 210,
    CreateLocationGroup = 211,
    UpdateLocationGroup = 212,
    DeleteLocationGroup = 213,
    ReadInfo = 230,
    CreateInfo = 231,
    UpdateInfo = 232,
    DeleteInfo = 233,
    ReadTask = 240,
    CreateTask = 241,
    UpdateTask = 242,
    DeleteTask = 243,
    ReadForm = 250,
    CreateForm = 251,
    UpdateForm = 252,
    DeleteForm = 253,
    ReadSensitiveForm = 254,
    ReadFilledForm = 260,
    CreateFilledForm = 261,
    UpdateFilledForm = 262,
    DeleteFilledForm = 263,
    ReadChat = 270,
    CreateChat = 271,
    UpdateChat = 272,
    DeleteChat = 273,
    UseEmailPerChat = 274,
    UseChatWithoutCloak = 275,
    ReadOverview = 280,
    ReadKnowledgeArticle = 290,
    CreateKnowledgeArticle = 291,
    UpdateKnowledgeArticle = 292,
    DeleteKnowledgeArticle = 293,
    ReadHolidaySet = 300,
    CreateHolidaySet = 301,
    UpdateHolidaySet = 302,
    DeleteHolidaySet = 303,
    ReadFile = 310,
    CreateFile = 311,
    UpdateFile = 312,
    DeleteFile = 313,
    ReadLoginChallenge = 320,
    CreateLoginChallenge = 321,
    UpdateLoginChallenge = 322,
    DeleteLoginChallenge = 323,
    ReadFilledFormField = 330,
    CreateFilledFormField = 331,
    UpdateFilledFormField = 332,
    DeleteFilledFormField = 333,
    ReadFormRow = 340,
    CreateFormRow = 341,
    UpdateFormRow = 342,
    DeleteFormRow = 343,
    ReadFormField = 350,
    CreateFormField = 351,
    UpdateFormField = 352,
    DeleteFormField = 353,
    ReadAttachment = 360,
    CreateAttachment = 361,
    UpdateAttachment = 362,
    DeleteAttachment = 363,
    ReadExecution = 370,
    CreateExecution = 371,
    UpdateExecution = 372,
    DeleteExecution = 373,
    ReadImpact = 380,
    CreateImpact = 381,
    UpdateImpact = 382,
    DeleteImpact = 383,
    ReadCondition = 390,
    CreateCondition = 391,
    UpdateCondition = 392,
    DeleteCondition = 393,
    ReadChatMessage = 400,
    CreateChatMessage = 401,
    UpdateChatMessage = 402,
    DeleteChatMessage = 403,
    ReadChatMember = 410,
    CreateChatMember = 411,
    UpdateChatMember = 412,
    DeleteChatMember = 413,
    ReadChatMemberMessage = 420,
    CreateChatMemberMessage = 421,
    UpdateChatMemberMessage = 422,
    DeleteChatMemberMessage = 423,
    ReadHoliday = 430,
    CreateHoliday = 431,
    UpdateHoliday = 432,
    DeleteHoliday = 433,
    ReadHistory = 440,
    CreateHistory = 441,
    UpdateHistory = 442,
    DeleteHistory = 443,
    ReadTaskJob = 450,
    CreateTaskJob = 451,
    UpdateTaskJob = 452,
    DeleteTaskJob = 453,
    ReadTaskJobStatus = 460,
    CreateTaskJobStatus = 461,
    UpdateTaskJobStatus = 462,
    DeleteTaskJobStatus = 463,
    ReadFolderAccess = 470,
    CreateFolderAccess = 471,
    UpdateFolderAccess = 472,
    DeleteFolderAccess = 473,
    UseAllFolderAccess = 474,
    ReadTaskJobStateReasonSet = 480,
    CreateTaskJobStateReasonSet = 481,
    UpdateTaskJobStateReasonSet = 482,
    DeleteTaskJobStateReasonSet = 483,
    ReadTaskJobStateReason = 490,
    CreateTaskJobStateReason = 491,
    UpdateTaskJobStateReason = 492,
    DeleteTaskJobStateReason = 493,
    ReadPing = 500,
    CreatePing = 501,
    UpdatePing = 502,
    DeletePing = 503,
    ReadMe = 520,
    ReadProductSubscription = 530,
    CreateProductSubscription = 531,
    UpdateProductSubscription = 532,
    DeleteProductSubscription = 533,
    UseExpiredSubscription = 535,
    ShowExpiredSubscription = 536,
    UseAdmin = 540,
    ReadGraph = 550,
    ReadDateTimePattern = 580,
    CreateDateTimePattern = 581,
    UpdateDateTimePattern = 582,
    DeleteDateTimePattern = 583,
    ReadFormValue = 590,
    CreateFormValue = 591,
    UpdateFormValue = 592,
    DeleteFormValue = 593,
    ReadPlannedTime = 600,
    CreatePlannedTime = 601,
    UpdatePlannedTime = 602,
    DeletePlannedTime = 603,
    ReadTaskJobCondition = 610,
    CreateTaskJobCondition = 611,
    UpdateTaskJobCondition = 612,
    DeleteTaskJobCondition = 613,
    ReadCustomValue = 620,
    CreateCustomValue = 621,
    UpdateCustomValue = 622,
    DeleteCustomValue = 623,
    ReadCustomPropertyValue = 630,
    CreateCustomPropertyValue = 631,
    UpdateCustomPropertyValue = 632,
    DeleteCustomPropertyValue = 633,
    ReadSettingValue = 640,
    CreateSettingValue = 641,
    UpdateSettingValue = 642,
    DeleteSettingValue = 643,
    ReadState = 650,
    CreateState = 651,
    UpdateState = 652,
    DeleteState = 653,
    ReadStateMachine = 660,
    CreateStateMachine = 661,
    UpdateStateMachine = 662,
    DeleteStateMachine = 663,
    ReadStateTransition = 670,
    CreateStateTransition = 671,
    UpdateStateTransition = 672,
    DeleteStateTransition = 673,
    ReadDataTable = 680,
    CreateDataTable = 681,
    UpdateDataTable = 682,
    DeleteDataTable = 683,
    ExportDataTable = 684,
    ReadDataTableColumn = 690,
    CreateDataTableColumn = 691,
    UpdateDataTableColumn = 692,
    DeleteDataTableColumn = 693,
    ReadDashboard = 700,
    CreateDashboard = 701,
    UpdateDashboard = 702,
    DeleteDashboard = 703,
    UseAllDashboard = 704,
    ReadSolution = 710,
    CreateSolution = 711,
    UpdateSolution = 712,
    DeleteSolution = 713,
    ReadSolutionItem = 720,
    CreateSolutionItem = 721,
    UpdateSolutionItem = 722,
    DeleteSolutionItem = 723,
    ReadDataTableFilter = 730,
    CreateDataTableFilter = 731,
    UpdateDataTableFilter = 732,
    DeleteDataTableFilter = 733,
    ReadPrioritySet = 740,
    CreatePrioritySet = 741,
    UpdatePrioritySet = 742,
    DeletePrioritySet = 743,
    ReadPriority = 750,
    CreatePriority = 751,
    UpdatePriority = 752,
    DeletePriority = 753,
    ReadEscalationLevel = 790,
    CreateEscalationLevel = 791,
    UpdateEscalationLevel = 792,
    DeleteEscalationLevel = 793,
    ReadEscalationRule = 800,
    CreateEscalationRule = 801,
    UpdateEscalationRule = 802,
    DeleteEscalationRule = 803,
    ReadTicketEvent = 810,
    CreateTicketEvent = 811,
    UpdateTicketEvent = 812,
    DeleteTicketEvent = 813,
    ReadAudit = 820,
    ReadMission = 830,
    CreateMission = 831,
    UpdateMission = 832,
    DeleteMission = 833,
    UpdateActualMissionDates = 834,
    UpdatePlannedMissionDates = 835,
    UpdateClosedMission = 836,
    DisplayMissionMap = 838,
    DisplayMissionPlan = 839,
    AssignMissionResource = 840,
    AssignMissionEditor = 841,
    AssignMissionToMe = 842,
    ReadLink = 850,
    CreateLink = 851,
    UpdateLink = 852,
    DeleteLink = 853,
    ReadLinkedElement = 860,
    CreateLinkedElement = 861,
    UpdateLinkedElement = 862,
    DeleteLinkedElement = 863,
    ReadAttachmentForTech = 870,
    CreateAttachmentForTech = 871,
    UpdateAttachmentForTech = 872,
    DeleteAttachmentForTech = 873,
    ReadMailBox = 880,
    CreateMailBox = 881,
    UpdateMailBox = 882,
    DeleteMailBox = 883,
    ReadTemplate = 890,
    CreateTemplate = 891,
    UpdateTemplate = 892,
    DeleteTemplate = 893,
    ReadDocumentTemplate = 900,
    CreateDocumentTemplate = 901,
    UpdateDocumentTemplate = 902,
    DeleteDocumentTemplate = 903,
    EditBetaDocumentTemplates = 904,
    ReadInventoryNumberPattern = 910,
    CreateInventoryNumberPattern = 911,
    UpdateInventoryNumberPattern = 912,
    DeleteInventoryNumberPattern = 913,
    ReadInventoryNumber = 920,
    CreateInventoryNumber = 921,
    UpdateInventoryNumber = 922,
    DeleteInventoryNumber = 923,
    ReadContract = 930,
    CreateContract = 931,
    UpdateContract = 932,
    DeleteContract = 933,
    ReadResourceTaskSelector = 940,
    CreateResourceTaskSelector = 941,
    UpdateResourceTaskSelector = 942,
    DeleteResourceTaskSelector = 943,
    ReadResourceTaskPattern = 950,
    CreateResourceTaskPattern = 951,
    UpdateResourceTaskPattern = 952,
    DeleteResourceTaskPattern = 953,
    ReadResourceTaskCondition = 960,
    CreateResourceTaskCondition = 961,
    UpdateResourceTaskCondition = 962,
    DeleteResourceTaskCondition = 963,
    ReadResourceTask = 970,
    CreateResourceTask = 971,
    UpdateResourceTask = 972,
    DeleteResourceTask = 973,
    ReadArticleKind = 980,
    CreateArticleKind = 981,
    UpdateArticleKind = 982,
    DeleteArticleKind = 983,
    ReadMissionReport = 990,
    CreateMissionReport = 991,
    UpdateMissionReport = 992,
    DeleteMissionReport = 993,
    ReadMissionReportGroup = 1000,
    CreateMissionReportGroup = 1001,
    UpdateMissionReportGroup = 1002,
    DeleteMissionReportGroup = 1003,
    ReadArticleUsage = 1010,
    CreateArticleUsage = 1011,
    UpdateArticleUsage = 1012,
    DeleteArticleUsage = 1013,
    ReadArticle = 1020,
    CreateArticle = 1021,
    UpdateArticle = 1022,
    DeleteArticle = 1023,
    ReadMissionReportSkeleton = 1030,
    CreateMissionReportSkeleton = 1031,
    UpdateMissionReportSkeleton = 1032,
    DeleteMissionReportSkeleton = 1033,
    ReadMissionReportGroupSkeleton = 1040,
    CreateMissionReportGroupSkeleton = 1041,
    UpdateMissionReportGroupSkeleton = 1042,
    DeleteMissionReportGroupSkeleton = 1043,
    ReadArticleUsageSkeleton = 1050,
    CreateArticleUsageSkeleton = 1051,
    UpdateArticleUsageSkeleton = 1052,
    DeleteArticleUsageSkeleton = 1053,
    ReadSkeletonRoot = 1060,
    CreateSkeletonRoot = 1061,
    UpdateSkeletonRoot = 1062,
    DeleteSkeletonRoot = 1063,
    ReadAppOverview = 1070,
    CreateAppOverview = 1071,
    UpdateAppOverview = 1072,
    DeleteAppOverview = 1073,
    ReadAppShortcut = 1080,
    CreateAppShortcut = 1081,
    UpdateAppShortcut = 1082,
    DeleteAppShortcut = 1083,
    ReadAppShortcutParameter = 1090,
    CreateAppShortcutParameter = 1091,
    UpdateAppShortcutParameter = 1092,
    DeleteAppShortcutParameter = 1093,
    ReadAppShortcutValue = 1100,
    CreateAppShortcutValue = 1101,
    UpdateAppShortcutValue = 1102,
    DeleteAppShortcutValue = 1103,
    ReadTicketByProcessDefinition = 1110,
    CreateTicketByProcessDefinition = 1111,
    UpdateTicketByProcessDefinition = 1112,
    DeleteTicketByProcessDefinition = 1113,
    CreateTicketByProcess = 1114,
    ReadChatSkeleton = 1120,
    CreateChatSkeleton = 1121,
    UpdateChatSkeleton = 1122,
    DeleteChatSkeleton = 1123,
    ReadTicketSkeleton = 1130,
    CreateTicketSkeleton = 1131,
    UpdateTicketSkeleton = 1132,
    DeleteTicketSkeleton = 1133,
    ReadResourceTicketSkeletonSelector = 1134,
    CreateResourceTicketSkeletonSelector = 1135,
    UpdateResourceTicketSkeletonSelector = 1136,
    DeleteResourceTicketSkeletonSelector = 1137,
    ReadMissionSkeleton = 1140,
    CreateMissionSkeleton = 1141,
    UpdateMissionSkeleton = 1142,
    DeleteMissionSkeleton = 1143,
    ReadAuditOfCollection = 1150,
    ReadAuditOfProperty = 1160,
    ReadCategory = 1170,
    CreateCategory = 1171,
    UpdateCategory = 1172,
    DeleteCategory = 1173,
    ReadChatByFormDefinition = 1180,
    CreateChatByFormDefinition = 1181,
    UpdateChatByFormDefinition = 1182,
    DeleteChatByFormDefinition = 1183,
    ReadChatByFormMessagePattern = 1190,
    CreateChatByFormMessagePattern = 1191,
    UpdateChatByFormMessagePattern = 1192,
    DeleteChatByFormMessagePattern = 1193,
    ReadAreaAssignment = 1200,
    CreateAreaAssignment = 1201,
    UpdateAreaAssignment = 1202,
    DeleteAreaAssignment = 1203,
    ReadDeviceMetaEntry = 1211,
    CreateDeviceMetaEntry = 1212,
    UpdateDeviceMetaEntry = 1213,
    DeleteDeviceMetaEntry = 1214,
    ReadMissionsPerLocationByMonth = 1221,
    ReadMissionsPerSolutionContactByMonth = 1222,
    ReadMissionsPerLocationPerTypeByMonth = 1223,
    ReadTicketsPerLocationByMonth = 1224,
    ReadTicketsPerLocationPerTypeByMonth = 1225,
    ReadMissionsPerType = 1226,
    ReadMissionsPerResource = 1227,
    ReadMissionsPerSolutionContact = 1228,
    ReadMissionsPerResourceManufacturer = 1229,
    ReadTicketsPerResourceManufacturer = 1230,
    ReadTicketsPerType = 1231,
    ReadMissionsPerLocationByTime = 1232,
    ReadMissionsPerSolutionContactByTime = 1233,
    ReadMissionsPerLocationPerTypeByTime = 1234,
    ReadTicketsPerLocationByTime = 1235,
    ReadTicketsPerLocationPerTypeByTime = 1236,
    ReadMissionReceipt = 1251,
    CreateMissionReceipt = 1252,
    UpdateMissionReceipt = 1253,
    DeleteMissionReceipt = 1254,
    ReadMissionReceiptReport = 1261,
    CreateMissionReceiptReport = 1262,
    UpdateMissionReceiptReport = 1263,
    DeleteMissionReceiptReport = 1264,
    ReadApiKey = 1271,
    CreateApiKey = 1272,
    UpdateApiKey = 1273,
    DeleteApiKey = 1274,
    ReadControlTag = 1311,
    CreateControlTag = 1312,
    UpdateControlTag = 1313,
    DeleteControlTag = 1314,
    ReadControlPoint = 1321,
    CreateControlPoint = 1322,
    UpdateControlPoint = 1323,
    DeleteControlPoint = 1324,
    ReadMissionInspectionReport = 1331,
    CreateMissionInspectionReport = 1332,
    UpdateMissionInspectionReport = 1333,
    DeleteMissionInspectionReport = 1334,
    ReadErrorCode = 1341,
    CreateErrorCode = 1342,
    UpdateErrorCode = 1343,
    DeleteErrorCode = 1344,
    ReadConsumptionCode = 1351,
    CreateConsumptionCode = 1352,
    UpdateConsumptionCode = 1353,
    DeleteConsumptionCode = 1354,
    ReadConnectedDefinition = 1361,
    CreateConnectedDefinition = 1362,
    UpdateConnectedDefinition = 1363,
    DeleteConnectedDefinition = 1364,
    ReadOccurredConnectedError = 1371,
    CreateOccurredConnectedError = 1372,
    UpdateOccurredConnectedError = 1373,
    DeleteOccurredConnectedError = 1374,
    ReadOccurredConnectedConsumption = 1381,
    CreateOccurredConnectedConsumption = 1382,
    UpdateOccurredConnectedConsumption = 1383,
    DeleteOccurredConnectedConsumption = 1384,
    ReadTimelineSetting = 1391,
    CreateTimelineSetting = 1392,
    UpdateTimelineSetting = 1393,
    DeleteTimelineSetting = 1394,
    UseGetMeTimeline = 1400,
    ReadMailNode = 1410,
    CreateMailNode = 1412,
    UpdateMailNode = 1413,
    DeleteMailNode = 1414,
    ReadTicketByMailDefinition = 1420,
    CreateTicketByMailDefinition = 1422,
    UpdateTicketByMailDefinition = 1423,
    DeleteTicketByMailDefinition = 1424,
    CreateTicketByContractDefinition = 1430,
    ReadTicketByContractDefinition = 1431,
    UpdateTicketByContractDefinition = 1432,
    DeleteTicketByContractDefinition = 1433,
    ReadEditorSuggestion = 1440,
    CreateEditorSuggestion = 1441,
    UpdateEditorSuggestion = 1442,
    DeleteEditorSuggestion = 1443,
    ReadEditorSuggestionTarget = 1450,
    CreateEditorSuggestionTarget = 1451,
    UpdateEditorSuggestionTarget = 1452,
    DeleteEditorSuggestionTarget = 1453,
    ReadEditorQuest = 1460,
    CreateEditorQuest = 1461,
    UpdateEditorQuest = 1462,
    DeleteEditorQuest = 1463,
    ReadEditorRequest = 1470,
    CreateEditorRequest = 1471,
    UpdateEditorRequest = 1472,
    DeleteEditorRequest = 1473,
    DisplayEditorRequest = 1474,
    ReadResourceTaskConsumptionTrigger = 1480,
    CreateResourceTaskConsumptionTrigger = 1481,
    UpdateResourceTaskConsumptionTrigger = 1482,
    DeleteResourceTaskConsumptionTrigger = 1483,
    ReadOccurredConnectedConsumptionEntry = 1490,
    CreateOccurredConnectedConsumptionEntry = 1491,
    UpdateOccurredConnectedConsumptionEntry = 1492,
    DeleteOccurredConnectedConsumptionEntry = 1493,
    CreateNotificationRule = 1500,
    ReadNotificationRule = 1501,
    UpdateNotificationRule = 1502,
    DeleteNotificationRule = 1503,
    CreateNotificationTarget = 1510,
    ReadNotificationTarget = 1511,
    UpdateNotificationTarget = 1512,
    DeleteNotificationTarget = 1513,
    ImportEntity = 1520,
    ExportEntity = 1521,
    ReadSearchList = 1531,
    CreateSearchList = 1532,
    UpdateSearchList = 1533,
    DeleteSearchList = 1534,
    DisplaySearchLists = 1535,
    ReadGeneralDataProtectionRegulation = 1541,
    CreateGeneralDataProtectionRegulation = 1542,
    UpdateGeneralDataProtectionRegulation = 1543,
    DeleteGeneralDataProtectionRegulation = 1544,
    DisplayGeneralDataProtectionRegulations = 1545,
    ReadGeneralDataProtectionRegulationLogEntry = 1546,
    UseSetPassword = 1550,
    ReadGeneralDataProtectionRestriction = 1551,
    CreateGeneralDataProtectionRestriction = 1552,
    UpdateGeneralDataProtectionRestriction = 1553,
    DeleteGeneralDataProtectionRestriction = 1554,
    UseClassification = 1560,
    ReadLabel = 1561,
    CreateLabel = 1562,
    UpdateLabel = 1563,
    DeleteLabel = 1564,
    DisplayLabels = 1565,
    ModifyLabelManually = 1566,
    DisplayBackend = 10000,
    DisplayMasterData = 10100,
    DisplayContacts = 10101,
    DisplayFunctions = 10102,
    DisplayLocations = 10103,
    DisplayLocationGroups = 10104,
    DisplayForms = 10105,
    DisplayTasks = 10106,
    DisplayInfos = 10107,
    DisplayKnowledge = 10108,
    DisplayDevices = 10109,
    DisplayChats = 10110,
    DisplayDocuments = 10111,
    DisplayHolidays = 10112,
    DisplayDashboard = 10113,
    DisplayManual = 10114,
    DisplayAddresses = 10115,
    DisplayCompanies = 10116,
    DisplayRole = 10117,
    DisplayProductSubscription = 10118,
    DisplayStereoTypes = 10119,
    DisplaySettings = 10120,
    DisplayResources = 10121,
    DisplayTickets = 10122,
    DisplayStateMachines = 10123,
    DisplayDataTables = 10124,
    DisplayDataDashboard = 10125,
    DisplayPriorities = 10126,
    DisplayEscalations = 10128,
    DisplayMissions = 10129,
    DisplayInventoryNumberPatterns = 10130,
    DisplayContracts = 10131,
    DisplayResourceTasks = 10132,
    DisplayArticles = 10133,
    DisplaySkeletons = 10134,
    DisplayAppOverviews = 10135,
    DisplayProcesses = 10136,
    DisplayCategories = 10137,
    DisplayServices = 10138,
    DisplayControlPoint = 10139,
    DisplayConnectedDefinition = 10140,
    DisplayMissionReceipts = 10141,
    DisplayMailNodes = 10142,
    DisplayPortalAnalytics = 10143,
    DisplayNotificationRules = 10144,
    DisplayAppDashboard = 10201,
    DisplayAppInfos = 10202,
    DisplayAppTasks = 10203,
    DisplayAppTaskArchive = 10204,
    DisplayAppKnowledge = 10205,
    DisplayAppDocuments = 10206,
    DisplayAppChats = 10207,
    ReadCalendarMission = 10301,
    CreateCalendarMission = 10302,
    UpdateCalendarMission = 10303,
    DeleteCalendarMission = 10304,
    ReadMailLog = 10311,
    CreateMailLog = 10312,
    UpdateMailLog = 10313,
    DeleteMailLog = 10314,
    ReadResourcesByLocationDefinition = 10510,
    CreateResourcesByLocationDefinition = 10511,
    UpdateResourcesByLocationDefinition = 10512,
    DeleteResourcesByLocationDefinition = 10513,
    ReadTicketsByLocationDefinition = 10520,
    CreateTicketsByLocationDefinition = 10521,
    UpdateTicketsByLocationDefinition = 10522,
    DeleteTicketsByLocationDefinition = 10523,
    ReadResourceSkeleton = 10530,
    CreateResourceSkeleton = 10531,
    UpdateResourceSkeleton = 10532,
    DeleteResourceSkeleton = 10533,
    ReadTicketByLocationSkeleton = 10540,
    CreateTicketByLocationSkeleton = 10541,
    UpdateTicketByLocationSkeleton = 10542,
    DeleteTicketByLocationSkeleton = 10543,
    ReadSafe = 10550,
    CreateSafe = 10551,
    UpdateSafe = 10552,
    DeleteSafe = 10553,
    DisplaySafe = 10554,
    BypassSafe = 10555,
    ReadKnowledgePath = 10561,
    CreateKnowledgePath = 10562,
    UpdateKnowledgePath = 10563,
    DeleteKnowledgePath = 10564,
    ReadQuickAccessConfiguration = 10570,
    CreateQuickAccessConfiguration = 10571,
    UpdateQuickAccessConfiguration = 10572,
    DeleteQuickAccessConfiguration = 10573,
    DisplayQuickAccessConfiguration = 10574,
    ReadQuickAccessKnowledgeConfiguration = 10580,
    ReadQuickAccessIssueTemplateConfiguration = 10590,
    ReadQuickAccessDashboardConfiguration = 10591,
    ReadHintCode = 10601,
    CreateHintCode = 10602,
    UpdateHintCode = 10603,
    DeleteHintCode = 10604,
    ReadOccurredConnectedHint = 10605,
    CreateOccurredConnectedHint = 10606,
    UpdateOccurredConnectedHint = 10607,
    DeleteOccurredConnectedHint = 10608,
    ReadResourceEvent = 10610,
    CreateResourceEvent = 10611,
    UpdateResourceEvent = 10612,
    DeleteResourceEvent = 10613,
    ReadResourceManufacturer = 10620,
    CreateResourceManufacturer = 10621,
    UpdateResourceManufacturer = 10622,
    DeleteResourceManufacturer = 10623,
    DisplayResourceManufacturers = 10624,
    ReadResourceModel = 10630,
    CreateResourceModel = 10631,
    UpdateResourceModel = 10632,
    DeleteResourceModel = 10633,
    MergeResourceModel = 10634,
    ReadIssueTemplate = 10640,
    CreateIssueTemplate = 10641,
    UpdateIssueTemplate = 10642,
    DeleteIssueTemplate = 10643,
    DisplayIssueTemplates = 10644,
    ReadIssueNotification = 10646,
    CreateIssueNotification = 10647,
    UpdateIssueNotification = 10648,
    DeleteIssueNotification = 10649,
    ReadMessageIssueContent = 10650,
    CreateMessageIssueContent = 10651,
    UpdateMessageIssueContent = 10652,
    DeleteMessageIssueContent = 10653,
    ReadIssue = 10660,
    CreateIssue = 10661,
    UpdateIssue = 10662,
    DeleteIssue = 10663,
    ReadAllIssue = 10664,
    ReadRecentIssue = 10665,
    ReadUserIssue = 10666,
    ReadWinterhalterServiceCallIssue = 10667,
    ReadWinterhalterProductOrderIssue = 10668,
    ReadWinterhalterProductOrder = 10669,
    ReadOccurredConnectedHintHistory = 10670,
    ReadOccurredConnectedErrorHistory = 10671,
    ReadOccurredConnectedConsumptionHistory = 10672,
    UseMassImport = 10673,
    ReadInvitation = 10680,
    CreateInvitation = 10681,
    UpdateInvitation = 10682,
    DeleteInvitation = 10683,
    ShowReportForMaintenance = 10710,
    ShowReportForPrime = 10711,
    ReadKnowledgeExam = 10720,
    CreateKnowledgeExam = 10721,
    UpdateKnowledgeExam = 10723,
    DeleteKnowledgeExam = 10724,
    ReadKnowledgeExamAnswer = 10730,
    CreateKnowledgeExamAnswer = 10731,
    UpdateKnowledgeExamAnswer = 10733,
    DeleteKnowledgeExamAnswer = 10734,
    ReadTicketIssueContent = 10740,
    CreateTicketIssueContent = 10741,
    UpdateTicketIssueContent = 10742,
    DeleteTicketIssueContent = 10743,
    ReadMissionIssueContent = 10750,
    CreateMissionIssueContent = 10751,
    UpdateMissionIssueContent = 10752,
    DeleteMissionIssueContent = 10753,
    ReadWinterhalterServiceIssueContent = 10760,
    CreateWinterhalterServiceIssueContent = 10761,
    UpdateWinterhalterServiceIssueContent = 10762,
    DeleteWinterhalterServiceIssueContent = 10763,
    ReadWinterhalterOrderIssueContent = 10770,
    CreateWinterhalterOrderIssueContent = 10771,
    UpdateWinterhalterOrderIssueContent = 10772,
    DeleteWinterhalterOrderIssueContent = 10773,
    ReadMailReceiver = 103321,
    CreateMailReceiver = 103322,
    UpdateMailReceiver = 103323,
    DeleteMailReceiver = 103324,
    MergeResourceManufacturer = 106245,
}
