<ng-container *ngIf="(detailDataFormatted$ | async) as detailDataFormatted">
  <ng-container *ngIf="detailDataFormatted.additionalDetails && detailDataFormatted.additionalDetails.mission">
    <ion-card *ngIf="detailDataFormatted.additionalDetails.mission as missionDetail" class="right-padding">
      <ion-item>
        <ion-label class="set-color">
          <p>{{ "COMPONENTS.MISSIONS.STATE.PLACEHOLDER" | translate }}:
            <app-shared-issue-custom-read-only-mission-state-options
              [stateOption]="missionDetail.state!"></app-shared-issue-custom-read-only-mission-state-options>
          </p>
        </ion-label>
      </ion-item>
    </ion-card>
  </ng-container>
  <app-shared-custom-property-sets-read-only-card
    [propertySetsDetail]="detailDataFormatted.customPropertySets"></app-shared-custom-property-sets-read-only-card>
</ng-container>
