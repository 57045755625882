import {Component, Input} from '@angular/core';
import {CommonModule} from "@angular/common";
import {IonicModule} from "@ionic/angular";

@Component({
  selector: 'app-shared-prop',
  templateUrl: './shared-prop.component.html',
  styleUrls: ['./shared-prop.component.scss'],
  imports: [
    CommonModule,
    IonicModule
  ],
  standalone: true
})
export class SharedPropComponent {

  @Input() title: string | null | undefined;

}
