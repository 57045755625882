<ion-header [translucent]="true">
  <ion-toolbar>
    <ion-title [innerText]="'LOGIN.PAGE_NAME' | translate"></ion-title>
  </ion-toolbar>
</ion-header>

<ion-content [fullscreen]="true">
  <ion-spinner *ngIf="isLoading$ | async"></ion-spinner>
  <div *ngIf="!(isLoading$ | async)" class="modal-center">
    <ng-container>
      <app-link-login-message-card></app-link-login-message-card>
    </ng-container>
  </div>
</ion-content>
