import {createReducer, on} from "@ngrx/store";
import {SharedIssueBaseState} from "@shared/components/issues/store/shared-issue-base.state";
import {SharedIssueBaseActions} from "@shared/components/issues/store/shared-issue-base.action-type";
import {IssueContentType, IssueType} from "@server-models";
import {
  ICustomPropertyStructureFormatted
} from "@shared/components/custom-property-form/interfaces/custom-property-structure-formatted.interface";
import {CoreEnvironmentService} from "@core/services/environment/core-environment.service";

function getInitialSharedIssueState(): SharedIssueBaseState {
  const env = CoreEnvironmentService.getEnvironment();
  return {
    page: {},
    items: [],
    itemsTemplate: [],
    issueItem: {},
    isNavigationRequired: true,
    selectedIssueItem: {
      id: NaN,
      issueType: <IssueType>{}
    },
    selectedTemplate: {
      id: NaN,
      templateIssueType: <IssueContentType>{},
      data: {}
    },
    shortCutTemplate: {
      issueTemplateId: NaN,
      contentType: <IssueContentType>{}
    },
    selectedProductOrderCatalog: {},
    selectedStereotype: {},
    selectedStereotypeEntityType: {},
    selectedPicture: {},
    selectedPictureIsLoading: false,
    selectedPictureIsLoadingId: NaN,
    selectedPictureAsBlob: new Blob(),
    savedFile: {
      pictureId: NaN,
      fileId: NaN,
      path: ''
    },
    selectedSignatureAsBlob: new Blob(),
    selectedSignatureIsLoading: false,
    selectedSignatureIsLoadingId: NaN,
    savedSignature: {
      id: NaN,
      index: NaN
    },
    detailDataFormatted: <ICustomPropertyStructureFormatted>{},
    paging: {
      totalItems: 0,
      pageNumber: 0,
      pageSize: env?.apiUrl?.pageDefaultSize || 1,
      totalPages: 1
    },
    isLoading: true,
    isLoadingId: NaN
  }
}

export const SharedIssueBaseStore = createReducer(
  getInitialSharedIssueState(),
  on(SharedIssueBaseActions.getItemsPaginated, (state) => ({
    ...state,
    isLoading: true
  })),
  on(SharedIssueBaseActions.getItemsPaginatedSuccess, (state, { data }) => ({
    ...state,
    // Important! include the current state.items and the data.items to create a new array with the
    // existing ones and the new one that is requested
    items: [...state.items, ...data.items],
    paging: data.paging,
    isLoading: false
  })),
  on(SharedIssueBaseActions.getItemsPaginatedRefresh, (state, { data }) => ({
    ...state,
    items: [...data.items],
    paging: data.paging,
    isLoading: false
  })),
  on(SharedIssueBaseActions.getItemsPaginatedCancel, (state) => ({
    ...state,
    isLoading: false
  })),
  on(SharedIssueBaseActions.getItemsPaginatedFail, (state) => ({
    ...state,
    isLoading: false
  })),
  on(SharedIssueBaseActions.getItemsTemplate, (state) => ({
    ...state,
    isLoading: true
  })),
  on(SharedIssueBaseActions.getItemsTemplateSuccess, (state, { data }) => ({
    ...state,
    itemsTemplate: [...data.items],
    isLoading: true
  })),
  on(SharedIssueBaseActions.getItemsTemplateDone, (state) => ({
    ...state,
    isLoading: false
  })),
  on(SharedIssueBaseActions.getItemsTemplateFail, (state) => ({
    ...state,
    isLoading: false
  })),
  on(SharedIssueBaseActions.getItemsTemplatePreviewByIssueType, (state, { id, issueType }) => ({
    ...state,
    shortCutTemplate: {
      issueTemplateId: id,
      contentType: issueType
    },
    selectedTemplate: {
      ...state.selectedTemplate,
      id,
      templateIssueType: issueType
    },
    isLoading: true
  })),
  on(SharedIssueBaseActions.getItemsTemplatePreview, (state) => ({
    ...state,
    isLoading: true
  })),
  on(SharedIssueBaseActions.getItemsTemplatePreviewSuccess, (state, { data }) => ({
    ...state,
    selectedTemplate: {
      ...state.selectedTemplate,
      data
    },
    isLoading: true
  })),
  on(SharedIssueBaseActions.getItemsResourceSuccess, (state, { data }) => ({
    ...state,
    isLoading: true
  })),
  on(SharedIssueBaseActions.getItemsTemplatePreviewDone, (state) => ({
    ...state,
    isLoading: false
  })),
  on(SharedIssueBaseActions.getItemsTemplatePreviewFail, (state) => ({
    ...state,
    isLoading: false
  })),
  on(SharedIssueBaseActions.getById, (state) => ({
    ...state,
    isLoading: true
  })),
  on(SharedIssueBaseActions.getIssueByIdByIssueType, (state, { id, issueType }) => ({
    ...state,
    selectedIssueItem: {
      id,
      issueType
    },
    isLoading: true
  })),
  on(SharedIssueBaseActions.getByIdSuccess, (state, { data }) => ({
    ...state,
    issueItem: data,
    isLoading: false
  })),
  on(SharedIssueBaseActions.getByIdFail, (state) => ({
    ...state,
    isLoading: false
  })),
  on(SharedIssueBaseActions.prepareFormsToSendTemplatePreview, (state) => ({
    ...state,
    isLoading: true
  })),
  on(SharedIssueBaseActions.sendTemplatePreview, (state) => ({
    ...state,
    isLoading: true
  })),
  on(SharedIssueBaseActions.sendTemplatePreviewSuccess, (state) => ({
    ...state,
    savedFile: getInitialSharedIssueState().savedFile,
    selectedPicture: getInitialSharedIssueState().selectedPicture,
    selectedPictureAsBlob: getInitialSharedIssueState().selectedPictureAsBlob,
    isLoading: false
  })),
  on(SharedIssueBaseActions.thanksPageNavigationBack, (state) => ({
    ...state,
    isLoading: true
  })),
  on(SharedIssueBaseActions.navigateToNewSpecificTemplate, (state, { templateShortCut }) => ({
    ...state,
    isLoading: true,
    shortCutTemplate: templateShortCut
  })),
  on(SharedIssueBaseActions.thanksPageNavigationBackDone, (state) => ({
    ...state,
    isLoading: false
  })),
  on(SharedIssueBaseActions.sendTemplatePreviewFail, (state) => ({
    ...state,
    isLoading: false
  })),
  on(SharedIssueBaseActions.getStereotypeById, (state, { issueId }) => ({
    ...state,
    selectedIssueId: issueId,
    isLoading: true
  })),
  on(SharedIssueBaseActions.navigateToTemplatePreview, (state, { templateId, templateIssueType }) => ({
    ...state,
    selectedTemplate: {
      ...state.selectedTemplate,
      id: templateId,
      templateIssueType
    },
    isLoading: false
  })),
  on(SharedIssueBaseActions.navigateToDetail, (state, { issueId, issueType, isNavigationRequired }) => ({
    ...state,
    isNavigationRequired: isNavigationRequired ?? true,
    selectedIssueItem: {
      id: issueId,
      issueType
    },
    isLoading: true
  })),
  on(SharedIssueBaseActions.getStereotypeByIdSuccess, (state, { stereotype }) => ({
    ...state,
    selectedStereotype: stereotype,
    isLoading: true
  })),
  on(SharedIssueBaseActions.getStereotypeByIdFail, (state) => ({
    ...state,
    isLoading: false
  })),
  on(SharedIssueBaseActions.getStereotypeByEntityType, (state) => ({
    ...state,
    isLoading: true
  })),
  on(SharedIssueBaseActions.getStereotypeByEntityTypeSuccess, (state) => ({
    ...state,
    isLoading: true
  })),
  on(SharedIssueBaseActions.getStereotypeByEntityTypeFail, (state) => ({
    ...state,
    isLoading: false
  })),
  on(SharedIssueBaseActions.formatReadDetailDataFinish, (state, { detailDataFormatted }) => ({
    ...state,
    detailDataFormatted,
    isLoading: false
  })),
  on(SharedIssueBaseActions.askPictureInput, (state, { isLoadingId }) => ({
    ...state,
    selectedPictureIsLoading: true,
    selectedPictureIsLoadingId: isLoadingId
  })),
  on(SharedIssueBaseActions.askPictureInputSuccess, (state, { picture, pictureId }) => ({
    ...state,
    selectedPicture: {
      picture,
      pictureId
    },
  })),
  on(SharedIssueBaseActions.askPictureInputFail, (state) => ({
    ...state,
    selectedPictureIsLoading: false
  })),
  on(SharedIssueBaseActions.reducePictureSuccess, (state, { blob }) => ({
    ...state,
    selectedPictureAsBlob: blob
  })),
  on(SharedIssueBaseActions.reducePictureError, (state,) => ({
    ...state,
    selectedPictureIsLoading: false
  })),
  on(SharedIssueBaseActions.savePictureSuccess, (state, { pictureId, fileId, path }) => ({
    ...state,
    savedFile: {
      pictureId,
      fileId,
      path
    },
    selectedPictureIsLoading: false
  })),
  on(SharedIssueBaseActions.savePictureFail, (state) => ({
    ...state,
    selectedPictureIsLoading: false
  })),
  on(SharedIssueBaseActions.savedPictureClear, (state) => ({
    ...state,
    savedFile: getInitialSharedIssueState().savedFile,
    selectedPicture: getInitialSharedIssueState().selectedPicture,
    selectedPictureAsBlob: getInitialSharedIssueState().selectedPictureAsBlob,
  })),
  on(SharedIssueBaseActions.convertDateURLToBlob, (state, { isLoadingId }) => ({
    ...state,
    selectedSignatureIsLoading: true,
    selectedSignatureIsLoadingId: isLoadingId
  })),
  on(SharedIssueBaseActions.convertDateURLToBlobSuccess, (state, { blob }) => ({
    ...state,
    selectedSignatureAsBlob: blob,
  })),
  on(SharedIssueBaseActions.convertDateURLToBlobFail, (state,) => ({
    ...state,
    selectedSignatureIsLoading: false
  })),
  on(SharedIssueBaseActions.saveSignatureSuccess, (state, { id, index }) => ({
    ...state,
    savedSignature: {
      id,
      index
    },
    selectedSignatureIsLoading: false
  })),
  on(SharedIssueBaseActions.saveSignatureFail, (state) => ({
    ...state,
    selectedSignatureIsLoading: false
  })),
  on(SharedIssueBaseActions.savedSignatureClear, (state) => ({
    ...state,
    savedSignature: getInitialSharedIssueState().savedSignature,
    selectedSignatureAsBlob: new Blob(),
  })),
  on(SharedIssueBaseActions.getWinterhalterProductOrderCatalog, (state) => ({
    ...state,
    isLoading: true
  })),
  on(SharedIssueBaseActions.getWinterhalterProductOrderCatalogSuccess, (state, { data }) => ({
    ...state,
    selectedProductOrderCatalog: data,
    isLoading: true
  })),
  on(SharedIssueBaseActions.getWinterhalterProductOrderCatalogFail, (state) => ({
    ...state,
    isLoading: true
  }))
);
