<form [formGroup]="parentForm">
  <ng-container *ngIf="currentTemplatePreview && currentProductOrderCatalog">
    <app-shared-issue-custom-form-winterhalter-product-order
      (inputChange)="formInnerModified($event)"
      [productOrderData]="currentTemplatePreview.data!"
      [catalogData]="currentProductOrderCatalog"
    ></app-shared-issue-custom-form-winterhalter-product-order>
  </ng-container>
</form>

