import {Component, Input, OnInit} from '@angular/core';
import {CommonModule} from "@angular/common";
import {IonicModule} from "@ionic/angular";
import {TranslateModule} from "@ngx-translate/core";
import {ReadInputTextComponent} from "@shared/components/custom-property-read/inputs/text/read-input-text.component";
import {
  WinterhalterServiceCallDetailsInterface
} from "@shared/components/issues/interfaces/winterhalter/service-call/details.interface";

@Component({
  selector: 'app-issue-custom-read-winterhalter-service-call',
  templateUrl: './service-call-read.component.html',
  styleUrls: ['./service-call-read.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, TranslateModule, ReadInputTextComponent],

})
export class IssueCustomWinterhalterServiceCallReadComponent implements OnInit {
  @Input() headerTitle: string;
  @Input() serviceCall: WinterhalterServiceCallDetailsInterface;

  constructor() {
    this.headerTitle = "";
    this.serviceCall = <WinterhalterServiceCallDetailsInterface>{};
  }

  ngOnInit() {

  }
}
