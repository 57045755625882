<ion-card
  *ngFor="let propertySets of propertySetsDetail; let setsIndex = index">
  <ion-card-header>
    <ion-text color="dark">
      <h4 class="ion-no-margin">
        {{ propertySets.name }}
      </h4>
    </ion-text>
  </ion-card-header>
  <ng-container *ngFor="let issue of propertySets.customPropertiesFormatted; let issueIndex = index">
    <app-shared-custom-property-read-only [title]="issue.title"
                                          [inputProperty]="issue"></app-shared-custom-property-read-only>
  </ng-container>
</ion-card>


