import {Injectable} from "@angular/core";
import {Camera, CameraResultType, Photo} from "@capacitor/camera";
import {from, Observable} from "rxjs";
import {AlertBaseControllerService} from "@shared/services/shared-alert-controller.service";
import {TranslateService} from "@ngx-translate/core";

@Injectable({
  providedIn: 'root'
})
export class SharedCameraService {

  constructor(
    private _alertBaseControllerService: AlertBaseControllerService,
    private _translateService: TranslateService
  ) {

  }


  /**
   * @name getPhoto
   * @description
   * get photo by capacitor as Observable
   * @memberof SharedCameraService
   */
  getPhoto(): Observable<Photo> {
    return from(this._getPhoto());
  }

  /**
   * @name photoToBlobByWebPath
   * @description
   * return an Observable Blob from webPath promise
   * @memberof SharedCameraService
   * @param photo
   */
  photoToBlobByWebPath(photo: any): Observable<Blob | null> {
    return from(this._photoToBlobByWebPath(photo));
  }

  /**
   * @name _photoToBlobByWebPath
   * @description
   * return a promise Blob from webPath
   * @memberof SharedCameraService
   * @param photo
   * @private
   */
  private async _photoToBlobByWebPath(photo: any): Promise<Blob | null> {
    if (photo?.hasOwnProperty('webPath')) {
      const response = await fetch(photo.webPath);
      return await response.blob();
    } else {
      return null
    }
  }

  /**
   * @name _getPhoto
   * @description
   * get photo by capacitor as promise
   * @memberof SharedCameraService
   * @private
   */
  private async _getPhoto(): Promise<Photo> {
    try {
      return await Camera.getPhoto({
        quality: 50,
        allowEditing: true,
        resultType: CameraResultType.Uri
      });

    } catch (error) {
      this._alertBaseControllerService.observableAlert({
        header: this._translateService.instant("TEAM.ISSUES.COMPONENTS.PICTURE.CAMERA.ALERT.HEADER"),
        message: this._translateService.instant("TEAM.ISSUES.COMPONENTS.PICTURE.CAMERA.ALERT.MESSAGE"),
        buttons: [
          {
            text: this._translateService.instant("TEAM.ISSUES.COMPONENTS.PICTURE.CAMERA.ALERT.BUTTONS.DETAILS.TITLE"),
            handler: () => this._alertBaseControllerService.observableAlert({
              header: this._translateService.instant("TEAM.ISSUES.COMPONENTS.PICTURE.CAMERA.ALERT.BUTTONS.DETAILS.HEADER"),
              message: error?.toString()!,
              buttons: [
                {
                  text: this._translateService.instant("TEAM.ISSUES.COMPONENTS.PICTURE.CAMERA.ALERT.BUTTONS.DETAILS.BUTTON")
                }
              ]
            })
          },
          {
            text: this._translateService.instant("TEAM.ISSUES.COMPONENTS.PICTURE.CAMERA.ALERT.BUTTONS.DETAILS.BUTTON")
          }
        ]
      })
      throw error
    }
  }
}
