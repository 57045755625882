import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {switchMap} from 'rxjs';
import {exhaustMap, map} from 'rxjs/operators';
import {Store} from "@ngrx/store";
import {SharedLoginStorageBaseService} from "../services/shared-login-storage-base.service";
import {Router} from "@angular/router";
import {generateColor} from "@core/helpers/color/core-helper-parse-css";
import {ColorVariable} from "@core/helpers/color/core-helper-color-variables";
import {TenantInfoDto, TokenDto} from "@server-models";
import {TranslateService} from "@ngx-translate/core";
import {SharedModalControllerService} from "@shared/services/shared-modal-controller.service";
import {SharedLoginBaseActions} from "@shared/stores/login-base/store/shared-login-base.actions-type";
import {AlertBaseControllerService} from "@shared/services/shared-alert-controller.service";
import {SharedLanguageService} from "@shared/services/shared-language.service";

@Injectable({
  providedIn: 'root'
})
export class SharedLoginBaseEffects {

  constructor(
    protected _store: Store,
    protected actions$: Actions,
    protected loginStorage: SharedLoginStorageBaseService,
    protected _alertControllerService: AlertBaseControllerService,
    protected _translateService: TranslateService,
    protected _modalControllerService: SharedModalControllerService,
    protected _router: Router,
    protected _languageService: SharedLanguageService
  ) {
  }

  loginFetchTenantSettingsFail$ = createEffect(() => this.actions$.pipe(
    ofType(SharedLoginBaseActions.fetchTenantSettingsFail),
    exhaustMap(() => this.loginStorage.remove()
    )), { dispatch: false });

  loginLogout$ = createEffect(() => this.actions$.pipe(
    ofType(SharedLoginBaseActions.initLogout),
    switchMap(() => this.loginStorage.clear().pipe(
      map(() => {
        this._router.navigate(['']).then(() => window.location.reload());
      })
    ))
  ), { dispatch: false });

  /**
   * @name _setTenantColor
   * @description
   * set the default color of a tenant
   * @memberof SharedLoginBaseEffects
   * @param colorHexString
   * @private
   */
  protected _setTenantColor(colorHexString: string): void {
    const isSemcoColor = colorHexString.toUpperCase() == '#F1B143';
    if (isSemcoColor) {
      const app = (window.location?.pathname?.split("/") || [])[1]?.toLowerCase();
      switch (app) {
        case "tech":
          colorHexString = "#0052a1";
          break;
        case "team":
          colorHexString = "#e73a23";
          break;
        case "link":
          colorHexString = "#02a14d";
          break;
      }
    }

    if (colorHexString !== undefined) {
      const color: ColorVariable = generateColor("primary", "--ion-color-primary", colorHexString);
      const attrMap: ColorVariable = {
        value: '',
        valueRgb: '-rgb',
        contrast: '-contrast',
        contrastRgb: '-contrast-rgb',
        shade: '-shade',
        tint: '-tint',
      };

      //force semco contrast
      if (isSemcoColor) {
        color.contrast = '#ffffff';
        color.contrastRgb = '255,255,255;';
      }

      Object.keys(attrMap).forEach(key => {
        document.documentElement.style.setProperty('--ion-color-primary' + attrMap[key], color[key]!);
      });

      document.querySelector('meta[name="theme-color"]')?.setAttribute('content', colorHexString);
    }
  }

  /**
   * @name _selectedTenantDisplayName
   * @description
   * return the displayName of a tenantId
   * @memberof SharedLoginBaseEffects
   * @param tenants
   * @param tenantId
   * @private
   */
  protected _selectedTenantDisplayName(tenants: TenantInfoDto[], tenantId: number): string | null | undefined {
    return tenants?.find(tenant => tenant.tenantId === tenantId)!?.displayName;
  }

  /**
   * @name _stripJwt
   * @description
   * remove Jwt key from TokenDto Object
   * @param tokenDto
   * @protected
   * @returns {TokenDto} (-token)
   */
  protected _stripJwt(tokenDto: TokenDto): TokenDto {
    let strippedTokenDto = tokenDto!;
    const { token, ...newTokenDto } = strippedTokenDto;
    return newTokenDto;
  }
}
