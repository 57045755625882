/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum RoleGeneralTerms {
    OrgaUser = '0',
    Admin = 1,
    Manager = 2,
    DeviceUser = 4,
    SystemAdmin = 5,
    Custom = 6,
    ServiceEmployee = 12,
    QuickAccessUser = 13,
    QuickAccessKnowledge = 14,
    QuickAccessIssueTemplate = 15,
    QuickAccessDashboard = 16,
    TeamUser = 17,
    Analyst = 18,
    QuickAccessIssueHistory = 19,
    TechUser = 20,
}
