import {Injectable} from "@angular/core";
import {SharedLoginStorageBaseService} from "@shared/stores/login-base/services/shared-login-storage-base.service";

@Injectable({
  providedIn: 'root'
})
export class LinkLoginStorageService extends SharedLoginStorageBaseService {
  linkId?: number;
  linkKey?: string;
}
