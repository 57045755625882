import {Component, Input, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {TranslateModule} from "@ngx-translate/core";

@Component({
  selector: 'app-shared-custom-property-read-only-input-text',
  templateUrl: './shared-custom-property-read-only-input-text.component.html',
  styleUrls: ['./shared-custom-property-read-only-input-text.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, TranslateModule]
})
export class SharedCustomPropertyReadOnlyInputTextComponent implements OnInit {

  @Input() title: string;
  @Input() value: string;
  @Input() hasNoPadding: boolean = false;

  constructor() {
    this.title = "";
    this.value = "";
  }

  ngOnInit() {

  }
}
