import {Component, Input, OnInit} from '@angular/core';
import {CommonModule} from "@angular/common";
import {IonicModule} from "@ionic/angular";
import {SharedCustomPropertyReadOnlyComponent} from "@shared/components/custom-property-read-only/shared-custom-property-read-only.component";
import {
  ICustomPropertySetFormatted
} from "@shared/components/custom-property-form/interfaces/custom-property-set-formatted.interface";

@Component({
  selector: 'app-shared-custom-property-sets-read-only-card',
  templateUrl: './shared-custom-property-sets-read-only-card.component.html',
  styleUrls: ['./shared-custom-property-sets-read-only-card.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, SharedCustomPropertyReadOnlyComponent],

})
export class SharedCustomPropertySetsReadOnlyCardComponent implements OnInit {
  @Input() propertySetsDetail: ICustomPropertySetFormatted[];


  constructor() {
    this.propertySetsDetail = [];
  }

  ngOnInit() {
  }

}
