import {Component, OnInit} from '@angular/core';
import {CommonModule} from "@angular/common";
import {IonicModule} from "@ionic/angular";
import {SharedSafePipe} from "@shared/pipes/shared-safe.pipe";
import {select, Store} from "@ngrx/store";
import {Observable} from "rxjs";
import {
  ICustomPropertyStructureFormatted
} from "@shared/components/custom-property-form/interfaces/custom-property-structure-formatted.interface";
import {SharedIssueBaseSelectors} from "@shared/components/issues/store/shared-issue-base.selectors-type";
import {SharedIssueBaseState} from "@shared/components/issues/store/shared-issue-base.state";
import {TranslateModule} from "@ngx-translate/core";
import {
  ControlTriggerType,
  IssueReasonType,
  MessageControlDto,
  ReachedConsumptionIssueReasonProtocolListDto,
  ReachedErrorIssueReasonProtocolListDto, ReachedFixedTimeIssueReasonProtocolListDto
} from "@server-models";
import {SharedDateHelperService} from "@shared/services/shared-date-helper.service";

@Component({
  selector: 'app-shared-issue-read-only-message-control',
  templateUrl: './shared-issue-read-only-message-control.component.html',
  styleUrls: ['./shared-issue-read-only-message-control.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, SharedSafePipe, TranslateModule],

})
export class SharedIssueReadOnlyMessageControlComponent implements OnInit {
  id: number;
  isLoading$!: Observable<boolean>;
  detailDataFormatted$!: Observable<ICustomPropertyStructureFormatted>;

  reasonErrorCode?: ReachedErrorIssueReasonProtocolListDto;
  reasonConsumption?: ReachedConsumptionIssueReasonProtocolListDto;
  reasonFixedTime?: ReachedFixedTimeIssueReasonProtocolListDto;

  protected readonly IssueReasonType = IssueReasonType;
  protected readonly ControlTriggerType = ControlTriggerType;

  constructor(
    private _store: Store<SharedIssueBaseState>,
    public dateHelperService: SharedDateHelperService
  ) {
    this.id = NaN;
    this.reasonErrorCode = undefined;
    this.reasonConsumption = undefined;
    this.reasonFixedTime = undefined;
  }

  ngOnInit() {
    this.isLoading$ = this._store.pipe(select(SharedIssueBaseSelectors.selectIsLoading));
    this.detailDataFormatted$ = this._store.pipe(select(SharedIssueBaseSelectors.selectDetailDataFormatted));
  }

  checkReasonType(controlMessage: MessageControlDto, issueReasonType: IssueReasonType):
    | ReachedErrorIssueReasonProtocolListDto
    | ReachedConsumptionIssueReasonProtocolListDto
    | ReachedFixedTimeIssueReasonProtocolListDto
    | undefined {
    switch (issueReasonType) {
      case IssueReasonType.ReachedError: {
        return this.reasonErrorCode = controlMessage.reason as ReachedErrorIssueReasonProtocolListDto;
      }
      case IssueReasonType.ReachedConsumption: {
        return this.reasonConsumption = controlMessage.reason as ReachedConsumptionIssueReasonProtocolListDto;
      }
      case IssueReasonType.ReachedFixedTime: {
        return this.reasonFixedTime = controlMessage.reason as ReachedFixedTimeIssueReasonProtocolListDto;
      }
      default: {
        return
      }
    }
  }

  convertFrequency(frequency: string): { years: number; weeks: number; days: number } {
    return this.dateHelperService.splitTimeStringToYearsWeeksDays(frequency);
  }

  /**
   * @name formatFrequencyAmount
   * @description
   * will remove the last character from the incoming string when the amount is less than 1
   * this works for English and German language (also Spanish)
   * @param type
   * @param amount
   */
  formatFrequencyAmount(type: string, amount: number): string {
    return amount == 1 ? type.substring(0, type.length - 1) : type;
  }
}
