import {Component, Input, OnInit} from '@angular/core';
import {Observable, of} from "rxjs";
import {KnowledgeArticleDto} from '@server-models';
import {CommonModule} from "@angular/common";
import {IonicModule} from "@ionic/angular";
import {DomSanitizer} from "@angular/platform-browser";
import {SharedAttachmentsComponent} from "@shared/components/attachments/shared-attachments.component";
import {SharedUserHtmlComponent} from "@shared/components/user-html/shared-user-html.component";

@Component({
  selector: 'app-knowledge-detail',
  templateUrl: './knowledge-detail.component.html',
  styleUrls: ['./knowledge-detail.component.scss'],
  standalone: true,
  imports: [CommonModule, IonicModule, SharedAttachmentsComponent, SharedUserHtmlComponent]
})
export class KnowledgeDetailComponent implements OnInit {

  @Input()
  public knowledge$: Observable<KnowledgeArticleDto | undefined> = of();

  constructor(
    private sanitizer: DomSanitizer
  ) {
  }

  ngOnInit() {
  }

  sanitize(content: string | null | undefined) {
    return this.sanitizer.bypassSecurityTrustHtml(content || '');
  }
}
