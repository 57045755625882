import {Component, Input, OnInit} from '@angular/core';
import {CommonModule} from "@angular/common";
import {IonicModule} from "@ionic/angular";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {
  MissionIssueDetailsDto,
  MissionState
} from "@server-models";
import {
  MissionStateOptionsComponent
} from "@shared/components/issues/components/custom/read/mission/components/mission-state-options/mission-state-options.component";

@Component({
  selector: 'app-issue-custom-read-mission',
  templateUrl: './issue-custom-mission-read.component.html',
  styleUrls: ['./issue-custom-mission-read.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, TranslateModule, MissionStateOptionsComponent],

})
export class IssueCustomMissionReadComponent implements OnInit {
  @Input() missionDetail: MissionIssueDetailsDto;
  protected readonly MissionState = MissionState;

  constructor(
    private _translate: TranslateService
  ) {
    this.missionDetail = {};
  }

  ngOnInit() {
  }
}
