import {Component, OnInit} from '@angular/core';
import {CommonModule} from "@angular/common";
import {IonicModule} from "@ionic/angular";
import {TranslateModule} from "@ngx-translate/core";
import {SharedSafePipe} from "@shared/pipes/shared-safe.pipe";
import {select, Store} from "@ngrx/store";
import {SharedUserHtmlComponent} from "@shared/components/user-html/shared-user-html.component";
import {SharedAttachmentsComponent} from "@shared/components/attachments/shared-attachments.component";
import {Observable} from "rxjs";
import {
  SharedCustomPropertyReadOnlyComponent
} from "@shared/components/custom-property-read-only/shared-custom-property-read-only.component";
import {
  ICustomPropertyStructureFormatted
} from "@shared/components/custom-property-form/interfaces/custom-property-structure-formatted.interface";
import {SharedIssueBaseSelectors} from "@shared/components/issues/store/shared-issue-base.selectors-type";
import {SharedIssueBaseState} from "@shared/components/issues/store/shared-issue-base.state";
import {
  SharedCustomPropertyReadOnlyInputTextComponent
} from "@shared/components/custom-property-read-only/components/input-text/shared-custom-property-read-only-input-text.component";
import {SharedImageService} from "@shared/services/shared-image.service";

@Component({
  selector: 'app-shared-issue-read-only-winterhalter-product-order',
  templateUrl: './shared-issue-read-only-winterhalter-product-order.component.html',
  styleUrls: ['./shared-issue-read-only-winterhalter-product-order.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, TranslateModule, SharedSafePipe, SharedUserHtmlComponent, SharedAttachmentsComponent, SharedCustomPropertyReadOnlyComponent, SharedCustomPropertyReadOnlyInputTextComponent],

})
export class SharedIssueReadOnlyWinterhalterProductOrderComponent implements OnInit {
  id: number;
  isLoading$!: Observable<boolean>;
  detailDataFormatted$!: Observable<ICustomPropertyStructureFormatted>;

  constructor(
    private _store: Store<SharedIssueBaseState>,
    public imageService: SharedImageService
  ) {
    this.id = NaN;
  }

  ngOnInit() {
    this.isLoading$ = this._store.pipe(select(SharedIssueBaseSelectors.selectIsLoading));
    this.detailDataFormatted$ = this._store.pipe(select(SharedIssueBaseSelectors.selectDetailDataFormatted));
  }
}
