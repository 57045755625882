import {Component, Input, OnInit} from "@angular/core";
import {IonicModule} from "@ionic/angular";
import {CommonModule} from "@angular/common";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {AttachmentInternalDto} from "@shared/components/attachments/interfaces/attachment-internal-dto.interface";
import {AttachmentTypes} from "@server-models";
import {AttachmentsService} from "@shared/components/attachments/services/attachments.service";

@Component({
  selector: 'app-attachments-box',
  templateUrl: './attachments-box.component.html',
  styleUrls: ['./attachments-box.component.scss'],
  imports: [
    IonicModule,
    CommonModule,
    TranslateModule
  ],
  standalone: true
})
export class AttachmentsBoxComponent implements OnInit {
  @Input() mappedAttachments: AttachmentInternalDto[];

  attachmentType: typeof AttachmentTypes = AttachmentTypes;

  constructor(
    public attachmentsService: AttachmentsService,
    private _translateService: TranslateService
  ) {
    this.mappedAttachments = [];
  }

  ngOnInit() {
  }

  /**
   * @name getAttachmentByType
   * @description
   * return the label or icon related to AttachtmentType
   * @memberof AttachmentsBoxComponent
   * @param attachmentType
   */
  getAttachmentByType(attachmentType: AttachmentTypes): { label: string, icon: string } {
    let valueByType;
    switch (attachmentType) {
      case AttachmentTypes.File: {
        valueByType = {
          label: this._translateService.instant('COMPONENTS.ATTACHMENTS.FILES.TYPES.FILE'),
          icon: 'document-attach-outline'
        }
      }
        break;
      case AttachmentTypes.Knowledge: {
        valueByType = {
          label: this._translateService.instant('COMPONENTS.ATTACHMENTS.FILES.TYPES.KNOWLEDGE'),
          icon: 'library-outline'
        }
      }
        break;
      case AttachmentTypes.Link: {
        valueByType = {
          label: this._translateService.instant('COMPONENTS.ATTACHMENTS.FILES.TYPES.LINK'),
          icon: 'link-outline'
        }
      }
        break;
      default: {
        valueByType = {
          label: this._translateService.instant('COMPONENTS.ATTACHMENTS.FILES.TYPES.FILE'),
          icon: 'document-attach-outline'
        }
      }
    }

    return valueByType;
  }
}
