import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {IssueListDto} from "@server-models";
import {CommonModule} from "@angular/common";
import {IonicModule} from "@ionic/angular";
import {TranslateModule} from "@ngx-translate/core";
import {SafePipe} from "@shared/pipes/safe.pipe";
import {Store} from "@ngrx/store";
import {UserHtmlComponent} from "@features/user-html/user-html.component";
import {AttachmentsComponent} from "@shared/components/attachments/attachments.component";
import {RouterLink} from "@angular/router";
import {BaseIssuesActions} from "@shared/components/issues/store/base-issues.action-type";
import {ClockService} from "@core/services/clock.service";
import {CacheService} from "@shared/services/cache/cache.service";

@Component({
  selector: 'app-base-issue-card',
  templateUrl: './base-issue-card.component.html',
  styleUrls: ['./base-issue-card.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, TranslateModule, SafePipe, UserHtmlComponent, AttachmentsComponent, RouterLink],

})
export class BaseIssueCardComponent implements OnInit {

  @Input() issue: IssueListDto;
  @Output() navigateToIssueEvent: EventEmitter<void>;

  formattedDate: string;

  constructor(
    private _store: Store,
    private _cacheService: CacheService,
    private _clockService: ClockService
  ) {
    this.issue = {};
    this.formattedDate = "";
    this.navigateToIssueEvent = new EventEmitter<void>();
  }

  ngOnInit() {
    this.formattedDate = this._clockService.format(this._clockService.parse(this.issue.lastModified!));
  }

  navigateToIssue(issue: IssueListDto): void {
    this.navigateToIssueEvent.emit();

    this._store.dispatch(BaseIssuesActions.navigateToDetail({ issueId: issue.issueId!, issueType: issue.issueType!, cacheGuid: this._cacheService.generateGuid() }))
  }

}
