<ion-header [translucent]="true">
  <ion-toolbar color="primary">
    <div class="container">
      <ion-buttons>
        <ion-button (click)="back()">
          <ion-icon slot="start" name="arrow-back-sharp"></ion-icon>
        </ion-button>
      </ion-buttons>
      <ng-container>
        <ion-spinner *ngIf="(isLoading$ | async)"></ion-spinner>
        <ion-title
          *ngIf="!(isLoading$ | async)">{{ ('TEAM.ISSUES.PAGES.READ.PAGE_NAME' | translate) + ': ' + (detailDataFormatted$ | async)?.headerTitle }}
        </ion-title>
      </ng-container>
    </div>
  </ion-toolbar>
</ion-header>
<ion-content>
  <ion-spinner *ngIf="(isLoading$ | async)"></ion-spinner>
  <ion-card>
    <ion-card-content>
      <ng-container *ngIf="!(isLoading$ | async)">
        <ng-container
          *ngIf="(detailDataFormatted$ | async) as detailDataFormatted"
          [ngSwitch]="detailDataFormatted.issueType">
          <app-base-issue-read-message *ngSwitchCase="IssueType.Message"></app-base-issue-read-message>
          <app-base-issue-read-mission *ngSwitchCase="IssueType.Mission"></app-base-issue-read-mission>
          <app-base-issue-read-ticket *ngSwitchCase="IssueType.Ticket"></app-base-issue-read-ticket>
          <app-base-issue-read-winterhalter-service-call *ngSwitchCase="IssueType.WinterhalterServiceCall"></app-base-issue-read-winterhalter-service-call>
          <app-base-issue-read-winterhalter-product-order *ngSwitchCase="IssueType.WinterhalterProductOrder"></app-base-issue-read-winterhalter-product-order>
        </ng-container>
      </ng-container>
    </ion-card-content>

  </ion-card>

</ion-content>
