/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum SeedSequence {
    Init = '0',
    InitTechAdmin = 1,
    InitCorporateIdentitySetting = 2,
    AddGeneralSettingDefaultLanguage = 3,
    InitTaskJobStatusGenre = 4,
    MigrateOldAppUser = 5,
    InitDashboardRole = 6,
    InitChatManagerRole = 7,
    InitInfoManagerRole = 8,
    InitSemcoDefaultStereotype = 9,
    InitTicketSetting = 10,
    InitMissionSetting = 11,
    InitContractDefault = 12,
    InitArticleKind = 13,
    InitArticleStereotypes = 14,
    AddGeneralSettingDefaultLoginMethod = 15,
    AddGeneralSettingAvailableLoginMethod = 16,
    InitServiceEmployee = 17,
    InitInspectionReportStereotype = 18,
    InitControlTagStereotype = 19,
    InitNewChatAndTaskTemplates = 20,
    InitQuickAccess = 21,
    InitQuickAccessProcesses = 22,
    InitQuickAccessDashboard = 23,
    InitTeamApp = 24,
    InitAnalyst = 25,
    InitQuickAccessIssueHistory = 26,
    InitTechApp = 27,
    AddDefaultMissionReportSettings = 28,
}
