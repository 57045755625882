import {Component, Input, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {TranslateModule} from "@ngx-translate/core";
import {SharedDateHelperService} from "@shared/services/shared-date-helper.service";

@Component({
  selector: 'app-shared-custom-property-read-only-input-time-of-day',
  templateUrl: './shared-custom-property-read-only-input-time-of-day.component.html',
  styleUrls: ['./shared-custom-property-read-only-input-time-of-day.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, TranslateModule]
})
export class SharedCustomPropertyReadOnlyInputTimeOfDayComponent implements OnInit {

  @Input() title: string;
  @Input() value: number;

  timeOfDayISO: string;

  constructor(private _dateHelper: SharedDateHelperService) {
    this.title = "";
    this.value = NaN;
    this.timeOfDayISO = "";
  }

  ngOnInit() {
    this.timeOfDayISO = this._dateHelper.convertMinutesToISO(this.value)!;

  }
}
