import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {CommonModule} from "@angular/common";
import {IonicModule} from "@ionic/angular";
import {TranslateModule} from "@ngx-translate/core";
import {SharedSafePipe} from "@shared/pipes/shared-safe.pipe";
import {SharedUserHtmlComponent} from "@shared/components/user-html/shared-user-html.component";
import {SharedAttachmentsComponent} from "@shared/components/attachments/shared-attachments.component";
import {RouterLink} from "@angular/router";
import {SharedCustomPropertyFormInputComponent} from "@shared/components/custom-property-form/components/input/shared-custom-property-form-input.component";
import {FormBuilder, FormGroup, ReactiveFormsModule} from "@angular/forms";
import {SharedCustomPropertyFormInputTextComponent} from "@shared/components/custom-property-form/components/input/components/text/shared-custom-property-form-input-text.component";
import {
  IssueContentType,
  TicketIssuePreviewDto
} from "@server-models";
import {
  SharedIssueCustomFormPriorityComponent
} from "@shared/components/issues/components/custom/form/priority/shared-issue-custom-form-priority.component";
import {Observable, of} from "rxjs";
import {select, Store} from "@ngrx/store";
import {SharedIssueBaseSelectors} from "@shared/components/issues/store/shared-issue-base.selectors-type";
import {TTemplatePreviewDto} from "@shared/components/issues/types/template-preview-dto.type";
import {SharedCustomPropertyFormComponent} from "@shared/components/custom-property-form/shared-custom-property-form.component";

@Component({
  selector: 'app-shared-issue-new-template-detail-ticket',
  templateUrl: './shared-issue-new-template-detail-ticket.component.html',
  styleUrls: ['./shared-issue-new-template-detail-ticket.component.scss'],
  standalone: true,
  imports: [
    IonicModule,
    CommonModule,
    TranslateModule,
    SharedSafePipe,
    SharedUserHtmlComponent,
    SharedAttachmentsComponent,
    RouterLink,
    SharedCustomPropertyFormInputTextComponent,
    ReactiveFormsModule,
    SharedCustomPropertyFormInputComponent,
    SharedIssueCustomFormPriorityComponent,
    SharedCustomPropertyFormComponent
  ]
})
export class SharedIssueNewTemplateDetailTicketComponent implements OnInit {

  @Output() formChange = new EventEmitter<FormGroup>();
  parentForm: FormGroup;
  selectedTemplatePreview$: Observable<{
    id: number,
    templateIssueType: IssueContentType,
    data: TTemplatePreviewDto
  }>;
  currentTemplatePreview: {
    issueContentType: IssueContentType,
    data: TicketIssuePreviewDto
  };

  constructor(
    private _fb: FormBuilder,
    private _store: Store
  ) {
    this.parentForm = this._fb.group({});
    this.selectedTemplatePreview$ = of(<{
      id: number,
      templateIssueType: IssueContentType,
      data: TTemplatePreviewDto
    }>{});
    this.currentTemplatePreview = <{
      issueContentType: IssueContentType,
      data: TicketIssuePreviewDto
    }>{};
  }

  ngOnInit() {
    this.selectedTemplatePreview$ = this._store.pipe(select(SharedIssueBaseSelectors.selectSelectedTemplate));
    this.selectedTemplatePreview$.subscribe((templatePreview) => {
      this.currentTemplatePreview = {
        issueContentType: templatePreview.templateIssueType,
        data: templatePreview.data as TicketIssuePreviewDto
      };
    })
  }

  formInnerModified(ev: any) {
    this.parentForm = ev;
    this.formChange.emit(this.parentForm);
  }
}
