<form [formGroup]="categoryForm">
  <ion-card>
    <ion-card-header *ngIf="serviceCalLData">
      <ion-text color="dark">
        <h4 class="ion-no-margin">
          {{ serviceCalLData.name }}
        </h4>
      </ion-text>
    </ion-card-header>
    <ion-card-content class="space-reduce">
      <ion-list class="ion-no-padding">

        <!-- Category -->
        <ion-item class="ion-no-padding">
          <ion-select
            (ionChange)="onCategoryChange($event)"
            formControlName="category"
            interface="popover"
            labelPlacement="stacked"
            [label]="('COMPONENTS.ISSUES.SERVICES.WINTERHALTER.SERVICE_CALL.CATEGORY_LABEL' | translate) + ' *'">
            <ion-select-option *ngFor="let category of categoryList" [value]="category.key">
              {{ category.translation }}
            </ion-select-option>
          </ion-select>
        </ion-item>

        <div class="input-error"
             *ngIf="categoryForm.get('category')?.errors && (categoryForm.get('category')?.dirty || categoryForm.get('category')?.touched)">
          <p
            *ngIf="categoryForm.get('category')?.errors?.['required']">{{ 'TEAM.ISSUES.COMPONENTS.INPUTS.ERRORS.REQUIRED' | translate }}</p>
        </div>

        <!-- Problem (depends on selected category) -->
        <ion-item class="ion-no-padding" *ngIf="selectedCategoryProblems && selectedCategoryProblems.length > 0">
          <ion-select
            formControlName="problem"
            interface="popover"
            labelPlacement="stacked"
            [label]="('COMPONENTS.ISSUES.SERVICES.WINTERHALTER.SERVICE_CALL.PROBLEM_LABEL' | translate) + ' *'">
            <ion-select-option *ngFor="let problem of selectedCategoryProblems" [value]="problem.key">
              {{ problem.translation }}
            </ion-select-option>
          </ion-select>
        </ion-item>

        <div class="input-error"
             *ngIf="categoryForm.get('problem')?.errors && (categoryForm.get('problem')?.dirty || categoryForm.get('problem')?.touched)">
          <p
            *ngIf="categoryForm.get('problem')?.errors?.['required']">{{ 'TEAM.ISSUES.COMPONENTS.INPUTS.ERRORS.REQUIRED' | translate }}</p>
        </div>

        <!-- Message  -->
        <app-shared-custom-property-form-input-multi-text
          [label]="'COMPONENTS.ISSUES.SERVICES.WINTERHALTER.SERVICE_CALL.MESSAGE_LABEL' | translate"
          [isRequired]="isRequired('message')"
          [formControl]="getControl('message')">
        </app-shared-custom-property-form-input-multi-text>

        <!-- Contact First Name  -->
        <app-shared-custom-property-form-input-text
          [label]="'COMPONENTS.ISSUES.SERVICES.WINTERHALTER.SERVICE_CALL.FIRST_NAME_LABEL' | translate"
          [formControl]="getControl('contactFirstName')"
          [isRequired]="isRequired('contactFirstName')"></app-shared-custom-property-form-input-text>

        <!-- Contact Last Name  -->
        <app-shared-custom-property-form-input-text
          [label]="'COMPONENTS.ISSUES.SERVICES.WINTERHALTER.SERVICE_CALL.LAST_NAME_LABEL' | translate"
          [formControl]="getControl('contactLastName')"
          [isRequired]="isRequired('contactLastName')"></app-shared-custom-property-form-input-text>

      </ion-list>
    </ion-card-content>
  </ion-card>
</form>
