import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges
} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {
  ControlValueAccessor,
  FormControl,
  FormsModule, NG_VALUE_ACCESSOR,
  ReactiveFormsModule
} from "@angular/forms";
import {TranslateModule} from "@ngx-translate/core";
import {select, Store} from "@ngrx/store";
import {SharedSafePipe} from "@shared/pipes/shared-safe.pipe";
import {Observable, of, withLatestFrom} from "rxjs";
import {map} from "rxjs/operators";
import {SharedIssueBaseState} from "@shared/components/issues/store/shared-issue-base.state";
import {SharedIssueBaseSelectors} from "@shared/components/issues/store/shared-issue-base.selectors-type";
import {SharedIssueBaseActions} from "@shared/components/issues/store/shared-issue-base.action-type";
import {IPhotoValue} from "@shared/components/custom-property-form/interfaces/photo-value.interface";


@Component({
  selector: 'app-shared-custom-property-form-input-picture',
  templateUrl: './shared-custom-property-form-input-picture.component.html',
  styleUrls: ['./shared-custom-property-form-input-picture.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, FormsModule,
    ReactiveFormsModule, TranslateModule, SharedSafePipe],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: SharedCustomPropertyFormInputPictureComponent
    }
  ]
})
export class SharedCustomPropertyFormInputPictureComponent implements OnChanges, OnDestroy, ControlValueAccessor {

  @Input() label: string = '';
  @Input() isRequired: boolean;
  @Output() valueChange = new EventEmitter<string>();
  @Input() formControl: FormControl;
  @Input() propertyId: number;
  @Input() index: number;

  value: any;
  isCameraEnabled: boolean;
  isCameraOverlayEnabled: boolean;
  isUploadEnabled: boolean;
  photoValues: IPhotoValue[]
  pictureLoading$: Observable<boolean>;
  pictureLoadingId$: Observable<number>;


  onChange: any = () => {
  };
  onTouch: any = () => {
  };

  constructor(
    private _store: Store<SharedIssueBaseState>,
    private _cd: ChangeDetectorRef
  ) {
    this.formControl = new FormControl();
    this.isCameraEnabled = true;
    this.isCameraOverlayEnabled = false;
    this.isUploadEnabled = false;
    this.pictureLoading$ = of(false);
    this.pictureLoadingId$ = of(NaN);
    this.index = NaN;
    this.isRequired = false;
    this.photoValues = [];
    this.propertyId = NaN;
  }

  writeValue(value: any): void {
    if (value !== undefined) {
      this.value = value;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['formControl']) {
      this.photoValues.push({ isCameraButtonShown: false, pictureId: this.propertyId });
      this.pictureLoading$ = this._store.pipe(select(SharedIssueBaseSelectors.selectSelectedPictureIsLoading));
      this.pictureLoadingId$ = this._store.pipe(select(SharedIssueBaseSelectors.selectSelectedPictureIsLoadingId));

      if (changes['formControl'].currentValue?.value === null) {
        this.clear(this.propertyId);
      }

      this._store.pipe(select(SharedIssueBaseSelectors.selectSelectedPicture)).subscribe(
        (value) => {
          if (Object.keys(value).length > 0) {
            const { pictureId } = value;
            const index = this.photoValues.findIndex((photo: IPhotoValue) => photo.pictureId === pictureId);
            if (index !== -1) {
              this.photoValues.splice(index, 1, { ...value, id: this.propertyId, isCameraButtonShown: true });
            }
          }
        });
      this._store.pipe(
        select(SharedIssueBaseSelectors.selectSavedFile),
        map((savedFile) => {
          if (savedFile.pictureId === this.propertyId) {
            this.formControl.setValue({
              ownValue: savedFile.fileId
            });
          }
        })
      ).subscribe()
    }
  }

  /**
   * @name hasCameraButtonState
   * @description
   * check the isCameraButtonShown of the photoValues to hide or show the camera button
   * @memberof SharedCustomPropertyFormInputPictureComponent
   * @param propertyId
   */
  hasCameraButtonState(propertyId: number): boolean {
    const index = this.photoValues.findIndex((photo: IPhotoValue) => photo.pictureId === propertyId);
    return this.photoValues[index].isCameraButtonShown;
  }

  /**
   * @name pictureTakeDispatch
   * @description
   * dispatch the action to ask for picture
   * @memberof SharedCustomPropertyFormInputPictureComponent
   */
  pictureTakeDispatch() {
    this.formControl.markAsDirty();
    this.formControl.markAsTouched();
    this._store.dispatch(SharedIssueBaseActions.askPictureInput({
      isLoadingId: this.index,
      pictureId: this.propertyId
    }))

  }

  /**
   * @name clear
   * @description
   * clear the webpath and the formcontrol value setting as undefined
   * @memberof SharedCustomPropertyFormInputPictureComponent
   * @param propertyId
   */
  clear(propertyId: number) {
    const index = this.photoValues.findIndex((photo: IPhotoValue) => photo.pictureId === propertyId);
    this.photoValues[index].isCameraButtonShown = false;
    this.photoValues[index].picture = undefined;
    this.formControl.markAsDirty();
    this.formControl.setValue({
      ownValue: undefined,
      onwFileValue: undefined
    });
  }

  clearImage(): void {
    if (this.formControl.value.ownFileValue) {
      this.formControl.setValue({
        ownValue: undefined,
        onwFileValue: undefined
      });
    }
  }

  /**
   * @name checkLoading
   * @description
   * check if there is a isLoading state with a specific index
   * @memberof SharedCustomPropertyFormInputPictureComponent
   */
  checkLoading(): Observable<boolean> {
    return this.pictureLoadingId$.pipe(
      withLatestFrom(this.pictureLoading$),
      map(([id, isLoading]) => (this.index! === id) && isLoading)
    );
  }

  /**
   * @name checkLines
   * @description
   * check if existing webPath to add or remove bottom line
   * @memberof SharedCustomPropertyFormInputPictureComponent
   * @param propertyId
   */
  checkLines(propertyId: number): string {
    const index = this.photoValues.findIndex((photo: IPhotoValue) => photo.pictureId === propertyId);
    return this.photoValues[index].picture?.webPath ? "none" : "";
  }

  ngOnDestroy(): void {
    this._store.dispatch(SharedIssueBaseActions.savedPictureClear({}));
  }

}
