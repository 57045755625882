import {Component, OnInit} from '@angular/core';
import {select, Store} from "@ngrx/store";
import {IonicModule, ViewWillEnter} from "@ionic/angular";
import {TranslateModule} from "@ngx-translate/core";
import {AsyncPipe, NgForOf, NgIf} from "@angular/common";
import {Observable} from "rxjs";
import {ActivatedRoute, Router} from "@angular/router";
import {IssueTemplateDto, IssueTemplateListDto} from "@server-models";
import {SharedIssueBaseSelectors} from "@shared/components/issues/store/shared-issue-base.selectors-type";
import {SharedIssueBaseActions} from "@shared/components/issues/store/shared-issue-base.action-type";
import {SharedIssueBaseService} from "@shared/components/issues/services/shared-issue-base.service";

@Component({
  selector: 'app-shared-issue-new-template-list',
  templateUrl: './shared-issue-new-template-list.page.html',
  styleUrls: ['./shared-issue-new-template-list.page.scss'],
  imports: [
    IonicModule,
    TranslateModule,
    AsyncPipe,
    NgIf,
    NgForOf
  ],
  standalone: true
})
export class SharedIssueNewTemplateListPage implements OnInit, ViewWillEnter {
  isLoading$!: Observable<boolean>;
  issuesTemplateList$!: Observable<IssueTemplateListDto[]>;
  isBackButtonEnable: boolean;

  issueTemplateList!: IssueTemplateListDto[];

  constructor(
    private _store: Store,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _issueBaseService: SharedIssueBaseService
  ) {
    this.isBackButtonEnable = true;
  }

  ngOnInit() {
    this.isLoading$ = this._store.pipe(select(SharedIssueBaseSelectors.selectIsLoading));
    this.issuesTemplateList$ = this._store.pipe(select(SharedIssueBaseSelectors.selectPageTemplate));
    this.issuesTemplateList$.subscribe((templateList => {
      this.issueTemplateList = this._issueBaseService.filterValidIssueContentTypes(templateList);
    }));
  }

  ionViewWillEnter() {
    const activatedRouterData = this._activatedRoute?.snapshot?.data['newTemplate'];
    if (activatedRouterData && 'isBackButtonEnable' in activatedRouterData) {
      this.isBackButtonEnable = activatedRouterData?.isBackButtonEnable;
    }

    this.loadTemplates();
  }

  /**
   * @name loadTemplates
   * @description
   * dispatch the action to request get items template
   * @memberof SharedIssueNewTemplateListPage
   */
  loadTemplates(): void {
    this._store.dispatch(SharedIssueBaseActions.getItemsTemplate({}));
  }

  navigateToTemplatePreview(selectedTemplate: IssueTemplateDto) {
    this._store.dispatch(SharedIssueBaseActions.getItemsTemplatePreviewByIssueType({
      id: selectedTemplate.issueTemplateId!,
      issueType: selectedTemplate.contentType!
    }))
  }

  /**
   * @name back
   * @description
   * navigate to issues previous page
   * @memberof SharedIssueNewTemplateListPage
   */
  back(): void {
    this._store.dispatch(SharedIssueBaseActions.navigateToIssues())
  }

}
