import {Injectable} from "@angular/core";
import jwt_decode from "jwt-decode";
import {Subject} from "rxjs";
import {SharedModalControllerService} from "@shared/services/shared-modal-controller.service";
import {Storage} from "@ionic/storage-angular";
import {CoreStorageBaseService} from "@core/storage/core-storage-base.service";
import {CoreEnvironmentService} from "@core/services/environment/core-environment.service";

@Injectable({
  providedIn: 'root'
})
export class TokenBaseHelperService extends CoreStorageBaseService<any> {

  tokenRefreshSubject: Subject<any>;
  private readonly _offsetSec: number;
  private _apiUrlOne: string;
  protected key: string = "login";

  constructor(
    protected _mCService: SharedModalControllerService,
    storage: Storage
  ) {
    super(storage);
    this.tokenRefreshSubject = new Subject();
    this._offsetSec = 30;
    const environment = CoreEnvironmentService.getEnvironment();
    this._offsetSec = environment.apiUrl.tokenExpireOffsetSec ?
      environment.apiUrl.tokenExpireOffsetSec : this._offsetSec;
    this._apiUrlOne = environment.apiUrl.one;
  }

  /**
   * @name isTokenExpiredBoth
   * @description
   * @memberof TokenBaseHelperService
   * @param token
   * @returns {boolean}
   */
  public isTokenExpiredBoth(token: string | null | undefined): boolean {
    let currentToken;
    currentToken = token!;

    const date = this._getTokenExpirationDate(currentToken);
    if (date === undefined) return false;
    this._addExpireOffset(date);
    return !(date.valueOf() >= new Date().valueOf());
  }

  /**
   * @name _decodeToken
   * @memberof TokenBaseHelperService
   * @description
   * Decode the token to read it
   * @param token
   * @private
   * @return {any}
   */
  private _decodeToken(token: string | null | undefined): any {
    return token ? jwt_decode(token) : undefined;
  }

  /**
   * @name _getTokenExpirationDate
   * @memberof TokenBaseHelperService
   * @description
   * return the expiration date in seconds
   * @param token
   * @private
   * @return {Date | undefined}
   */
  private _getTokenExpirationDate(token: string): Date | undefined {
    const decoded = this._decodeToken(token);

    if (decoded?.exp === undefined) return undefined;

    const date = new Date(0);
    date.setUTCSeconds(decoded?.exp);
    return date;
  }

  /**
   * @name _addExpireOffset
   * @memberof TokenBaseHelperService
   * @description
   * Include an offset in second to prevent the token expiration
   * @param date
   * @private
   */
  private _addExpireOffset(date: Date): void {
    date.setSeconds(date.getSeconds() - this._offsetSec);
  }
}
