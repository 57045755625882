import {Component, OnInit} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {IonicModule} from '@ionic/angular';
import {TranslateModule} from '@ngx-translate/core';
import {CommonModule} from '@angular/common';
import {Observable, of} from 'rxjs';
import {
  IssueContentType,
  ResourceProfileIssueTemplateShortCutDto,
} from '@server-models';
import {ActivatedRoute} from '@angular/router';
import {FormArray, FormGroup, FormsModule} from '@angular/forms';
import {SharedIssueBaseSelectors} from '@shared/components/issues/store/shared-issue-base.selectors-type';
import {SharedIssueBaseActions} from '@shared/components/issues/store/shared-issue-base.action-type';
import {TTemplatePreviewDto} from '@shared/components/issues/types/template-preview-dto.type';
import {
  SharedIssueNewTemplateDetailComponent
} from "@shared/components/issues/pages/new-template-detail/components/message/shared-issue-new-template-detail.component";
import {
  SharedIssueNewTemplateDetailMissionComponent
} from "@shared/components/issues/pages/new-template-detail/components/mission/shared-issue-new-template-detail-mission.component";
import {
  SharedIssueNewTemplateDetailTicketComponent
} from "@shared/components/issues/pages/new-template-detail/components/ticket/shared-issue-new-template-detail-ticket.component";
import {
  SharedIssueNewTemplateDetailWinterhalterServiceCallComponent
} from "@shared/components/issues/pages/new-template-detail/components/winterhalter/service-call/shared-issue-new-template-detail-winterhalter-service-call.component";
import {
  SharedIssueNewTemplateDetailWinterhalterPorductOrderComponent
} from "@shared/components/issues/pages/new-template-detail/components/winterhalter/product-order/shared-issue-new-template-detail-winterhalter-product-order.component";

@Component({
  selector: 'app-shared-issue-new-template-detail',
  templateUrl: './shared-issue-new-template-detail.page.html',
  styleUrls: ['./shared-issue-new-template-detail.page.scss'],
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule,
    FormsModule,
    SharedIssueNewTemplateDetailComponent,
    SharedIssueNewTemplateDetailMissionComponent,
    SharedIssueNewTemplateDetailTicketComponent,
    SharedIssueNewTemplateDetailWinterhalterServiceCallComponent,
    SharedIssueNewTemplateDetailWinterhalterPorductOrderComponent,
  ],
  standalone: true,
})
export class SharedIssueNewTemplateDetailPage implements OnInit {
  protected readonly IssueContentType = IssueContentType;
  isLoading$!: Observable<boolean>;
  shortCutTemplate$: Observable<ResourceProfileIssueTemplateShortCutDto>;
  shortCutTemplateId: number;
  templateId!: string;
  formEmitted: FormGroup;
  isBackButtonEnable: boolean;
  selectedTemplatePreview$: Observable<{
    id: number;
    templateIssueType: IssueContentType;
    data: TTemplatePreviewDto;
  }>;

  currentIssueContentType: IssueContentType;

  currentTemplatePreview: TTemplatePreviewDto;

  constructor(private _store: Store, private _activatedRoute: ActivatedRoute) {
    this.formEmitted = new FormGroup<any>({});
    this.isBackButtonEnable = true;
    this.shortCutTemplate$ = of<ResourceProfileIssueTemplateShortCutDto>({});
    this.shortCutTemplateId = NaN;
    this.selectedTemplatePreview$ = of(
      <
        {
          id: number;
          templateIssueType: IssueContentType;
          data: TTemplatePreviewDto;
        }
        >{}
    );

    this.currentIssueContentType = <IssueContentType>{};

    this.currentTemplatePreview = {};
  }

  ngOnInit() {
    this.isLoading$ = this._store.pipe(
      select(SharedIssueBaseSelectors.selectIsLoading)
    );
    this.shortCutTemplate$ = this._store.pipe(
      select(SharedIssueBaseSelectors.selectShortCutTemplate)
    );
    this.shortCutTemplate$.subscribe((shortCut) => {
      this.shortCutTemplateId = shortCut.issueTemplateId!;
    });
  }

  ionViewWillEnter(): void {
    this.init();
  }

  init() {
    const activatedRouterData =
      this._activatedRoute.snapshot.data['templatePreview'];

    if (activatedRouterData && 'isBackButtonEnable' in activatedRouterData) {
      this.isBackButtonEnable = activatedRouterData?.isBackButtonEnable;
    }

    this.selectedTemplatePreview$ = this._store.pipe(
      select(SharedIssueBaseSelectors.selectSelectedTemplate)
    );
    this.selectedTemplatePreview$.subscribe((templatePreview) => {
      if (!isNaN(templatePreview.id)) {
        this.currentTemplatePreview = templatePreview.data;
      } else {
        this._activatedRoute.paramMap.subscribe(params => {
          const existingId = params.get('id');
          if (existingId) {
            this._store.dispatch(SharedIssueBaseActions.getItemsTemplate({ templatePreviewId: existingId }));
          }
        });
      }
    });


  }

  /**
   * @name onChangeForm
   * @description
   * event controlled on change form event from template preview card component to process the form
   * @memberof SharedIssueNewTemplateDetailPage
   * @param ev
   */
  onChangeForm(ev: FormArray | FormGroup): void {
    this._processForm(ev, this.formEmitted);
  }

  /**
   * @name _processForm
   * @description
   * check the type of the incoming form and send to the corresponding treatment
   * @memberof SharedIssueNewTemplateDetailPage
   * @param incForm
   * @param formEmitted
   * @private
   */
  private _processForm(incForm: FormArray | FormGroup, formEmitted: FormGroup | FormArray): void {
    if (incForm instanceof FormGroup) {
      this._processFormGroup(incForm, formEmitted as FormGroup);
    } else if (incForm instanceof FormArray) {
      this._processFormArray(incForm, formEmitted as FormArray);
    }

    // Update validity after modifications
    formEmitted.updateValueAndValidity();
  }

  /**
   * @name _processFormGroup
   * @description
   * it will process any formGroup and check if there is an existing FormGroup | FormArray to process it correctly
   * @memberof SharedIssueNewTemplateDetailPage
   * @param incForm
   * @param formEmitted
   * @private
   */
  private _processFormGroup(incForm: FormGroup, formEmitted: FormGroup): void {
    Object.keys(incForm.controls).forEach((key) => {
      const control = incForm.controls[key];

      if (control instanceof FormGroup) {
        this._addOrProcessNestedGroup(control, formEmitted, key);
      } else if (control instanceof FormArray) {
        this._addOrProcessNestedArray(control, formEmitted, key);
      } else {
        formEmitted.setControl(key, control);
      }
    });
  }

  /**
   * @name _processFormArray
   * @description
   * it will process any FormArray and check if there is an existing FormArray | FormGroup to process it correctly
   * @memberof SharedIssueNewTemplateDetailPage
   * @param incForm
   * @param formEmitted
   * @private
   */
  private _processFormArray(incForm: FormArray, formEmitted: FormArray): void {
    incForm.controls.forEach((control, index) => {
      let nestedControl = formEmitted.at(index) as FormGroup | FormArray;

      if (control instanceof FormGroup) {
        this._addOrProcessNestedGroup(control, nestedControl, index);
      } else if (control instanceof FormArray) {
        this._addOrProcessNestedArray(control, nestedControl, index);
      } else {
        formEmitted.setControl(index, control);
      }
    });
  }

  /**
   * @name _addOrProcessNestedGroup
   * @description
   * it will add or process depends on it is existing an inherit form or not formGroups
   * @memberof SharedIssueNewTemplateDetailPage
   * @param control
   * @param formEmitted
   * @param key
   * @private
   */
  private _addOrProcessNestedGroup(control: FormGroup, formEmitted: FormGroup | FormArray, key: string | number): void {
    let nestedGroup = (formEmitted instanceof FormGroup ? formEmitted.controls[key as string] : formEmitted.at(key as number)) as FormGroup;

    if (!nestedGroup) {
      nestedGroup = new FormGroup({});
      if (formEmitted instanceof FormGroup) {
        formEmitted.addControl(key as string, nestedGroup);
      } else {
        formEmitted.setControl(key as number, nestedGroup);
      }
    }

    this._processForm(control, nestedGroup);
  }

  /**
   * @name _addOrProcessNestedArray
   * @description
   * it will add or process depends on it is existing an inherit form or not FormArray
   * @memberof SharedIssueNewTemplateDetailPage
   * @param control
   * @param formEmitted
   * @param key
   * @private
   */
  private _addOrProcessNestedArray(control: FormArray, formEmitted: FormGroup | FormArray, key: string | number): void {
    let nestedArray = (formEmitted instanceof FormGroup ? formEmitted.controls[key as string] : formEmitted.at(key as number)) as FormArray;

    if (!nestedArray) {
      nestedArray = new FormArray<any>([]);
      if (formEmitted instanceof FormGroup) {
        formEmitted.addControl(key as string, nestedArray);
      } else {
        formEmitted.setControl(key as number, nestedArray);
      }
    }

    this._processForm(control, nestedArray);
  }


  /**
   * @name submitForm
   * @description
   * submit form to dispatch the action to send template preview with the data filled
   * @memberof SharedIssueNewTemplateDetailPage
   */
  submitForm() {
    this._store.dispatch(
      SharedIssueBaseActions.prepareFormsToSendTemplatePreview({
        form: this.formEmitted,
        templatePreviewDto: this.currentTemplatePreview,
      })
    );
  }

  /**
   * @name loadTemplatesPreview
   * @description
   * dispatch the action to request template preview
   * @memberof SharedIssueNewTemplateDetailPage
   */
  loadTemplatesPreview(id: string): void {
    this._store.dispatch(SharedIssueBaseActions.getItemsTemplatePreview({ id }));
  }

  /**
   * @name back
   * @description
   * navigate to previous page issues new
   * @memberof SharedIssueNewTemplateDetailPage
   */
  back(): void {
    if (this.shortCutTemplateId) {
      this._store.dispatch(SharedIssueBaseActions.navigateToIssues());
    } else {
      this._store.dispatch(SharedIssueBaseActions.navigateToNewIssue());
    }
  }
}
