<ion-header>
  <ion-toolbar>
    <ion-title>{{ name }}</ion-title>

    <ion-buttons slot="end">
      <ion-button color="primary" (click)="exit()">
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div class="image-container">
    <img alt="image-view" class="image-obj" [src]="image | sharedSafe: 'url'"/>
  </div>

</ion-content>
