import {Observable} from "rxjs";
import {Injectable} from "@angular/core";
import {ResourceProfileDashboardDto} from "@server-models";
import {CoreHttpClientApiBaseService} from "@core/services/http-client-api-base/core-http-client-api-base.service";

@Injectable({
  providedIn: 'root',
})
export class HintService extends CoreHttpClientApiBaseService {
  getResourceDashboard(resourceId: number): Observable<ResourceProfileDashboardDto> {
    return super.get<ResourceProfileDashboardDto>(`/tech/resource/${ resourceId }/profile/dashboard`);
  }
}
