import {Injectable} from "@angular/core";
import {IssueContentType, IssueType} from "@server-models";
import {LinkIssueMessageApiService} from "@link/pages/issues/services/link-issue-message-api.service";
import {LinkIssueMissionApiService} from "@link/pages/issues/services/link-issue-mission-api.service";
import {LinkIssueTicketApiService} from "@link/pages/issues/services/link-issue-ticket-api.service";
import {
  LinkIssueWinterhalterServiceCallApiService
} from "@link/pages/issues/services/winterhalter/link-issue-winterhalter-service-call-api.service";
import {IIssueService} from "@shared/components/issues/interfaces/issue-service.interface";
import {
  LinkIssueWinterhalterProductOrderApiService
} from "@link/pages/issues/services/winterhalter/link-issue-winterhalter-product-order-api.service";
import {LinkIssueControlMessageApiService} from "@link/pages/issues/services/link-issue-control-message-api.service";

@Injectable({
  providedIn: 'root',
})
export class LinkIssueService {
  constructor(
    private _linkIssuesMessageApiService: LinkIssueMessageApiService,
    private _linkIssuesMissionApiService: LinkIssueMissionApiService,
    private _linkIssuesTicketApiService: LinkIssueTicketApiService,
    private _linkIssuesControlMessageApiService: LinkIssueControlMessageApiService,
    private _linkIssuesWinterhalterServiceCallService: LinkIssueWinterhalterServiceCallApiService,
    private _linkIssuesWinterhalterProductOrderService: LinkIssueWinterhalterProductOrderApiService
  ) {

  }

  getApiServiceByIssueType(issueType: IssueType): IIssueService {
    switch (issueType) {
      case IssueType.Message:
        return this._linkIssuesMessageApiService;
      case IssueType.Mission:
        return this._linkIssuesMissionApiService;
      case IssueType.Ticket:
        return this._linkIssuesTicketApiService;
      case IssueType.ControlMessage:
        return this._linkIssuesControlMessageApiService;
      case IssueType.WinterhalterServiceCall:
        return this._linkIssuesWinterhalterServiceCallService;
      case IssueType.WinterhalterProductOrder:
        return this._linkIssuesWinterhalterProductOrderService;
      default:
        throw new Error(`Api service, Unsupported issue type: ${ issueType }`);
    }
  }

  getApiServiceByIssueContentType(issueContentType: IssueContentType): IIssueService {
    switch (issueContentType) {
      case IssueContentType.Message:
        return this._linkIssuesMessageApiService;
      case IssueContentType.Mission:
        return this._linkIssuesMissionApiService;
      case IssueContentType.Ticket:
        return this._linkIssuesTicketApiService;
      case IssueContentType.WinterhalterServiceCall:
        return this._linkIssuesWinterhalterServiceCallService;
      case IssueContentType.WinterhalterProductOrder:
        return this._linkIssuesWinterhalterProductOrderService;
      default:
        throw new Error(`Api service, Unsupported issue content type: ${ issueContentType }`);
    }
  }
}
