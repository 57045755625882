import {Component, Input, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {TranslateModule, TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-shared-custom-property-read-only-input-checkbox',
  templateUrl: './shared-custom-property-read-only-input-checkbox.component.html',
  styleUrls: ['./shared-custom-property-read-only-input-checkbox.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, TranslateModule]
})
export class SharedCustomPropertyReadOnlyInputCheckboxComponent implements OnInit {

  @Input() title: string;
  @Input() value: boolean;

  transformValue: string;

  constructor(
    private _translateService: TranslateService
  ) {
    this.title = "";
    this.transformValue = "";
    this.value = false;
  }

  ngOnInit() {
    this.changeBooleanValues();

  }

  /**
   * @name
   * @description
   * change the boolean to a readable values by user
   * @memberof SharedCustomPropertyReadOnlyInputCheckboxComponent
   */
  changeBooleanValues(): void {
    if (this.value) {
      this.transformValue = this._translateService.instant("TEAM.ISSUES.COMPONENTS.CHECKBOX.CONFIRM");
    }
    if (!this.value) {
      this.transformValue = this._translateService.instant("TEAM.ISSUES.COMPONENTS.CHECKBOX.DENIED");
    }
  }
}
