import {Injectable} from "@angular/core";
import {Storage} from "@ionic/storage-angular";
import {CoreStorageBaseService} from "@core/storage/core-storage-base.service";
import {EAppType} from "@shared/models/AppType.enum";
import {GeneralSettingDto, ResourceProfileOverviewDto, TokenDto} from "@server-models";
import {DemoDto} from "@shared/models/DemoDto";

@Injectable({
  providedIn: 'root'
})
export class SharedLoginStorageBaseService extends CoreStorageBaseService<{
  app?: EAppType,
  token?: TokenDto,
  demo?: DemoDto,
  isLogged?: boolean,
  isLogging?: boolean,
  linkId?: number,
  linkKey?: string,
  currentTenant?: {
    tenantLanguage?: string | null;
    tenantId?: number,
    isGuest?: boolean,
    tenantDisplayName?: string | null,
    tenantSettings?: GeneralSettingDto
  },
  profile?: ResourceProfileOverviewDto

}> {
  protected key: string = 'login';

  constructor(storage: Storage) {
    super(storage);
  }
}
