<ion-item-group *ngIf="mappedAttachments!.length > 0" class="ion-margin-top">
  <ion-item-divider mode="md" *ngIf="hasHeader">
    <ion-button shape="round" color="medium">
      <ion-icon slot="icon-only" name="attach-outline"></ion-icon>
    </ion-button>
    <ion-text color="medium"><h2>{{ "COMPONENTS.ATTACHMENTS.FILES.LABEL" | translate }}</h2></ion-text>
  </ion-item-divider>
  <ng-container *ngFor="let attachment of mappedAttachments">
    <!--    File: pdf/images-->
    <ion-item
      (click)="attachmentsService.dispatchAction(attachment)"
      [disabled]="(attachmentsService.checkLoadingButton(attachment.attachmentId) | async)"
      [button]="true"
      [detail]="true"
      lines="full"
      *ngIf="attachment.type === attachmentType.File">
      <ion-icon class="padding-left" *ngIf="isIconFolderEnabled" name="document-attach" slot="start"></ion-icon>
      <ion-label>{{ attachment.name }}</ion-label>
      <ion-badge
        *ngIf="!(attachmentsService.checkLoadingButton(attachment.attachmentId) | async)"
        color="primary"
        slot="end">
        {{ "COMPONENTS.ATTACHMENTS.FILES.TYPES.FILE" | translate }}
      </ion-badge>
      <ion-spinner *ngIf="(attachmentsService.checkLoadingButton(attachment.attachmentId) | async)"
                   slot="end"></ion-spinner>
    </ion-item>
    <!--    Link-->
    <ion-item
      (click)="attachmentsService.dispatchAction(attachment)"
      [disabled]="(attachmentsService.checkLoadingButton(attachment.attachmentId) | async)"
      [button]="true"
      [detail]="true"
      lines="full"
      *ngIf="attachment.type === attachmentType.Link">
      <ion-icon class="padding-left" *ngIf="isIconFolderEnabled" name="document-attach" slot="start"></ion-icon>
      <ion-label>{{ attachment.name }}</ion-label>
      <ion-badge
        *ngIf="!(attachmentsService.checkLoadingButton(attachment.attachmentId) | async)"
        color="primary"
        slot="end">
        {{ "COMPONENTS.ATTACHMENTS.FILES.TYPES.LINK" | translate }}
      </ion-badge>
      <ion-spinner *ngIf="(attachmentsService.checkLoadingButton(attachment.attachmentId) | async)"
                   slot="end"></ion-spinner>
    </ion-item>
    <!--    Knowledge-->
    <ion-item
      (click)="attachmentsService.dispatchAction(attachment)"
      [disabled]="(attachmentsService.checkLoadingButton(attachment.attachmentId) | async)"
      [button]="true"
      [detail]="true"
      lines="full"
      *ngIf="attachment.type === attachmentType.Knowledge">
      <ion-icon class="padding-left" *ngIf="isIconFolderEnabled" name="document-attach" slot="start"></ion-icon>
      <ion-label>{{ attachment.name }}</ion-label>
      <ion-badge
        *ngIf="!(attachmentsService.checkLoadingButton(attachment.attachmentId) | async)"
        color="primary"
        slot="end">
        {{ "COMPONENTS.ATTACHMENTS.FILES.TYPES.KNOWLEDGE" | translate }}
      </ion-badge>
      <ion-spinner *ngIf="(attachmentsService.checkLoadingButton(attachment.attachmentId) | async)"
                   slot="end"></ion-spinner>
    </ion-item>
  </ng-container>
</ion-item-group>
