import {Injectable} from "@angular/core";
import {IEnvironment} from "@shared/services/interfaces/environment.interface";


@Injectable({
  providedIn: 'root'
})
export class CoreEnvironmentService {
  private static environment: IEnvironment;

  static setEnvironment(env: IEnvironment) {
    CoreEnvironmentService.environment = env;
  }

  static getEnvironment(): IEnvironment {
    return CoreEnvironmentService.environment;
  }
}
