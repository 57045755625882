import {Injectable} from "@angular/core";
import {
  IssueContentType,
  IssueTemplateListDto,
  IssueType,
  ResourceProfileDto,
  StereotypeDto,
} from "@server-models";
import {
  ICustomPropertyStructureFormatted
} from "@shared/components/custom-property-form/interfaces/custom-property-structure-formatted.interface";
import {FormBuilder, FormGroup} from "@angular/forms";
import {TranslateService} from "@ngx-translate/core";
import {SharedDateHelperService} from "@shared/services/shared-date-helper.service";
import {TIssueDto} from "@shared/components/issues/types/issue-dto.type";
import {TSubmitIssueDto} from "@shared/components/issues/types/submit-issue-dto.type";
import {SharedStereotypeService} from "@shared/services/stereotype/shared-stereotype.service";
import {
  IAdditionalDetails
} from "@shared/components/custom-property-form/interfaces/structure-formatted-additional-details.interface";

@Injectable({
  providedIn: 'root',
})
export abstract class SharedIssueBaseService {

  constructor(
    protected _fb: FormBuilder,
    protected _translateService: TranslateService,
    protected _dateHelperService: SharedDateHelperService,
    protected _stereotypeBaseService: SharedStereotypeService
  ) {
  }

  abstract formatReadDetailData(issueDto: TIssueDto, issueType: IssueType,
                                stereotypeDto?: StereotypeDto): ICustomPropertyStructureFormatted;

  /**
   * @name getAdditionalFormattedData
   * @description
   * abstract method to delegate the task of add additional data to children
   * this method is use to add additional data into the formatted object
   * @memberof SharedIssueBaseService
   * @param issueDto
   */
  abstract getAdditionalFormattedData(issueDto: TIssueDto): Partial<IAdditionalDetails>;

  abstract prepareFormIssueDto(
    form: FormGroup,
    templatePreviewDto: unknown,
    resourceDto?: ResourceProfileDto,
    tenantId?: number
  ): TSubmitIssueDto;

  /**
   * @name filterValidIssueContentTypes
   * @description
   * check if the contentType of the templateList is one of the IssueContentType key objects (types included)
   * @memberof SharedIssueBaseService
   * @param templateList
   * @protected
   */
  filterValidIssueContentTypes(templateList: IssueTemplateListDto[]): IssueTemplateListDto[] {
    return templateList.filter(b => b.contentType !== undefined && Object.values(IssueContentType).includes(b.contentType));
  }

  formatIssueType(issueType: IssueType): string {
    switch (issueType) {
      case IssueType.Message: {
        return this._translateService.instant('GENERAL.ISSUE_TYPE_LIST.MESSAGE');
      }
      case IssueType.Ticket: {
        return this._translateService.instant('GENERAL.ISSUE_TYPE_LIST.TICKET');
      }
      case IssueType.Mission: {
        return this._translateService.instant('GENERAL.ISSUE_TYPE_LIST.MISSION');
      }
      case IssueType.ControlMessage: {
        return this._translateService.instant('GENERAL.ISSUE_TYPE_LIST.CONTROL.MESSAGE');
      }
      case IssueType.ControlMission: {
        return this._translateService.instant('GENERAL.ISSUE_TYPE_LIST.CONTROL.MISSION');
      }
      case IssueType.WinterhalterServiceCall: {
        return this._translateService.instant('GENERAL.ISSUE_TYPE_LIST.WINTERHALTER.SERVICE_CALL');
      }
      case IssueType.WinterhalterProductOrder: {
        return this._translateService.instant('GENERAL.ISSUE_TYPE_LIST.WINTERHALTER.PRODUCT_ORDER');
      }
      case IssueType.MailIssue: {
        return this._translateService.instant('GENERAL.ISSUE_TYPE_LIST.MAIL');
      }
    }
  }

}
