/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum AppFunctions {
    EditTenant = 12,
    ReadTenantUsage = 13,
    ReadFile = 20,
    EditFile = 21,
    ManipulateFile = 22,
    ShowAppDocuments = 23,
    ShowBackendDocuments = 24,
    SynchronizeMasterData = 28,
    ManipulateMasterData = 29,
    ReadContact = 30,
    EditContact = 31,
    DisplayContact = 32,
    DeleteContact = 33,
    EditSystemContact = 34,
    ReadLocation = 40,
    EditLocation = 41,
    DisplayLocation = 42,
    DeleteLocation = 43,
    ReadAddress = 50,
    EditAddress = 51,
    DisplayAddress = 52,
    DeleteAddress = 53,
    ReadCompany = 60,
    EditCompany = 61,
    DisplayCompany = 62,
    DeleteCompany = 63,
    DisplayInfo = 69,
    ReadInfo = 70,
    EditInfo = 71,
    ReadDevice = 80,
    EditDevice = 81,
    RegisterDevice = 82,
    EditPing = 85,
    EditTask = 90,
    EditTaskJob = 91,
    EditForm = 101,
    EditFilledForm = 102,
    ManipulateFilledForm = 103,
    DisplayKnowledge = 119,
    ReadKnowledge = 120,
    EditKnowledge = 121,
    SubmitAnswer = 122,
    ReadHoliday = 130,
    EditHoliday = 131,
    EditChat = 141,
    ShowAppChat = 142,
    ShowBackendChat = 143,
    AllowChatPerEmail = 144,
    ShowAllChatsPackage = 145,
    ReadFolderAccess = 150,
    EditFolderAccess = 151,
    EditRole = 161,
    EditHistory = 171,
    ReadLoginChallenge = 180,
    EditLoginChallenge = 181,
    ReadLoginProvider = 182,
    EditLoginProvider = 183,
    EditProductSubscription = 184,
    ReadStereotype = 200,
    EditStereotype = 201,
    ManipulateStereotype = 202,
    DisplayStereotype = 203,
    ReadTicket = 210,
    EditTicket = 211,
    ForceTicketState = 212,
    AssignTicketToMe = 213,
    AssignTicketToEditor = 214,
    DisplayTicket = 215,
    DeleteTicket = 216,
    AssignTicketResource = 217,
    EditSettings = 220,
    ReadSettings = 221,
    ReadGraph = 225,
    ShowDashboard = 226,
    ShowAppOverview = 227,
    ReadExternalDocument = 230,
    EditExternalDocument = 231,
    ReadExternalAllDocument = 232,
    ReadResource = 240,
    EditResource = 241,
    DisplayResource = 242,
    DeleteResource = 243,
    ReadQuickAccess = 244,
    EditQuickAccess = 245,
    DisplayQuickAccess = 246,
    ReadStateMachine = 250,
    EditStateMachine = 251,
    DisplayStateMachine = 252,
    ReadDataTable = 260,
    EditDataTable = 261,
    DisplayDataTable = 262,
    ExportDataTable = 263,
    ReadDashboard = 270,
    EditDashboard = 271,
    DisplayDashboard = 272,
    EditAllDashboard = 273,
    ReadPriority = 280,
    EditPriority = 281,
    DisplayPriority = 282,
    ReadEscalation = 300,
    UseEscalation = 301,
    EditEscalation = 302,
    DisplayEscalation = 303,
    ReadAudit = 310,
    AssignMissionToMe = 317,
    AssignMissionToEditor = 318,
    AssignMissionResource = 319,
    ReadMission = 320,
    EditMission = 321,
    EditActualMissionDate = 322,
    EditPlannedMissionDate = 323,
    EditClosedMission = 324,
    DisplayMission = 325,
    DeleteMission = 327,
    DisplayMissionMap = 328,
    DisplayMissionPlan = 329,
    ReadLink = 330,
    EditLink = 331,
    ReadAttachmentForTech = 340,
    EditAttachmentForTech = 341,
    ReadTemplate = 350,
    EditTemplate = 351,
    ReadDocumentTemplate = 360,
    EditDocumentTemplate = 361,
    EditBetaDocumentTemplates = 362,
    ReadInventoryNumberPattern = 370,
    EditInventoryNumberPattern = 371,
    DisplayInventoryNumberPattern = 372,
    ReadContract = 380,
    EditContract = 381,
    DisplayContract = 382,
    DeleteContract = 383,
    ReadResourceTask = 390,
    EditResourceTask = 391,
    DisplayResourceTask = 392,
    DeleteResourceTask = 393,
    ReadArticle = 400,
    EditArticle = 401,
    DisplayArticle = 402,
    DeleteArticle = 403,
    ReadMissionReport = 410,
    EditMissionReport = 411,
    ReadSkeleton = 420,
    EditSkeleton = 421,
    DisplaySkeleton = 422,
    DeleteSkeleton = 423,
    ReadSolution = 433,
    EditSolution = 434,
    ReadAppOverview = 440,
    EditAppOverview = 441,
    DisplayAppOverview = 442,
    DeleteAppOverview = 443,
    ReadTicketByProcessDefinition = 450,
    EditTicketByProcessDefinition = 451,
    DisplayTicketByProcessDefinition = 452,
    DeleteTicketByProcessDefinition = 453,
    CreateTicketByProcess = 454,
    ReadCategory = 460,
    EditCategory = 461,
    DisplayCategory = 462,
    DeleteCategory = 463,
    ReadChatByFormDefinition = 470,
    EditChatByFormDefinition = 471,
    DisplayChatByFormDefinition = 472,
    DeleteChatByFormDefinition = 473,
    StartChatByForm = 474,
    ReadAreaAssignment = 480,
    EditAreaAssignment = 481,
    DisplayServicePackage = 490,
    ReadSubscriptionPackage = 491,
    ReadAnalytic = 500,
    ReadPortalAnalytic = 501,
    ReadApiKey = 510,
    EditApiKey = 511,
    ReadMissionReceipt = 520,
    EditMissionReceipt = 521,
    DisplayMissionReceipt = 522,
    ReadControlPoint = 530,
    EditControlPoint = 531,
    DisplayControlPoint = 532,
    DeleteControlPoint = 533,
    ReadMissionInspectionReport = 540,
    EditMissionInspectionReport = 541,
    ReadConnectedDefinition = 550,
    EditConnectedDefinition = 551,
    DisplayConnectedDefinition = 552,
    DeleteConnectedDefinition = 553,
    ReadTimelineSetting = 560,
    EditTimelineSetting = 561,
    DeleteTimelineSetting = 563,
    ReadMeTimelineSetting = 564,
    ReadMailNode = 570,
    EditMailNode = 571,
    DeleteMailNode = 573,
    DisplayMailNode = 574,
    ReadNotificationRule = 580,
    EditNotificationRule = 581,
    ReadEditorQuest = 590,
    EditEditorQuest = 591,
    ImportExport = 600,
    ReadGeneralDataProtectionRegulation = 605,
    EditGeneralDataProtectionRegulation = 606,
    DisplayGeneralDataProtectionRegulation = 607,
    DeleteGeneralDataProtectionRegulation = 608,
    ReadSearchList = 610,
    EditSearchList = 611,
    DisplaySearchList = 612,
    DeleteSearchList = 613,
    ReadResourcesByLocationDefinition = 620,
    EditResourcesByLocationDefinition = 621,
    ReadTicketsByLocationDefinition = 625,
    EditTicketsByLocationDefinition = 626,
    UseSetPassword = 630,
    ReadMailLog = 640,
    UseClassification = 650,
    ReadLabel = 651,
    EditLabel = 652,
    DisplayLabel = 653,
    DeleteLabel = 654,
    ModifyLabelManually = 655,
    IsOrgaUser = 1000,
    Manager = 1001,
    ShowsExpiredSubscription = 1008,
    IsAdmin = 1009,
    IsSystemAdmin = 1010,
    CanUseExpiredSubscription = 1011,
    Admin = 1012,
    IsOrgaUserInTech = 1016,
    IsQuickAccessIssueHistory = 1022,
    IsServiceEmployee = 1023,
    IsQuickAccessUser = 1024,
    IsQuickAccessKnowledge = 1025,
    IsQuickAccessIssueTemplate = 1026,
    IsQuickAccessDashboard = 1027,
    TeamUser = 1028,
    AnalystUser = 1029,
    OrgaClassicModule = 2002,
    TechClassicModule = 2011,
    OrgaModule = 2012,
    InspectionClassicAddon = 2013,
    MissionClassicAddon = 2014,
    ConnectedClassicAddon = 2015,
    SafeClassicAddon = 2016,
    QuickAccessClassicAddon = 2017,
    TechModule = 2018,
    DataClassicModule = 2020,
    ClassificationClassicAddon = 2021,
    SyncLocationClassicAddon = 2022,
    DataModule = 2023,
    InspectionAddon = 2024,
    MissionAddon = 2025,
    ConnectedAddon = 2026,
    SafeAddon = 2027,
    QuickAccessAddon = 2028,
    ClassificationAddon = 2029,
    CalendarMission = 2030,
    SyncLocationAddon = 2031,
    ReadCalendar = 2040,
    ReadSafe = 2050,
    MaintainSafe = 2052,
    AdministrateSafe = 2053,
    ReadResourceManufacturer = 2060,
    EditResourceManufacturer = 2061,
    DisplayResourceManufacturer = 2062,
    DeleteResourceManufacturer = 2063,
    OrgaTechClassicModule = 2070,
    SyncReceiptsClassicAddon = 2071,
    SyncContactsClassicAddon = 2072,
    SyncDriveClassicAddon = 2073,
    GdprClassicAddon = 2074,
    NotificationClassicAddon = 2075,
    OrgaTechModule = 2080,
    SyncReceiptsAddon = 2081,
    SyncContactsAddon = 2082,
    SyncDriveAddon = 2083,
    GdprAddon = 2084,
    NotificationAddon = 2085,
    TeamModule = 2086,
    MdmClassicModule = 2087,
    MaintenanceReportAddon = 2088,
    PrimeReportAddon = 2089,
    ReadIssueTemplate = 2090,
    EditIssueTemplate = 2091,
    DisplayIssueTemplate = 2092,
    DeleteIssueTemplate = 2093,
    ReadMessageIssueContent = 2100,
    EditMessageIssueContent = 2101,
    ReadTicketIssueContent = 2102,
    EditTicketIssueContent = 2103,
    ReadMissionIssueContent = 2104,
    EditMissionIssueContent = 2105,
    ReadWinterhalterServiceCallIssueContent = 2106,
    EditWinterhalterServiceCallIssueContent = 2107,
    ReadWinterhalterProductOrderIssueContent = 2108,
    EditWinterhalterProductOrderIssueContent = 2109,
    EditIssue = 2111,
    DeleteIssue = 2113,
    SendIssue = 2114,
    ReadRecentIssue = 2115,
    ReadAllIssue = 2116,
    UseMassImport = 2120,
    EditInvitation = 2131,
    ShowReportForMaintenance = 2140,
    ShowReportForPrime = 2141,
}
