import {Observable} from "rxjs";
import {Injectable} from "@angular/core";
import {KnowledgeArticleDto} from "@server-models";
import {CoreHttpClientApiBaseService} from "@core/services/http-client-api-base/core-http-client-api-base.service";

@Injectable({
  providedIn: 'root',
})
export class LinkKnowledgeArticleApiService extends CoreHttpClientApiBaseService {

  getKnowledgeArticle(knowledgeArticleId: number): Observable<KnowledgeArticleDto> {
    return super.get<KnowledgeArticleDto>(`/orga/v3/knowledgearticle/${ knowledgeArticleId }/start`);
  }
}
