import {Observable} from "rxjs";
import {Injectable} from "@angular/core";
import {KnowledgeArticleSimpleDto, ResourceProfileKnowledgeDto} from "@server-models";
import {map} from "rxjs/operators";
import {CoreHttpClientApiBaseService} from "@core/services/http-client-api-base/core-http-client-api-base.service";

@Injectable({
  providedIn: 'root',
})
export class LinkKnowledgeApiService extends CoreHttpClientApiBaseService {

  getResourceKnowledge(resourceId: number): Observable<Array<KnowledgeArticleSimpleDto>> {
    return super.get<ResourceProfileKnowledgeDto>(`/tech/resource/${ resourceId }/profile/knowledge`).pipe(map(
      x => x.knowledgeArticles || []
    ));
  }
}
