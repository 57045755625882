import {ITeamEnvironment} from "./environment.interface";

export const environment: ITeamEnvironment = {
  production: false,
  apiUrl: {
    one: "http://localhost:8100",
    pageDefaultSize: 25,
    defaultTimeoutSec: 30,
    tokenExpireOffsetSec: 30
  },
  lang: {
    defaultLanguage: "de-DE",
    isUseBrowserLanguage: false
  },
};
