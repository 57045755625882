import {Component, OnInit} from '@angular/core';
import {CommonModule} from "@angular/common";
import {IonicModule} from "@ionic/angular";
import {TranslateModule} from "@ngx-translate/core";
import {SharedSafePipe} from "@shared/pipes/shared-safe.pipe";
import {SharedIssueBaseActions} from "@shared/components/issues/store/shared-issue-base.action-type";
import {Store} from "@ngrx/store";

@Component({
  selector: 'app-shared-issue-success',
  templateUrl: './shared-issue-success.page.html',
  styleUrls: ['./shared-issue-success.page.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, TranslateModule, SharedSafePipe],

})
export class SharedIssueSuccessPage implements OnInit {

  constructor(
    private _store: Store
  ) {

  }

  ngOnInit() {
  }

  /**
   * @name back
   * @description
   * dispatch an action to navigate to the previous allow page
   * @memberof SharedIssueSuccessPage
   */
  back(): void {
    this._store.dispatch(SharedIssueBaseActions.thanksPageNavigationBack())
  }

}
