<ng-container *ngIf="mappedAttachments!.length > 0">
  <ng-container *ngFor="let attachment of mappedAttachments">
    <ion-col size-xs="12" size-sm="6" size-md="6" size-lg="6">
      <ng-container *ngIf="getAttachmentByType(attachment.type) as attachmentInfo">
        <ion-item class="status"
                  [disabled]="(attachmentsService.checkLoadingButton(attachment.attachmentId) | async)"
                  lines="none"
        >
          <ion-button slot="start" fill="outline" (click)="attachmentsService.dispatchAction(attachment)">
            <ion-icon *ngIf="!(attachmentsService.checkLoadingButton(attachment.attachmentId) | async)"
                      [name]="attachmentInfo.icon"></ion-icon>
            <ion-spinner *ngIf="(attachmentsService.checkLoadingButton(attachment.attachmentId) | async)"
                         slot="end"></ion-spinner>
          </ion-button>
          <ion-input [label]="attachmentInfo.label" labelPlacement="stacked"
                     [value]="attachment.name"
                     [readonly]="true"></ion-input>
        </ion-item>
      </ng-container>
    </ion-col>
  </ng-container>
</ng-container>
