import {Injectable} from "@angular/core";
import {IssueContentType, IssueType,} from "@server-models";
import {SharedIssueMessageService} from "@shared/components/issues/services/shared-issue-message.service";
import {SharedIssueMissionService} from "@shared/components/issues/services/shared-issue-mission.service";
import {SharedIssueTicketService} from "@shared/components/issues/services/shared-issue-ticket.service";
import {
  SharedIssueWinterhalterServiceCallService
} from "@shared/components/issues/services/winterhalter/shared-issue-winterhalter-service-call.service";
import {
  SharedIssueWinterhalterProductOrderService
} from "@shared/components/issues/services/winterhalter/shared-issue-winterhalter-product-order.service";
import {SharedIssueControlMessageService} from "@shared/components/issues/services/shared-issue-control-message.service";

@Injectable({
  providedIn: 'root',
})
export class SharedIssueFactory {

  constructor(
    private _issueBaseMessageService: SharedIssueMessageService,
    private _issueBaseMissionService: SharedIssueMissionService,
    private _issueBaseTicketService: SharedIssueTicketService,
    private _issueBaseControlMessageService: SharedIssueControlMessageService,
    private _issueBaseWinterhalterServiceCallService: SharedIssueWinterhalterServiceCallService,
    private _issueBaseWinterhalterProductOrderService: SharedIssueWinterhalterProductOrderService,
  ) {
  }

  getServiceByIssueType(issueType: IssueType | IssueContentType): SharedIssueMessageService | SharedIssueMissionService | SharedIssueTicketService | SharedIssueControlMessageService | SharedIssueWinterhalterServiceCallService | SharedIssueWinterhalterProductOrderService {
    switch (issueType) {
      case IssueType.Message:
      case IssueContentType.Message:
        return this._issueBaseMessageService;
      case IssueType.Mission:
      case IssueContentType.Mission:
        return this._issueBaseMissionService;
      case IssueType.Ticket:
      case IssueContentType.Ticket:
        return this._issueBaseTicketService;
      case IssueType.ControlMessage:
        return this._issueBaseControlMessageService;
      case IssueType.WinterhalterServiceCall:
      case IssueContentType.WinterhalterServiceCall:
        return this._issueBaseWinterhalterServiceCallService
      case IssueType.WinterhalterProductOrder:
      case IssueContentType.WinterhalterProductOrder:
        return this._issueBaseWinterhalterProductOrderService
      default:
        throw new Error(`Service, Unsupported issue type: ${ issueType }`);
    }
  }
}
