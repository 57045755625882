import {Injectable} from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class CacheService {

  /**
   * @name generateGuid
   * @description
   * generate a Guid for cache
   * @memberof CacheService
   * @returns {string}
   */
  generateGuid(): string {
    return Math.random().toString(36).substring(2)
  }
}
