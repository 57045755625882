import {Injectable} from "@angular/core";
import {Observable, switchMap, throwError} from "rxjs";
import {IAlertControllerCreate} from "@shared/interfaces/alert-controller-create.interface";
import {TranslateService} from "@ngx-translate/core";
import {Router} from "@angular/router";
import {AlertBaseControllerService} from "@shared/services/shared-alert-controller.service";

@Injectable({
  providedIn: 'root'
})
export class CoreErrorHandlerBaseService {

  protected _errorText: string;

  constructor(
    protected _alertControllerService: AlertBaseControllerService,
    protected _translateService: TranslateService,
    protected _router: Router
  ) {
    this._errorText = this._translateService.instant("GENERAL.ENDPOINTS.ONE.ERRORS.ERROR");
  }

  /**
   * @name handlerErrors
   * @description
   * Handle the error incoming
   * @memberof CoreErrorHandlerBaseService
   * @param error
   * @returns {Observable<never>}
   */
  handlerErrors(error: any): Observable<never> {
    const alertFormat: IAlertControllerCreate = this._formatAlertError(error);
    return this._alertControllerService.observableAlert(alertFormat).pipe(
      switchMap(() => throwError(() => error))
    );
  }

  /**
   * @name _formatAlertError
   * @description
   * format the error to return an alert controller create format
   * @memberof CoreErrorHandlerBaseService
   * @param error
   * @protected
   * @returns {IAlertControllerCreate}
   */
  protected _formatAlertError(error: any): IAlertControllerCreate {
    const errorStatus: number = error.status;
    const errorName: string = error.name;

    let alertReturn: IAlertControllerCreate;

    switch (errorStatus) {
      case 0:
        alertReturn = this._formatAlertFor0(errorStatus, errorName, error.message);
        break;
      case 400:
        alertReturn = this._formatAlertFor400(errorStatus, errorName, error.error.Errors);
        break;
      case 401:
        alertReturn = this._formatAlertFor401(errorStatus, errorName, error.message);
        break;
      case 403:
        alertReturn = this._formatAlertFor403(errorStatus, errorName, error.message);
        break;
      case 404:
        alertReturn = this._formatAlertFor404(errorStatus, errorName, error.error.Errors);
        break;
      case 422:
        alertReturn = this._formatAlertFor422(errorStatus, errorName, error.message);
        break;
      case 500:
        alertReturn = this._formatAlertFor500(errorStatus, errorName, error.message);
        break;
      default:
        alertReturn = this._formatAlertForDefault(errorStatus, errorName, error.message);
        break;
    }
    return alertReturn;
  }

  /**
   * @name _formatAlertFor0
   * @description
   * protected function to be able to override from child services in case need it
   * returns a default alert controller create
   * @memberof CoreErrorHandlerBaseService
   * @param status
   * @param name
   * @param message
   * @protected
   * @returns {IAlertControllerCreate}
   */
  protected _formatAlertFor0(status: number, name: string, message: any): IAlertControllerCreate {
    return this._formatAlertForDefault(status, name, message);
  }

  /**
   * @name _formatAlertFor400
   * @description
   * protected function to be able to override from child services in case need it
   * returns a special error alert controller create
   * @memberof CoreErrorHandlerBaseService
   * @param status
   * @param name
   * @param message
   * @protected
   * @returns {IAlertControllerCreate}
   */
  protected _formatAlertFor400(status: number, name: string, message: any): IAlertControllerCreate {
    return this._formatAlertForSpecialErrors(status, name, message);
  }

  /**
   * @name _formatAlertFor401
   * @description
   * protected function to be able to override from child services in case need it
   * returns a default alert controller create
   * @memberof CoreErrorHandlerBaseService
   * @param status
   * @param name
   * @param message
   * @protected
   * @returns {IAlertControllerCreate}
   */
  protected _formatAlertFor401(status: number, name: string, message: any): IAlertControllerCreate {
    return this._formatAlertForDefault(status, name, message);
  }

  /**
   * @name _formatAlertFor403
   * @description
   * protected function to be able to override from child services in case need it
   * returns a default alert controller create
   * @memberof CoreErrorHandlerBaseService
   * @param status
   * @param name
   * @param message
   * @protected
   * @returns {IAlertControllerCreate}
   */
  protected _formatAlertFor403(status: number, name: string, message: any): IAlertControllerCreate {
    return this._formatAlertForDefault(status, name, message);
  }

  /**
   * @name _formatAlertFor404
   * @description
   * protected function to be able to override from child services in case need it
   * returns a special error alert controller create
   * @memberof CoreErrorHandlerBaseService
   * @param status
   * @param name
   * @param message
   * @protected
   * @returns {IAlertControllerCreate}
   */
  protected _formatAlertFor404(status: number, name: string, message: any): IAlertControllerCreate {
    return this._formatAlertForSpecialErrors(status, name, message);
  }

  /**
   * @name _formatAlertFor422
   * @description
   * protected function to be able to override from child services in case need it
   * returns a default alert controller create
   * @memberof CoreErrorHandlerBaseService
   * @param status
   * @param name
   * @param message
   * @protected
   * @returns {IAlertControllerCreate}
   */
  protected _formatAlertFor422(status: number, name: string, message: any): IAlertControllerCreate {
    return this._formatAlertForDefault(status, name, message);
  }

  /**
   * @name _formatAlertFor500
   * @description
   * protected function to be able to override from child services in case need it
   * returns a default alert controller create
   * @memberof CoreErrorHandlerBaseService
   * @param status
   * @param name
   * @param message
   * @protected
   * @returns {IAlertControllerCreate}
   */
  protected _formatAlertFor500(status: number, name: string, message: any): IAlertControllerCreate {
    return this._formatAlertForDefault(status, name, message);
  }

  /**
   * @name _formatAlertForDefault
   * @description
   * protected function to be able to override from child services in case need it
   * returns a default alert controller created
   * @memberof CoreErrorHandlerBaseService
   * @param status
   * @param name
   * @param message
   * @protected
   * @returns {IAlertControllerCreate}
   */
  protected _formatAlertForDefault(status: number, name: string, message: string): IAlertControllerCreate {
    const userMessage = this._filterErrorStatus(status);
    const detailFormatAlert = this._formatDetailFormatAlert(status, name, message);
    this._errorText = this._translateService.instant("GENERAL.ENDPOINTS.ONE.ERRORS.ERROR");

    return {
      header: `${ this._errorText }: ${ status.toString() }`,
      message: userMessage,
      buttons: [
        {
          text: this._translateService.instant("GENERAL.ALERT.BUTTONS.DETAILS"),
          handler: () => {
            this._alertControllerService.observableAlert(detailFormatAlert);
          }
        },
        {
          text: 'OK',
        }
      ]
    };
  }

  /**
   * @name _formatDetailFormatAlert
   * @description
   * private function for details alert view of the error
   * @memberof CoreErrorHandlerBaseService
   * @param status
   * @param name
   * @param message
   * @private
   * @returns {IAlertControllerCreate}
   */
  private _formatDetailFormatAlert(status: number, name: string, message: string): IAlertControllerCreate {
    this._errorText = this._translateService.instant("GENERAL.ENDPOINTS.ONE.ERRORS.ERROR");
    return {
      header: `${ this._errorText }: ${ status.toString() }`,
      subHeader: name,
      message: message,
      buttons: [
        { text: 'OK' }
      ],
    };
  }

  /**
   * @name _formatAlertForSpecialErrors
   * @description
   * protected function to be able to override from child services in case need it
   * returns a special error alert controller created
   * @memberof CoreErrorHandlerBaseService
   * @param status
   * @param name
   * @param errorMessage
   * @protected
   * @returns {IAlertControllerCreate}
   */
  protected _formatAlertForSpecialErrors(status: number, name: string, errorMessage: any[]): IAlertControllerCreate {
    const formattedMessage = this._getAllErrors(errorMessage)
    this._errorText = this._translateService.instant("GENERAL.ENDPOINTS.ONE.ERRORS.ERROR");
    return {
      header: `${ this._errorText }: ${ status.toString() }`,
      subHeader: name,
      message: formattedMessage,
      buttons: [
        { text: 'OK' }
      ]
    };
  }

  /**
   * @name _filterErrorStatus
   * @memberof CoreErrorHandlerBaseService
   * @description
   * Filter by status returning the user message translated depends of status
   * @param status
   * @protected
   * @return {string}
   */
  protected _filterErrorStatus(status: number): string {
    let userMessage: string;

    switch (status) {
      case 0:
        userMessage = this._textErrorStatus0();
        break;
      case 400:
        userMessage = this._textErrorStatus400();
        break;
      case 401:
        userMessage = this._textErrorStatus401();
        break;
      case 403:
        userMessage = this._textErrorStatus403();
        break;
      case 422:
        userMessage = this._textErrorStatus422();
        break;
      case 500:
        userMessage = this._textErrorStatus500();
        break;
      default:
        userMessage = this._textDefaultErrorStatus();
        break;
    }

    return userMessage;
  }

  /**
   * @name _textErrorStatus0
   * @description
   * return the translation text of error status 0
   * @memberof CoreErrorHandlerBaseService
   * @protected
   * @returns {string}
   */
  protected _textErrorStatus0(): string {
    return this._translateService.instant("GENERAL.ENDPOINTS.ONE.ERRORS.0");
  }

  /**
   * @name _textErrorStatus400
   * @description
   * return the translation text of error status 400
   * @memberof CoreErrorHandlerBaseService
   * @protected
   * @returns {string}
   */
  protected _textErrorStatus400(): string {
    return this._translateService.instant("GENERAL.ENDPOINTS.ONE.ERRORS.400");
  }

  /**
   * @name _textErrorStatus401
   * @description
   * return the translation text of error status 401
   * @memberof CoreErrorHandlerBaseService
   * @protected
   * @returns {string}
   */
  protected _textErrorStatus401(): string {
    return this._translateService.instant("GENERAL.ENDPOINTS.ONE.ERRORS.401");
  }

  /**
   * @name _textErrorStatus403
   * @description
   * return the translation text of error status 403
   * @memberof CoreErrorHandlerBaseService
   * @protected
   * @returns {string}
   */
  protected _textErrorStatus403(): string {
    return this._translateService.instant("GENERAL.ENDPOINTS.ONE.ERRORS.403");
  }

  /**
   * @name _textErrorStatus422
   * @description
   * return the translation text of error status 422
   * @memberof CoreErrorHandlerBaseService
   * @protected
   * @returns {string}
   */
  protected _textErrorStatus422(): string {
    return this._translateService.instant("GENERAL.ENDPOINTS.ONE.ERRORS.422");
  }

  /**
   * @name _textErrorStatus500
   * @description
   * return the translation text of error status 500
   * @memberof CoreErrorHandlerBaseService
   * @protected
   * @returns {string}
   */
  protected _textErrorStatus500(): string {
    return this._translateService.instant("GENERAL.ENDPOINTS.ONE.ERRORS.500");
  }

  /**
   * @name _textDefaultErrorStatus
   * @description
   * return the translation text of error default
   * @memberof CoreErrorHandlerBaseService
   * @protected
   * @returns {string}
   */
  protected _textDefaultErrorStatus(): string {
    return this._translateService.instant("GENERAL.ENDPOINTS.ONE.ERRORS.DEFAULT");
  }

  /**
   * @name _getAllErrors
   * @memberof CoreErrorHandlerBaseService
   * @description
   * return all errors or the error existing in the object
   * @param errorsServer
   * @protected
   * @returns {string}
   */
  protected _getAllErrors(errorsServer: any[]): string {
    let response: string = '';
    if (errorsServer.length > 1) {
      errorsServer.forEach((error, index) => {
        response += `[${ index + 1 }].- ${ error.Description }\n`;
      });
    } else {
      response = errorsServer[0].Description
    }

    return response;
  }
}
