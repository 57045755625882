import {Component, Input, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {TranslateModule} from "@ngx-translate/core";
import {SharedUserHtmlComponent} from "@shared/components/user-html/shared-user-html.component";
import {CustomInfoKinds} from "@server-models";

@Component({
  selector: 'app-shared-custom-property-form-input-info',
  templateUrl: './shared-custom-property-form-input-info.component.html',
  styleUrls: ['./shared-custom-property-form-input-info.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule,
    TranslateModule, SharedUserHtmlComponent],
})
export class SharedCustomPropertyFormInputInfoComponent implements OnInit {

  @Input() label: string = '';
  @Input() infoData!: {
    userContent: string,
    kind: CustomInfoKinds
  };


  constructor() {
  }

  ngOnInit() {

  }
}
