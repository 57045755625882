import {Component} from '@angular/core';
import {CommonModule} from "@angular/common";
import {IonicModule} from "@ionic/angular";
import {TranslateModule} from "@ngx-translate/core";

@Component({
  selector: 'app-link-login-message-card',
  templateUrl: './link-login-message-card.component.html',
  styleUrls: ['./link-login-message-card.component.scss'],
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule,
  ],
  standalone: true
})
export class LinkLoginMessageCardComponent {


}
