/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type { AcceptedInvitationDto } from './models/AcceptedInvitationDto';
export type { AcceptEditorRequestDto } from './models/AcceptEditorRequestDto';
export type { AcceptInvitationDto } from './models/AcceptInvitationDto';
export type { AcceptInvitationViaIdentityDto } from './models/AcceptInvitationViaIdentityDto';
export type { AccessOfContactDto } from './models/AccessOfContactDto';
export type { ActivateDemoSubscriptionDto } from './models/ActivateDemoSubscriptionDto';
export type { ActivationMissionByInspectionDtm } from './models/ActivationMissionByInspectionDtm';
export type { ActivationMissionByTaskDtm } from './models/ActivationMissionByTaskDtm';
export type { ActivationMissionByTicketDtm } from './models/ActivationMissionByTicketDtm';
export type { ActivationMissionDtm } from './models/ActivationMissionDtm';
export type { AddLabelDto } from './models/AddLabelDto';
export type { AddressDtm } from './models/AddressDtm';
export type { AddressDto } from './models/AddressDto';
export type { AddressInMissionDto } from './models/AddressInMissionDto';
export type { AddressInMissionListDto } from './models/AddressInMissionListDto';
export type { AddressListDto } from './models/AddressListDto';
export type { AddressListForCompanyDto } from './models/AddressListForCompanyDto';
export type { AddressListForLocationDto } from './models/AddressListForLocationDto';
export type { AddressLocationDto } from './models/AddressLocationDto';
export type { AddressSimpleDto } from './models/AddressSimpleDto';
export type { AddressSimpleListDto } from './models/AddressSimpleListDto';
export type { AddressTtm } from './models/AddressTtm';
export type { AlertImpactConsequenceDto } from './models/AlertImpactConsequenceDto';
export type { AlertImpactCreateV1Dto } from './models/AlertImpactCreateV1Dto';
export type { AlertImpactDto } from './models/AlertImpactDto';
export type { AlertImpactV1Dto } from './models/AlertImpactV1Dto';
export type { AllowedContextDto } from './models/AllowedContextDto';
export type { AllResourceTicketSkeletonSelectorDto } from './models/AllResourceTicketSkeletonSelectorDto';
export type { AlternativeEditorSuggestionDto } from './models/AlternativeEditorSuggestionDto';
export type { AnalyticByTimePortalRequestDto } from './models/AnalyticByTimePortalRequestDto';
export type { AnalyticParams } from './models/AnalyticParams';
export type { AnalyticPortalRequestDto } from './models/AnalyticPortalRequestDto';
export { AppEntityType } from './models/AppEntityType';
export { AppFunctions } from './models/AppFunctions';
export type { AppliedTemplateDto } from './models/AppliedTemplateDto';
export type { ApplyFromResourceByLocationDto } from './models/ApplyFromResourceByLocationDto';
export type { ApplyFromResourcesByLocationDto } from './models/ApplyFromResourcesByLocationDto';
export type { ApplyFromTicketByLocationDto } from './models/ApplyFromTicketByLocationDto';
export type { ApplyFromTicketsByLocationDto } from './models/ApplyFromTicketsByLocationDto';
export type { ApplyLocationOntoResourceQueueDto } from './models/ApplyLocationOntoResourceQueueDto';
export type { AppOverviewDto } from './models/AppOverviewDto';
export type { AppOverviewExportDto } from './models/AppOverviewExportDto';
export type { AppOverviewListDto } from './models/AppOverviewListDto';
export type { AppPermissionDto } from './models/AppPermissionDto';
export { AppPermissions } from './models/AppPermissions';
export type { AppRoleDto } from './models/AppRoleDto';
export type { AppRoleExportDto } from './models/AppRoleExportDto';
export type { AppRoleInfoDto } from './models/AppRoleInfoDto';
export type { AppRoleListDto } from './models/AppRoleListDto';
export type { AppRoleListForContactDto } from './models/AppRoleListForContactDto';
export type { AppRoleListForContactDtoPageOutputDto } from './models/AppRoleListForContactDtoPageOutputDto';
export type { AppRolePermissionDto } from './models/AppRolePermissionDto';
export type { AppShortcutCreateChatByDefinitionDto } from './models/AppShortcutCreateChatByDefinitionDto';
export type { AppShortcutCreateChatByFormDefinitionExportDto } from './models/AppShortcutCreateChatByFormDefinitionExportDto';
export type { AppShortcutCreateChatByProcessDto } from './models/AppShortcutCreateChatByProcessDto';
export type { AppShortcutCreateChatByProcessExportDto } from './models/AppShortcutCreateChatByProcessExportDto';
export type { AppShortcutCreateChatDto } from './models/AppShortcutCreateChatDto';
export type { AppShortcutCreateChatExportDto } from './models/AppShortcutCreateChatExportDto';
export type { AppShortcutDto } from './models/AppShortcutDto';
export type { AppShortcutExportDto } from './models/AppShortcutExportDto';
export type { AppShortcutOpenKnowledgeArticleDto } from './models/AppShortcutOpenKnowledgeArticleDto';
export type { AppShortcutOpenKnowledgeArticleExportDto } from './models/AppShortcutOpenKnowledgeArticleExportDto';
export type { AppShortcutParameterDto } from './models/AppShortcutParameterDto';
export type { AppShortcutRouteDto } from './models/AppShortcutRouteDto';
export type { AppShortcutRouteExportDto } from './models/AppShortcutRouteExportDto';
export type { AppShortcutShowDocumentFolderDto } from './models/AppShortcutShowDocumentFolderDto';
export type { AppShortcutShowDocumentFolderExportDto } from './models/AppShortcutShowDocumentFolderExportDto';
export type { AppShortcutShowTaskArchiveDto } from './models/AppShortcutShowTaskArchiveDto';
export type { AppShortcutShowTaskArchiveExportDto } from './models/AppShortcutShowTaskArchiveExportDto';
export { AppShortcutType } from './models/AppShortcutType';
export type { AppShortcutUrlDto } from './models/AppShortcutUrlDto';
export type { AppShortcutUrlExportDto } from './models/AppShortcutUrlExportDto';
export type { AppShortcutValueDto } from './models/AppShortcutValueDto';
export { AppShortcutValueType } from './models/AppShortcutValueType';
export type { AppTenantDto } from './models/AppTenantDto';
export type { AppTenantListDto } from './models/AppTenantListDto';
export type { AppTenantTokenDto } from './models/AppTenantTokenDto';
export type { AppTimelineListDto } from './models/AppTimelineListDto';
export type { AppTimelineSettingDto } from './models/AppTimelineSettingDto';
export type { AppTokenDto } from './models/AppTokenDto';
export type { ArchivedEntityQueueDto } from './models/ArchivedEntityQueueDto';
export type { ArchivedTaskReasonQueueDto } from './models/ArchivedTaskReasonQueueDto';
export type { ArchiveDto } from './models/ArchiveDto';
export type { AreaAssignmentBulkDto } from './models/AreaAssignmentBulkDto';
export type { AreaAssignmentDto } from './models/AreaAssignmentDto';
export type { AreaAssignmentInContactDto } from './models/AreaAssignmentInContactDto';
export type { AreaAssignmentListDto } from './models/AreaAssignmentListDto';
export { AreaAssignmentReach } from './models/AreaAssignmentReach';
export type { ArticleDto } from './models/ArticleDto';
export type { ArticleInUsageDto } from './models/ArticleInUsageDto';
export type { ArticleInUsageSkeletonDto } from './models/ArticleInUsageSkeletonDto';
export type { ArticleKindDto } from './models/ArticleKindDto';
export type { ArticleKindListDto } from './models/ArticleKindListDto';
export type { ArticleKindSimpleDto } from './models/ArticleKindSimpleDto';
export type { ArticleKindSimpleListDto } from './models/ArticleKindSimpleListDto';
export type { ArticleListDto } from './models/ArticleListDto';
export type { ArticleUsageDtm } from './models/ArticleUsageDtm';
export type { ArticleUsageDto } from './models/ArticleUsageDto';
export type { ArticleUsageListDto } from './models/ArticleUsageListDto';
export type { ArticleUsageSkeletonDto } from './models/ArticleUsageSkeletonDto';
export type { ArticleUsageTtm } from './models/ArticleUsageTtm';
export type { AssignAttachmentToSolutionDto } from './models/AssignAttachmentToSolutionDto';
export type { AttachmentFormFieldDto } from './models/AttachmentFormFieldDto';
export type { AttachmentForTechCompleteDto } from './models/AttachmentForTechCompleteDto';
export type { AttachmentForTechDto } from './models/AttachmentForTechDto';
export { AttachmentTypeForTech } from './models/AttachmentTypeForTech';
export { AttachmentTypes } from './models/AttachmentTypes';
export type { AuditDto } from './models/AuditDto';
export { AuditKind } from './models/AuditKind';
export type { AuditListDto } from './models/AuditListDto';
export type { AuditOfCollectionDto } from './models/AuditOfCollectionDto';
export type { AuditOfPropertyDto } from './models/AuditOfPropertyDto';
export { AuditPropertyType } from './models/AuditPropertyType';
export type { AzureLoginProviderDto } from './models/AzureLoginProviderDto';
export type { AzureLoginProviderListDto } from './models/AzureLoginProviderListDto';
export type { AzureLoginProviderRoleDto } from './models/AzureLoginProviderRoleDto';
export type { AzureLoginProviderRoleListDto } from './models/AzureLoginProviderRoleListDto';
export { BillingType } from './models/BillingType';
export { Brands } from './models/Brands';
export type { Calendar } from './models/Calendar';
export type { CalendarAlgorithmType } from './models/CalendarAlgorithmType';
export type { CalendarConnectionDto } from './models/CalendarConnectionDto';
export type { CalendarDataTableDto } from './models/CalendarDataTableDto';
export type { CalendarEventDto } from './models/CalendarEventDto';
export type { CalendarListDto } from './models/CalendarListDto';
export type { CalendarPropertyDto } from './models/CalendarPropertyDto';
export type { CalendarWeekRule } from './models/CalendarWeekRule';
export type { CancelJobsQueueDto } from './models/CancelJobsQueueDto';
export type { CancelJobsType } from './models/CancelJobsType';
export type { CancelResourceTaskQueueDto } from './models/CancelResourceTaskQueueDto';
export type { CancelSearchingEditorQueueDto } from './models/CancelSearchingEditorQueueDto';
export type { CarrySolutionMailInQueueDto } from './models/CarrySolutionMailInQueueDto';
export type { CategoriesByTypeDto } from './models/CategoriesByTypeDto';
export type { CategorizedRelationDto } from './models/CategorizedRelationDto';
export { CategorizedType } from './models/CategorizedType';
export type { CategoryDto } from './models/CategoryDto';
export type { CategoryInRelationListDto } from './models/CategoryInRelationListDto';
export type { CategoryListDto } from './models/CategoryListDto';
export { CategorySector } from './models/CategorySector';
export type { CategorySimpleDto } from './models/CategorySimpleDto';
export type { ChangedAreaAssignmentQueueDto } from './models/ChangedAreaAssignmentQueueDto';
export type { ChangedEntityQueueDto } from './models/ChangedEntityQueueDto';
export type { ChangedLocationAndLocationGroupConnectionQueueDto } from './models/ChangedLocationAndLocationGroupConnectionQueueDto';
export type { ChangedLocationGroupLocationQueueDto } from './models/ChangedLocationGroupLocationQueueDto';
export type { ChangedStateOfMissionDto } from './models/ChangedStateOfMissionDto';
export type { ChangedStatusConditionDto } from './models/ChangedStatusConditionDto';
export type { ChangedStatusConditionV1Dto } from './models/ChangedStatusConditionV1Dto';
export type { ChangeEmailDto } from './models/ChangeEmailDto';
export type { ChangeResourceStateDto } from './models/ChangeResourceStateDto';
export type { ChangeStateOfMissionDto } from './models/ChangeStateOfMissionDto';
export type { ChangeTaskJobStatusDto } from './models/ChangeTaskJobStatusDto';
export type { ChangeTicketStateWhenMissionClosedQueueDto } from './models/ChangeTicketStateWhenMissionClosedQueueDto';
export type { ChangeTicketStateWhenMissionStateChangedQueueDto } from './models/ChangeTicketStateWhenMissionStateChangedQueueDto';
export { CharGroups } from './models/CharGroups';
export type { ChatByDefinitionGeneralDataProtectionRestrictionDto } from './models/ChatByDefinitionGeneralDataProtectionRestrictionDto';
export type { ChatByFormDefinitionDto } from './models/ChatByFormDefinitionDto';
export type { ChatByFormDefinitionListDto } from './models/ChatByFormDefinitionListDto';
export type { ChatByFormDefinitionSimpleDto } from './models/ChatByFormDefinitionSimpleDto';
export type { ChatByFormDefinitionSimpleExportDto } from './models/ChatByFormDefinitionSimpleExportDto';
export type { ChatByFormFunctionReceivingDto } from './models/ChatByFormFunctionReceivingDto';
export type { ChatByFormLocationReceivingDto } from './models/ChatByFormLocationReceivingDto';
export type { ChatByFormMessagePatternDto } from './models/ChatByFormMessagePatternDto';
export type { ChatByFormReceiverDto } from './models/ChatByFormReceiverDto';
export { ChatByFormReceiverType } from './models/ChatByFormReceiverType';
export type { ChatByFormReceivingDto } from './models/ChatByFormReceivingDto';
export { ChatByFormReceivingKind } from './models/ChatByFormReceivingKind';
export type { ChatByMailDto } from './models/ChatByMailDto';
export type { ChatByMailIdentificationDto } from './models/ChatByMailIdentificationDto';
export type { ChatByMailMessageDto } from './models/ChatByMailMessageDto';
export type { ChatByMailOriginDto } from './models/ChatByMailOriginDto';
export type { ChatDto } from './models/ChatDto';
export type { ChatListDto } from './models/ChatListDto';
export type { ChatMemberDto } from './models/ChatMemberDto';
export type { ChatMemberListDto } from './models/ChatMemberListDto';
export type { ChatMemberMessageListDto } from './models/ChatMemberMessageListDto';
export { ChatMemberType } from './models/ChatMemberType';
export type { ChatMessageDto } from './models/ChatMessageDto';
export type { ChatMessageInNoteListDto } from './models/ChatMessageInNoteListDto';
export type { ChatMessageListDto } from './models/ChatMessageListDto';
export type { ChatMessageNotificationDto } from './models/ChatMessageNotificationDto';
export { ChatMessageType } from './models/ChatMessageType';
export type { ChatSkeletonDto } from './models/ChatSkeletonDto';
export type { ChatSkeletonV1Dto } from './models/ChatSkeletonV1Dto';
export type { ChatSourceCompleteListDto } from './models/ChatSourceCompleteListDto';
export { ChatSourceType } from './models/ChatSourceType';
export type { ChatStarterByDefinitionDto } from './models/ChatStarterByDefinitionDto';
export type { ChatStarterByFormDto } from './models/ChatStarterByFormDto';
export type { ChatStarterByProcessDto } from './models/ChatStarterByProcessDto';
export type { ChatStarterDto } from './models/ChatStarterDto';
export type { ChatStartersDto } from './models/ChatStartersDto';
export { ChatStarterType } from './models/ChatStarterType';
export type { CheckboxFormDefaultDto } from './models/CheckboxFormDefaultDto';
export type { CheckboxFormFieldDto } from './models/CheckboxFormFieldDto';
export type { CheckboxFormValueDto } from './models/CheckboxFormValueDto';
export type { CheckTenantActivityQueueDto } from './models/CheckTenantActivityQueueDto';
export type { ChildTaskSimpleDto } from './models/ChildTaskSimpleDto';
export { Clients } from './models/Clients';
export type { ClosedLocationQueueDto } from './models/ClosedLocationQueueDto';
export type { ClosedLocationReasonQueueDto } from './models/ClosedLocationReasonQueueDto';
export type { ClosingLocationOptionsDto } from './models/ClosingLocationOptionsDto';
export { CodeStatus } from './models/CodeStatus';
export type { CollectionTaskCreateV1Dto } from './models/CollectionTaskCreateV1Dto';
export type { CollectionTaskDto } from './models/CollectionTaskDto';
export type { CollectionTaskSimpleV1Dto } from './models/CollectionTaskSimpleV1Dto';
export type { CollectionTaskV1Dto } from './models/CollectionTaskV1Dto';
export { CombineOperator } from './models/CombineOperator';
export type { CommissionContactQueueDto } from './models/CommissionContactQueueDto';
export type { CommissionEditorDto } from './models/CommissionEditorDto';
export type { CommissionEditorInternalDto } from './models/CommissionEditorInternalDto';
export type { CommonColumnDto } from './models/CommonColumnDto';
export type { CommonCustomColumnDto } from './models/CommonCustomColumnDto';
export type { CommonCustomFilterDto } from './models/CommonCustomFilterDto';
export type { CommonDestinationDto } from './models/CommonDestinationDto';
export type { CommonEmailDestinationDto } from './models/CommonEmailDestinationDto';
export type { CommonFilterDto } from './models/CommonFilterDto';
export type { CommonGroupedFilterDto } from './models/CommonGroupedFilterDto';
export type { CommonTransferColumnDto } from './models/CommonTransferColumnDto';
export type { CommonTransferFilterDto } from './models/CommonTransferFilterDto';
export type { CompanyAddressDto } from './models/CompanyAddressDto';
export type { CompanyDtm } from './models/CompanyDtm';
export type { CompanyDto } from './models/CompanyDto';
export type { CompanyFunctionDto } from './models/CompanyFunctionDto';
export type { CompanyListDto } from './models/CompanyListDto';
export type { CompanyListForAddressDto } from './models/CompanyListForAddressDto';
export type { CompanyListForContactDto } from './models/CompanyListForContactDto';
export type { CompanyListForFunctionDto } from './models/CompanyListForFunctionDto';
export type { CompanyListSimpleDto } from './models/CompanyListSimpleDto';
export type { CompanySimpleDto } from './models/CompanySimpleDto';
export type { CompanyTtm } from './models/CompanyTtm';
export type { CompareInfo } from './models/CompareInfo';
export { ConcreteTaskTypes } from './models/ConcreteTaskTypes';
export type { ConditionDto } from './models/ConditionDto';
export { ConditionTypes } from './models/ConditionTypes';
export type { ConditionV1Dto } from './models/ConditionV1Dto';
export type { ConfirmationTaskCreateV1Dto } from './models/ConfirmationTaskCreateV1Dto';
export type { ConfirmationTaskDto } from './models/ConfirmationTaskDto';
export type { ConfirmationTaskListV1Dto } from './models/ConfirmationTaskListV1Dto';
export type { ConfirmationTaskSimpleV1Dto } from './models/ConfirmationTaskSimpleV1Dto';
export type { ConfirmationTaskV1Dto } from './models/ConfirmationTaskV1Dto';
export type { ConnectedConsumptionDto } from './models/ConnectedConsumptionDto';
export type { ConnectedDefinitionDto } from './models/ConnectedDefinitionDto';
export type { ConnectedDefinitionListDto } from './models/ConnectedDefinitionListDto';
export type { ConnectedDefinitionSimpleDto } from './models/ConnectedDefinitionSimpleDto';
export type { ConnectedErrorDto } from './models/ConnectedErrorDto';
export type { ConnectedEventsDto } from './models/ConnectedEventsDto';
export type { ConnectedEventsResultDto } from './models/ConnectedEventsResultDto';
export type { ConnectedHintDto } from './models/ConnectedHintDto';
export type { ConnectedIdentificationByDirectIdDto } from './models/ConnectedIdentificationByDirectIdDto';
export type { ConnectedIdentificationBySerialNumberDto } from './models/ConnectedIdentificationBySerialNumberDto';
export type { ConnectedIdentificationDto } from './models/ConnectedIdentificationDto';
export { ConnectedIdentificationType } from './models/ConnectedIdentificationType';
export type { ConnectedResourceIdentificationDto } from './models/ConnectedResourceIdentificationDto';
export type { ConsumptionCodeDto } from './models/ConsumptionCodeDto';
export type { ConsumptionCodeListDto } from './models/ConsumptionCodeListDto';
export type { ConsumptionCodeSimpleDto } from './models/ConsumptionCodeSimpleDto';
export type { ConsumptionCodeSimpleListDto } from './models/ConsumptionCodeSimpleListDto';
export type { ContactChatMemberDto } from './models/ContactChatMemberDto';
export type { ContactDtm } from './models/ContactDtm';
export type { ContactDto } from './models/ContactDto';
export type { ContactGroupSimpleDto } from './models/ContactGroupSimpleDto';
export type { ContactInMissionListDto } from './models/ContactInMissionListDto';
export type { ContactInTicketListDto } from './models/ContactInTicketListDto';
export type { ContactIssueReceiverDto } from './models/ContactIssueReceiverDto';
export type { ContactListDto } from './models/ContactListDto';
export type { ContactListForCompanyDto } from './models/ContactListForCompanyDto';
export type { ContactListForFunctionDto } from './models/ContactListForFunctionDto';
export type { ContactListForFunctionDtoPageOutputDto } from './models/ContactListForFunctionDtoPageOutputDto';
export type { ContactListForInfoDto } from './models/ContactListForInfoDto';
export type { ContactListForLocationDto } from './models/ContactListForLocationDto';
export type { ContactListForLocationGroupDto } from './models/ContactListForLocationGroupDto';
export type { ContactListForRoleDto } from './models/ContactListForRoleDto';
export { ContactLoginMethod } from './models/ContactLoginMethod';
export type { ContactLoginMethodSettingDto } from './models/ContactLoginMethodSettingDto';
export { ContactNotificationBehaviour } from './models/ContactNotificationBehaviour';
export type { ContactNotificationTargetDto } from './models/ContactNotificationTargetDto';
export type { ContactRelationDto } from './models/ContactRelationDto';
export type { ContactRelationListDto } from './models/ContactRelationListDto';
export type { ContactSimpleDto } from './models/ContactSimpleDto';
export type { ContactSimpleListDto } from './models/ContactSimpleListDto';
export type { ContactTtm } from './models/ContactTtm';
export { ContentType } from './models/ContentType';
export { ContextCloak } from './models/ContextCloak';
export { ContextKind } from './models/ContextKind';
export type { ContractDto } from './models/ContractDto';
export type { ContractListDto } from './models/ContractListDto';
export type { ContractSimpleDto } from './models/ContractSimpleDto';
export type { ContractSimpleListDto } from './models/ContractSimpleListDto';
export { ContractState } from './models/ContractState';
export { ControllerOperationId } from './models/ControllerOperationId';
export type { ControlPointDtm } from './models/ControlPointDtm';
export type { ControlPointDto } from './models/ControlPointDto';
export type { ControlPointFilterDto } from './models/ControlPointFilterDto';
export type { ControlPointForResourceListDto } from './models/ControlPointForResourceListDto';
export type { ControlPointInMissionDto } from './models/ControlPointInMissionDto';
export type { ControlPointInMissionListDto } from './models/ControlPointInMissionListDto';
export type { ControlPointInTagDto } from './models/ControlPointInTagDto';
export type { ControlPointListDto } from './models/ControlPointListDto';
export type { ControlPointSimpleDto } from './models/ControlPointSimpleDto';
export type { ControlPointTtm } from './models/ControlPointTtm';
export type { ControlTagDto } from './models/ControlTagDto';
export type { ControlTagInPointListDto } from './models/ControlTagInPointListDto';
export type { ControlTagListDto } from './models/ControlTagListDto';
export type { ControlTagReferenceListDto } from './models/ControlTagReferenceListDto';
export type { ControlTagSimpleDto } from './models/ControlTagSimpleDto';
export type { CoverGeneralDataProtectionRegulatedEntityQueueDto } from './models/CoverGeneralDataProtectionRegulatedEntityQueueDto';
export type { CreateChatByFormDto } from './models/CreateChatByFormDto';
export type { CreatedByDto } from './models/CreatedByDto';
export type { CreateFileDto } from './models/CreateFileDto';
export type { CreateFolderDto } from './models/CreateFolderDto';
export type { CreateManualTaskJobDto } from './models/CreateManualTaskJobDto';
export type { CreateTicketByProcessDto } from './models/CreateTicketByProcessDto';
export type { CreateTicketByProcessResponseDto } from './models/CreateTicketByProcessResponseDto';
export type { CultureInfo } from './models/CultureInfo';
export type { CultureSettingDto } from './models/CultureSettingDto';
export type { CultureTypes } from './models/CultureTypes';
export type { CurrentAppOverviewDto } from './models/CurrentAppOverviewDto';
export type { CustomCheckboxDefaultDto } from './models/CustomCheckboxDefaultDto';
export type { CustomCheckboxDefaultExportDto } from './models/CustomCheckboxDefaultExportDto';
export type { CustomCheckboxDto } from './models/CustomCheckboxDto';
export type { CustomCheckboxExportDto } from './models/CustomCheckboxExportDto';
export type { CustomCheckboxValueDto } from './models/CustomCheckboxValueDto';
export type { CustomDateDefaultDto } from './models/CustomDateDefaultDto';
export type { CustomDateDefaultExportDto } from './models/CustomDateDefaultExportDto';
export type { CustomDatePropertyDto } from './models/CustomDatePropertyDto';
export type { CustomDatePropertyExportDto } from './models/CustomDatePropertyExportDto';
export type { CustomDateValueDto } from './models/CustomDateValueDto';
export type { CustomDefaultCompleteDto } from './models/CustomDefaultCompleteDto';
export type { CustomDefaultDto } from './models/CustomDefaultDto';
export type { CustomDefaultExportDto } from './models/CustomDefaultExportDto';
export type { CustomDropDownDefaultExportDto } from './models/CustomDropDownDefaultExportDto';
export type { CustomDropDownDefaultValueDto } from './models/CustomDropDownDefaultValueDto';
export type { CustomDropDownDto } from './models/CustomDropDownDto';
export type { CustomDropDownExportDto } from './models/CustomDropDownExportDto';
export type { CustomDropDownValueDto } from './models/CustomDropDownValueDto';
export type { CustomEmailDefaultDto } from './models/CustomEmailDefaultDto';
export type { CustomEmailDefaultExportDto } from './models/CustomEmailDefaultExportDto';
export type { CustomEmailPropertyDto } from './models/CustomEmailPropertyDto';
export type { CustomEmailPropertyExportDto } from './models/CustomEmailPropertyExportDto';
export type { CustomEmailValueDto } from './models/CustomEmailValueDto';
export type { CustomFilterDto } from './models/CustomFilterDto';
export type { CustomInfoDto } from './models/CustomInfoDto';
export type { CustomInfoExportDto } from './models/CustomInfoExportDto';
export { CustomInfoKinds } from './models/CustomInfoKinds';
export type { CustomInfoValueDto } from './models/CustomInfoValueDto';
export type { CustomMultilineDefaultDto } from './models/CustomMultilineDefaultDto';
export type { CustomMultilineDefaultExportDto } from './models/CustomMultilineDefaultExportDto';
export type { CustomMultilinePropertyDto } from './models/CustomMultilinePropertyDto';
export type { CustomMultilinePropertyExportDto } from './models/CustomMultilinePropertyExportDto';
export type { CustomMultilineValueDto } from './models/CustomMultilineValueDto';
export type { CustomMultipleChoiceDefaultDto } from './models/CustomMultipleChoiceDefaultDto';
export type { CustomMultipleChoiceDefaultExportDto } from './models/CustomMultipleChoiceDefaultExportDto';
export type { CustomMultipleChoiceDto } from './models/CustomMultipleChoiceDto';
export type { CustomMultipleChoiceExportDto } from './models/CustomMultipleChoiceExportDto';
export type { CustomMultipleChoiceValueDto } from './models/CustomMultipleChoiceValueDto';
export type { CustomNumericDefaultDto } from './models/CustomNumericDefaultDto';
export type { CustomNumericDefaultExportDto } from './models/CustomNumericDefaultExportDto';
export type { CustomNumericPropertyDto } from './models/CustomNumericPropertyDto';
export type { CustomNumericPropertyExportDto } from './models/CustomNumericPropertyExportDto';
export type { CustomNumericValueDto } from './models/CustomNumericValueDto';
export type { CustomPhoneDefaultDto } from './models/CustomPhoneDefaultDto';
export type { CustomPhoneDefaultExportDto } from './models/CustomPhoneDefaultExportDto';
export type { CustomPhoneDto } from './models/CustomPhoneDto';
export type { CustomPhoneExportDto } from './models/CustomPhoneExportDto';
export type { CustomPhoneValueDto } from './models/CustomPhoneValueDto';
export type { CustomPictureDto } from './models/CustomPictureDto';
export type { CustomPictureExportDto } from './models/CustomPictureExportDto';
export type { CustomPictureValueDto } from './models/CustomPictureValueDto';
export type { CustomPropertiesForModel } from './models/CustomPropertiesForModel';
export type { CustomPropertyCompleteDto } from './models/CustomPropertyCompleteDto';
export type { CustomPropertyDto } from './models/CustomPropertyDto';
export type { CustomPropertyExportDto } from './models/CustomPropertyExportDto';
export type { CustomPropertyInConnectedIdentificationDto } from './models/CustomPropertyInConnectedIdentificationDto';
export type { CustomPropertySetDto } from './models/CustomPropertySetDto';
export type { CustomPropertySetExportDto } from './models/CustomPropertySetExportDto';
export type { CustomPropertySetListDto } from './models/CustomPropertySetListDto';
export type { CustomPropertySimpleDto } from './models/CustomPropertySimpleDto';
export type { CustomPropertyTtm } from './models/CustomPropertyTtm';
export { CustomPropertyType } from './models/CustomPropertyType';
export type { CustomPropertyViewSetDto } from './models/CustomPropertyViewSetDto';
export type { CustomSetInConnectedIdentificationDto } from './models/CustomSetInConnectedIdentificationDto';
export type { CustomSetReferenceDto } from './models/CustomSetReferenceDto';
export type { CustomSetReferenceExportDto } from './models/CustomSetReferenceExportDto';
export type { CustomSetReferenceInConnectedIdentificationDto } from './models/CustomSetReferenceInConnectedIdentificationDto';
export type { CustomSetReferenceListDto } from './models/CustomSetReferenceListDto';
export type { CustomSignatureDto } from './models/CustomSignatureDto';
export type { CustomSignatureExportDto } from './models/CustomSignatureExportDto';
export type { CustomSignatureValueDto } from './models/CustomSignatureValueDto';
export type { CustomTextDefaultDto } from './models/CustomTextDefaultDto';
export type { CustomTextDefaultExportDto } from './models/CustomTextDefaultExportDto';
export type { CustomTextPropertyDto } from './models/CustomTextPropertyDto';
export type { CustomTextPropertyExportDto } from './models/CustomTextPropertyExportDto';
export type { CustomTextValueDto } from './models/CustomTextValueDto';
export type { CustomTimeDefaultDto } from './models/CustomTimeDefaultDto';
export type { CustomTimeDefaultExportDto } from './models/CustomTimeDefaultExportDto';
export type { CustomTimePropertyDto } from './models/CustomTimePropertyDto';
export type { CustomTimePropertyExportDto } from './models/CustomTimePropertyExportDto';
export type { CustomTimeValueDto } from './models/CustomTimeValueDto';
export type { CustomValueCompleteDto } from './models/CustomValueCompleteDto';
export type { CustomValueDtm } from './models/CustomValueDtm';
export type { CustomValueDto } from './models/CustomValueDto';
export type { CustomValueImportEntry } from './models/CustomValueImportEntry';
export type { DailyDateTimePatternDto } from './models/DailyDateTimePatternDto';
export type { DailyDateTimePatternV1Dto } from './models/DailyDateTimePatternV1Dto';
export type { DashboardDto } from './models/DashboardDto';
export type { DashboardItemDto } from './models/DashboardItemDto';
export { DashboardItemTypes } from './models/DashboardItemTypes';
export type { DashboardListDto } from './models/DashboardListDto';
export type { DashboardTableReferenceDto } from './models/DashboardTableReferenceDto';
export type { DataTableColumnDto } from './models/DataTableColumnDto';
export { DataTableColumnType } from './models/DataTableColumnType';
export type { DataTableCustomColumnDto } from './models/DataTableCustomColumnDto';
export type { DataTableCustomFilterDto } from './models/DataTableCustomFilterDto';
export type { DataTableDto } from './models/DataTableDto';
export type { DataTableFilterDto } from './models/DataTableFilterDto';
export { DataTableFilterType } from './models/DataTableFilterType';
export type { DataTableGroupedFilterDto } from './models/DataTableGroupedFilterDto';
export type { DataTableInDashboardDto } from './models/DataTableInDashboardDto';
export type { DataTableInRestrictionDto } from './models/DataTableInRestrictionDto';
export type { DataTableInSelectorDto } from './models/DataTableInSelectorDto';
export type { DataTableListDto } from './models/DataTableListDto';
export type { DataTableSimpleDto } from './models/DataTableSimpleDto';
export type { DataTableTransferColumnDto } from './models/DataTableTransferColumnDto';
export type { DataTableTransferFilterDto } from './models/DataTableTransferFilterDto';
export { DataTableUsage } from './models/DataTableUsage';
export type { DateFormDefaultDto } from './models/DateFormDefaultDto';
export type { DateFormFieldDto } from './models/DateFormFieldDto';
export type { DateFormValueDto } from './models/DateFormValueDto';
export type { DateTimeFormatInfo } from './models/DateTimeFormatInfo';
export type { DateTimePatternDto } from './models/DateTimePatternDto';
export type { DateTimePatternV1Dto } from './models/DateTimePatternV1Dto';
export type { DayOfWeek } from './models/DayOfWeek';
export type { DeactivateResourceTaskQueueDto } from './models/DeactivateResourceTaskQueueDto';
export type { DeclineEditorRequestDto } from './models/DeclineEditorRequestDto';
export type { DecodedTokenDto } from './models/DecodedTokenDto';
export type { DeletedAreaAssignmentQueueDto } from './models/DeletedAreaAssignmentQueueDto';
export type { DeletedEntityQueueDto } from './models/DeletedEntityQueueDto';
export type { DeletedLocationGroupLocationQueueDto } from './models/DeletedLocationGroupLocationQueueDto';
export type { DeviceActionNotificationDto } from './models/DeviceActionNotificationDto';
export { DeviceActionTypes } from './models/DeviceActionTypes';
export type { DeviceCodeChallengeDto } from './models/DeviceCodeChallengeDto';
export type { DeviceDto } from './models/DeviceDto';
export type { DeviceListDto } from './models/DeviceListDto';
export type { DeviceMetaEntryDto } from './models/DeviceMetaEntryDto';
export type { DeviceMetaListDto } from './models/DeviceMetaListDto';
export { DeviceStatus } from './models/DeviceStatus';
export type { DigitShapes } from './models/DigitShapes';
export type { DocumentChildTaskDto } from './models/DocumentChildTaskDto';
export type { DocumentChildTaskSimpleDto } from './models/DocumentChildTaskSimpleDto';
export type { DocumentRequestDto } from './models/DocumentRequestDto';
export type { DocumentSearchRequestDto } from './models/DocumentSearchRequestDto';
export type { DocumentTaskCreateV1Dto } from './models/DocumentTaskCreateV1Dto';
export type { DocumentTaskDto } from './models/DocumentTaskDto';
export type { DocumentTaskListV1Dto } from './models/DocumentTaskListV1Dto';
export type { DocumentTaskSimpleV1Dto } from './models/DocumentTaskSimpleV1Dto';
export type { DocumentTaskV1Dto } from './models/DocumentTaskV1Dto';
export { DocumentTemplateContextType } from './models/DocumentTemplateContextType';
export type { DocumentTemplateDto } from './models/DocumentTemplateDto';
export type { DocumentTemplateExportDto } from './models/DocumentTemplateExportDto';
export type { DocumentTemplateListDto } from './models/DocumentTemplateListDto';
export type { DocumentTemplateSimpleDto } from './models/DocumentTemplateSimpleDto';
export type { DocumentTemplateSimpleExportDto } from './models/DocumentTemplateSimpleExportDto';
export type { DropDownFormDefaultDto } from './models/DropDownFormDefaultDto';
export type { DropDownFormFieldDto } from './models/DropDownFormFieldDto';
export type { DropDownFormValueDto } from './models/DropDownFormValueDto';
export type { DropDownFormValueForCompleteDto } from './models/DropDownFormValueForCompleteDto';
export { DropDownStyles } from './models/DropDownStyles';
export type { DynamicFieldDto } from './models/DynamicFieldDto';
export type { EditorDto } from './models/EditorDto';
export type { EditorQuestDto } from './models/EditorQuestDto';
export { EditorQuestState } from './models/EditorQuestState';
export type { EditorRequestDto } from './models/EditorRequestDto';
export { EditorRequestState } from './models/EditorRequestState';
export type { EditorSuggestionBaseDto } from './models/EditorSuggestionBaseDto';
export type { EditorSuggestionByContactDto } from './models/EditorSuggestionByContactDto';
export type { EditorSuggestionByFunctionDto } from './models/EditorSuggestionByFunctionDto';
export type { EditorSuggestionTargetDto } from './models/EditorSuggestionTargetDto';
export { EditorSuggestionTargetType } from './models/EditorSuggestionTargetType';
export { EditorSuggestionType } from './models/EditorSuggestionType';
export type { EmailAddressDto } from './models/EmailAddressDto';
export type { EmailButtonTemplateDto } from './models/EmailButtonTemplateDto';
export type { EmailChatMessageDto } from './models/EmailChatMessageDto';
export type { EmailFailedMailDeliveryDto } from './models/EmailFailedMailDeliveryDto';
export type { EmailHitNotificationRuleDto } from './models/EmailHitNotificationRuleDto';
export type { EmailImportDto } from './models/EmailImportDto';
export type { EmailImportMessageDto } from './models/EmailImportMessageDto';
export type { EmailIssueNotificationDto } from './models/EmailIssueNotificationDto';
export type { EmailIssueReceiverDto } from './models/EmailIssueReceiverDto';
export type { EmailMissionActivationMailDto } from './models/EmailMissionActivationMailDto';
export type { EmailNotificationTargetDto } from './models/EmailNotificationTargetDto';
export type { EmailReportDataTableMailDto } from './models/EmailReportDataTableMailDto';
export type { EmailResetPasswordDto } from './models/EmailResetPasswordDto';
export type { EmailSolutionNoteMailDto } from './models/EmailSolutionNoteMailDto';
export type { EmailTaskJobChangedStatusAlertDto } from './models/EmailTaskJobChangedStatusAlertDto';
export type { EmailTaskJobPercentageDto } from './models/EmailTaskJobPercentageDto';
export type { EmailTaskJobTimeAlertDto } from './models/EmailTaskJobTimeAlertDto';
export type { EmailTemplateDto } from './models/EmailTemplateDto';
export type { EmailTextLineTemplateDto } from './models/EmailTextLineTemplateDto';
export type { EmbeddedFileExportDto } from './models/EmbeddedFileExportDto';
export type { EmbeddedPowerBiDto } from './models/EmbeddedPowerBiDto';
export type { EmptyContextDto } from './models/EmptyContextDto';
export type { EntityIdContextDto } from './models/EntityIdContextDto';
export type { Enum } from './models/Enum';
export type { ErrorCodeDto } from './models/ErrorCodeDto';
export type { ErrorCodeListDto } from './models/ErrorCodeListDto';
export type { ErrorCodeSimpleDto } from './models/ErrorCodeSimpleDto';
export type { ErrorCodeSimpleListDto } from './models/ErrorCodeSimpleListDto';
export type { ErrorDescriptionDto } from './models/ErrorDescriptionDto';
export type { ErrorResultDto } from './models/ErrorResultDto';
export type { EscalationLevelDtm } from './models/EscalationLevelDtm';
export type { EscalationLevelDto } from './models/EscalationLevelDto';
export type { EscalationLevelListDto } from './models/EscalationLevelListDto';
export type { EscalationLevelSimpleDto } from './models/EscalationLevelSimpleDto';
export type { EscalationLevelSimpleListDto } from './models/EscalationLevelSimpleListDto';
export type { EscalationLevelTtm } from './models/EscalationLevelTtm';
export type { EscalationRuleDto } from './models/EscalationRuleDto';
export type { EscalationRulesInEventListDto } from './models/EscalationRulesInEventListDto';
export type { ExecuteableTaskDto } from './models/ExecuteableTaskDto';
export type { ExecutionPlanCompleteListDto } from './models/ExecutionPlanCompleteListDto';
export type { ExecutionPlanDto } from './models/ExecutionPlanDto';
export type { ExecutionPlanV1Dto } from './models/ExecutionPlanV1Dto';
export type { ExecutionPlanViewV1Dto } from './models/ExecutionPlanViewV1Dto';
export { ExecutionTypes } from './models/ExecutionTypes';
export type { ExportDashboardDto } from './models/ExportDashboardDto';
export type { ExportDashboardItemDto } from './models/ExportDashboardItemDto';
export type { ExportDataTableColumnDto } from './models/ExportDataTableColumnDto';
export type { ExportDataTableCustomColumnDto } from './models/ExportDataTableCustomColumnDto';
export type { ExportDataTableCustomFilterDto } from './models/ExportDataTableCustomFilterDto';
export type { ExportDataTableDashboardReferenceDto } from './models/ExportDataTableDashboardReferenceDto';
export type { ExportDataTableDto } from './models/ExportDataTableDto';
export type { ExportDataTableFilterDto } from './models/ExportDataTableFilterDto';
export type { ExportDataTableGroupedFilterDto } from './models/ExportDataTableGroupedFilterDto';
export type { ExportDataTableTransferColumnDto } from './models/ExportDataTableTransferColumnDto';
export type { ExportDataTableTransferFilterDto } from './models/ExportDataTableTransferFilterDto';
export { ExportDestinationTypes } from './models/ExportDestinationTypes';
export type { ExportMetadata } from './models/ExportMetadata';
export type { ExportMissionByInspectionDtm } from './models/ExportMissionByInspectionDtm';
export type { ExportMissionByTaskDtm } from './models/ExportMissionByTaskDtm';
export type { ExportMissionByTicketDtm } from './models/ExportMissionByTicketDtm';
export type { ExportMissionDtm } from './models/ExportMissionDtm';
export type { ExportTaskJobCsvQueueDto } from './models/ExportTaskJobCsvQueueDto';
export type { ExportTicketDtm } from './models/ExportTicketDtm';
export { ExternalApp } from './models/ExternalApp';
export type { ExternalAppLoginDto } from './models/ExternalAppLoginDto';
export type { ExternalChatMemberDto } from './models/ExternalChatMemberDto';
export type { ExternalIdTransfer } from './models/ExternalIdTransfer';
export type { ExternalTokenDto } from './models/ExternalTokenDto';
export type { FailedMailDeliveryQueueDto } from './models/FailedMailDeliveryQueueDto';
export { FieldRating } from './models/FieldRating';
export type { FileAttachmentForTechDto } from './models/FileAttachmentForTechDto';
export type { FileDto } from './models/FileDto';
export { FileFormat } from './models/FileFormat';
export type { FileOrgaAttachmentDto } from './models/FileOrgaAttachmentDto';
export type { FillAFormDto } from './models/FillAFormDto';
export type { FillAFormFieldDto } from './models/FillAFormFieldDto';
export type { FilledCustomValueDto } from './models/FilledCustomValueDto';
export type { FilledCustomValueListDto } from './models/FilledCustomValueListDto';
export type { FilledCustomValueRelationDto } from './models/FilledCustomValueRelationDto';
export type { FilledCustomValueRelationListDto } from './models/FilledCustomValueRelationListDto';
export type { FilledFieldDtm } from './models/FilledFieldDtm';
export type { FilledFormCreateDto } from './models/FilledFormCreateDto';
export type { FilledFormDtm } from './models/FilledFormDtm';
export type { FilledFormDto } from './models/FilledFormDto';
export type { FilledFormFieldCreateDto } from './models/FilledFormFieldCreateDto';
export type { FilledFormOrgaAttachmentDto } from './models/FilledFormOrgaAttachmentDto';
export type { FilledFormPageDtm } from './models/FilledFormPageDtm';
export type { FilledRowDtm } from './models/FilledRowDtm';
export type { FillLabelGapsQueueDto } from './models/FillLabelGapsQueueDto';
export type { FilterDto } from './models/FilterDto';
export { FilterKind } from './models/FilterKind';
export { FilterOperations } from './models/FilterOperations';
export { FilterOperators } from './models/FilterOperators';
export { FilterTypes } from './models/FilterTypes';
export type { FinishStatusTaskJobDto } from './models/FinishStatusTaskJobDto';
export type { FinishTaskJobQueueDto } from './models/FinishTaskJobQueueDto';
export type { FixedResourceTicketSkeletonSelectorDto } from './models/FixedResourceTicketSkeletonSelectorDto';
export type { FolderAccessContactDto } from './models/FolderAccessContactDto';
export type { FolderAccessContactListDto } from './models/FolderAccessContactListDto';
export type { FolderAccessDto } from './models/FolderAccessDto';
export type { FolderAccessFunctionDto } from './models/FolderAccessFunctionDto';
export type { FolderAccessFunctionListDto } from './models/FolderAccessFunctionListDto';
export type { FolderAccessListDto } from './models/FolderAccessListDto';
export type { FolderAccessLocationDto } from './models/FolderAccessLocationDto';
export type { FolderAccessLocationGroupDto } from './models/FolderAccessLocationGroupDto';
export type { FolderAccessLocationGroupListDto } from './models/FolderAccessLocationGroupListDto';
export type { FolderAccessLocationListDto } from './models/FolderAccessLocationListDto';
export type { FolderOrgaAttachmentDto } from './models/FolderOrgaAttachmentDto';
export type { FollowedUpMissionsDto } from './models/FollowedUpMissionsDto';
export type { FollowUpMissionDto } from './models/FollowUpMissionDto';
export type { FollowUpMissionsDto } from './models/FollowUpMissionsDto';
export type { FollowUpTicketDto } from './models/FollowUpTicketDto';
export type { ForcePasswordDto } from './models/ForcePasswordDto';
export type { FormCreateDto } from './models/FormCreateDto';
export type { FormCsvCreatedByContactDto } from './models/FormCsvCreatedByContactDto';
export type { FormCsvDto } from './models/FormCsvDto';
export type { FormDefaultDto } from './models/FormDefaultDto';
export type { FormDto } from './models/FormDto';
export type { FormFieldCreateDto } from './models/FormFieldCreateDto';
export type { FormFieldCsvDto } from './models/FormFieldCsvDto';
export type { FormFieldsForModel } from './models/FormFieldsForModel';
export type { FormFieldTtm } from './models/FormFieldTtm';
export { FormFieldTypes } from './models/FormFieldTypes';
export type { FormInDocumentTaskDto } from './models/FormInDocumentTaskDto';
export type { FormListDto } from './models/FormListDto';
export type { FormLocationDto } from './models/FormLocationDto';
export type { FormLocationGroupDto } from './models/FormLocationGroupDto';
export type { FormRowCreateDto } from './models/FormRowCreateDto';
export type { FormRowCsvDto } from './models/FormRowCsvDto';
export { FormRowTypes } from './models/FormRowTypes';
export type { FormSimpleDto } from './models/FormSimpleDto';
export type { FormTextValueCsvDto } from './models/FormTextValueCsvDto';
export type { FormTtm } from './models/FormTtm';
export type { FormValueDto } from './models/FormValueDto';
export type { FunctionChatMemberDto } from './models/FunctionChatMemberDto';
export type { FunctionDto } from './models/FunctionDto';
export type { FunctionExportDto } from './models/FunctionExportDto';
export type { FunctionExportSimpleDto } from './models/FunctionExportSimpleDto';
export type { FunctionInAreaListDto } from './models/FunctionInAreaListDto';
export type { FunctionIssueReceiverDto } from './models/FunctionIssueReceiverDto';
export type { FunctionListDto } from './models/FunctionListDto';
export type { FunctionListForCompanyDto } from './models/FunctionListForCompanyDto';
export type { FunctionListForContactDto } from './models/FunctionListForContactDto';
export type { FunctionListForContactDtoPageOutputDto } from './models/FunctionListForContactDtoPageOutputDto';
export type { FunctionListSimpleDto } from './models/FunctionListSimpleDto';
export type { FunctionNotificationTargetDto } from './models/FunctionNotificationTargetDto';
export type { FunctionRelationDto } from './models/FunctionRelationDto';
export type { FunctionRelationListDto } from './models/FunctionRelationListDto';
export type { FunctionSimpleDto } from './models/FunctionSimpleDto';
export type { FunctionTtm } from './models/FunctionTtm';
export { GeneralDataProtectionEntityType } from './models/GeneralDataProtectionEntityType';
export type { GeneralDataProtectionRegulationDto } from './models/GeneralDataProtectionRegulationDto';
export type { GeneralDataProtectionRegulationListDto } from './models/GeneralDataProtectionRegulationListDto';
export type { GeneralDataProtectionRegulationLogEntryListDto } from './models/GeneralDataProtectionRegulationLogEntryListDto';
export type { GeneralDataProtectionRestrictionDto } from './models/GeneralDataProtectionRestrictionDto';
export { GeneralDataProtectionRestrictionType } from './models/GeneralDataProtectionRestrictionType';
export type { GeneralSettingDto } from './models/GeneralSettingDto';
export { GeneralTerms } from './models/GeneralTerms';
export type { GenerateMissionByTaskQueueDto } from './models/GenerateMissionByTaskQueueDto';
export type { GroupedFilterDto } from './models/GroupedFilterDto';
export type { HexColorSettingDto } from './models/HexColorSettingDto';
export type { HintCodeDto } from './models/HintCodeDto';
export type { HintCodeListDto } from './models/HintCodeListDto';
export type { HintCodeSimpleDto } from './models/HintCodeSimpleDto';
export type { HintCodeSimpleListDto } from './models/HintCodeSimpleListDto';
export { HintLevel } from './models/HintLevel';
export { HistoryTypes } from './models/HistoryTypes';
export type { HitNotificationRuleQueueDto } from './models/HitNotificationRuleQueueDto';
export type { HolidayDto } from './models/HolidayDto';
export type { HolidaySetDto } from './models/HolidaySetDto';
export type { HolidaySetListDto } from './models/HolidaySetListDto';
export type { HolidaySetSimpleDto } from './models/HolidaySetSimpleDto';
export type { IHtmlViewData } from './models/IHtmlViewData';
export type { ImpactCreateV1Dto } from './models/ImpactCreateV1Dto';
export type { ImpactDto } from './models/ImpactDto';
export { ImpactTypes } from './models/ImpactTypes';
export type { ImpactV1Dto } from './models/ImpactV1Dto';
export { ImportBehavior } from './models/ImportBehavior';
export type { ImportQueueDto } from './models/ImportQueueDto';
export { ImportRelationBehaviour } from './models/ImportRelationBehaviour';
export { ImportType } from './models/ImportType';
export type { IncomingEmailRequestDto } from './models/IncomingEmailRequestDto';
export type { IncomingMailDto } from './models/IncomingMailDto';
export type { InfoContactDto } from './models/InfoContactDto';
export type { InfoDto } from './models/InfoDto';
export type { InfoFormFieldDto } from './models/InfoFormFieldDto';
export type { InfoFormValueDto } from './models/InfoFormValueDto';
export type { InfoListDto } from './models/InfoListDto';
export type { InfoMembershipByLocationListDto } from './models/InfoMembershipByLocationListDto';
export type { InfoMembershipListDto } from './models/InfoMembershipListDto';
export type { InfoNotificationRuleTtm } from './models/InfoNotificationRuleTtm';
export type { InfoProgressContactListDto } from './models/InfoProgressContactListDto';
export type { InfoProgressDetailingFilterDto } from './models/InfoProgressDetailingFilterDto';
export type { InfoProgressFunctionListDto } from './models/InfoProgressFunctionListDto';
export type { InfoProgressLocationListDto } from './models/InfoProgressLocationListDto';
export { InfoState } from './models/InfoState';
export type { InfoTtm } from './models/InfoTtm';
export type { InheritedResourceQueueDto } from './models/InheritedResourceQueueDto';
export type { InheritResourceQueueDto } from './models/InheritResourceQueueDto';
export type { InitChatDto } from './models/InitChatDto';
export type { InitializeAccessesByTenantQueueDto } from './models/InitializeAccessesByTenantQueueDto';
export type { InitializeAccessesQueueDto } from './models/InitializeAccessesQueueDto';
export type { InitializedChatDto } from './models/InitializedChatDto';
export type { InitializedChatResultDto } from './models/InitializedChatResultDto';
export type { InitLabelsQueueDto } from './models/InitLabelsQueueDto';
export type { InitLearningLabelsQueueDto } from './models/InitLearningLabelsQueueDto';
export type { INotificationDto } from './models/INotificationDto';
export { InspectionFilterType } from './models/InspectionFilterType';
export type { InspectionPointDto } from './models/InspectionPointDto';
export type { InspectionReportFilterDto } from './models/InspectionReportFilterDto';
export type { InspectionRequestDto } from './models/InspectionRequestDto';
export type { InternalErrorDto } from './models/InternalErrorDto';
export type { InterpretChangedAreaAssignmentQueueDto } from './models/InterpretChangedAreaAssignmentQueueDto';
export type { InventoryNumberListDto } from './models/InventoryNumberListDto';
export type { InventoryNumberPatternDto } from './models/InventoryNumberPatternDto';
export type { InventoryNumberPatternExportDto } from './models/InventoryNumberPatternExportDto';
export type { InventoryNumberPatternListDto } from './models/InventoryNumberPatternListDto';
export type { InventoryNumberPatternSimpleDto } from './models/InventoryNumberPatternSimpleDto';
export type { InventoryNumberPatternSimpleExportDto } from './models/InventoryNumberPatternSimpleExportDto';
export type { InventoryNumberPatternSimpleListDto } from './models/InventoryNumberPatternSimpleListDto';
export type { InvitationCheckDto } from './models/InvitationCheckDto';
export type { InvitationDto } from './models/InvitationDto';
export type { InvitationListDto } from './models/InvitationListDto';
export { InvitationPurpose } from './models/InvitationPurpose';
export { InvitationType } from './models/InvitationType';
export type { InvoiceAddressDtm } from './models/InvoiceAddressDtm';
export { IssueContentType } from './models/IssueContentType';
export type { IssueDetailsListDto } from './models/IssueDetailsListDto';
export type { IssueDtm } from './models/IssueDtm';
export type { IssueListDto } from './models/IssueListDto';
export type { IssueNotificationDto } from './models/IssueNotificationDto';
export { IssueNotificationType } from './models/IssueNotificationType';
export type { IssueReceiverDto } from './models/IssueReceiverDto';
export { IssueReceiverType } from './models/IssueReceiverType';
export { IssueResourceSelectorKind } from './models/IssueResourceSelectorKind';
export type { IssueSimpleDto } from './models/IssueSimpleDto';
export type { IssueTemplateDto } from './models/IssueTemplateDto';
export type { IssueTemplateListDto } from './models/IssueTemplateListDto';
export type { IssueTemplateSimpleDto } from './models/IssueTemplateSimpleDto';
export type { IssueTtm } from './models/IssueTtm';
export { IssueType } from './models/IssueType';
export type { IStereotypedDto } from './models/IStereotypedDto';
export type { KnowledgeArticleDto } from './models/KnowledgeArticleDto';
export type { KnowledgeArticleInPathDto } from './models/KnowledgeArticleInPathDto';
export type { KnowledgeArticleListDto } from './models/KnowledgeArticleListDto';
export type { KnowledgeArticleOrgaAttachmentDto } from './models/KnowledgeArticleOrgaAttachmentDto';
export type { KnowledgeArticleSimpleDto } from './models/KnowledgeArticleSimpleDto';
export type { KnowledgeArticleSimpleExportDto } from './models/KnowledgeArticleSimpleExportDto';
export type { KnowledgeArticleStartDto } from './models/KnowledgeArticleStartDto';
export type { KnowledgeArticleV2Dto } from './models/KnowledgeArticleV2Dto';
export type { KnowledgeArticleV2ListDto } from './models/KnowledgeArticleV2ListDto';
export type { KnowledgeExamAnswerDto } from './models/KnowledgeExamAnswerDto';
export type { KnowledgeExamDto } from './models/KnowledgeExamDto';
export type { KnowledgeExamInPathDto } from './models/KnowledgeExamInPathDto';
export type { KnowledgeExamListDto } from './models/KnowledgeExamListDto';
export type { KnowledgeExamStartDto } from './models/KnowledgeExamStartDto';
export type { KnowledgePathDto } from './models/KnowledgePathDto';
export type { KnowledgePathListDto } from './models/KnowledgePathListDto';
export type { KnowledgePathListForKnowledgeArticleDto } from './models/KnowledgePathListForKnowledgeArticleDto';
export type { KnowledgePathProgressContactListDto } from './models/KnowledgePathProgressContactListDto';
export type { KnowledgePathProgressDetailingFilterDto } from './models/KnowledgePathProgressDetailingFilterDto';
export type { KnowledgePathProgressFunctionListDto } from './models/KnowledgePathProgressFunctionListDto';
export type { KnowledgePathProgressLocationListDto } from './models/KnowledgePathProgressLocationListDto';
export type { KnowledgePathSimpleDto } from './models/KnowledgePathSimpleDto';
export type { KnowledgePathStartDto } from './models/KnowledgePathStartDto';
export { LabelDescriptorType } from './models/LabelDescriptorType';
export type { LabelDto } from './models/LabelDto';
export type { LabeledEntityDto } from './models/LabeledEntityDto';
export type { LabelListDto } from './models/LabelListDto';
export type { LabelRelationListDto } from './models/LabelRelationListDto';
export type { LabelSimpleDto } from './models/LabelSimpleDto';
export type { LabelSimpleListDto } from './models/LabelSimpleListDto';
export type { LabelSummaryDto } from './models/LabelSummaryDto';
export type { LearnLabelsQueueDto } from './models/LearnLabelsQueueDto';
export type { LinkDto } from './models/LinkDto';
export type { LinkedElementDto } from './models/LinkedElementDto';
export { LinkedElementType } from './models/LinkedElementType';
export type { LinkOrgaAttachmentDto } from './models/LinkOrgaAttachmentDto';
export type { LinkToContactDto } from './models/LinkToContactDto';
export type { LinkToContractDto } from './models/LinkToContractDto';
export type { LinkToMissionDto } from './models/LinkToMissionDto';
export type { LinkToResourceDto } from './models/LinkToResourceDto';
export type { LinkToTicketDto } from './models/LinkToTicketDto';
export type { LocalDateTime } from './models/LocalDateTime';
export type { LocationBaseDtm } from './models/LocationBaseDtm';
export type { LocationChatMemberDto } from './models/LocationChatMemberDto';
export type { LocationDtm } from './models/LocationDtm';
export type { LocationDto } from './models/LocationDto';
export type { LocationExportSimpleDto } from './models/LocationExportSimpleDto';
export type { LocationGroupDto } from './models/LocationGroupDto';
export type { LocationGroupExportSimpleDto } from './models/LocationGroupExportSimpleDto';
export type { LocationGroupInfoDto } from './models/LocationGroupInfoDto';
export type { LocationGroupListDto } from './models/LocationGroupListDto';
export type { LocationGroupListForContactDto } from './models/LocationGroupListForContactDto';
export type { LocationGroupListForLocationDto } from './models/LocationGroupListForLocationDto';
export type { LocationGroupLocationDto } from './models/LocationGroupLocationDto';
export type { LocationGroupRelationDto } from './models/LocationGroupRelationDto';
export type { LocationGroupRelationListDto } from './models/LocationGroupRelationListDto';
export type { LocationGroupSimpleDto } from './models/LocationGroupSimpleDto';
export type { LocationGroupSimpleListDto } from './models/LocationGroupSimpleListDto';
export type { LocationGroupTaskV1Dto } from './models/LocationGroupTaskV1Dto';
export type { LocationInArticleUsageListDto } from './models/LocationInArticleUsageListDto';
export type { LocationInfoDto } from './models/LocationInfoDto';
export type { LocationInMissionDtm } from './models/LocationInMissionDtm';
export type { LocationInMissionDto } from './models/LocationInMissionDto';
export type { LocationListDto } from './models/LocationListDto';
export type { LocationListForAddressDto } from './models/LocationListForAddressDto';
export type { LocationListForContactDto } from './models/LocationListForContactDto';
export type { LocationListForContactDtoPageOutputDto } from './models/LocationListForContactDtoPageOutputDto';
export type { LocationListForLocationGroupDto } from './models/LocationListForLocationGroupDto';
export type { LocationListForResourcesDto } from './models/LocationListForResourcesDto';
export type { LocationRelationDto } from './models/LocationRelationDto';
export type { LocationRelationListDto } from './models/LocationRelationListDto';
export type { LocationSimpleDto } from './models/LocationSimpleDto';
export type { LocationSimpleListDto } from './models/LocationSimpleListDto';
export { LocationState } from './models/LocationState';
export type { LocationTaskV1Dto } from './models/LocationTaskV1Dto';
export type { LocationTtm } from './models/LocationTtm';
export type { LoginByBadgeDto } from './models/LoginByBadgeDto';
export type { LoginByPinDto } from './models/LoginByPinDto';
export type { LoginByQuickAccessDto } from './models/LoginByQuickAccessDto';
export type { LoginChallengeDto } from './models/LoginChallengeDto';
export { LoginChallengeTypes } from './models/LoginChallengeTypes';
export type { LoginProviderDto } from './models/LoginProviderDto';
export type { LoginProviderListDto } from './models/LoginProviderListDto';
export type { LoginProviderRoleDto } from './models/LoginProviderRoleDto';
export type { LoginProviderRoleListDto } from './models/LoginProviderRoleListDto';
export type { LoginProviders } from './models/LoginProviders';
export type { LookBackOnResourceConsumptionQueueDto } from './models/LookBackOnResourceConsumptionQueueDto';
export type { LookBackResourceTaskConsumptionTriggerDto } from './models/LookBackResourceTaskConsumptionTriggerDto';
export type { MailBoxDto } from './models/MailBoxDto';
export type { MailBoxListDto } from './models/MailBoxListDto';
export type { MailDto } from './models/MailDto';
export type { MailInQueueDto } from './models/MailInQueueDto';
export type { MailLogListDto } from './models/MailLogListDto';
export type { MailMergeComponent } from './models/MailMergeComponent';
export type { MailMergeHtml } from './models/MailMergeHtml';
export type { MailNodeDto } from './models/MailNodeDto';
export type { MailNodeListDto } from './models/MailNodeListDto';
export type { MailNodeSimpleDto } from './models/MailNodeSimpleDto';
export type { MailOutQueueDto } from './models/MailOutQueueDto';
export type { MailReceiverListDto } from './models/MailReceiverListDto';
export { MailRelationTypes } from './models/MailRelationTypes';
export type { MailStatusDto } from './models/MailStatusDto';
export type { ManualExecutionDto } from './models/ManualExecutionDto';
export type { ManualExecutionPlanViewV1Dto } from './models/ManualExecutionPlanViewV1Dto';
export type { ManualExecutionV1Dto } from './models/ManualExecutionV1Dto';
export type { MarkItemAsSolutionDto } from './models/MarkItemAsSolutionDto';
export type { MassImportDto } from './models/MassImportDto';
export type { MeDto } from './models/MeDto';
export type { MessageIssueContentDto } from './models/MessageIssueContentDto';
export type { MessageIssueDto } from './models/MessageIssueDto';
export type { MessageIssueListDto } from './models/MessageIssueListDto';
export type { MessageIssuePreviewDto } from './models/MessageIssuePreviewDto';
export { MessageStatus } from './models/MessageStatus';
export type { MetaEntryDto } from './models/MetaEntryDto';
export { MetaEntryType } from './models/MetaEntryType';
export type { MissionAppDto } from './models/MissionAppDto';
export type { MissionByInspectionDescriptionTtm } from './models/MissionByInspectionDescriptionTtm';
export type { MissionByInspectionDtm } from './models/MissionByInspectionDtm';
export type { MissionByInspectionDto } from './models/MissionByInspectionDto';
export type { MissionByManualDto } from './models/MissionByManualDto';
export type { MissionByTaskDescriptionTtm } from './models/MissionByTaskDescriptionTtm';
export type { MissionByTaskDtm } from './models/MissionByTaskDtm';
export type { MissionByTaskDto } from './models/MissionByTaskDto';
export type { MissionByTicketDescriptionTtm } from './models/MissionByTicketDescriptionTtm';
export type { MissionByTicketDtm } from './models/MissionByTicketDtm';
export type { MissionByTicketDto } from './models/MissionByTicketDto';
export type { MissionCompleteDto } from './models/MissionCompleteDto';
export type { MissionDescriptionContextDto } from './models/MissionDescriptionContextDto';
export type { MissionDescriptionTtm } from './models/MissionDescriptionTtm';
export type { MissionDistanceListDto } from './models/MissionDistanceListDto';
export type { MissionDtm } from './models/MissionDtm';
export type { MissionDto } from './models/MissionDto';
export { MissionEditorSelectorKind } from './models/MissionEditorSelectorKind';
export type { MissionInArticleListDto } from './models/MissionInArticleListDto';
export type { MissionInspectionReportDtm } from './models/MissionInspectionReportDtm';
export type { MissionInspectionReportDto } from './models/MissionInspectionReportDto';
export type { MissionInspectionReportInMissionDto } from './models/MissionInspectionReportInMissionDto';
export type { MissionInspectionReportTtm } from './models/MissionInspectionReportTtm';
export type { MissionIssueContentDto } from './models/MissionIssueContentDto';
export type { MissionIssueDetailsDto } from './models/MissionIssueDetailsDto';
export type { MissionIssueDto } from './models/MissionIssueDto';
export type { MissionIssueListDto } from './models/MissionIssueListDto';
export type { MissionIssuePreviewDto } from './models/MissionIssuePreviewDto';
export type { MissionNoteTtm } from './models/MissionNoteTtm';
export type { MissionNotificationRuleTtm } from './models/MissionNotificationRuleTtm';
export type { MissionReceiptDto } from './models/MissionReceiptDto';
export type { MissionReportDtm } from './models/MissionReportDtm';
export type { MissionReportDto } from './models/MissionReportDto';
export type { MissionReportGroupDtm } from './models/MissionReportGroupDtm';
export type { MissionReportGroupDto } from './models/MissionReportGroupDto';
export type { MissionReportGroupSkeletonDto } from './models/MissionReportGroupSkeletonDto';
export type { MissionReportGroupTtm } from './models/MissionReportGroupTtm';
export type { MissionReportReceiptDto } from './models/MissionReportReceiptDto';
export type { MissionReportSkeletonDto } from './models/MissionReportSkeletonDto';
export type { MissionReportTtm } from './models/MissionReportTtm';
export type { MissionSimpleDto } from './models/MissionSimpleDto';
export type { MissionSimpleListDto } from './models/MissionSimpleListDto';
export type { MissionSkeletonDto } from './models/MissionSkeletonDto';
export type { MissionSkeletonV1Dto } from './models/MissionSkeletonV1Dto';
export type { MissionSolutionDto } from './models/MissionSolutionDto';
export type { MissionSourceDto } from './models/MissionSourceDto';
export type { MissionsPerLocationByMonthDto } from './models/MissionsPerLocationByMonthDto';
export type { MissionsPerLocationByTimeDto } from './models/MissionsPerLocationByTimeDto';
export type { MissionsPerLocationPerTypeByMonthDto } from './models/MissionsPerLocationPerTypeByMonthDto';
export type { MissionsPerLocationPerTypeByTimeDto } from './models/MissionsPerLocationPerTypeByTimeDto';
export type { MissionsPerResourceDto } from './models/MissionsPerResourceDto';
export type { MissionsPerResourceManufacturerDto } from './models/MissionsPerResourceManufacturerDto';
export type { MissionsPerSolutionContactByMonthDto } from './models/MissionsPerSolutionContactByMonthDto';
export type { MissionsPerSolutionContactByTimeDto } from './models/MissionsPerSolutionContactByTimeDto';
export type { MissionsPerSolutionContactDto } from './models/MissionsPerSolutionContactDto';
export type { MissionsPerTypeDto } from './models/MissionsPerTypeDto';
export { MissionStartingState } from './models/MissionStartingState';
export { MissionState } from './models/MissionState';
export type { MissionStateTransitionDto } from './models/MissionStateTransitionDto';
export type { MissionTtm } from './models/MissionTtm';
export { MissionType } from './models/MissionType';
export type { MonthlyDateTimePatternDto } from './models/MonthlyDateTimePatternDto';
export type { MonthlyDateTimePatternV1Dto } from './models/MonthlyDateTimePatternV1Dto';
export type { MonthlyWeekdayDateTimePatternDto } from './models/MonthlyWeekdayDateTimePatternDto';
export type { MonthlyWeekdayDateTimePatternV1Dto } from './models/MonthlyWeekdayDateTimePatternV1Dto';
export type { MoveResourceQueueDto } from './models/MoveResourceQueueDto';
export type { MultilineFormDefaultDto } from './models/MultilineFormDefaultDto';
export type { MultilineFormFieldDto } from './models/MultilineFormFieldDto';
export type { MultilineFormValueDto } from './models/MultilineFormValueDto';
export type { MuteHintDto } from './models/MuteHintDto';
export type { NoteCallerDto } from './models/NoteCallerDto';
export type { NoteChatMessageDto } from './models/NoteChatMessageDto';
export type { NoteCompleteDto } from './models/NoteCompleteDto';
export type { NoteDtm } from './models/NoteDtm';
export type { NoteDto } from './models/NoteDto';
export type { NoteForMissionByInspectionTtm } from './models/NoteForMissionByInspectionTtm';
export type { NoteForMissionByTaskTtm } from './models/NoteForMissionByTaskTtm';
export type { NoteForMissionByTicketTtm } from './models/NoteForMissionByTicketTtm';
export type { NoteForTicketTtm } from './models/NoteForTicketTtm';
export type { NoteMailBoxDto } from './models/NoteMailBoxDto';
export type { NoteMailBoxListDto } from './models/NoteMailBoxListDto';
export type { NoteMailDto } from './models/NoteMailDto';
export type { NoteTextDto } from './models/NoteTextDto';
export { NoteType } from './models/NoteType';
export { NotificationReceiverStatus } from './models/NotificationReceiverStatus';
export { NotificationReceiverType } from './models/NotificationReceiverType';
export type { NotificationRuleDto } from './models/NotificationRuleDto';
export type { NotificationRuleListDto } from './models/NotificationRuleListDto';
export type { NotificationTargetDto } from './models/NotificationTargetDto';
export { NotificationTargetType } from './models/NotificationTargetType';
export { NotificationTrigger } from './models/NotificationTrigger';
export { NotificationType } from './models/NotificationType';
export { NotificationTypes } from './models/NotificationTypes';
export type { NumberFormatInfo } from './models/NumberFormatInfo';
export type { NumberFormDefaultDto } from './models/NumberFormDefaultDto';
export type { NumberFormFieldDto } from './models/NumberFormFieldDto';
export type { NumberFormValueDto } from './models/NumberFormValueDto';
export type { OccuredEventQueueDto } from './models/OccuredEventQueueDto';
export type { OccurredConsumptionHistoryListDto } from './models/OccurredConsumptionHistoryListDto';
export type { OccurredConsumptionListDto } from './models/OccurredConsumptionListDto';
export type { OccurredConsumptionQueueDto } from './models/OccurredConsumptionQueueDto';
export type { OccurredErrorHistoryListDto } from './models/OccurredErrorHistoryListDto';
export type { OccurredErrorListDto } from './models/OccurredErrorListDto';
export type { OccurredErrorQueueDto } from './models/OccurredErrorQueueDto';
export type { OccurredHintHistoryListDto } from './models/OccurredHintHistoryListDto';
export type { OccurredHintListDto } from './models/OccurredHintListDto';
export type { OccurredHintQueueDto } from './models/OccurredHintQueueDto';
export type { OpenEditorRequestOnContactListDto } from './models/OpenEditorRequestOnContactListDto';
export { Orders } from './models/Orders';
export type { OrgaAttachmentDto } from './models/OrgaAttachmentDto';
export type { OrgaAttachmentListDto } from './models/OrgaAttachmentListDto';
export type { OrgaDocumentAccessDto } from './models/OrgaDocumentAccessDto';
export type { OrgaFolderItemDto } from './models/OrgaFolderItemDto';
export { OrgaFolderItemType } from './models/OrgaFolderItemType';
export type { OutboxReferenceQueueDto } from './models/OutboxReferenceQueueDto';
export type { OutOfRangeResourceTaskConsumptionTriggerDto } from './models/OutOfRangeResourceTaskConsumptionTriggerDto';
export type { OverviewDto } from './models/OverviewDto';
export type { OverviewTaskDto } from './models/OverviewTaskDto';
export { OverviewTaskKind } from './models/OverviewTaskKind';
export type { OverviewTaskRequestDto } from './models/OverviewTaskRequestDto';
export { Owners } from './models/Owners';
export { PadDirection } from './models/PadDirection';
export type { PagingHeader } from './models/PagingHeader';
export type { PasswordLoginDto } from './models/PasswordLoginDto';
export { PdfViewBehaviour } from './models/PdfViewBehaviour';
export type { PercentageConditionDto } from './models/PercentageConditionDto';
export type { PercentageConditionV1Dto } from './models/PercentageConditionV1Dto';
export type { PermissionAreaDto } from './models/PermissionAreaDto';
export type { PermissionFunctionDto } from './models/PermissionFunctionDto';
export type { PermissionPackageDto } from './models/PermissionPackageDto';
export type { PictureFormDefaultDto } from './models/PictureFormDefaultDto';
export type { PictureFormFieldDto } from './models/PictureFormFieldDto';
export type { PictureFormValueDto } from './models/PictureFormValueDto';
export type { PictureSettingDto } from './models/PictureSettingDto';
export type { PingDeviceActionNotificationDto } from './models/PingDeviceActionNotificationDto';
export type { PingDto } from './models/PingDto';
export type { PingListDto } from './models/PingListDto';
export type { PlannedExecutionDto } from './models/PlannedExecutionDto';
export type { PlannedExecutionPlanViewV1Dto } from './models/PlannedExecutionPlanViewV1Dto';
export type { PlannedExecutionV1Dto } from './models/PlannedExecutionV1Dto';
export type { PlannedTimeDto } from './models/PlannedTimeDto';
export type { PlannedTimeV1Dto } from './models/PlannedTimeV1Dto';
export { Platforms } from './models/Platforms';
export type { PointDto } from './models/PointDto';
export type { PointListDto } from './models/PointListDto';
export type { PreSelectedDto } from './models/PreSelectedDto';
export type { PreSelectedReferenceDto } from './models/PreSelectedReferenceDto';
export type { PreviewDocumentTemplateDto } from './models/PreviewDocumentTemplateDto';
export type { PreviewedChatByFormDto } from './models/PreviewedChatByFormDto';
export type { PreviewedChatByFormFunctionReceivingDto } from './models/PreviewedChatByFormFunctionReceivingDto';
export type { PreviewedChatByFormLocationReceivingDto } from './models/PreviewedChatByFormLocationReceivingDto';
export type { PreviewedChatByFormReceivingDto } from './models/PreviewedChatByFormReceivingDto';
export type { PreviewMissionInputDto } from './models/PreviewMissionInputDto';
export type { PreviewRequestDto } from './models/PreviewRequestDto';
export type { PreviewResourceByLocationDto } from './models/PreviewResourceByLocationDto';
export type { PreviewResourcesByLocationDefinitionDto } from './models/PreviewResourcesByLocationDefinitionDto';
export type { PreviewResourcesByLocationDto } from './models/PreviewResourcesByLocationDto';
export type { PreviewTemplateDto } from './models/PreviewTemplateDto';
export type { PreviewTicketByLocationDto } from './models/PreviewTicketByLocationDto';
export type { PreviewTicketsByLocationDefinitionDto } from './models/PreviewTicketsByLocationDefinitionDto';
export type { PreviewTicketsByLocationDto } from './models/PreviewTicketsByLocationDto';
export type { PrimaryEditorSuggestionDto } from './models/PrimaryEditorSuggestionDto';
export type { PriorityDtm } from './models/PriorityDtm';
export type { PriorityDto } from './models/PriorityDto';
export type { PriorityExportDto } from './models/PriorityExportDto';
export type { PriorityListDto } from './models/PriorityListDto';
export type { PriorityModelTtm } from './models/PriorityModelTtm';
export type { PrioritySetDto } from './models/PrioritySetDto';
export type { PrioritySetExportDto } from './models/PrioritySetExportDto';
export type { PrioritySetListDto } from './models/PrioritySetListDto';
export type { PrioritySetSimpleDto } from './models/PrioritySetSimpleDto';
export type { PrioritySetSimpleExportDto } from './models/PrioritySetSimpleExportDto';
export type { PrioritySettingDto } from './models/PrioritySettingDto';
export type { PrioritySimpleDto } from './models/PrioritySimpleDto';
export type { PrioritySimpleDtoPreSelectedReferenceDto } from './models/PrioritySimpleDtoPreSelectedReferenceDto';
export type { PrioritySimpleListDto } from './models/PrioritySimpleListDto';
export { ProductEditions } from './models/ProductEditions';
export { ProductKind } from './models/ProductKind';
export type { ProductSubscriptionDto } from './models/ProductSubscriptionDto';
export type { ProductSubscriptionListDto } from './models/ProductSubscriptionListDto';
export type { ProductSubscriptionSimpleDto } from './models/ProductSubscriptionSimpleDto';
export { PropertyRating } from './models/PropertyRating';
export type { PublishIssueQueueDto } from './models/PublishIssueQueueDto';
export type { PublishIssuesEmailDto } from './models/PublishIssuesEmailDto';
export type { PurgedInventoryNumberPatternDto } from './models/PurgedInventoryNumberPatternDto';
export type { PushedInfoNotificationDto } from './models/PushedInfoNotificationDto';
export type { PushedInfoQueueDto } from './models/PushedInfoQueueDto';
export type { PushedStartedMissionNotificationDto } from './models/PushedStartedMissionNotificationDto';
export type { PushNotificationQueueDto } from './models/PushNotificationQueueDto';
export type { PushRegisterDto } from './models/PushRegisterDto';
export type { PushStartedMissionQueueDto } from './models/PushStartedMissionQueueDto';
export type { QuestInMissionDto } from './models/QuestInMissionDto';
export type { QuestInMissionListDto } from './models/QuestInMissionListDto';
export type { QueueDto } from './models/QueueDto';
export type { QuickAccessConfigurationDto } from './models/QuickAccessConfigurationDto';
export type { QuickAccessConfigurationSimpleListDto } from './models/QuickAccessConfigurationSimpleListDto';
export type { QuickAccessDashboardConfigurationDto } from './models/QuickAccessDashboardConfigurationDto';
export { QuickAccessDashboardVisibility } from './models/QuickAccessDashboardVisibility';
export type { QuickAccessDto } from './models/QuickAccessDto';
export { QuickAccessIssueHistoryVisibility } from './models/QuickAccessIssueHistoryVisibility';
export type { QuickAccessIssueTemplateConfigurationDto } from './models/QuickAccessIssueTemplateConfigurationDto';
export { QuickAccessIssueTemplateVisibility } from './models/QuickAccessIssueTemplateVisibility';
export type { QuickAccessKnowledgeConfigurationDto } from './models/QuickAccessKnowledgeConfigurationDto';
export { QuickAccessKnowledgeVisibility } from './models/QuickAccessKnowledgeVisibility';
export type { ReceiptImportDto } from './models/ReceiptImportDto';
export type { ReceivedIssueQueueDto } from './models/ReceivedIssueQueueDto';
export { RecordStatus } from './models/RecordStatus';
export type { ReevaluateAccessChunkQueueDto } from './models/ReevaluateAccessChunkQueueDto';
export type { ReevaluateAccessQueueDto } from './models/ReevaluateAccessQueueDto';
export type { RefreshTokenDto } from './models/RefreshTokenDto';
export { RefreshTokenTypes } from './models/RefreshTokenTypes';
export type { RefreshTokenV3Dto } from './models/RefreshTokenV3Dto';
export type { RefreshWinterhalterProductOrderQueueDto } from './models/RefreshWinterhalterProductOrderQueueDto';
export type { RefreshWinterhalterServiceCallQueueDto } from './models/RefreshWinterhalterServiceCallQueueDto';
export type { RegisterDeviceByLocationDto } from './models/RegisterDeviceByLocationDto';
export type { RegisterDeviceDto } from './models/RegisterDeviceDto';
export type { RegisterUserDto } from './models/RegisterUserDto';
export type { RegistrationEmailDto } from './models/RegistrationEmailDto';
export type { RemoveLabelDto } from './models/RemoveLabelDto';
export type { RenderDocumentTemplateDto } from './models/RenderDocumentTemplateDto';
export type { RenderedTemplateDto } from './models/RenderedTemplateDto';
export type { RenderTemplateDto } from './models/RenderTemplateDto';
export type { RepairCacheFeatureQueueDto } from './models/RepairCacheFeatureQueueDto';
export type { RepairCacheQueueDto } from './models/RepairCacheQueueDto';
export type { RepeatableFormRowCreateDto } from './models/RepeatableFormRowCreateDto';
export type { ReportDataTableDto } from './models/ReportDataTableDto';
export type { ReportDataTableQueueDto } from './models/ReportDataTableQueueDto';
export { ReportGroup } from './models/ReportGroup';
export { ReportLevel } from './models/ReportLevel';
export type { RequestPasswordResetEmailDto } from './models/RequestPasswordResetEmailDto';
export type { RequestUserRegistrationDto } from './models/RequestUserRegistrationDto';
export type { ResetPasswordDto } from './models/ResetPasswordDto';
export type { ResolvedAreaData } from './models/ResolvedAreaData';
export type { ResolvedTokenData } from './models/ResolvedTokenData';
export type { ResourceBaseDtm } from './models/ResourceBaseDtm';
export type { ResourceDtm } from './models/ResourceDtm';
export type { ResourceDto } from './models/ResourceDto';
export type { ResourceEventListDto } from './models/ResourceEventListDto';
export { ResourceEventType } from './models/ResourceEventType';
export type { ResourceFilterDto } from './models/ResourceFilterDto';
export type { ResourceImportDto } from './models/ResourceImportDto';
export type { ResourceInArticleUsageListDto } from './models/ResourceInArticleUsageListDto';
export type { ResourceInMissionDtm } from './models/ResourceInMissionDtm';
export type { ResourceInMissionDto } from './models/ResourceInMissionDto';
export type { ResourceInMissionListDto } from './models/ResourceInMissionListDto';
export type { ResourceInNoteListDto } from './models/ResourceInNoteListDto';
export type { ResourceInTicketDto } from './models/ResourceInTicketDto';
export type { ResourceInTicketListDto } from './models/ResourceInTicketListDto';
export type { ResourceListDto } from './models/ResourceListDto';
export type { ResourceLocationProfileDto } from './models/ResourceLocationProfileDto';
export type { ResourceManufacturerDto } from './models/ResourceManufacturerDto';
export type { ResourceManufacturerImportDto } from './models/ResourceManufacturerImportDto';
export type { ResourceManufacturerListDto } from './models/ResourceManufacturerListDto';
export type { ResourceManufacturerMergeDto } from './models/ResourceManufacturerMergeDto';
export type { ResourceManufacturerSimpleDto } from './models/ResourceManufacturerSimpleDto';
export type { ResourceManufacturerSimpleListDto } from './models/ResourceManufacturerSimpleListDto';
export type { ResourceModelDto } from './models/ResourceModelDto';
export type { ResourceModelListDto } from './models/ResourceModelListDto';
export type { ResourceModelMergeDto } from './models/ResourceModelMergeDto';
export type { ResourceModelMoveDto } from './models/ResourceModelMoveDto';
export type { ResourceModelSimpleDto } from './models/ResourceModelSimpleDto';
export type { ResourceModelSimpleListDto } from './models/ResourceModelSimpleListDto';
export type { ResourcePathPartDto } from './models/ResourcePathPartDto';
export type { ResourcePathPartSimpleListDto } from './models/ResourcePathPartSimpleListDto';
export type { ResourceProfileConsumptionDto } from './models/ResourceProfileConsumptionDto';
export type { ResourceProfileDashboardDto } from './models/ResourceProfileDashboardDto';
export type { ResourceProfileDto } from './models/ResourceProfileDto';
export type { ResourceProfileErrorDto } from './models/ResourceProfileErrorDto';
export type { ResourceProfileFeatureDto } from './models/ResourceProfileFeatureDto';
export type { ResourceProfileHintDto } from './models/ResourceProfileHintDto';
export type { ResourceProfileIssueTemplateShortCutDto } from './models/ResourceProfileIssueTemplateShortCutDto';
export type { ResourceProfileKnowledgeDto } from './models/ResourceProfileKnowledgeDto';
export type { ResourceProfileOverviewDto } from './models/ResourceProfileOverviewDto';
export type { ResourceProfileStatisticsDto } from './models/ResourceProfileStatisticsDto';
export type { ResourceQuickAccessListDto } from './models/ResourceQuickAccessListDto';
export type { ResourceSafeRequestDto } from './models/ResourceSafeRequestDto';
export type { ResourceSafetySimpleDto } from './models/ResourceSafetySimpleDto';
export type { ResourcesByLocationDefinitionDto } from './models/ResourcesByLocationDefinitionDto';
export type { ResourcesByLocationDefinitionListDto } from './models/ResourcesByLocationDefinitionListDto';
export type { ResourcesByLocationDefinitionSimpleDto } from './models/ResourcesByLocationDefinitionSimpleDto';
export type { ResourceSettingDto } from './models/ResourceSettingDto';
export type { ResourceSimpleDto } from './models/ResourceSimpleDto';
export type { ResourceSimpleListDto } from './models/ResourceSimpleListDto';
export type { ResourceSkeletonDto } from './models/ResourceSkeletonDto';
export { ResourceState } from './models/ResourceState';
export type { ResourceStereotypeUsageDto } from './models/ResourceStereotypeUsageDto';
export type { ResourceTaskConsumptionScheduleDto } from './models/ResourceTaskConsumptionScheduleDto';
export type { ResourceTaskConsumptionTriggerDto } from './models/ResourceTaskConsumptionTriggerDto';
export { ResourceTaskConsumptionTriggerType } from './models/ResourceTaskConsumptionTriggerType';
export type { ResourceTaskDtm } from './models/ResourceTaskDtm';
export type { ResourceTaskDto } from './models/ResourceTaskDto';
export type { ResourceTaskErrorScheduleDto } from './models/ResourceTaskErrorScheduleDto';
export type { ResourceTaskFixedScheduleDto } from './models/ResourceTaskFixedScheduleDto';
export type { ResourceTaskListDto } from './models/ResourceTaskListDto';
export type { ResourceTaskModelDto } from './models/ResourceTaskModelDto';
export type { ResourceTaskOffsetPatternDto } from './models/ResourceTaskOffsetPatternDto';
export type { ResourceTaskPatternDto } from './models/ResourceTaskPatternDto';
export { ResourceTaskPatternType } from './models/ResourceTaskPatternType';
export type { ResourceTaskRelativeScheduleDto } from './models/ResourceTaskRelativeScheduleDto';
export type { ResourceTaskScheduleDto } from './models/ResourceTaskScheduleDto';
export { ResourceTaskScheduleType } from './models/ResourceTaskScheduleType';
export type { ResourceTaskSelectorDto } from './models/ResourceTaskSelectorDto';
export type { ResourceTaskSimpleDto } from './models/ResourceTaskSimpleDto';
export type { ResourceTaskSimpleListDto } from './models/ResourceTaskSimpleListDto';
export type { ResourceTicketSkeletonSelectorDto } from './models/ResourceTicketSkeletonSelectorDto';
export { ResourceTicketSkeletonSelectorType } from './models/ResourceTicketSkeletonSelectorType';
export type { ResourceTreeListDto } from './models/ResourceTreeListDto';
export type { ResourceTtm } from './models/ResourceTtm';
export { ResourceType } from './models/ResourceType';
export type { RestartConnectedMissionQueueDto } from './models/RestartConnectedMissionQueueDto';
export type { RestartRelativeMissionByTaskQueueDto } from './models/RestartRelativeMissionByTaskQueueDto';
export type { RestrictedByTypeResourceTicketSkeletonSelectorDto } from './models/RestrictedByTypeResourceTicketSkeletonSelectorDto';
export { RoleGeneralTerms } from './models/RoleGeneralTerms';
export type { RoleInAreaListDto } from './models/RoleInAreaListDto';
export type { RoleSimpleDto } from './models/RoleSimpleDto';
export type { RoleSimpleListDto } from './models/RoleSimpleListDto';
export type { SafeDto } from './models/SafeDto';
export type { SanctionImpactCreateV1Dto } from './models/SanctionImpactCreateV1Dto';
export type { SanctionImpactDto } from './models/SanctionImpactDto';
export type { SanctionImpactV1Dto } from './models/SanctionImpactV1Dto';
export type { SearchListDto } from './models/SearchListDto';
export type { SearchListFormDefaultDto } from './models/SearchListFormDefaultDto';
export type { SearchListFormFieldDto } from './models/SearchListFormFieldDto';
export type { SearchListFormValueDto } from './models/SearchListFormValueDto';
export type { SearchListListDto } from './models/SearchListListDto';
export type { SearchListSimpleDto } from './models/SearchListSimpleDto';
export type { SearchWeight } from './models/SearchWeight';
export type { SecuredResourceQueueDto } from './models/SecuredResourceQueueDto';
export { Seeds } from './models/Seeds';
export { SeedSequence } from './models/SeedSequence';
export type { SelectResourcesByTaskQueueDto } from './models/SelectResourcesByTaskQueueDto';
export { SemcoArticleKinds } from './models/SemcoArticleKinds';
export { SemcoStereotypes } from './models/SemcoStereotypes';
export type { SendChatMessageNoteQueueDto } from './models/SendChatMessageNoteQueueDto';
export type { SendChatMessageToSolutionQueueDto } from './models/SendChatMessageToSolutionQueueDto';
export type { SendGridMailDto } from './models/SendGridMailDto';
export type { SendGridStatusDto } from './models/SendGridStatusDto';
export type { SendMailNoteQueueDto } from './models/SendMailNoteQueueDto';
export type { SendMissionActivationEmailQueueDto } from './models/SendMissionActivationEmailQueueDto';
export type { SendMissionMailDto } from './models/SendMissionMailDto';
export type { SendStateToChatQueueDto } from './models/SendStateToChatQueueDto';
export { SettingType } from './models/SettingType';
export type { SettingValueDto } from './models/SettingValueDto';
export { SettingValueType } from './models/SettingValueType';
export type { ShareIdentifierDto } from './models/ShareIdentifierDto';
export type { ShippingAddressDtm } from './models/ShippingAddressDtm';
export type { ShortContactDto } from './models/ShortContactDto';
export type { SignatureFormDefaultDto } from './models/SignatureFormDefaultDto';
export type { SignatureFormFieldDto } from './models/SignatureFormFieldDto';
export type { SignatureFormValueDto } from './models/SignatureFormValueDto';
export type { SimpleChatDto } from './models/SimpleChatDto';
export type { SimpleIdDto } from './models/SimpleIdDto';
export type { SimpleLoginProviderDto } from './models/SimpleLoginProviderDto';
export type { SingleDateTimePatternDto } from './models/SingleDateTimePatternDto';
export type { SingleDateTimePatternV1Dto } from './models/SingleDateTimePatternV1Dto';
export type { SingleFormRowCreateDto } from './models/SingleFormRowCreateDto';
export type { SkeletonDto } from './models/SkeletonDto';
export type { SkeletonRootDto } from './models/SkeletonRootDto';
export type { SkeletonRootSimpleDto } from './models/SkeletonRootSimpleDto';
export type { SkeletonRootSimpleListDto } from './models/SkeletonRootSimpleListDto';
export type { SolutionAttachmentDto } from './models/SolutionAttachmentDto';
export type { SolutionAttachmentsDto } from './models/SolutionAttachmentsDto';
export type { SolutionDetailsDto } from './models/SolutionDetailsDto';
export type { SolutionDto } from './models/SolutionDto';
export type { SolutionItemDtm } from './models/SolutionItemDtm';
export type { SolutionItemDto } from './models/SolutionItemDto';
export type { SolutionItemListDto } from './models/SolutionItemListDto';
export type { SolutionMailInQueueDto } from './models/SolutionMailInQueueDto';
export type { SolutionMemberDto } from './models/SolutionMemberDto';
export { SolutionMemberType } from './models/SolutionMemberType';
export type { SortVersion } from './models/SortVersion';
export { SqlDatePart } from './models/SqlDatePart';
export type { StartingMissionByManualDto } from './models/StartingMissionByManualDto';
export type { StartingMissionByTicketDto } from './models/StartingMissionByTicketDto';
export type { StartingMissionContactEditorDto } from './models/StartingMissionContactEditorDto';
export type { StartingMissionDto } from './models/StartingMissionDto';
export type { StartingMissionEditorDto } from './models/StartingMissionEditorDto';
export { StartingMissionEditorType } from './models/StartingMissionEditorType';
export type { StartingMissionFunctionEditorDto } from './models/StartingMissionFunctionEditorDto';
export type { StartInspectionDto } from './models/StartInspectionDto';
export type { StartSearchingEditorDto } from './models/StartSearchingEditorDto';
export type { StartSearchingEditorQueueDto } from './models/StartSearchingEditorQueueDto';
export type { StateChangeRequestDto } from './models/StateChangeRequestDto';
export type { StateDtm } from './models/StateDtm';
export type { StateDto } from './models/StateDto';
export type { StateExportDto } from './models/StateExportDto';
export type { StateListDto } from './models/StateListDto';
export type { StateMachineDto } from './models/StateMachineDto';
export type { StateMachineExportDto } from './models/StateMachineExportDto';
export type { StateMachineListDto } from './models/StateMachineListDto';
export type { StateMachineSimpleDto } from './models/StateMachineSimpleDto';
export type { StateMachineSimpleExportDto } from './models/StateMachineSimpleExportDto';
export type { StateMachineSimpleListDto } from './models/StateMachineSimpleListDto';
export type { StatePreviewRequestDto } from './models/StatePreviewRequestDto';
export type { StatePreviewResponseDto } from './models/StatePreviewResponseDto';
export type { StateSimpleDto } from './models/StateSimpleDto';
export type { StateSimpleDtoPreSelectedReferenceDto } from './models/StateSimpleDtoPreSelectedReferenceDto';
export type { StateSimpleExportDto } from './models/StateSimpleExportDto';
export type { StateSimpleListDto } from './models/StateSimpleListDto';
export type { StateTransitionDto } from './models/StateTransitionDto';
export type { StateTransitionExportDto } from './models/StateTransitionExportDto';
export type { StatusImpactCreateV1Dto } from './models/StatusImpactCreateV1Dto';
export type { StatusImpactDto } from './models/StatusImpactDto';
export type { StatusImpactV1Dto } from './models/StatusImpactV1Dto';
export type { SteppedResourceTaskConsumptionTriggerDto } from './models/SteppedResourceTaskConsumptionTriggerDto';
export type { StereotypedShadowDto } from './models/StereotypedShadowDto';
export type { StereotypeDtm } from './models/StereotypeDtm';
export type { StereotypeDto } from './models/StereotypeDto';
export type { StereotypeExportDto } from './models/StereotypeExportDto';
export type { StereotypeInConnectedIdentificationDto } from './models/StereotypeInConnectedIdentificationDto';
export type { StereotypeListDto } from './models/StereotypeListDto';
export type { StereotypeSettingDto } from './models/StereotypeSettingDto';
export type { StereotypeSimpleDto } from './models/StereotypeSimpleDto';
export type { StereotypeSimpleDtoPreSelectedReferenceDto } from './models/StereotypeSimpleDtoPreSelectedReferenceDto';
export type { StereotypeSimpleExportDto } from './models/StereotypeSimpleExportDto';
export type { StereotypeSimpleListDto } from './models/StereotypeSimpleListDto';
export type { StereotypeTtm } from './models/StereotypeTtm';
export type { StereotypeUsageDto } from './models/StereotypeUsageDto';
export type { StringPreSelectedDto } from './models/StringPreSelectedDto';
export type { SubmitMessageIssueDto } from './models/SubmitMessageIssueDto';
export type { SubmitMissionIssueDto } from './models/SubmitMissionIssueDto';
export type { SubmitTicketIssueDto } from './models/SubmitTicketIssueDto';
export type { SubmitUserIssueDto } from './models/SubmitUserIssueDto';
export type { SubmitWinterhalterProductDto } from './models/SubmitWinterhalterProductDto';
export type { SubmitWinterhalterProductOrderIssueDto } from './models/SubmitWinterhalterProductOrderIssueDto';
export type { SubmitWinterhalterServiceCallIssueDto } from './models/SubmitWinterhalterServiceCallIssueDto';
export type { SuggestedContactDto } from './models/SuggestedContactDto';
export type { SuggestedEditorDto } from './models/SuggestedEditorDto';
export type { SuggestedEditorsDto } from './models/SuggestedEditorsDto';
export type { SuggestedFunctionDto } from './models/SuggestedFunctionDto';
export type { SynchronizeAddressInLocationDto } from './models/SynchronizeAddressInLocationDto';
export type { SynchronizeContactInLocationDto } from './models/SynchronizeContactInLocationDto';
export type { SynchronizedLocationDto } from './models/SynchronizedLocationDto';
export type { SynchronizeLocationDto } from './models/SynchronizeLocationDto';
export type { SystemContactDto } from './models/SystemContactDto';
export type { SystemContactListDto } from './models/SystemContactListDto';
export { SystemDirections } from './models/SystemDirections';
export type { TagFilterDto } from './models/TagFilterDto';
export type { TaskAsSanctionDto } from './models/TaskAsSanctionDto';
export type { TaskCreateV1Dto } from './models/TaskCreateV1Dto';
export type { TaskDto } from './models/TaskDto';
export type { TaskFormFilledFormCreateDto } from './models/TaskFormFilledFormCreateDto';
export type { TaskGeneralDataProtectionRestrictionDto } from './models/TaskGeneralDataProtectionRestrictionDto';
export type { TaskGenerationQueueDto } from './models/TaskGenerationQueueDto';
export type { TaskInJobListDto } from './models/TaskInJobListDto';
export { TaskJobConditionType } from './models/TaskJobConditionType';
export type { TaskJobCountListDto } from './models/TaskJobCountListDto';
export type { TaskJobDataRequestDto } from './models/TaskJobDataRequestDto';
export type { TaskJobDto } from './models/TaskJobDto';
export type { TaskJobGraphDto } from './models/TaskJobGraphDto';
export type { TaskJobListDto } from './models/TaskJobListDto';
export type { TaskJobLocationRequestDto } from './models/TaskJobLocationRequestDto';
export { TaskJobState } from './models/TaskJobState';
export type { TaskJobStateDto } from './models/TaskJobStateDto';
export type { TaskJobStateListDto } from './models/TaskJobStateListDto';
export type { TaskJobStateReasonDto } from './models/TaskJobStateReasonDto';
export type { TaskJobStateReasonSetDto } from './models/TaskJobStateReasonSetDto';
export type { TaskJobStateReasonSetListDto } from './models/TaskJobStateReasonSetListDto';
export type { TaskJobStateReasonSetSimpleDto } from './models/TaskJobStateReasonSetSimpleDto';
export { TaskJobStateTerm } from './models/TaskJobStateTerm';
export { TaskJobStatusGenre } from './models/TaskJobStatusGenre';
export type { TaskListDto } from './models/TaskListDto';
export type { TaskListV1Dto } from './models/TaskListV1Dto';
export type { TaskSimpleDto } from './models/TaskSimpleDto';
export type { TaskSimpleExportDto } from './models/TaskSimpleExportDto';
export type { TaskSimpleListDto } from './models/TaskSimpleListDto';
export type { TaskSimpleV1Dto } from './models/TaskSimpleV1Dto';
export { TaskState } from './models/TaskState';
export type { TaskStateNotificationDto } from './models/TaskStateNotificationDto';
export { TaskTypes } from './models/TaskTypes';
export type { TaskV1Dto } from './models/TaskV1Dto';
export { TemplateContextType } from './models/TemplateContextType';
export type { TemplateDto } from './models/TemplateDto';
export type { TemplateExportDto } from './models/TemplateExportDto';
export type { TemplateListDto } from './models/TemplateListDto';
export type { TemplateSimpleDto } from './models/TemplateSimpleDto';
export type { TenantInContactListDto } from './models/TenantInContactListDto';
export type { TenantInfoDto } from './models/TenantInfoDto';
export type { TenantSettingDto } from './models/TenantSettingDto';
export type { TenantUsageDto } from './models/TenantUsageDto';
export type { TextFormDefaultDto } from './models/TextFormDefaultDto';
export type { TextFormFieldDto } from './models/TextFormFieldDto';
export type { TextFormValueDto } from './models/TextFormValueDto';
export type { TextInfo } from './models/TextInfo';
export type { TextSettingDto } from './models/TextSettingDto';
export type { TextTemplateContextDto } from './models/TextTemplateContextDto';
export type { TextTemplateTtm } from './models/TextTemplateTtm';
export type { TicketAppDto } from './models/TicketAppDto';
export type { TicketByContractDefinitionDto } from './models/TicketByContractDefinitionDto';
export type { TicketByContractDefinitionSimpleDto } from './models/TicketByContractDefinitionSimpleDto';
export type { TicketByLocationSkeletonDto } from './models/TicketByLocationSkeletonDto';
export type { TicketByMailDefinitionDto } from './models/TicketByMailDefinitionDto';
export type { TicketByProcessDefinitionDto } from './models/TicketByProcessDefinitionDto';
export { TicketByProcessDefinitionKind } from './models/TicketByProcessDefinitionKind';
export type { TicketByProcessDefinitionListDto } from './models/TicketByProcessDefinitionListDto';
export type { TicketByProcessDefinitionPreviewDto } from './models/TicketByProcessDefinitionPreviewDto';
export type { TicketByProcessDefinitionSimpleDto } from './models/TicketByProcessDefinitionSimpleDto';
export type { TicketByProcessDefinitionSimpleExportDto } from './models/TicketByProcessDefinitionSimpleExportDto';
export type { TicketByProcessDefinitionV1Dto } from './models/TicketByProcessDefinitionV1Dto';
export type { TicketByProcessUsageDto } from './models/TicketByProcessUsageDto';
export type { TicketChatMemberDto } from './models/TicketChatMemberDto';
export type { TicketDtm } from './models/TicketDtm';
export type { TicketDto } from './models/TicketDto';
export type { TicketEventCompleteDto } from './models/TicketEventCompleteDto';
export { TicketEventType } from './models/TicketEventType';
export type { TicketFromMailDto } from './models/TicketFromMailDto';
export type { TicketInMissionDto } from './models/TicketInMissionDto';
export type { TicketInMissionListDto } from './models/TicketInMissionListDto';
export type { TicketIssueContentDto } from './models/TicketIssueContentDto';
export type { TicketIssueDetailsDto } from './models/TicketIssueDetailsDto';
export type { TicketIssueDto } from './models/TicketIssueDto';
export type { TicketIssueListDto } from './models/TicketIssueListDto';
export type { TicketIssuePreviewDto } from './models/TicketIssuePreviewDto';
export type { TicketListDto } from './models/TicketListDto';
export type { TicketNotificationRuleTtm } from './models/TicketNotificationRuleTtm';
export type { TicketProcessPreviewDto } from './models/TicketProcessPreviewDto';
export type { TicketsByLocationDefinitionDto } from './models/TicketsByLocationDefinitionDto';
export type { TicketsByLocationDefinitionListDto } from './models/TicketsByLocationDefinitionListDto';
export type { TicketsByLocationDefinitionSimpleDto } from './models/TicketsByLocationDefinitionSimpleDto';
export type { TicketSettingDto } from './models/TicketSettingDto';
export type { TicketSimpleDto } from './models/TicketSimpleDto';
export type { TicketSimpleListDto } from './models/TicketSimpleListDto';
export type { TicketSkeletonDto } from './models/TicketSkeletonDto';
export type { TicketSkeletonV1Dto } from './models/TicketSkeletonV1Dto';
export type { TicketSolutionDto } from './models/TicketSolutionDto';
export type { TicketSourceDto } from './models/TicketSourceDto';
export type { TicketsPerLocationByMonthDto } from './models/TicketsPerLocationByMonthDto';
export type { TicketsPerLocationByTimeDto } from './models/TicketsPerLocationByTimeDto';
export type { TicketsPerLocationPerTypeByMonthDto } from './models/TicketsPerLocationPerTypeByMonthDto';
export type { TicketsPerLocationPerTypeByTimeDto } from './models/TicketsPerLocationPerTypeByTimeDto';
export type { TicketsPerResourceManufacturerDto } from './models/TicketsPerResourceManufacturerDto';
export type { TicketsPerTypeDto } from './models/TicketsPerTypeDto';
export type { TicketStateChangeByChangedMissionStateRequestDto } from './models/TicketStateChangeByChangedMissionStateRequestDto';
export type { TicketStateChangeByClosedMissionRequestDto } from './models/TicketStateChangeByClosedMissionRequestDto';
export type { TicketStateChangeRequestDto } from './models/TicketStateChangeRequestDto';
export type { TicketStateSettingDto } from './models/TicketStateSettingDto';
export type { TicketStateTransitionDto } from './models/TicketStateTransitionDto';
export type { TicketStereotypeUsageDto } from './models/TicketStereotypeUsageDto';
export type { TicketTtm } from './models/TicketTtm';
export type { TimeConditionDto } from './models/TimeConditionDto';
export type { TimeConditionV1Dto } from './models/TimeConditionV1Dto';
export type { TimeFormDefaultDto } from './models/TimeFormDefaultDto';
export type { TimeFormFieldDto } from './models/TimeFormFieldDto';
export type { TimeFormValueDto } from './models/TimeFormValueDto';
export { TimeGraphKind } from './models/TimeGraphKind';
export type { TimelineCollectionTaskDto } from './models/TimelineCollectionTaskDto';
export type { TimelineConfirmationTaskDto } from './models/TimelineConfirmationTaskDto';
export type { TimelineDocumentTaskDto } from './models/TimelineDocumentTaskDto';
export { TimelineEntryImportance } from './models/TimelineEntryImportance';
export type { TimelineEntryMissionDto } from './models/TimelineEntryMissionDto';
export type { TimelineEntryTaskJobDto } from './models/TimelineEntryTaskJobDto';
export { TimelineEntryType } from './models/TimelineEntryType';
export type { TimelineResourceDto } from './models/TimelineResourceDto';
export type { TimelineSettingDto } from './models/TimelineSettingDto';
export type { TimelineSettingListDto } from './models/TimelineSettingListDto';
export type { TimelineSettingStereotypeDto } from './models/TimelineSettingStereotypeDto';
export type { TimelineTaskDto } from './models/TimelineTaskDto';
export { TimePatterns } from './models/TimePatterns';
export type { TokenDto } from './models/TokenDto';
export type { TranslatedEnum } from './models/TranslatedEnum';
export type { UninformedMemberListDto } from './models/UninformedMemberListDto';
export type { UserImportDto } from './models/UserImportDto';
export type { UserInContactListDto } from './models/UserInContactListDto';
export type { UserIssueDtm } from './models/UserIssueDtm';
export type { UserIssueEmailTtm } from './models/UserIssueEmailTtm';
export type { UserRoleDto } from './models/UserRoleDto';
export type { UserSimpleDto } from './models/UserSimpleDto';
export type { UserSimpleListDto } from './models/UserSimpleListDto';
export { UserTypes } from './models/UserTypes';
export { ValueType } from './models/ValueType';
export type { VirtualizeResourceQueueDto } from './models/VirtualizeResourceQueueDto';
export type { WeeklyDateTimePatternDto } from './models/WeeklyDateTimePatternDto';
export type { WeeklyDateTimePatternV1Dto } from './models/WeeklyDateTimePatternV1Dto';
export type { WhitelistedResourceTicketSkeletonSelectorDto } from './models/WhitelistedResourceTicketSkeletonSelectorDto';
export type { WinterhalterIssueDtm } from './models/WinterhalterIssueDtm';
export type { WinterhalterIssueEmailTtm } from './models/WinterhalterIssueEmailTtm';
export type { WinterhalterOrderIssueContentDto } from './models/WinterhalterOrderIssueContentDto';
export type { WinterhalterProductCatalogDto } from './models/WinterhalterProductCatalogDto';
export type { WinterhalterProductDetailDto } from './models/WinterhalterProductDetailDto';
export type { WinterhalterProductOrderDto } from './models/WinterhalterProductOrderDto';
export type { WinterhalterProductOrderIssueDto } from './models/WinterhalterProductOrderIssueDto';
export type { WinterhalterProductOrderIssueListDto } from './models/WinterhalterProductOrderIssueListDto';
export type { WinterhalterProductOrderIssuePreviewDto } from './models/WinterhalterProductOrderIssuePreviewDto';
export type { WinterhalterServiceCallCategoryPreviewDto } from './models/WinterhalterServiceCallCategoryPreviewDto';
export type { WinterhalterServiceCallIssueDto } from './models/WinterhalterServiceCallIssueDto';
export type { WinterhalterServiceCallIssueListDto } from './models/WinterhalterServiceCallIssueListDto';
export type { WinterhalterServiceCallIssuePreviewDto } from './models/WinterhalterServiceCallIssuePreviewDto';
export type { WinterhalterServiceCallProblemPreviewDto } from './models/WinterhalterServiceCallProblemPreviewDto';
export type { WinterhalterServiceIssueContentDto } from './models/WinterhalterServiceIssueContentDto';
