import {Injectable} from "@angular/core";
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {EMPTY, mergeMap, Observable, take, throwError} from "rxjs";
import {TranslateService} from "@ngx-translate/core";
import {Clients, TokenDto} from "@server-models";
import {select, Store} from "@ngrx/store";
import {catchError} from "rxjs/operators";
import {Router} from "@angular/router";
import {StorageServiceBase} from "@core/storage/storage-service-base";
import {Storage} from "@ionic/storage-angular";
import {AlertBaseControllerService} from "@shared/services/alert-controller.service";
import {TokenBaseHelperService} from "@shared/services/token-base-helper.service";
import {LoginBaseSelectors} from "@shared/stores/login-base/store/login-base.selector-type";
import {LoginBaseActions} from "@shared/stores/login-base/store/login-base.actions-type";
import {ErrorHandlerBaseService} from "@core/services/error-handler-base/error-handler-base.service";
import {RequestOptions} from "@shared/interfaces/request-options.interface";

@Injectable({
  providedIn: 'root'
})
export abstract class HttpClientBaseApiService extends StorageServiceBase<any> {

  // one works as proxy, identity app /identity, orga app /orga, tech app /tech
  protected _apiUrlOne: string = "";
  private _selectToken$: Observable<TokenDto | null | undefined>;
  protected _errorHandler: ErrorHandlerBaseService;
  protected key: string = 'login';
  protected clientType: Clients = Clients.Team;

  // private _selectLoading$: Observable<boolean>;

  protected constructor(
      public http: HttpClient,
      protected _router: Router,
      protected _store: Store,
      protected _alertControllerService: AlertBaseControllerService,
      protected _translationService: TranslateService,
      private _tokenHelperService: TokenBaseHelperService,
      storage: Storage
  ) {
    super(storage);
    this._selectToken$ = this._store.pipe(select(LoginBaseSelectors.selectToken));
    this._errorHandler = new ErrorHandlerBaseService(this._alertControllerService, this._translationService, this._router);
    // this._selectLoading$ = this._store.pipe(select(selectIsLoading));
  }

  /**
   * @name get
   * @memberof HttpClientBaseApiService
   * @description
   * public method to handle a simple get
   * @param endpoint
   * @param useAuth
   * @param options
   * @return {Observable<T>}
   */
  get<T>(endpoint: string, useAuth: boolean = true, options?: RequestOptions): Observable<T> {
    const headers: HttpHeaders = this._getHeaders(options?.headers);
    const params: HttpParams = this._getParams(options?.params);
    const errorHandler: ErrorHandlerBaseService = options?.errorHandler || this._errorHandler;
    return this._handleRequest<T>(useAuth, (innerHeaders, innerParams) =>
      this._simpleGet<T>(
        endpoint,
        this._overrideDefaultHeaders(headers, innerHeaders),
        this._mergeParams(params, innerParams)
      ).pipe(
          catchError((error) => throwError(() => errorHandler?.handlerErrors(error)))
      )
    );
  }

  /**
   * @name _simpleGet
   * @memberof HttpClientBaseApiService
   * @description
   * return a httpclient get
   * @param endpoint
   * @param headers
   * @param params
   * @private
   * @return {Observable<T>}
   */
  private _simpleGet<T>(endpoint: string, headers?: HttpHeaders, params?: HttpParams): Observable<T> {
    return this.http.get<T>(`${this._apiUrlOne}${endpoint}`, {
      headers,
      params
    });
  }

  /**
   * @name post
   * @memberof HttpClientBaseApiService
   * @description
   * public method to handle a simple post
   * @param endpoint
   * @param useAuth
   * @param body
   * @param options
   * @return {Observable<T>}
   */
  post<T>(endpoint: string, useAuth: boolean = true, body?: any, options?: RequestOptions): Observable<T> {
    const headers: HttpHeaders = this._getHeaders(options?.headers);
    const params: HttpParams = this._getParams(options?.params);
    const errorHandler: ErrorHandlerBaseService = options?.errorHandler || this._errorHandler;
    return this._handleRequest<T>(useAuth, (innerHeaders, innerParams) =>
      this._simplePost<T>(
        endpoint,
        body,
        this._overrideDefaultHeaders(headers, innerHeaders),
        this._mergeParams(params, innerParams)
      ).pipe(
          catchError((error) => throwError(() => errorHandler?.handlerErrors(error)))
      )
    );
  }

  /**
   * @name _simplePost
   * @memberof HttpClientBaseApiService
   * @description
   * return a httpclient post
   * @param endpoint
   * @param body
   * @param headers
   * @param params
   * @private
   * @return {Observable<T>}
   */
  private _simplePost<T>(endpoint: string, body?: any, headers?: HttpHeaders, params?: HttpParams): Observable<T> {
    return this.http.post<T>(`${this._apiUrlOne}${endpoint}`, body, {
      headers,
      params
    });
  }

  /**
   * @name put
   * @memberof HttpClientBaseApiService
   * @description
   * public method to handle a simple put
   * @param endpoint
   * @param useAuth
   * @param body
   * @param options
   * @return {Observable<T>}
   */
  put<T>(endpoint: string, useAuth: boolean = true, body?: any, options?: RequestOptions): Observable<T> {
    const headers: HttpHeaders = this._getHeaders(options?.headers);
    const params: HttpParams = this._getParams(options?.params);
    const errorHandler: ErrorHandlerBaseService = options?.errorHandler || this._errorHandler;
    return this._handleRequest<T>(useAuth,  (innerHeaders, innerParams) =>
      this._simplePut<T>(
        endpoint,
        body,
        this._overrideDefaultHeaders(headers, innerHeaders),
        this._mergeParams(params, innerParams)
      ).pipe(
          catchError((error) => throwError(() => errorHandler?.handlerErrors(error)))
      )
    );
  }

  /**
   * @name _simplePut
   * @memberof HttpClientBaseApiService
   * @description
   * return a httpclient put
   * @param endpoint
   * @param body
   * @param headers
   * @param params
   * @private
   * @return {Observable<T>}
   */
  private _simplePut<T>(endpoint: string, body?: any, headers?: HttpHeaders, params?: HttpParams): Observable<T> {
    return this.http.put<T>(`${this._apiUrlOne}${endpoint}`, body, {
      headers,
      params
    });

  }

  /**
   * @name patch
   * @memberof HttpClientBaseApiService
   * @description
   * public method to handle a simple patch
   * @param endpoint
   * @param useAuth
   * @param body
   * @param options
   * @return {Observable<T>}
   */
  patch<T>(endpoint: string, useAuth: boolean = true, body?: any, options?: RequestOptions): Observable<T> {
    const headers: HttpHeaders = this._getHeaders(options?.headers);
    const params: HttpParams = this._getParams(options?.params);
    const errorHandler: ErrorHandlerBaseService = options?.errorHandler || this._errorHandler;
    return this._handleRequest<T>(useAuth, (innerHeaders, innerParams) =>
      this._simplePatch<T>(
        endpoint,
        body,
        this._overrideDefaultHeaders(headers, innerHeaders),
        this._mergeParams(params, innerParams)
      ).pipe(
          catchError((error) => throwError(() => errorHandler?.handlerErrors(error)))
      )
    );
  }

  /**
   * @name _simplePatch
   * @memberof HttpClientBaseApiService
   * @description
   * return a httpclient patch
   * @param endpoint
   * @param body
   * @param headers
   * @param params
   * @private
   * @return {Observable<T>}
   */
  private _simplePatch<T>(endpoint: string, body?: any, headers?: HttpHeaders, params?: HttpParams): Observable<T> {
    return this.http.patch<T>(`${this._apiUrlOne}${endpoint}`, body, {
      headers,
      params
    });

  }

  /**
   * @name delete
   * @memberof HttpClientBaseApiService
   * @description
   * public method to handle a simple delete
   * @param endpoint
   * @param useAuth
   * @param options
   * @return {Observable<T>}
   */
  delete<T>(endpoint: string, useAuth: boolean = true, options?: RequestOptions): Observable<T> {
    const headers: HttpHeaders = this._getHeaders(options?.headers);
    const params: HttpParams = this._getParams(options?.params);
    const errorHandler: ErrorHandlerBaseService = options?.errorHandler || this._errorHandler;
    return this._handleRequest<T>(useAuth, (innerHeaders, innerParams) =>
      this._simpleDelete<T>(
        endpoint,
        this._overrideDefaultHeaders(headers, innerHeaders),
        this._mergeParams(params, innerParams)
      ).pipe(
          catchError((error) => throwError(() => errorHandler?.handlerErrors(error)))
      )
    );
  }

  /**
   * @name _simpleDelete
   * @memberof HttpClientBaseApiService
   * @description
   * return a httpclient delete
   * @param endpoint
   * @param headers
   * @param params
   * @private
   * @return {Observable<T>}
   */
  private _simpleDelete<T>(endpoint: string, headers: HttpHeaders, params: HttpParams): Observable<T> {
    return this.http.delete<T>(`${this._apiUrlOne}${endpoint}`, {
      headers,
      params
    });
  }

  /**
   * @name _handleRequest
   * @memberof HttpClientBaseApiService
   * @description
   * Handle all request to check if is need use authorization, if is the case check if the token is expired
   * in case that the token is expired it will hold the current request and dispatch a refresh token, once the steps
   * are clear it will include the auth header and retry once the previous request
   * @param useAuth
   * @param requestFn
   * @private
   * @return Observable<T>
   */
  private _handleRequest<T>(useAuth: boolean, requestFn: (headers: HttpHeaders, params: HttpParams) => Observable<T>): Observable<T> {
    let headers: HttpHeaders = this._getHeaders();
    let params: HttpParams = this._getParams();

    if (useAuth) {
      return this._selectToken$.pipe(
        mergeMap((tokenDto) => {

          let existingToken = tokenDto!.token;
          const isExpired = this._tokenHelperService.isTokenExpiredBoth(tokenDto!.token);

          if ((isExpired || existingToken === undefined)) {
            this._store.dispatch(LoginBaseActions.loginRefresh({clientType: this.clientType}));
            return EMPTY;
          }

          headers = this._getAuthHeader(headers, existingToken!);

          return requestFn(headers, params);
        }),
        take(1)
      );
    } else {
      return requestFn(headers, params);
    }
  }

  /**
   * @name _getAcceptLanguageHeader
   * @memberof HttpClientBaseApiService
   * @description
   * get the accept-language from the translation service currentLang
   * @private
   */
  private _getAcceptLanguageHeader(headers: HttpHeaders): HttpHeaders {
    return headers.set('Accept-Language', this._translationService.currentLang);
  }

  /**
   * @name _getAuthHeader
   * @memberof HttpClientBaseApiService
   * @description
   * get the Authorization header
   * @param headers
   * @param token
   * @private
   */
  private _getAuthHeader(headers: HttpHeaders, token: string | null): HttpHeaders {
    return headers.set('Authorization', `Bearer ${token}`);
  }

  /**
   * @name _getHeaders
   * @memberof HttpClientBaseApiService
   * @description
   * get all headers given and mix with the current existence
   * @param headers
   */
  _getHeaders(headers?: { [key: string]: string }): HttpHeaders {
    let newHeaders: HttpHeaders = new HttpHeaders();

    newHeaders = this._getAcceptLanguageHeader(newHeaders);

    if (headers) {
      for (const key in headers) {
        if (headers.hasOwnProperty(key)) {
          newHeaders = newHeaders.set(key, headers[key]);
        }
      }
    }

    return newHeaders;
  }

  /**
   * @name _getParams
   * @memberof HttpClientBaseApiService
   * @description
   * get all params given and mix with the current existence
   * @param params
   * @private
   */
  private _getParams(params?: { [key: string]: string }): HttpParams {
    let newParams: HttpParams = new HttpParams();

    // Set additional parameters
    if (params) {
      newParams = Object.keys(params).reduce(
        (acc, key) => acc.set(key, params[key]),
        newParams
      );
    }

    return newParams;
  }

  /**
   * @name _overrideDefaultHeaders
   * @memberof HttpClientBaseApiService
   * @description
   * Override default headers
   * @param headersA
   * @param headersB
   * @private
   */
  private _overrideDefaultHeaders(headersA: HttpHeaders, headersB: HttpHeaders): HttpHeaders {
    let mergedHeaders = headersB;

    if (headersA) {
      headersA.keys().forEach(headerName => {
        const headerValueA = headersA.getAll(headerName);
        if (headerValueA) {
          mergedHeaders = mergedHeaders.set(headerName, headerValueA);
        }
      });
    }
    return mergedHeaders;
  }

  /**
   * @name _mergeParams
   * @memberof HttpClientBaseApiService
   * @description
   * Merge params to return one HttpParams
   * @param paramsA
   * @param paramsB
   * @private
   */
  private _mergeParams(paramsA: HttpParams, paramsB: HttpParams): HttpParams {
    let mergedParams = paramsA;

    paramsB.keys().forEach(paramName => {
      const paramValueB = paramsB.getAll(paramName);
      if (paramValueB) {
        const paramValueA = mergedParams.getAll(paramName);
        if (paramValueA) {
          const mergedValue = [...paramValueA, ...paramValueB].join(',');
          mergedParams = mergedParams.set(paramName, mergedValue);
        } else {
          mergedParams = mergedParams.set(paramName, paramValueB.join(','));
        }
      }
    });

    return mergedParams;
  }

}
