import {Component, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {TranslateModule} from '@ngx-translate/core';
import {select, Store} from '@ngrx/store';
import {Observable, of} from 'rxjs';
import {SharedIssueBaseSelectors} from '@shared/components/issues/store/shared-issue-base.selectors-type';
import {SharedIssueBaseState} from '@shared/components/issues/store/shared-issue-base.state';
import {SharedCustomPropertyReadOnlyInputTextComponent} from '@shared/components/custom-property-read-only/components/input-text/shared-custom-property-read-only-input-text.component';
import {map} from 'rxjs/operators';
import {WinterhalterOperationStatus, WinterhalterOrderDto} from '@server-models';
import {SharedCustomPropertyReadOnlyInputDateComponent} from '@shared/components/custom-property-read-only/components/input-date/shared-custom-property-read-only-input-date.component';
import {
  ICustomPropertyStructureFormatted
} from '@shared/components/custom-property-form/interfaces/custom-property-structure-formatted.interface';

export const winterhalterOrderStateEnumOptions = [
  {
    value: WinterhalterOperationStatus.Created,
    label: 'COMPONENTS.ISSUES.SERVICES.WINTERHALTER.WINTERHALTER_ORDER_STATE_ENUM.CREATED'
  },
  {
    value: WinterhalterOperationStatus.Instructed,
    label: 'COMPONENTS.ISSUES.SERVICES.WINTERHALTER.WINTERHALTER_ORDER_STATE_ENUM.INSTRUCTED'
  },
  {
    value: WinterhalterOperationStatus.Transition,
    label: 'COMPONENTS.ISSUES.SERVICES.WINTERHALTER.WINTERHALTER_ORDER_STATE_ENUM.TRANSITION'
  },
  {
    value: WinterhalterOperationStatus.Done,
    label: 'COMPONENTS.ISSUES.SERVICES.WINTERHALTER.WINTERHALTER_ORDER_STATE_ENUM.DONE'
  },
  {
    value: WinterhalterOperationStatus.Canceled,
    label: 'COMPONENTS.ISSUES.SERVICES.WINTERHALTER.WINTERHALTER_ORDER_STATE_ENUM.CANCELED'
  }
]

@Component({
  selector: 'app-shared-issue-read-only-winterhalter-order-info',
  templateUrl: './shared-issue-read-only-winterhalter-order-info.component.html',
  styleUrls: ['./shared-issue-read-only-winterhalter-order-info.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, SharedCustomPropertyReadOnlyInputTextComponent, SharedCustomPropertyReadOnlyInputDateComponent, TranslateModule]

})
export class SharedIssueReadOnlyWinterhalterOrderInfoComponent implements OnInit {

  detailDataFormatted$!: Observable<ICustomPropertyStructureFormatted>;
  winterhalterOrder$?: Observable<WinterhalterOrderDto | undefined> = of();
  winterhalterStateValue$?: Observable<string | undefined> = of();
  isLoading$!: Observable<boolean>;

  constructor(
    private _store: Store<SharedIssueBaseState>
  ) {
  }

  ngOnInit() {
    this.isLoading$ = this._store.pipe(select(SharedIssueBaseSelectors.selectIsLoading));

    this.detailDataFormatted$ = this._store.pipe(select(SharedIssueBaseSelectors.selectDetailDataFormatted))

    this.winterhalterOrder$ = this.detailDataFormatted$.pipe(
      map(detailData => detailData?.additionalDetails?.winterhalter?.winterhalterOrder)
    );

    this.winterhalterStateValue$ = this.winterhalterOrder$.pipe(
      map(order => winterhalterOrderStateEnumOptions.find(option => option.value === (order?.operations ?? [])[0]?.status)?.label)
    );
  }
}
