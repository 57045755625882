import {Injectable} from "@angular/core";
import {IssuesBaseService} from "@shared/components/issues/services/issues-base.service";
import {
  IssueType,
  ResourceProfileDto,
  SubmitWinterhalterProductDto,
  SubmitWinterhalterProductOrderIssueDto,
  WinterhalterProductOrderIssueDto,
  WinterhalterProductOrderIssuePreviewDto,
} from "@server-models";
import {IssueFormattedDetailData} from "@shared/components/issues/interfaces/issue-formatted-detail-data.interface";
import {FormGroup} from "@angular/forms";

@Injectable({
  providedIn: 'root',
})
export class IssuesBaseWinterhalterProductOrderService extends IssuesBaseService {
  override formatReadDetailData(issueDto: WinterhalterProductOrderIssueDto, issueType: IssueType): IssueFormattedDetailData {
    const baseResult = <IssueFormattedDetailData>{
      issueId: issueDto.issueId,
      headerTitle: issueDto.title,
      issueType: issueType
    }

    return {
      ...baseResult,
      ...this.getAdditionalFormattedData(issueDto)
    };
  }

  override prepareFormIssueDto(form: FormGroup, templatePreviewDto: WinterhalterProductOrderIssuePreviewDto, resourceDto?: ResourceProfileDto, tenantId?: number): SubmitWinterhalterProductOrderIssueDto {
    const formValues = form.value;
    const submitWinterhalterProductDtoList = (Object.values(formValues) as SubmitWinterhalterProductDto[]);
    const combinedProducts = submitWinterhalterProductDtoList.map(({productId, quantity}) => ({
        productId,
        quantity
      }));
    return {
      title: templatePreviewDto.name,
      tenantId,
      products: combinedProducts,
      resource: resourceDto
    };
  }

  override getAdditionalFormattedData(issueDto: WinterhalterProductOrderIssueDto): Partial<IssueFormattedDetailData> {
    return {
      winterhalter: {
        productOrder: {
          resource: issueDto.resource,
          orderId: issueDto.orderId,
          formData: {
            productList: issueDto.products!
          }
        }
      }
    };
  }
}
