<form [formGroup]="customValues" (change)="formModified()">
  <ng-container formGroupName="CustomValues">
    <ng-container *ngFor="let groupName of getGroupNames(); let groupIndex = index">
      <ion-card [formGroupName]="groupName">
        <ion-card-header>{{ getPropertyName(groupIndex) }}</ion-card-header>

        <ion-card-content class="space-reduce" *ngFor="let controlName of getControlNames(groupName); let controlIndex = index">
          <app-custom-property
            [inputProperty]="getProperty(groupIndex, controlName)"
            [formControl]="getControlFromGroup(groupName, controlName)"
            [index]="controlIndex">
          </app-custom-property>
        </ion-card-content>
      </ion-card>
    </ng-container>
  </ng-container>
</form>
