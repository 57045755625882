import {Injectable} from "@angular/core";
import {
  CustomPropertyDto,
  CustomSetReferenceDto,
  FilledCustomValueDto,
  IssueType,
  MessageIssueDto,
  MessageIssuePreviewDto,
  StereotypeDto,
  StereotypeUsageDto,
  SubmitMessageIssueDto
} from "@server-models";
import {
  ICustomPropertyStructureFormatted
} from "@shared/components/custom-property-form/interfaces/custom-property-structure-formatted.interface";
import {FormGroup} from "@angular/forms";
import {SharedIssueStereotypeBaseService} from "@shared/components/issues/services/stereotype/shared-issue-stereotype-base.service";
import {TIssueDto} from "../types/issue-dto.type";
import {
  IAdditionalDetails
} from "@shared/components/custom-property-form/interfaces/structure-formatted-additional-details.interface";

@Injectable({
  providedIn: 'root',
})
export class SharedIssueMessageService extends SharedIssueStereotypeBaseService {

  override formatReadDetailData(issueDtoType: TIssueDto, issueType: IssueType,
                                stereotypeDto?: StereotypeDto | undefined): ICustomPropertyStructureFormatted {
    const issueDtoTypeMessage = issueDtoType as MessageIssueDto;
    return this._stereotypeBaseService.customPropertiesFormattedFromStereotype(
      issueDtoTypeMessage.issueId!,
      issueDtoTypeMessage.title!,
      issueType,
      issueDtoTypeMessage.customPropertyValues!,
      stereotypeDto!,
      issueDtoTypeMessage,
      (dto) => this.getAdditionalFormattedData(dto)
    );
  }

  prepareFormIssueDto(formGroup: FormGroup, templatePreviewDto: MessageIssuePreviewDto): SubmitMessageIssueDto {
    const customValuesFromGroup = formGroup.get('CustomValues') as FormGroup;
    const customPropertyValues = this._stereotypeBaseService.prepareCustomValues(customValuesFromGroup); // to build the customValues
    const issueStereotype: StereotypeUsageDto = templatePreviewDto.issueStereotype!;
    const customPropertySets: CustomSetReferenceDto[] = issueStereotype.customPropertySets!;

    const sortedCustomPropertySets: CustomSetReferenceDto[] = this._stereotypeBaseService.sortCustomPropertySets(customPropertySets);
    const stereotypeCustomProperties: CustomPropertyDto[] = this._stereotypeBaseService.flattenCustomProperties(sortedCustomPropertySets);
    const mutateCustomPropertyValues: FilledCustomValueDto[] = this._stereotypeBaseService.prepareCustomPropertyValues(customPropertyValues, issueStereotype.tenantId!, stereotypeCustomProperties);

    return {
      title: templatePreviewDto.name,
      tenantId: issueStereotype?.tenantId,
      stereotypeId: issueStereotype?.stereotypeId,
      stereotypeRowVersion: issueStereotype?.rowVersion,
      customPropertyValues: mutateCustomPropertyValues
    };
  }

  getAdditionalFormattedData(issueDto: MessageIssueDto): Partial<IAdditionalDetails> {
    return {};
  }

}
