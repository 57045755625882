import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {CommonModule} from "@angular/common";
import {IonicModule} from "@ionic/angular";
import {TranslateModule} from "@ngx-translate/core";
import {SafePipe} from "@shared/pipes/safe.pipe";
import {UserHtmlComponent} from "@features/user-html/user-html.component";
import {AttachmentsComponent} from "@shared/components/attachments/attachments.component";
import {RouterLink} from "@angular/router";
import {CustomPropertyComponent} from "@shared/components/custom-property/custom-property.component";
import {FormBuilder, FormGroup, ReactiveFormsModule} from "@angular/forms";
import {InputTextComponent} from "@shared/components/custom-property/inputs/text/input-text.component";
import {
  IssueContentType,
  TicketIssuePreviewDto
} from "@server-models";
import {
  IssueCustomPriorityFormComponent
} from "@shared/components/issues/components/custom/form/priority/issue-custom-priority-form.component";
import {
  IssueCustomPropertyFormComponent
} from "@shared/components/issues/components/custom/form/properties/issue-custom-property-form.component";
import {Observable, of} from "rxjs";
import {select, Store} from "@ngrx/store";
import {BaseIssuesSelectors} from "@shared/components/issues/store/base-issues.selectors-type";
import {TemplatePreviewDtoType} from "@shared/components/issues/types/template-preview-dto.type";

@Component({
  selector: 'app-base-issue-ticket-template-preview',
  templateUrl: './base-issue-ticket-template-preview.component.html',
  styleUrls: ['./base-issue-ticket-template-preview.component.scss'],
  standalone: true,
  imports: [
    IonicModule,
    CommonModule,
    TranslateModule,
    SafePipe,
    UserHtmlComponent,
    AttachmentsComponent,
    RouterLink,
    InputTextComponent,
    ReactiveFormsModule,
    CustomPropertyComponent,
    IssueCustomPriorityFormComponent,
    IssueCustomPropertyFormComponent
  ]
})
export class BaseIssueTicketTemplatePreviewComponent implements OnInit {

  // @Input() ticketTemplatePreview!: TicketIssuePreviewDto;
  @Output() formChange = new EventEmitter<FormGroup>();
  parentForm: FormGroup;
  selectedTemplatePreview$: Observable<{
    id: number,
    templateIssueType: IssueContentType,
    data: TemplatePreviewDtoType
  }>;
  currentTemplatePreview: {
    issueContentType: IssueContentType,
    data: TicketIssuePreviewDto
  };

  constructor(
    private _fb: FormBuilder,
    private _store: Store
  ) {
    this.parentForm = this._fb.group({});
    this.selectedTemplatePreview$ = of(<{
      id: number,
      templateIssueType: IssueContentType,
      data: TemplatePreviewDtoType
    }>{});
    this.currentTemplatePreview = <{
      issueContentType: IssueContentType,
      data: TicketIssuePreviewDto
    }>{};
  }

  ngOnInit() {
    this.selectedTemplatePreview$ = this._store.pipe(select(BaseIssuesSelectors.selectSelectedTemplate));
    this.selectedTemplatePreview$.subscribe((templatePreview) => {
      this.currentTemplatePreview = {
        issueContentType: templatePreview.templateIssueType,
        data: templatePreview.data as TicketIssuePreviewDto
      };
    })
  }

  formInnerModified(ev: any) {
    this.parentForm = ev;
    this.formChange.emit(this.parentForm);
  }
}
