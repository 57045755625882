import {AbstractControl, ValidationErrors, ValidatorFn} from "@angular/forms";
import {SharedDateHelperService} from "@shared/services/shared-date-helper.service";

export const invalidZeroTimeValidator = (dateHelper: SharedDateHelperService): ValidatorFn => {
  const zeroTimeISO = dateHelper.convertMinutesToISO(0);

  return (control: AbstractControl): ValidationErrors | null => {
    if (control.value === zeroTimeISO) {
      return { required: true };
    }
    return null;
  };
};
