<ion-item-group *ngIf="mappedAttachments!.length > 0">
  <ion-item-divider mode="md" *ngIf="showHeader">
    <ion-label>{{ "COMPONENTS.ATTACHMENTS.FILES.LABEL" | translate }}</ion-label>
  </ion-item-divider>
  <div *ngFor="let attachment of mappedAttachments">
    <!--    File: pdf/images-->
    <ion-item
      (click)="attachmentsService.dispatchAction(attachment)"
      [disabled]="(attachmentsService.checkLoadingButton(attachment.attachmentId) | async)"
      [button]="true"
      [detail]="true"
      lines="inset"
      *ngIf="attachment.type === attachmentType.File">
      <ion-label>{{ attachment.name }}</ion-label>
      <ion-badge
        *ngIf="!(attachmentsService.checkLoadingButton(attachment.attachmentId) | async)"
        color="medium"
        slot="end">
        {{ "COMPONENTS.ATTACHMENTS.FILES.TYPES.FILE" | translate }}
      </ion-badge>
      <ion-spinner *ngIf="(attachmentsService.checkLoadingButton(attachment.attachmentId) | async)" slot="end"></ion-spinner>
    </ion-item>
    <!--    Link-->
    <ion-item
      (click)="attachmentsService.dispatchAction(attachment)"
      [disabled]="(attachmentsService.checkLoadingButton(attachment.attachmentId) | async)"
      [button]="true"
      [detail]="true"
      lines="inset"
      *ngIf="attachment.type === attachmentType.Link">
      <ion-label>{{ attachment.name }}</ion-label>
      <ion-badge
        *ngIf="!(attachmentsService.checkLoadingButton(attachment.attachmentId) | async)"
        color="medium"
        slot="end">
        {{ "COMPONENTS.ATTACHMENTS.FILES.TYPES.LINK" | translate }}
      </ion-badge>
      <ion-spinner *ngIf="(attachmentsService.checkLoadingButton(attachment.attachmentId) | async)" slot="end"></ion-spinner>
    </ion-item>
    <!--    Knowledge-->
    <ion-item
      (click)="attachmentsService.dispatchAction(attachment)"
      [disabled]="(attachmentsService.checkLoadingButton(attachment.attachmentId) | async)"
      [button]="true"
      [detail]="true"
      lines="inset"
      *ngIf="attachment.type === attachmentType.Knowledge">
      <ion-label>{{ attachment.name }}</ion-label>
      <ion-badge
        *ngIf="!(attachmentsService.checkLoadingButton(attachment.attachmentId) | async)"
        color="medium"
        slot="end">
        {{ "COMPONENTS.ATTACHMENTS.FILES.TYPES.KNOWLEDGE" | translate }}
      </ion-badge>
      <ion-spinner *ngIf="(attachmentsService.checkLoadingButton(attachment.attachmentId) | async)" slot="end"></ion-spinner>
    </ion-item>
  </div>
</ion-item-group>
