import {Injectable} from "@angular/core";
import {
  CustomPropertyDto,
  CustomSetReferenceDto,
  FilledCustomValueDto, MissionEditorSelectorKind, MissionIssueDto,
  MissionIssuePreviewDto, PrioritySimpleDto, ResourceProfileDto,
  StereotypeUsageDto,
  SubmitMissionIssueDto
} from "@server-models";
import {IssueFormattedDetailData} from "@shared/components/issues/interfaces/issue-formatted-detail-data.interface";
import {FormGroup} from "@angular/forms";
import {IssueBaseStereotypeService} from "@shared/components/issues/services/stereotype/stereotype.service";

@Injectable({
  providedIn: 'root',
})
export class IssuesBaseMissionService extends IssueBaseStereotypeService {

  prepareFormIssueDto(formGroup: FormGroup, templatePreviewDto: MissionIssuePreviewDto, resourceDto?: ResourceProfileDto): SubmitMissionIssueDto {
    const priority = this.getPriority(templatePreviewDto, formGroup);

    const customPropertyValues = this.prepareCustomValues(formGroup); // to build the customValues
    const issueStereotype: StereotypeUsageDto = templatePreviewDto.issueStereotype!;

    const sortedCustomPropertySets: CustomSetReferenceDto[] = this._sortCustomPropertySets(issueStereotype);
    const stereotypeCustomProperties: CustomPropertyDto[] = this._flattenCustomProperties(sortedCustomPropertySets);
    const mutateCustomPropertyValues: FilledCustomValueDto[] = this._prepareCustomPropertyValues(customPropertyValues, issueStereotype, stereotypeCustomProperties);

    return {
      title: templatePreviewDto.name,
      tenantId: issueStereotype?.tenantId,
      stereotypeId: issueStereotype?.stereotypeId,
      stereotypeRowVersion: issueStereotype?.rowVersion,
      customPropertyValues: mutateCustomPropertyValues,
      priority,
      resource: resourceDto
    };
  }

  getPriority(templatePreviewDto: MissionIssuePreviewDto,formGroup: FormGroup): PrioritySimpleDto | undefined {
    let priority;
    const priorityList = templatePreviewDto.priorities!;

    if (templatePreviewDto.editorSelectorKind === MissionEditorSelectorKind.PrioritySearch) {
      if (priorityList.length === 1) {
        priority = priorityList[0];
      } else if (formGroup.get('Priority')) {
        const priorityFormGroup = formGroup.get('Priority') as FormGroup;
        priority = this.findPriorityValue(priorityFormGroup, priorityList);
        // Remove priority form from formGroup to have only customValues
        formGroup.removeControl('Priority');
      }
    }

    return priority;
  }

  findPriorityValue(priorityForm: FormGroup, priorities: PrioritySimpleDto[]): PrioritySimpleDto {
    const valueToFind = priorityForm.value;
    return priorities.find(p => p?.priorityId === valueToFind)!
  }

  getAdditionalFormattedData(issueDto: MissionIssueDto): Partial<IssueFormattedDetailData> {
    const missionDetail = issueDto.mission;
    return {
      mission: missionDetail
    };
  }
}
