import {Component, OnInit} from '@angular/core';
import {CommonModule} from "@angular/common";
import {IonicModule} from "@ionic/angular";
import {select, Store} from "@ngrx/store";
import {Observable} from "rxjs";
import {
  ICustomPropertyStructureFormatted
} from "@shared/components/custom-property-form/interfaces/custom-property-structure-formatted.interface";
import {SharedIssueBaseSelectors} from "@shared/components/issues/store/shared-issue-base.selectors-type";
import {SharedIssueBaseState} from "@shared/components/issues/store/shared-issue-base.state";
import {
  SharedCustomPropertySetsReadOnlyCardComponent
} from "@shared/components/custom-property-sets-read-only-card/shared-custom-property-sets-read-only-card.component";
import {TranslateModule} from "@ngx-translate/core";

@Component({
  selector: 'app-shared-issue-read-only-ticket',
  templateUrl: './shared-issue-read-only-ticket.component.html',
  styleUrls: ['./shared-issue-read-only-ticket.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, SharedCustomPropertySetsReadOnlyCardComponent, TranslateModule],

})
export class SharedIssueReadOnlyTicketComponent implements OnInit {
  id: number;
  isLoading$!: Observable<boolean>;
  detailDataFormatted$!: Observable<ICustomPropertyStructureFormatted>;

  constructor(
    private _store: Store<SharedIssueBaseState>
  ) {
    this.id = NaN;
  }

  ngOnInit() {
    this.isLoading$ = this._store.pipe(select(SharedIssueBaseSelectors.selectIsLoading));
    this.detailDataFormatted$ = this._store.pipe(select(SharedIssueBaseSelectors.selectDetailDataFormatted));
  }
}
