<ion-item-sliding>
  <ion-item button detail (click)="navigateToIssue(issue)">
    <ion-label class="ion-text-wrap">
      {{ issue.title }}
    </ion-label>
    <div class="extra-info-container">
      <ion-text color="medium" class="date-text">{{ formattedDate }}</ion-text>
    </div>
  </ion-item>
</ion-item-sliding>
