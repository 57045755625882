<ion-header [translucent]="true">
  <ion-toolbar color="primary">
    <div class="container">
      <ion-buttons>
        <ion-button (click)="back()">
          <ion-icon slot="start" name="arrow-back-sharp"></ion-icon>
        </ion-button>
      </ion-buttons>
      <ng-container>
        <ion-spinner *ngIf="(isLoading$ | async)"></ion-spinner>
        <ion-title
          *ngIf="!(isLoading$ | async)">{{ ('TEAM.ISSUES.PAGES.READ.PAGE_NAME' | translate) + ' #' + (detailDataFormatted$ | async)?.structureId }}
        </ion-title>
      </ng-container>
    </div>
  </ion-toolbar>
</ion-header>
<ion-content>
  <ion-spinner *ngIf="(isLoading$ | async)"></ion-spinner>
  <ng-container *ngIf="!(isLoading$ | async)">
    <ng-container *ngIf="(detailDataFormatted$ | async) as detailDataFormatted">
      <ion-card>
        <ion-card-header>
          <ion-text color="dark">
            <h4 class="ion-no-margin">
              {{ detailDataFormatted.headerTitle }}
            </h4>
          </ion-text>
          <ion-card-subtitle *ngIf="detailDataFormatted.lastModified" color="medium">
            <div class="mission-sub-header">
              {{ 'GENERAL.LAST_MODIFIED' | translate }}: {{ formatDate(detailDataFormatted.lastModified) }}
            </div>
          </ion-card-subtitle>
        </ion-card-header>
      </ion-card>
      <ng-container *ngIf="isWinterhalterIssue$ | async">
        <ion-card
          *ngIf="detailDataFormatted.additionalDetails?.winterhalter?.productOrder?.orderId || detailDataFormatted.additionalDetails?.winterhalter?.serviceCall?.orderId">
          <ion-card-content>
            <app-shared-issue-read-only-winterhalter-order-info></app-shared-issue-read-only-winterhalter-order-info>
          </ion-card-content>
        </ion-card>
      </ng-container>
      <ng-container
        [ngSwitch]="detailDataFormatted.type">
        <app-shared-issue-read-only-message *ngSwitchCase="IssueType.Message"></app-shared-issue-read-only-message>
        <app-shared-issue-read-only-mission *ngSwitchCase="IssueType.Mission"></app-shared-issue-read-only-mission>
        <app-shared-issue-read-only-ticket *ngSwitchCase="IssueType.Ticket"></app-shared-issue-read-only-ticket>
        <app-shared-issue-read-only-message-control
          *ngSwitchCase="IssueType.ControlMessage"></app-shared-issue-read-only-message-control>
        <app-shared-issue-read-only-winterhalter-service-call
          *ngSwitchCase="IssueType.WinterhalterServiceCall"></app-shared-issue-read-only-winterhalter-service-call>
        <app-shared-issue-read-only-winterhalter-product-order
          *ngSwitchCase="IssueType.WinterhalterProductOrder"></app-shared-issue-read-only-winterhalter-product-order>
      </ng-container>
    </ng-container>
  </ng-container>


</ion-content>
