/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum ControllerOperationId {
    ContactControllerList = 1,
    TicketControllerList = 2,
    ResourceControllerList = 3,
    MissionControllerList = 4,
    ContractControllerList = 5,
    LocationControllerList = 6,
    CompanyControllerList = 7,
    LocationGroupControllerList = 9,
    AddressControllerList = 10,
    ArticlesControllerList = 11,
    ResourceTaskControllerList = 12,
    InfoControllerList = 13,
    KnowledgeArticleControllerList = 14,
    TaskControllerList = 15,
    TicketsInResourcesControllerList = 16,
    MissionsInResourcesControllerList = 17,
    TicketInLocationControllerId = 18,
    ResourceManufacturerControllerId = 19,
    ResourceModelControllerId = 20,
    IssueControllerId = 21,
    ConsumptionHistoryForResourceList = 22,
    ErrorHistoryForResourceList = 23,
    HintHistoryForResourceList = 24,
    ResourceModelInManufacturerControllerId = 25,
    InfoProgressContactControllerId = 26,
    InfoProgressFunctionControllerId = 27,
    InfoProgressLocationControllerId = 28,
    KnowledgePathControllerList = 29,
    KnowledgePathProgressContactControllerId = 30,
    KnowledgePathProgressFunctionControllerId = 31,
    KnowledgePathProgressLocationControllerId = 32,
    OccuredConsumptionProtocol = 33,
    OccuredErrorProtocol = 34,
    TaskJobDataLocationControllerList = 35,
    TaskJobDataTaskControllerList = 36,
    ReservedInventoryNumberList = 37,
    MessageIssueControllerId = 211,
    TicketIssueControllerId = 212,
    MissionIssueControllerId = 213,
    WinterhalterServiceCallIssueControllerId = 214,
    WinterhalterProductOrderIssueControllerId = 215,
    MessageControlControllerId = 216,
    MessageControlInControlControllerId = 217,
}
