import {Injectable} from "@angular/core";
import {Actions} from "@ngrx/effects";
import {select, Store} from "@ngrx/store";
import {exhaustMap, mergeMap, Observable, withLatestFrom} from "rxjs";
import {catchError, map, tap} from "rxjs/operators";
import {IOrgaResponse} from "@shared/interfaces/orga-response.interface";
import {
  AppEntityType,
  FileDto,
  IssueContentType,
  IssueListDto,
  IssueTemplateListDto,
  IssueType,
  ResourceListDto,
  StereotypeDto,
  StereotypeListDto, WinterhalterProductCatalogDto
} from "@server-models";
import {Router} from "@angular/router";
import {SharedImageService} from "@shared/services/shared-image.service";
import {SharedCameraService} from "@shared/services/shared-camera.service";
import {SharedModalLoaderService} from "@shared/services/shared-modal-loader.service";
import {TranslateService} from "@ngx-translate/core";
import {LinkIssueApiService} from "@link/pages/issues/services/link-issue-api.service";
import {LinkIssueActions} from "@link/pages/issues/store/link-issue.actions-type";
import {LinkLoginSelectors} from "@link/pages/login/store/link-login.selector-type";
import {SharedIssueBaseService} from "@shared/components/issues/services/shared-issue-base.service";
import {IIssueRequestPagination} from "@shared/components/issues/interfaces/issue-request-pagination.interface";
import {
  ICustomPropertyStructureFormatted
} from "@shared/components/custom-property-form/interfaces/custom-property-structure-formatted.interface";
import {SharedIssueApiBaseEffects} from "@shared/components/issues/store/shared-issue-api-base.effects";
import {LinkLoginService} from "@link/pages/login/services/link-login.service";
import {SharedIssueBaseActions} from "@shared/components/issues/store/shared-issue-base.action-type";
import {TTemplatePreviewIssue} from "@shared/components/issues/store/shared-issue-base.actions";
import {LinkIssueService} from "@link/pages/issues/services/link-issue.service";
import {SharedIssueFactory} from "@shared/components/issues/services/shared-issue.factory";
import {LinkIssuesSelectors} from "@link/pages/issues/store/link-issues.selector-type";
import {FormGroup} from "@angular/forms";
import {IIssueService} from "@shared/components/issues/interfaces/issue-service.interface";
import {TTemplatePreviewDto} from "@shared/components/issues/types/template-preview-dto.type";
import {TSubmitIssueDto} from "@shared/components/issues/types/submit-issue-dto.type";
import {SharedIssueStereotypeBaseService} from "@shared/components/issues/services/stereotype/shared-issue-stereotype-base.service";
import {
  LinkIssueWinterhalterProductOrderApiService
} from "@link/pages/issues/services/winterhalter/link-issue-winterhalter-product-order-api.service";
import {SharedCacheService} from "@shared/services/cache/shared-cache.service";
import {LinkStereotypeApiService} from "@link/services/stereotype/link-stereotype-api.service";
import {SharedFileApiService} from "@shared/services/file/shared-file-api.service";

@Injectable({
  providedIn: 'root'
})
export class LinkIssuesApiEffects extends SharedIssueApiBaseEffects {

  constructor(
    actions$: Actions,
    store: Store,
    _cameraService: SharedCameraService,
    _modalLoaderService: SharedModalLoaderService,
    _translateService: TranslateService,
    _imageService: SharedImageService,
    _issuesBaseService: SharedIssueBaseService,
    _issueBaseStereotype: SharedIssueStereotypeBaseService,
    _issuesBaseFactory: SharedIssueFactory,
    _cacheService: SharedCacheService,
    _router: Router,
    private _linkIssuesApiService: LinkIssueApiService,
    private _linkIssuesService: LinkIssueService,
    private _sharedFileApiService: SharedFileApiService,
    private _linkLoginService: LinkLoginService,
    private _linkStereotypeApiService: LinkStereotypeApiService
  ) {
    super(
      actions$,
      store,
      _cameraService,
      _modalLoaderService,
      _translateService,
      _imageService,
      _issuesBaseService,
      _issueBaseStereotype,
      _issuesBaseFactory,
      _cacheService,
      _router
    )
  }

  _requestGetPaginated(action: IIssueRequestPagination): Observable<(IOrgaResponse<IssueListDto[]> | any)> {
    return this._linkIssuesApiService.getIssueRecentList(action.params).pipe(
      map((data: IOrgaResponse<IssueListDto[]>) =>
        action.isRefresh
          ? LinkIssueActions.getItemsPaginatedRefresh({ data })
          : LinkIssueActions.getItemsPaginatedSuccess({ data })
      ),
      catchError((error) => [LinkIssueActions.getItemsPaginatedFail({ error })])
    )
  }

  _requestGetTemplate(templatePreviewId: string): Observable<(IOrgaResponse<IssueTemplateListDto[]> | any)> {
    return this._linkIssuesApiService.getIssueTemplateList().pipe(
      map((data: IOrgaResponse<IssueTemplateListDto[]>) =>
        LinkIssueActions.getItemsTemplateSuccess({ data, templatePreviewId })
      ),
      catchError((error) => [LinkIssueActions.getItemsTemplateFail({ error })])
    )
  }

  override formatReadDetailDataFinish(action$: Observable<{
    detailDataFormatted: ICustomPropertyStructureFormatted
  }>): Observable<any> {
    return action$.pipe(
      withLatestFrom(this.store.pipe(select(LinkLoginSelectors.selectLinkId))),
      tap(([action, linkId]) => {
        this._router.navigate([`link/${ linkId! }/issues/${ action.detailDataFormatted.structureId }`])
      })
    )
  }

  override sendTemplatePreviewSuccess(action$: Observable<any>): Observable<any> {
    return action$.pipe(
      withLatestFrom(this.store.pipe(select(LinkLoginSelectors.selectLinkId))),
      exhaustMap(([_, linkId]) => {
        this._router.navigate([`link/${ linkId! }/issues/success`]);
        return [LinkIssueActions.thanksPageNavigation()]
      })
    );
  }

  override thanksPageNavigationBack(action$: Observable<any>): Observable<any> {
    return action$.pipe(
      withLatestFrom(this.store.pipe(select(LinkLoginSelectors.selectLinkId))),
      withLatestFrom(this.store.pipe(select(LinkLoginSelectors.selectFeatures))),
      exhaustMap(([[_, linkId], features]) => {
        const issuesNav = this._linkLoginService.checkIssuesNav(features!);
        if (issuesNav != null) {
          this._router.navigate([`link/${ linkId! }/${ issuesNav }`])
        }
        return [SharedIssueBaseActions.thanksPageNavigationBackDone()]
      })
    );
  }

  _requestGetStereotypeById(action$: { stereotypeId: number, cacheGuid: string }): Observable<{
    stereotypeId: number,
    cacheGuid: string
  } | any> {
    return this._linkStereotypeApiService.getStereotypeById(action$.stereotypeId).pipe(
      map((data: StereotypeDto) => {
          return LinkIssueActions.getStereotypeByIdSuccess({ stereotype: data, cacheGuid: action$.cacheGuid })
        }
      ),
      catchError((error) => [LinkIssueActions.getStereotypeByIdFail({ error })])
    )
  }

  _requestGetStereotypeByEntityType(entityType: AppEntityType,
                                    cacheControl: string): Observable<IOrgaResponse<StereotypeListDto> | any> {
    return this._linkStereotypeApiService.getStereotypeFilterByEntityType(entityType, cacheControl).pipe(
      map((data: IOrgaResponse<StereotypeListDto>) => {
          return LinkIssueActions.getStereotypeByEntityTypeSuccess({ data })
        }
      ),
      catchError((error) => [LinkIssueActions.getStereotypeByEntityTypeFail({ error })])
    )
  }

  override navigateToNewIssue(action$: Observable<any>) {
    return action$.pipe(
      withLatestFrom(this.store.pipe(select(LinkLoginSelectors.selectLinkId))),
      tap(([_, linkId]) => {
        this._router.navigate([`link/${ linkId! }/issues/new`])
      })
    )
  }

  override navigateToNewSpecificTemplate(action$: Observable<{ templateId: number }>) {
    return action$.pipe(
      withLatestFrom(this.store.pipe(select(LinkLoginSelectors.selectLinkId))),
      tap(([action, linkId]) => {
        this._router.navigate([`link/${ linkId! }/issues/new/${ action.templateId }`])
      })
    )
  }

  override navigateToIssues(action$: Observable<any>) {
    return action$.pipe(
      withLatestFrom(this.store.pipe(select(LinkLoginSelectors.selectLinkId))),
      tap(([_, linkId]) => {
        this._router.navigate([`link/${ linkId! }/issues`])
      })
    )
  }

  override navigateToTemplatePreview(action$: Observable<{
    templateId: number,
    templateIssueType: IssueContentType
  }>): Observable<any> {
    return action$.pipe(
      withLatestFrom(this.store.pipe(select(LinkLoginSelectors.selectLinkId))),
      tap(([{ templateId }, linkId]) => {
        this._router.navigate([`link/${ linkId! }/issues/new/${ templateId }`])
      })
    );
  }

  override _requestGetTemplatePreviewByIssueType(action: TTemplatePreviewIssue): Observable<(unknown | any)> {
    const apiService: IIssueService = this._linkIssuesService.getApiServiceByIssueContentType(action.issueType);
    return apiService.getIssueTemplatePreviewListById(action.id).pipe(
      map((data) => {
          return LinkIssueActions.getItemsTemplatePreviewSuccess({ data })
        }
      ),
      catchError((error) => [LinkIssueActions.getItemsTemplatePreviewFail({ error })])
    )
  }

  override requestItemsResource(action: any): Observable<any> {
    return this._linkIssuesApiService.requestResourceListByFilter([{}]).pipe(
      map((data: IOrgaResponse<ResourceListDto>) =>
        LinkIssueActions.getItemsResourceSuccess({ data })
      ),
      catchError((error) => [LinkIssueActions.getItemsResourceFail({ error })])
    )
  }


  _requestByIdByIssueType(action: { id: number, issueType: IssueType }): Observable<any> {
    const service: IIssueService = this._linkIssuesService.getApiServiceByIssueType(action.issueType);
    return service.getIssueById(action.id).pipe(
      map((data) =>
        LinkIssueActions.getByIdSuccess({ data, cacheGuid: this._cacheService.generateGuid() })
      ),
      catchError((error) => [LinkIssueActions.getByIdFail({ error })])
    )
  }

  override prepareFormsToSendTemplatePreview(action$: Observable<{
    form: FormGroup,
    templatePreviewDto: TTemplatePreviewDto
  }>): Observable<any> {
    return action$.pipe(
      withLatestFrom(this.store.pipe((select(LinkIssuesSelectors.selectSelectedTemplateIssueType)))),
      withLatestFrom(this.store.pipe(select(LinkLoginSelectors.selectResource))),
      withLatestFrom(this.store.pipe(select(LinkLoginSelectors.selectTenantId))),

      mergeMap(([[[{ form, templatePreviewDto }, templateIssueType], resourceDto], tenantId]) => {
        const service = this._issuesBaseFactory.getServiceByIssueType(templateIssueType);
        const submitReady = service.prepareFormIssueDto(form, templatePreviewDto, resourceDto, tenantId);
        return [LinkIssueActions.prepareFormsToSendTemplatePreviewDone({ submitReady })]
      })
    );
  }

  override sendTemplatePreview(action$: Observable<{
    issuePrepared: TSubmitIssueDto
  }>): Observable<void> {
    return action$.pipe(
      withLatestFrom(this.store.pipe(select(LinkIssuesSelectors.selectSelectedTemplateId))),
      withLatestFrom(this.store.pipe((select(LinkIssuesSelectors.selectSelectedTemplateIssueType)))),
      mergeMap(([[{ issuePrepared }, templateId], issueContentType]) => {
        return this._sendTemplatePreviewByIssueType({
          templateId,
          issueContentType,
          issuePrepared,
        });
      })
    );
  }

  override _sendTemplatePreviewByIssueType(action: {
    templateId: number,
    issuePrepared: TSubmitIssueDto
    issueContentType: IssueContentType
  }): Observable<(unknown | any)> {
    const apiService = this._linkIssuesService.getApiServiceByIssueContentType(action.issueContentType);
    return apiService.sendIssueTemplatePreviewById(action.templateId, action.issuePrepared).pipe(
      map(() => LinkIssueActions.sendTemplatePreviewSuccess({})),
      catchError(error => [LinkIssueActions.sendTemplatePreviewFail({ error })])
    );
  }

  _saveSignatureRequest(tenantId: number, blob: Blob, fileType: string, index: number): Observable<{
    signature: FileDto
  } | any> {
    return this._sharedFileApiService.upload(tenantId, blob, fileType).pipe(
      exhaustMap((data: FileDto) => {
          return [LinkIssueActions.saveSignatureSuccess({
            id: data.fileId!,
            index
          })]
        }
      ),
      catchError((error) => [LinkIssueActions.saveSignatureFail({ error })])
    )
  }

  _savePictureRequest(tenantId: number, blob: Blob, fileType: string, pictureId: number): Observable<{
    image: FileDto
  } | any> {
    return this._sharedFileApiService.upload(tenantId, blob, fileType).pipe(
      exhaustMap((data: FileDto) => {
          return [LinkIssueActions.savePictureSuccess({ fileId: data.fileId!, path: data.path!, pictureId })]
        }
      ),
      catchError((error) => [LinkIssueActions.savePictureFail({ error })])
    )
  }

  override getWinterhalterProductOrderCatalog(action$: Observable<any>): Observable<any> {
    return action$.pipe(
      withLatestFrom(this.store.pipe(select(LinkLoginSelectors.selectResource))),
      mergeMap(([_, resourceProfile]) => {
        return this._requestCatalogProducts({
          issueContentType: IssueContentType.WinterhalterProductOrder,
          resourceId: resourceProfile.resourceId!
        });
      })
    );
  }

  _requestCatalogProducts(action: { issueContentType: IssueContentType, resourceId: number }): Observable<any> {
    const apiService = this._linkIssuesService.getApiServiceByIssueContentType(action.issueContentType) as LinkIssueWinterhalterProductOrderApiService;
    return apiService.getCatalogByResourceId(action.resourceId).pipe(
      map((data: WinterhalterProductCatalogDto) => LinkIssueActions.getWinterhalterProductOrderCatalogSuccess({
        data
      })),
      catchError(error => [LinkIssueActions.getWinterhalterProductOrderCatalogFail({ error })])
    );
  }

}
