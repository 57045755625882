import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { Observable, of } from 'rxjs';
import {
  IssueContentType,
  ResourceProfileIssueTemplateShortCutDto,
} from '@server-models';
import { ActivatedRoute } from '@angular/router';
import {FormArray, FormGroup, FormsModule} from '@angular/forms';
import { BaseIssuesSelectors } from '@shared/components/issues/store/base-issues.selectors-type';
import { BaseIssuesActions } from '@shared/components/issues/store/base-issues.action-type';
import { BaseIssueMessageTemplatePreviewComponent } from '@shared/components/issues/components/base-issue-template-preview/components/base-issue-message-template-preview/base-issue-message-template-preview.component';
import { BaseIssueMissionTemplatePreviewComponent } from '@shared/components/issues/components/base-issue-template-preview/components/base-issue-mission-template-preview/base-issue-mission-template-preview.component';
import { BaseIssueTicketTemplatePreviewComponent } from '@shared/components/issues/components/base-issue-template-preview/components/base-issue-ticket-template-preview/base-issue-ticket-template-preview.component';
import { BaseIssueWinterhalterServiceCallTemplatePreviewComponent } from '@shared/components/issues/components/base-issue-template-preview/components/winterhalter/service-call-template-preview/service-call-template-preview.component';
import { TemplatePreviewDtoType } from '@shared/components/issues/types/template-preview-dto.type';
import { BaseIssueWinterhalterProductOrderTemplatePreviewComponent } from '@shared/components/issues/components/base-issue-template-preview/components/winterhalter/product-order-template-preview/product-order-template-preview.component';

@Component({
  selector: 'app-base-issues-template-preview',
  templateUrl: './base-issues-template-preview.component.html',
  styleUrls: ['./base-issues-template-preview.component.scss'],
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule,
    FormsModule,
    BaseIssueMessageTemplatePreviewComponent,
    BaseIssueMissionTemplatePreviewComponent,
    BaseIssueTicketTemplatePreviewComponent,
    BaseIssueWinterhalterServiceCallTemplatePreviewComponent,
    BaseIssueWinterhalterProductOrderTemplatePreviewComponent,
  ],
  standalone: true,
})
export class BaseIssuesTemplatePreviewComponent implements OnInit {
  protected readonly IssueContentType = IssueContentType;
  isLoading$!: Observable<boolean>;
  shortCutTemplate$: Observable<ResourceProfileIssueTemplateShortCutDto>;
  shortCutTemplateId: number;
  templateId!: string;
  formEmitted: FormGroup;
  isBackButtonEnable: boolean;
  selectedTemplatePreview$: Observable<{
    id: number;
    templateIssueType: IssueContentType;
    data: TemplatePreviewDtoType;
  }>;

  currentIssueContentType: IssueContentType;

  currentTemplatePreview: TemplatePreviewDtoType;

  constructor(private _store: Store, private _activatedRoute: ActivatedRoute) {
    this.formEmitted = new FormGroup<any>({});
    this.isBackButtonEnable = true;
    this.shortCutTemplate$ = of<ResourceProfileIssueTemplateShortCutDto>({});
    this.shortCutTemplateId = NaN;
    this.selectedTemplatePreview$ = of(
      <
        {
          id: number;
          templateIssueType: IssueContentType;
          data: TemplatePreviewDtoType;
        }
      >{}
    );

    this.currentIssueContentType = <IssueContentType>{};

    this.currentTemplatePreview = {};
  }

  ngOnInit() {
    this.isLoading$ = this._store.pipe(
      select(BaseIssuesSelectors.selectIsLoading)
    );
    this.shortCutTemplate$ = this._store.pipe(
      select(BaseIssuesSelectors.selectShortCutTemplate)
    );
    this.shortCutTemplate$.subscribe((shortCut) => {
      this.shortCutTemplateId = shortCut.issueTemplateId!;
    });

    this.selectedTemplatePreview$ = this._store.pipe(
      select(BaseIssuesSelectors.selectSelectedTemplate)
    );
    this.selectedTemplatePreview$.subscribe((templatePreview) => {
      this.currentTemplatePreview = templatePreview.data;
    });
  }

  ionViewWillEnter(): void {
    this.init();
  }

  init() {
    const activatedRouterData =
      this._activatedRoute.snapshot.data['templatePreview'];

    if (activatedRouterData && 'isBackButtonEnable' in activatedRouterData) {
      this.isBackButtonEnable = activatedRouterData?.isBackButtonEnable;
    }
  }

  /**
   * @name onChangeForm
   * @description
   * event controlled on change form event from template preview card component to process the form
   * @memberof BaseIssuesTemplatePreviewComponent
   * @param ev
   */
  onChangeForm(ev: FormArray | FormGroup): void {
    this._processForm(ev, this.formEmitted);
  }

  /**
   * @name _processForm
   * @description
   * check the type of the incoming form and send to the corresponding treatment
   * @memberof BaseIssuesTemplatePreviewComponent
   * @param incForm
   * @param formEmitted
   * @private
   */
  private _processForm(incForm: FormArray | FormGroup, formEmitted: FormGroup | FormArray): void {
    if (incForm instanceof FormGroup) {
      this._processFormGroup(incForm, formEmitted as FormGroup);
    } else if (incForm instanceof FormArray) {
      this._processFormArray(incForm, formEmitted as FormArray);
    }

    // Update validity after modifications
    formEmitted.updateValueAndValidity();
  }

  /**
   * @name _processFormGroup
   * @description
   * it will process any formGroup and check if there is an existing FormGroup | FormArray to process it correctly
   * @memberof BaseIssuesTemplatePreviewComponent
   * @param incForm
   * @param formEmitted
   * @private
   */
  private _processFormGroup(incForm: FormGroup, formEmitted: FormGroup): void {
    Object.keys(incForm.controls).forEach((key) => {
      const control = incForm.get(key);

      if (control instanceof FormGroup) {
        this._addOrProcessNestedGroup(control, formEmitted, key);
      } else if (control instanceof FormArray) {
        this._addOrProcessNestedArray(control, formEmitted, key);
      } else {
        formEmitted.setControl(key, control);
      }
    });
  }

  /**
   * @name _processFormArray
   * @description
   * it will process any FormArray and check if there is an existing FormArray | FormGroup to process it correctly
   * @memberof BaseIssuesTemplatePreviewComponent
   * @param incForm
   * @param formEmitted
   * @private
   */
  private _processFormArray(incForm: FormArray, formEmitted: FormArray): void {
    incForm.controls.forEach((control, index) => {
      let nestedControl = formEmitted.at(index) as FormGroup | FormArray;

      if (control instanceof FormGroup) {
        this._addOrProcessNestedGroup(control, nestedControl, index);
      } else if (control instanceof FormArray) {
        this._addOrProcessNestedArray(control, nestedControl, index);
      } else {
        formEmitted.setControl(index, control);
      }
    });
  }

  /**
   * @name _addOrProcessNestedGroup
   * @description
   * it will add or process depends on it is existing an inherit form or not formGroups
   * @memberof BaseIssuesTemplatePreviewComponent
   * @param control
   * @param formEmitted
   * @param key
   * @private
   */
  private _addOrProcessNestedGroup(control: FormGroup, formEmitted: FormGroup | FormArray, key: string | number): void {
    let nestedGroup = (formEmitted instanceof FormGroup ? formEmitted.get(key as string) : formEmitted.at(key as number)) as FormGroup;

    if (!nestedGroup) {
      nestedGroup = new FormGroup({});
      if (formEmitted instanceof FormGroup) {
        formEmitted.addControl(key as string, nestedGroup);
      } else {
        formEmitted.setControl(key as number, nestedGroup);
      }
    }

    this._processForm(control, nestedGroup);
  }

  /**
   * @name _addOrProcessNestedArray
   * @description
   * it will add or process depends on it is existing an inherit form or not FormArray
   * @memberof BaseIssuesTemplatePreviewComponent
   * @param control
   * @param formEmitted
   * @param key
   * @private
   */
  private _addOrProcessNestedArray(control: FormArray, formEmitted: FormGroup | FormArray, key: string | number): void {
    let nestedArray = (formEmitted instanceof FormGroup ? formEmitted.get(key as string) : formEmitted.at(key as number)) as FormArray;

    if (!nestedArray) {
      nestedArray = new FormArray<any>([]);
      if (formEmitted instanceof FormGroup) {
        formEmitted.addControl(key as string, nestedArray);
      } else {
        formEmitted.setControl(key as number, nestedArray);
      }
    }

    this._processForm(control, nestedArray);
  }


  /**
   * @name submitForm
   * @description
   * submit form to dispatch the action to send template preview with the data filled
   * @memberof BaseIssuesTemplatePreviewComponent
   */
  submitForm() {
    this._store.dispatch(
      BaseIssuesActions.prepareFormsToSendTemplatePreview({
        form: this.formEmitted,
        templatePreviewDto: this.currentTemplatePreview,
      })
    );
  }

  /**
   * @name loadTemplatesPreview
   * @description
   * dispatch the action to request template preview
   * @memberof BaseIssuesTemplatePreviewComponent
   */
  loadTemplatesPreview(id: string): void {
    this._store.dispatch(BaseIssuesActions.getItemsTemplatePreview({ id }));
  }

  /**
   * @name back
   * @description
   * navigate to previous page issues new
   * @memberof BaseIssuesTemplatePreviewComponent
   */
  back(): void {
    if (this.shortCutTemplateId) {
      this._store.dispatch(BaseIssuesActions.navigateToIssues());
    } else {
      this._store.dispatch(BaseIssuesActions.navigateToNewIssue());
    }
  }
}
