/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum DataTableFilterType {
    ByTransfer = '0',
    ByCustomProperty = 1,
    ByGroup = 2,
    Base = -1,
}
