<ng-container [ngSwitch]="stateOption">
  <span *ngSwitchCase="MissionState.Instructed">
          {{ 'COMPONENTS.MISSIONS.STATE.INSTRUCTED' | translate }}
        </span>
  <span *ngSwitchCase="MissionState.Created">
          {{ 'COMPONENTS.MISSIONS.STATE.CREATED' | translate }}
        </span>
  <span *ngSwitchCase="MissionState.Rejected">
          {{ 'COMPONENTS.MISSIONS.STATE.REJECTED' | translate }}
        </span>
  <span *ngSwitchCase="MissionState.Canceled">
          {{ 'COMPONENTS.MISSIONS.STATE.CANCELED' | translate }}
        </span>
  <span *ngSwitchCase="MissionState.Done">
          {{ 'COMPONENTS.MISSIONS.STATE.DONE' | translate }}
        </span>
</ng-container>
