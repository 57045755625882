import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {IssueListDto} from "@server-models";
import {CommonModule} from "@angular/common";
import {IonicModule} from "@ionic/angular";
import {Store} from "@ngrx/store";
import {SharedIssueBaseActions} from "@shared/components/issues/store/shared-issue-base.action-type";
import {CoreClockService} from "@core/services/core-clock.service";
import {SharedCacheService} from "@shared/services/cache/shared-cache.service";

@Component({
  selector: 'app-shared-issue-item',
  templateUrl: './shared-issue-item.component.html',
  styleUrls: ['./shared-issue-item.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule],

})
export class SharedIssueItemComponent implements OnInit {

  @Input() issue: IssueListDto;
  @Output() navigateToIssueEvent: EventEmitter<void>;

  formattedDate: string;

  constructor(
    private _store: Store,
    private _cacheService: SharedCacheService,
    private _clockService: CoreClockService
  ) {
    this.issue = {};
    this.formattedDate = "";
    this.navigateToIssueEvent = new EventEmitter<void>();
  }

  ngOnInit() {
    this.formattedDate = this._clockService.format(this._clockService.parse(this.issue.lastModified!));
  }

  navigateToIssue(issue: IssueListDto): void {
    this.navigateToIssueEvent.emit();

    this._store.dispatch(SharedIssueBaseActions.navigateToDetail({
      issueId: issue.issueId!,
      issueType: issue.issueType!,
      cacheGuid: this._cacheService.generateGuid()
    }))
  }

}
