import {Component, Input, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {TranslateModule} from "@ngx-translate/core";
import {CustomPropertyType} from "@server-models";
import {SharedCustomPropertyReadOnlyInputTextComponent} from "@shared/components/custom-property-read-only/components/input-text/shared-custom-property-read-only-input-text.component";
import {
  SharedCustomPropertyReadOnlyInputNumericComponent
} from "@shared/components/custom-property-read-only/components/input-number/shared-custom-property-read-only-input-numeric.component";
import {
  SharedCustomPropertyReadOnlyInputPictureComponent
} from "@shared/components/custom-property-read-only/components/input-picture/shared-custom-property-read-only-input-picture.component";
import {
  SharedCustomPropertyReadOnlyInputSignatureComponent
} from "@shared/components/custom-property-read-only/components/input-signature/shared-custom-property-read-only-input-signature.component";
import {
  SharedCustomPropertyReadOnlyInputTimeOfDayComponent
} from "@shared/components/custom-property-read-only/components/input-time-of-day/shared-custom-property-read-only-input-time-of-day.component";
import {SharedCustomPropertyReadOnlyInputDateComponent} from "@shared/components/custom-property-read-only/components/input-date/shared-custom-property-read-only-input-date.component";
import {SharedCustomPropertyReadOnlyInputInfoComponent} from "@shared/components/custom-property-read-only/components/input-info/shared-custom-property-read-only-input-info.component";
import {
  SharedCustomPropertyReadOnlyInputCheckboxComponent
} from "@shared/components/custom-property-read-only/components/input-checkbox/shared-custom-property-read-only-input-checkbox.component";
import {
  ICustomPropertyFormatted
} from "@shared/components/custom-property-form/interfaces/custom-property-formatted.interface";

@Component({
  selector: 'app-shared-custom-property-read-only',
  templateUrl: './shared-custom-property-read-only.component.html',
  styleUrls: ['./shared-custom-property-read-only.component.scss'],
  standalone: true,
  imports: [
    IonicModule,
    CommonModule,
    TranslateModule,
    SharedCustomPropertyReadOnlyInputTextComponent,
    SharedCustomPropertyReadOnlyInputNumericComponent,
    SharedCustomPropertyReadOnlyInputPictureComponent,
    SharedCustomPropertyReadOnlyInputSignatureComponent,
    SharedCustomPropertyReadOnlyInputTimeOfDayComponent,
    SharedCustomPropertyReadOnlyInputDateComponent,
    SharedCustomPropertyReadOnlyInputInfoComponent,
    SharedCustomPropertyReadOnlyInputCheckboxComponent,
  ]
})
export class SharedCustomPropertyReadOnlyComponent implements OnInit {

  @Input() inputProperty!: ICustomPropertyFormatted;


  protected readonly CustomPropertyType = CustomPropertyType;

  constructor() {

  }

  ngOnInit() {
  }


}
