import {Component, OnInit} from '@angular/core';
import {CommonModule} from "@angular/common";
import {IonicModule} from "@ionic/angular";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {SafePipe} from "@shared/pipes/safe.pipe";
import {select, Store} from "@ngrx/store";
import {UserHtmlComponent} from "@features/user-html/user-html.component";
import {AttachmentsComponent} from "@shared/components/attachments/attachments.component";
import {Observable} from "rxjs";
import {CustomPropertyReadComponent} from "@shared/components/custom-property-read/custom-property-read.component";
import {IssueFormattedDetailData} from "@shared/components/issues/interfaces/issue-formatted-detail-data.interface";
import {BaseIssuesSelectors} from "@shared/components/issues/store/base-issues.selectors-type";
import {BaseIssuesState} from "@shared/components/issues/store/base-issues.state";
import {ReadInputTextComponent} from "@shared/components/custom-property-read/inputs/text/read-input-text.component";
import {
  IssueCustomTicketReadComponent
} from "@shared/components/issues/components/custom/read/ticket/issue-custom-ticket-read.component";
import {
  IssueCustomPropertySetsReadComponent
} from "@shared/components/issues/components/custom/read/property-sets/issue-custom-property-sets-read.component";

@Component({
  selector: 'app-base-issue-read-ticket',
  templateUrl: './base-issue-read-ticket.component.html',
  styleUrls: ['./base-issue-read-ticket.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, TranslateModule, SafePipe, UserHtmlComponent, AttachmentsComponent, CustomPropertyReadComponent, ReadInputTextComponent, IssueCustomTicketReadComponent, IssueCustomPropertySetsReadComponent],

})
export class BaseIssueReadTicketComponent implements OnInit {
  id: number;
  isLoading$!: Observable<boolean>;
  detailDataFormatted$!: Observable<IssueFormattedDetailData>;

  constructor(
    private _store: Store<BaseIssuesState>,
    private _translate: TranslateService
  ) {
    this.id = NaN;
  }

  ngOnInit() {
    this.isLoading$ = this._store.pipe(select(BaseIssuesSelectors.selectIsLoading));
    this.detailDataFormatted$ = this._store.pipe(select(BaseIssuesSelectors.selectDetailDataFormatted));
  }
}
