import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {
  SubmitWinterhalterProductOrderIssueDto, WinterhalterProductCatalogDto,
  WinterhalterProductOrderIssueDto,
  WinterhalterProductOrderIssuePreviewDto
} from "@server-models";
import {LinkIssuesApiService} from "@link/pages/issues/services/link-issues-api.service";
import {LinkIssuesActions} from "@link/pages/issues/store/link-issues.actions-type";

@Injectable({
  providedIn: 'root',
})
export class LinkIssuesWinterhalterProductOrderApiService extends LinkIssuesApiService {

  override getIssueTemplatePreviewListById(id: number): Observable<WinterhalterProductOrderIssuePreviewDto> {
    this._store.dispatch(LinkIssuesActions.getWinterhalterProductOrderCatalog({}));
    return super.get<WinterhalterProductOrderIssuePreviewDto>(`/tech/v2/issue/winterhalterproductorder/template/${id}/preview`, undefined);
  }

  override getIssueById(issueId: number): Observable<WinterhalterProductOrderIssueDto> {
    return super.get<WinterhalterProductOrderIssueDto>(`/tech/v2/issue/winterhalterproductorder/${issueId}/recent`);
  }

  override sendIssueTemplatePreviewById(templateId: number, issue: SubmitWinterhalterProductOrderIssueDto): Observable<void> {
    return super.post<void>(`/tech/v2/issue/winterhalterproductorder/template/${templateId}/submitIssue`, undefined, issue);
  }

  getCatalogByResourceId(resourceId: number): Observable<WinterhalterProductCatalogDto> {
    return super.get<WinterhalterProductCatalogDto>(`/tech/v2/issue/winterhalterproductorder/resource/${resourceId}/catalog`, undefined);
  }
}
