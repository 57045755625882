import {Component, OnInit} from '@angular/core';
import {IonicModule} from '@ionic/angular';
import {Storage} from "@ionic/storage-angular";
import {Store} from "@ngrx/store";
import {appInit} from "@core/store/core-app.actions";
import {environment as linkEnv} from "@env-link/environment";
import {SharedLanguageService} from "@shared/services/shared-language.service";
import {CoreEnvironmentService} from "@core/services/environment/core-environment.service";

// Init the CoreEnvironmentService with the LINK app environment variables
CoreEnvironmentService.setEnvironment(linkEnv);

@Component({
  selector: 'app-root',
  templateUrl: 'link-app.component.html',
  styleUrls: ['link-app.component.scss'],
  standalone: true,
  imports: [IonicModule]
})
export class LinkAppComponent implements OnInit {


  constructor(
    private _storage: Storage,
    private _store: Store,
    private _languageService: SharedLanguageService,
  ) {
    const environment = CoreEnvironmentService.getEnvironment();
    this._languageService.setDefaultLanguageByEnvironment(environment);
  }

  async ngOnInit() {
    this._store.dispatch(appInit());
    await this._storage.create();
  }
}
