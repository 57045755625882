import {Component, OnInit} from '@angular/core';
import {CommonModule} from "@angular/common";
import {IonicModule} from "@ionic/angular";
import {TranslateModule} from "@ngx-translate/core";
import {select, Store} from "@ngrx/store";
import {Observable} from "rxjs";
import {SharedCustomPropertyReadOnlyComponent} from "@shared/components/custom-property-read-only/shared-custom-property-read-only.component";
import {
  ICustomPropertyStructureFormatted
} from "@shared/components/custom-property-form/interfaces/custom-property-structure-formatted.interface";
import {SharedIssueBaseActions} from "@shared/components/issues/store/shared-issue-base.action-type";
import {SharedIssueBaseSelectors} from "@shared/components/issues/store/shared-issue-base.selectors-type";
import {SharedIssueBaseState} from "@shared/components/issues/store/shared-issue-base.state";
import {IssueType} from "@server-models";
import {map} from 'rxjs/operators';
import {CoreClockService} from "@core/services/core-clock.service";
import {
  SharedIssueReadOnlyWinterhalterServiceCallComponent
} from "@shared/components/issues/pages/read-only/components/winterhalters/service-call/shared-issue-read-only-winterhalter-service-call.component";
import {
  SharedIssueReadOnlyWinterhalterProductOrderComponent
} from "@shared/components/issues/pages/read-only/components/winterhalters/product-order/shared-issue-read-only-winterhalter-product-order.component";
import {
  SharedIssueReadOnlyWinterhalterOrderInfoComponent
} from "@shared/components/issues/pages/read-only/components/winterhalters/order-info/shared-issue-read-only-winterhalter-order-info.component";
import {
  SharedIssueReadOnlyMessageComponent
} from "@shared/components/issues/pages/read-only/components/message/shared-issue-read-only-message.component";
import {
  SharedIssueReadOnlyMissionComponent
} from "@shared/components/issues/pages/read-only/components/mission/shared-issue-read-only-mission.component";
import {
  SharedIssueReadOnlyTicketComponent
} from "@shared/components/issues/pages/read-only/components/ticket/shared-issue-read-only-ticket.component";
import {
  SharedIssueReadOnlyMessageControlComponent
} from "@shared/components/issues/pages/read-only/components/message-control/shared-issue-read-only-message-control.component";

@Component({
  selector: 'app-shared-issue-read-only',
  templateUrl: './shared-issue-read-only.page.html',
  styleUrls: ['./shared-issue-read-only.page.scss'],
  standalone: true,
  imports: [
    IonicModule,
    CommonModule,
    TranslateModule,
    SharedCustomPropertyReadOnlyComponent,
    SharedIssueReadOnlyWinterhalterServiceCallComponent,
    SharedIssueReadOnlyWinterhalterProductOrderComponent,
    SharedIssueReadOnlyWinterhalterOrderInfoComponent,
    SharedIssueReadOnlyMessageComponent,
    SharedIssueReadOnlyMissionComponent,
    SharedIssueReadOnlyTicketComponent,
    SharedIssueReadOnlyMessageControlComponent
  ]

})
export class SharedIssueReadOnlyPage implements OnInit {
  id: number;
  isLoading$!: Observable<boolean>;
  detailDataFormatted$!: Observable<ICustomPropertyStructureFormatted>;
  protected readonly IssueType = IssueType;
  isWinterhalterIssue$!: Observable<boolean>;

  constructor(
    private _store: Store<SharedIssueBaseState>,
    private _clockService: CoreClockService
  ) {
    this.id = NaN;
  }

  ngOnInit() {
    this.isLoading$ = this._store.pipe(select(SharedIssueBaseSelectors.selectIsLoading));
    this.detailDataFormatted$ = this._store.pipe(select(SharedIssueBaseSelectors.selectDetailDataFormatted));
    this.isWinterhalterIssue$ = this.detailDataFormatted$.pipe(map(detailData => this._isWinterhalterIssue(detailData)));
  }

  /**
   * @name back
   * @description
   * navigates to previous page issues
   * @memberof SharedIssueReadOnlyPage
   */
  back(): void {
    this._store.dispatch(SharedIssueBaseActions.navigateToIssues());
  }

  private _isWinterhalterIssue(detailData: ICustomPropertyStructureFormatted): boolean {
    const issueType = detailData.type;
    return issueType === IssueType.WinterhalterProductOrder || issueType === IssueType.WinterhalterServiceCall;
  }

  formatDate(date: string | null | undefined): string {
    if (!date) return '';
    return this._clockService.formatDateWithWeekday(date, false, false);
  }
}
