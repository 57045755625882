import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {
  ControlValueAccessor,
  FormControl,
  FormsModule, NG_VALUE_ACCESSOR,
  ReactiveFormsModule
} from "@angular/forms";
import {TranslateModule} from "@ngx-translate/core";
import {SharedDateHelperService} from "@shared/services/shared-date-helper.service";

@Component({
  selector: 'app-shared-custom-property-form-input-time-of-day',
  templateUrl: './shared-custom-property-form-input-time-of-day.component.html',
  styleUrls: ['./shared-custom-property-form-input-time-of-day.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, FormsModule,
    ReactiveFormsModule, TranslateModule],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: SharedCustomPropertyFormInputTimeOfDayComponent
    }
  ]
})
export class SharedCustomPropertyFormInputTimeOfDayComponent implements OnChanges, ControlValueAccessor {

  @Input() label: string = '';
  @Input() isRequired: boolean;
  @Output() valueChange = new EventEmitter<string>();
  @Input() formControl: FormControl;
  @Input() idTimeOfDay: number;
  @Input() minMinutes!: number;
  @Input() maxMinutes!: number;

  value: string = '';
  currentDate: string;
  min?: string;
  max?: string;

  onChange: any = () => {
  };
  onTouch: any = () => {
  };

  constructor(
    private _dateHelperService: SharedDateHelperService
  ) {
    this.formControl = new FormControl();
    this.currentDate = new Date(0).toISOString();
    this.value = this.currentDate;
    this.isRequired = false;
    this.idTimeOfDay = NaN;
  }

  writeValue(value: any): void {
    if (value !== undefined) {
      this.value = value;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  onIonChange(event: any) {
    if (event.detail.value) {
      this.formControl.setValue(event.detail.value);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['formControl']) {
      this._setValues();
    }
  }

  private _setValues() {
    const value = this.formControl.value as number || 0;
    this.formControl.setValue(this._dateHelperService.convertMinutesToISO(value));

    if (this.minMinutes) {
      this.min = this._dateHelperService.convertMinutesToISO(this.minMinutes);
    }
    if (this.maxMinutes) {
      this.max = this._dateHelperService.convertMinutesToISO(this.maxMinutes);
    }
  }
}
