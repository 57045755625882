import {createAction, props} from "@ngrx/store";
import {IOrgaResponse} from "@shared/interfaces/orga-response.interface";
import {
  CustomValueCompleteDto,
  IssueContentType,
  IssueListDto,
  IssueTemplateListDto,
  IssueType,
  MessageIssuePreviewDto,
  MissionIssuePreviewDto,
  ResourceProfileDto,
  ResourceProfileIssueTemplateShortCutDto,
  StereotypeDto,
  StereotypeListDto,
  TicketIssuePreviewDto, WinterhalterProductOrderIssuePreviewDto,
  WinterhalterServiceCallIssuePreviewDto
} from "@server-models";
import {
  ICustomPropertyStructureFormatted
} from "@shared/components/custom-property-form/interfaces/custom-property-structure-formatted.interface";
import {Photo} from "@capacitor/camera";
import {FormGroup} from "@angular/forms";
import {TTemplatePreviewDto} from "@shared/components/issues/types/template-preview-dto.type";
import {TIssueDto} from "@shared/components/issues/types/issue-dto.type";
import {TSubmitIssueDto} from "@shared/components/issues/types/submit-issue-dto.type";

export const getItemsPaginated = createAction(
  '[Issues-Base] Get Paginated Start',
  props<any>()
);

export const getItemsPaginatedSuccess = createAction(
  '[Issues-Base] Get Paginated Success',
  props<{ data: IOrgaResponse<IssueListDto[]> }>()
);

export const getItemsPaginatedRefresh = createAction(
  '[Issues-Base] Get Paginated Refresh',
  props<{ data: IOrgaResponse<IssueListDto[]> }>()
);

export const getItemsPaginatedCancel = createAction(
  '[Issues-Base] Get Paginated Cancel'
);

export const getItemsPaginatedFail = createAction(
  '[Issues-Base] Get Paginated Fail',
  props<{ error: Error }>()
);

export const getItemsTemplate = createAction(
  '[Issues-Base] Get Template Start',
  props<{ templatePreviewId?: string }>()
);

export const getItemsTemplateSuccess = createAction(
  '[Issues-Base] Get Template Success',
  props<{ data: IOrgaResponse<IssueTemplateListDto[]>; templatePreviewId: string }>()
);

export const getItemsTemplateDone = createAction(
  '[Issues-Base] Get Template Done'
);

export const getItemsTemplateFail = createAction(
  '[Issues-Base] Get Template Fail',
  props<{ error: Error }>()
);

export const getItemsTemplatePreview = createAction(
  '[Issues-Base] Get Template Preview Start',
  props<{ id: string }>()
);

export const templatePreviewByIdMessage = createAction(
  '[Issue-Base Message] Template Preview By Id',
  props<{ id: number, issueType: IssueContentType }>()
);

export const templatePreviewByIdMission = createAction(
  '[Issue-Base Mission] Template Preview By Id',
  props<{ id: number, issueType: IssueContentType }>()
);

export const templatePreviewByIdTicket = createAction(
  '[Issue-Base Ticket] Template Preview By Id',
  props<{ id: number, issueType: IssueContentType }>()
);

export const templatePreviewByIdWinterhalterServiceCall = createAction(
  '[Issue-Base Winterhalter] Template Preview By Id Service Call',
  props<{ id: number, issueType: IssueContentType }>()
);

export const templatePreviewByIdWinterhalterProductOrder = createAction(
  '[Issue-Base Winterhalter] Template Preview By Id Product Order',
  props<{ id: number, issueType: IssueContentType }>()
);

export type TTemplatePreviewIssue =
  | ReturnType<typeof templatePreviewByIdMessage>
  | ReturnType<typeof templatePreviewByIdMission>
  | ReturnType<typeof templatePreviewByIdTicket>
  | ReturnType<typeof templatePreviewByIdWinterhalterServiceCall>
  | ReturnType<typeof templatePreviewByIdWinterhalterProductOrder>;

export const getItemsTemplatePreviewByIssueType = createAction(
  '[Issues-Base] Get Template Preview By Issue Type Start',
  props<{ id: number, issueType: IssueContentType }>()
);


export const getItemsTemplatePreviewSuccess = createAction(
  '[Issues-Base] Get Template Preview Success',
  props<{ data: any, isRefresh?: boolean }>()
);

export const getItemsResource = createAction(
  '[Issues-Base] Get Resource Start',
  props<any>()
);

export const getItemsResourceSuccess = createAction(
  '[Issues-Base] Get Resource Success',
  props<{ data: any, isRefresh?: boolean }>()
);

export const getItemsResourceFail = createAction(
  '[Issues-Base] Get Resource Success',
  props<{ error: any }>()
);

export const getItemsTemplatePreviewDone = createAction(
  '[Issues-Base] Get Template Preview Done',
);

export const getItemsTemplatePreviewFail = createAction(
  '[Issues-Base] Get Template Preview Fail',
  props<{ error: Error }>()
);

export const getById = createAction(
  '[Issues-Base] Get By Id Start',
  props<{ id: number, issueType: IssueType }>()
);

export const getByIdMessage = createAction(
  '[Issue-Base Message] Get By Id Message Start',
  props<{ id: number, issueType: IssueType }>()
);

export const getByIdMission = createAction(
  '[Issue-Base Mission] Get By Id Mission Start',
  props<{ id: number, issueType: IssueType }>()
);

export const getByIdTicket = createAction(
  '[Issue-Base Ticket] Get By Id Ticket Start',
  props<{ id: number, issueType: IssueType }>()
);

export const getByIdControlMessage = createAction(
  '[Issue-Base Control Message] Get By Id Control Message Start',
  props<{ id: number, issueType: IssueType }>()
);

export const getByIdWinterhalterServiceCall = createAction(
  '[Issue-Base Winterhalter] Get By Id Service Call Start',
  props<{ id: number, issueType: IssueType }>()
);

export const getByIdWinterhalterProductOrder = createAction(
  '[Issue-Base Winterhalter] Get By Id Product Order Start',
  props<{ id: number, issueType: IssueType }>()
);

export type TGetIssue =
  | ReturnType<typeof getByIdMessage>
  | ReturnType<typeof getByIdMission>
  | ReturnType<typeof getByIdTicket>
  | ReturnType<typeof getByIdControlMessage>
  | ReturnType<typeof getByIdWinterhalterServiceCall>
  | ReturnType<typeof getByIdWinterhalterProductOrder>;

export const getIssueByIdByIssueType = createAction(
  '[Issues-Base] Get Issue By Id By Issue Type Start',
  props<{ id: number, issueType: IssueType }>()
);

export const getByIdSuccess = createAction(
  '[Issues-Base] Get By Id Success',
  props<{ data: TIssueDto, cacheGuid: string }>()
);

export const getByIdFail = createAction(
  '[Issues-Base] Get By Id Fail',
  props<{ error: Error }>()
);

export const prepareFormsToSendTemplatePreview = createAction(
  '[Issues-Base] Prepare Forms Start',
  props<{ form: FormGroup, templatePreviewDto: TTemplatePreviewDto }>()
);

export const prepareFormsToSendTemplatePreviewDone = createAction(
  '[Issues-Base] Prepare Forms Done',
  props<{ submitReady: TSubmitIssueDto }>()
);

export const sendTemplatePreview = createAction(
  '[Issues-Base] Send Template Preview Start',
  props<{ issuePrepared: TSubmitIssueDto }>()
);

export const sendTemplatePreviewMessage = createAction(
  '[Issues-Base] Send Template Preview Message Start',
  props<{
    templateId: number,
    issueType: IssueContentType,
    customPropertyValues: CustomValueCompleteDto[],
    templatePreviewDto: MessageIssuePreviewDto,
    resourceDto?: ResourceProfileDto
  }>()
);

export const sendTemplatePreviewMission = createAction(
  '[Issues-Base] Send Template Preview Mission Start',
  props<{
    templateId: number,
    issueType: IssueContentType,
    customPropertyValues: CustomValueCompleteDto[],
    templatePreviewDto: MissionIssuePreviewDto,
    resourceDto?: ResourceProfileDto
  }>()
);

export const sendTemplatePreviewTicket = createAction(
  '[Issues-Base] Send Template Preview Ticket Start',
  props<{
    templateId: number,
    issueType: IssueContentType,
    customPropertyValues: CustomValueCompleteDto[],
    templatePreviewDto: TicketIssuePreviewDto,
    resourceDto?: ResourceProfileDto
  }>()
);

export const sendTemplatePreviewWinterhalterServiceCall = createAction(
  '[Issues-Base] Send Template Preview Winterhalter Service Call Start',
  props<{
    templateId: number,
    issueType: IssueContentType,
    customPropertyValues: CustomValueCompleteDto[],
    templatePreviewDto: WinterhalterServiceCallIssuePreviewDto,
    resourceDto?: ResourceProfileDto
  }>()
);

export const sendTemplatePreviewWinterhalterProductOrder = createAction(
  '[Issues-Base] Send Template Preview Winterhalter Product Order Start',
  props<{
    templateId: number,
    issueType: IssueContentType,
    customPropertyValues: CustomValueCompleteDto[],
    templatePreviewDto: WinterhalterProductOrderIssuePreviewDto,
    resourceDto?: ResourceProfileDto
  }>()
);

export type TSendTemplatePreviewByIdByIssue =
  | ReturnType<typeof sendTemplatePreviewMessage>
  | ReturnType<typeof sendTemplatePreviewMission>
  | ReturnType<typeof sendTemplatePreviewTicket>
  | ReturnType<typeof sendTemplatePreviewWinterhalterServiceCall>
  | ReturnType<typeof sendTemplatePreviewWinterhalterProductOrder>;

export const getWinterhalterProductOrderCatalog = createAction(
  '[Issues-Base] Get Product Order Catalog Start',
  props<any>()
);

export const getWinterhalterProductOrderCatalogSuccess = createAction(
  '[Issues-Base] Get Product Order Catalog Sucees',
  props<any>()
);

export const getWinterhalterProductOrderCatalogFail = createAction(
  '[Issues-Base] Get Product Order Catalog Fail',
  props<any>()
);

export const sendTemplatePreviewSuccess = createAction(
  '[Issues-Base] Send Template Preview Success',
  props<any>()
);

export const sendTemplatePreviewFail = createAction(
  '[Issues-Base] Send Template Preview Fail',
  props<{ error: Error }>()
);

export const getStereotypeById = createAction(
  '[Issues-Base] Get Stereotype By Id Start',
  props<{ stereotypeId: number, issueId: number, cacheGuid?: string }>()
);

export const getStereotypeByIdSuccess = createAction(
  '[Issues-Base] Get Stereotype By Id Success',
  props<{ stereotype: StereotypeDto, cacheGuid: string }>()
);

export const getStereotypeByIdFail = createAction(
  '[Issues-Base] Get Stereotype By Id Fail',
  props<{ error: Error }>()
);

export const getStereotypeByEntityType = createAction(
  '[Issues-Base] Get Stereotype By EntityType Start',
  props<{ entityType: any, cacheControl: string }>()
);

export const getStereotypeByEntityTypeSuccess = createAction(
  '[Issues-Base] Get Stereotype By EntityType Success',
  props<{ data: IOrgaResponse<StereotypeListDto> }>()
);

export const getStereotypeByEntityTypeFail = createAction(
  '[Issues-Base] Get Stereotype By EntityType Fail',
  props<{ error: Error }>()
);

export const navigateToDetail = createAction(
  '[Issues-Base] Navigate to issue detail Start',
  props<{ issueId: number, issueType: IssueType, cacheGuid: string; isNavigationRequired?: boolean; }>()
);

export const navigateToTemplatePreview = createAction(
  '[Issues-Base] Navigate to Template Preview Start',
  props<{ templateId: number, templateIssueType: IssueContentType }>()
);

export const formatReadDetailData = createAction(
  '[Issues-Base] Format read detail data Start'
);

export const formatReadDetailDataFinish = createAction(
  '[Issues-Base] Format read detail data Finish',
  props<{ detailDataFormatted: ICustomPropertyStructureFormatted }>()
);

export const askPictureInput = createAction(
  '[Issues-Base] Ask Picture Input',
  props<{ isLoadingId: number, pictureId: number }>()
);

export const askPictureInputSuccess = createAction(
  '[Issues-Base] Ask Picture Input Success',
  props<{ picture: Photo, pictureId: number }>()
);

export const askPictureInputFail = createAction(
  '[Issues-Base] Ask Picture Input Fail',
  props<{ error: Error }>()
);

export const reducePicture = createAction(
  '[Issues-Base] Reduce Picture size',
  props<{ picture: Photo, pictureId: number }>()
);

export const reducePictureSuccess = createAction(
  '[Issues-Base] Reduce Picture size Success',
  props<{ blob: Blob, pictureId: number }>()
);

export const reducePictureError = createAction(
  '[Issues-Base] Reduce Picture size Error',
  props<{ error: Error }>()
);

export const savePicture = createAction(
  '[Issues-Base] Save Picture to tenant',
  props<{ blob: Blob, fileType: string, pictureId: number }>()
);

export const savePictureSuccess = createAction(
  '[Issues-Base] Save Picture to tenant Success',
  props<{ pictureId: number, fileId: number, path: string }>()
);

export const savePictureFail = createAction(
  '[Issues-Base] Save Picture to tenant Fail',
  props<{ error: Error }>()
);

export const savedPictureClear = createAction(
  '[Issues-Base] Saved Picture Clear',
  props<any>()
);

export const convertDateURLToBlob = createAction(
  '[Issues-Base] Convert dateUrl to blob',
  props<{ isLoadingId: number, dataUrl: string }>()
);

export const convertDateURLToBlobSuccess = createAction(
  '[Issues-Base] Convert dateUrl to blob Success',
  props<{ blob: Blob; index: number }>()
);

export const convertDateURLToBlobFail = createAction(
  '[Issues-Base] Convert dateUrl to blob Fail',
  props<{ error: Error }>()
);

export const saveSignature = createAction(
  '[Issues-Base] Save signature',
  props<{ blob: Blob; index: number }>()
);

export const saveSignatureSuccess = createAction(
  '[Issues-Base] Save signature Success',
  props<{ id: number, index: number }>()
);

export const saveSignatureFail = createAction(
  '[Issues-Base] Save signature Fail',
  props<{ error: Error }>()
);

export const savedSignatureClear = createAction(
  '[Issues-Base] Selected Signature Clear',
  props<any>()
);

export const navigateToNewIssue = createAction(
  '[Issues-Base] Navigate to new'
);

export const navigateToNewSpecificTemplate = createAction(
  '[Issues-Base] Navigate to Specific Template',
  props<{ templateShortCut: ResourceProfileIssueTemplateShortCutDto }>()
);

export const navigateToIssues = createAction(
  '[Issues-Base] Navigate to Issues'
);

export const thanksPageNavigation = createAction(
  '[Issues-Base] Thanks Page Navigation Start'
);

export const thanksPageNavigationDone = createAction(
  '[Issues-Base] Thanks Page Navigation Done'
);

export const thanksPageNavigationBack = createAction(
  '[Issues-Base] Thanks Page Navigation Back'
);

export const thanksPageNavigationBackDone = createAction(
  '[Issues-Base] Thanks Page Navigation Back Done'
);



