import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';
import {select, Store,} from "@ngrx/store";
import {filter, Observable} from "rxjs";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {ShowHidePasswordComponent} from "@features/show-hide-password/show-hide-password.component";
import {TranslateModule} from "@ngx-translate/core";
import {AppType} from "@shared/models/AppType";
import {LoginMessageComponent} from "@link/pages/login/components/login-message/login-message.component";
import {LinkLoginActions} from "@link/pages/login/store/link-login.actions-type";
import {LinkLoginSelectors} from "@link/pages/login/store/link-login.selector-type";

@Component({
  selector: 'app-login',
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, FormsModule, ShowHidePasswordComponent, TranslateModule, LoginMessageComponent]
})
export class LinkLoginPage implements OnInit {

  @Output() isPasswordEnabledOut: EventEmitter<any>;
  public isLoading$: Observable<boolean> = this.store.select(LinkLoginSelectors.selectIsLoading);
  public isLogged$: Observable<boolean> = this.store.select(LinkLoginSelectors.selectIsLogged);
  public resourceId$: Observable<number | undefined> = this.store.pipe(select(LinkLoginSelectors.selectLinkId));
  public currentApp$: Observable<AppType> = this.store.select(LinkLoginSelectors.selectCurrentApp).pipe(
    filter(x => x != null)
  );

  public username?: string;
  public password?: string;

  private redirectRequest?: string;
  changeRegisterView: boolean;

  resourceIdStored: number;

  constructor(
    private store: Store,
    private _router: Router,
    private _activatedRoute: ActivatedRoute
  ) {
    this.changeRegisterView = false;
    this.isPasswordEnabledOut = new EventEmitter<any>();
    this.resourceIdStored = NaN;
  }

  ngOnInit() {
    this.resourceId$.subscribe((linkId) => {
      this.resourceIdStored = linkId!;
    })
    this.redirectRequest = this.getRedirectRequest();
    const routeSnapshot = this._activatedRoute.snapshot;

    const args: { resourceId?: number, key?: string, lang?: string } = {
      resourceId: parseInt(routeSnapshot.queryParamMap.get('resourceid') ?? ''),
      key: routeSnapshot.queryParamMap.get('key') ?? undefined,
      lang: routeSnapshot.queryParamMap.get('lang') ?? undefined
    }

    this.currentApp$.subscribe(async (appType) => {
      if (this.redirectRequest !== undefined) {
        await this.startApp(appType);
      }
    });

    this.isLogged$.subscribe((isLogged) => {
      if (isLogged && this.redirectRequest === undefined) {
        this._router.navigate([`link/${this.resourceIdStored}/home`])
      }
    });




    if (!!args?.resourceId && !!args.key) {
      this.store.dispatch(LinkLoginActions.byResource({resourceId: args.resourceId, key: args.key, lang: args.lang}));
    } else {
      this.store.dispatch(LinkLoginActions.init());
    }
  }

  private getRedirectRequest(): string | undefined {
    const queryParams = new URLSearchParams(window.location.search);
    const url = queryParams?.get('redirect');

    return url || undefined;
  };

  private async findRedirectUrl(appType: AppType, request?: string) {
    switch (appType) {
      case AppType.Link:
        return request?.startsWith('/link/') ? request : ('/link/' + this.resourceIdStored);
      default:
        return null;
    }
  }

  private async startApp(action: AppType) {
    const redirect = await this.findRedirectUrl(action, this.redirectRequest);
    const url = new URL(redirect ?? '', window.location.origin);
    const path = url.pathname;

    const queryParams: Params = {};
    url.searchParams.forEach((value, key) => {
      queryParams[key] = value;
    });

    await this._router.navigate([path], {queryParams});
  }
}
