import {Injectable} from "@angular/core";
import {LoadingController, LoadingOptions} from "@ionic/angular";
import {from, Observable} from "rxjs";
import type {SpinnerTypes} from "@ionic/core/dist/types/components/spinner/spinner-configs";

@Injectable({
  providedIn: 'root'
})
export class SharedModalLoaderService {

  modalLoader: any;

  constructor(private loadingCtrl: LoadingController) {
  }

  /**
   * @name present
   * @description
   * observable version of present
   * @memberof SharedModalLoaderService
   * @param message
   * @param spinnerType
   */
  present(message?: string, spinnerType?: SpinnerTypes): Observable<void> {
    return from(this._present(message, spinnerType));
  }

  /**
   * @name dismiss
   * @description
   * observable version of dismiss function
   * @memberof SharedModalLoaderService
   */
  dismiss(): Observable<void> {
    return from(this._dismiss());
  }

  /**
   * @name _create
   * @description
   * create modal loader with default options or with inc options
   * @memberof SharedModalLoaderService
   * @param message
   * @param spinnerType
   * @private
   */
  private async _create(message: string = 'Loading...', spinnerType: SpinnerTypes = "dots"): Promise<void> {
    const opts: LoadingOptions = {
      spinner: spinnerType,
      message,
    };
    this.modalLoader = await this.loadingCtrl.create(opts);
  }

  /**
   * @name _present
   * @description
   * show modal loader
   * @memberof SharedModalLoaderService
   * @param message
   * @param spinnerType
   * @private
   */
  private async _present(message?: string, spinnerType?: SpinnerTypes): Promise<void> {
    if (!this.modalLoader) {
      await this._create(message, spinnerType);
      this.modalLoader.present(message, spinnerType);
    }
  }

  /**
   * @name _dismiss
   * @description
   * close the top modal loader existing when no id send and reset modalLoader property
   * @memberof SharedModalLoaderService
   * @private
   */
  private async _dismiss(): Promise<void> {
    if (this.modalLoader) {
      this.modalLoader.dismiss();
    }
    this.modalLoader = undefined;
  }
}
