<ion-card *ngIf="serviceCall" class="right-padding">
  <ion-card-header>{{ headerTitle }}</ion-card-header>
  <app-read-input-text
    *ngIf="serviceCall.formData.contactFirstName"
    [title]="'COMPONENTS.ISSUES.SERVICES.WINTERHALTER.SERVICE_CALL.FIRST_NAME_LABEL' | translate"
    [value]="serviceCall.formData.contactFirstName">
  </app-read-input-text>
  <app-read-input-text
    *ngIf="serviceCall.formData.contactLastName"
    [title]="'COMPONENTS.ISSUES.SERVICES.WINTERHALTER.SERVICE_CALL.LAST_NAME_LABEL' | translate"
    [value]="serviceCall.formData.contactLastName">
  </app-read-input-text>
  <app-read-input-text
    *ngIf="serviceCall.formData.category"
    [title]="'COMPONENTS.ISSUES.SERVICES.WINTERHALTER.SERVICE_CALL.CATEGORY_LABEL' | translate"
    [value]="serviceCall.formData.category">
  </app-read-input-text>
  <app-read-input-text
    *ngIf="serviceCall.formData.problem"
    [title]="'COMPONENTS.ISSUES.SERVICES.WINTERHALTER.SERVICE_CALL.PROBLEM_LABEL' | translate"
    [value]="serviceCall.formData.problem">
  </app-read-input-text>
  <app-read-input-text
    *ngIf="serviceCall.formData.message"
    [title]="'COMPONENTS.ISSUES.SERVICES.WINTERHALTER.SERVICE_CALL.MESSAGE_LABEL' | translate"
    [value]="serviceCall.formData.message">
  </app-read-input-text>
</ion-card>
