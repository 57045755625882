import {Injectable} from "@angular/core";
import {EffectsBase} from "@core/base.effects";
import {Store} from "@ngrx/store";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {Observable} from "rxjs";
import {IssuesRequestPagination} from "@shared/components/issues/interfaces/issues-request-pagination.interface";
import {OrgaResponse} from "@shared/interfaces/orga-response.interface";
import {
  FileDto,
  IssueListDto,
  IssueTemplateListDto,
  IssueType,
  StereotypeDto,
  StereotypeListDto
} from "@server-models";
import {TypedAction} from "@ngrx/store/src/models";
import {IssueFormattedDetailData} from "@shared/components/issues/interfaces/issue-formatted-detail-data.interface";
import {Photo} from "@capacitor/camera";
import {BaseIssuesActions} from "@shared/components/issues/store/base-issues.action-type";
import {
  GetIssueType,
  TemplatePreviewIssueType
} from "@shared/components/issues/store/base-issues.actions";
import {FormGroup} from "@angular/forms";
import {TemplatePreviewDtoType} from "@shared/components/issues/types/template-preview-dto.type";
import {IssueDtoType} from "@shared/components/issues/types/issue-dto.type";
import {SubmitIssueDtoType} from "@shared/components/issues/types/submit-issue-dto.type";

@Injectable({
  providedIn: 'root'
})
export abstract class BaseIssuesEffectsBase extends EffectsBase {

  protected constructor(
    store: Store,
    rawActions$: Actions
  ) {
    super(store, rawActions$);
  }

  abstract getPaginated(action: Observable<IssuesRequestPagination>): Observable<OrgaResponse<IssueListDto[]> | any>;

  abstract getTemplate(action: Observable<TypedAction<string>>): Observable<OrgaResponse<IssueTemplateListDto[]> | any>;

  abstract getItemsTemplatePreviewByIssueType(action: Observable<TemplatePreviewIssueType>): Observable<(unknown | any)>;

  abstract getItemsTemplatePreviewSuccess(action: Observable<{
    data: unknown,
    needRefresh?: boolean
  }>): Observable<any>;

  abstract getItemsResource(action: Observable<any>): Observable<any>;

  abstract getItemsResourceSuccess(action: Observable<any>): Observable<any>;

  abstract getIssueByIdByIssueType(action: Observable<GetIssueType>): Observable<unknown | any>;

  abstract getByIdSuccess(action: Observable<{
    data: IssueDtoType
  }>): Observable<TypedAction<string>>;

  abstract getStereotypeByEntityType(action: Observable<{
    entityType: string,
    cacheControl: string
  }>): Observable<OrgaResponse<StereotypeListDto> | any>;

  abstract getStereotypeByEntityTypeSuccess(action: Observable<{ data: OrgaResponse<StereotypeListDto> }>): Observable<{
    id: number
  } | any>;

  abstract getStereotypeById(action: Observable<{
    stereotypeId: number,
    issueId: number,
    cacheGuid?: string
  }>): Observable<{
    stereotypeId: number,
    cacheGuid: string
  } | any>;

  abstract getStereotypeByIdSuccess(action: Observable<{
    stereotype: StereotypeDto,
    cacheGuid: string
  }>): Observable<OrgaResponse<StereotypeListDto> | any>;

  abstract prepareFormsToSendTemplatePreview(action: Observable<{ form: FormGroup, templatePreviewDto: TemplatePreviewDtoType }>): Observable<any>;

  abstract prepareFormsToSendTemplatePreviewDone(action: Observable<{
    submitReady: SubmitIssueDtoType
  }>): Observable<any>;

  abstract sendTemplatePreview(action: Observable<{
    issuePrepared: SubmitIssueDtoType
  }>): Observable<any>;

  abstract sendTemplatePreviewSuccess(action: Observable<any>): Observable<any>;

  abstract navigateToDetail(action: Observable<{
    issueId: number,
    issueType: IssueType,
    cacheGuid: string
  }>): Observable<any>;

  abstract formatReadDetailData(action: Observable<TypedAction<string>>): Observable<{
    detailDataFormatted: IssueFormattedDetailData
  } | any>;

  abstract formatReadDetailDataFinish(action: Observable<{
    detailDataFormatted: IssueFormattedDetailData
  }>): Observable<any>;

  abstract askPictureInput(action: Observable<{ isLoadingId: number }>): Observable<{ picture: Photo } | any>;

  abstract askPictureInputSuccess(action: Observable<{ picture: Photo }>): Observable<{ picture: Photo } | any>;

  abstract reducePicture(action: Observable<{ picture: Photo }>): Observable<{ blob: Blob } | any>;

  abstract reducePictureSuccess(action: Observable<{ blob: Blob }>): Observable<{ blob: Blob, fileType: string } | any>;

  abstract savePicture(action: Observable<{ blob: Blob, fileType: string }>): Observable<{ picture: FileDto } | any>;

  abstract convertDateURLToBlob(action: Observable<{ dataUrl: string }>): Observable<{ blob: Blob } | any>;

  abstract convertDateURLToBlobSuccess(action: Observable<{ blob: Blob }>): Observable<{ blob: Blob } | any>;

  abstract saveSignature(action: Observable<{ blob: Blob }>): Observable<{ image: FileDto } | any>;

  abstract navigateToNewIssue(action: Observable<any>): Observable<any>;

  abstract navigateToNewSpecificTemplate(action: Observable<any>): Observable<any>;

  abstract navigateToIssues(action: Observable<any>): Observable<any>;

  abstract navigateToTemplatePreview(action: Observable<any>): Observable<any>;

  abstract thanksPageNavigation(action: Observable<any>): Observable<any>;

  abstract thanksPageNavigationBack(action: Observable<any>): Observable<any>;

  abstract getWinterhalterProductOrderCatalog(action: Observable<any>): Observable<any>;

  navigateToNewIssue$ = createEffect(() => this.actions().pipe(
    ofType(BaseIssuesActions.navigateToNewIssue),
    action => this.navigateToNewIssue(action)
  ), {dispatch: false});

  navigateToNewSpecificTemplate$ = createEffect(() => this.actions().pipe(
    ofType(BaseIssuesActions.navigateToNewSpecificTemplate),
    action => this.navigateToNewSpecificTemplate(action)
  ), {dispatch: false});

  navigateToIssues$ = createEffect(() => this.actions().pipe(
    ofType(BaseIssuesActions.navigateToIssues),
    action => this.navigateToIssues(action)
  ), {dispatch: false});

  navigateToTemplatePreview$ = createEffect(() => this.actions().pipe(
    ofType(BaseIssuesActions.navigateToTemplatePreview),
    action => this.navigateToTemplatePreview(action)
  ), {dispatch: false});

  getPaginated$ = createEffect(() => this.actions().pipe(
    ofType(BaseIssuesActions.getItemsPaginated),
    action => this.getPaginated(action)
  ));

  getTemplate$ = createEffect(() => this.actions().pipe(
    ofType(BaseIssuesActions.getItemsTemplate),
    action => this.getTemplate(action)
  ));

  getItemsTemplatePreviewByIssueType$ = createEffect(() => this.actions().pipe(
    ofType<TemplatePreviewIssueType>(BaseIssuesActions.getItemsTemplatePreviewByIssueType),
    action => this.getItemsTemplatePreviewByIssueType(action)
  ));

  getItemsTemplatePreviewSuccess$ = createEffect(() => this.actions().pipe(
    ofType(BaseIssuesActions.getItemsTemplatePreviewSuccess),
    action => this.getItemsTemplatePreviewSuccess(action)
  ));

  getItemsResource$ = createEffect(() => this.actions().pipe(
    ofType(BaseIssuesActions.getItemsResource),
    action => this.getItemsResource(action)
  ));

  getItemsResourceSuccess$ = createEffect(() => this.actions().pipe(
    ofType(BaseIssuesActions.getItemsResourceSuccess),
    action => this.getItemsResourceSuccess(action)
  ));

  getIssueByIdByIssueType$ = createEffect(() => this.actions().pipe(
    ofType<GetIssueType>(BaseIssuesActions.getIssueByIdByIssueType),
    action => this.getIssueByIdByIssueType(action)
  ));

  getByIdSuccess$ = createEffect(() => this.actions().pipe(
    ofType(BaseIssuesActions.getByIdSuccess),
    action => this.getByIdSuccess(action)
  ));

  prepareFormsToSendTemplatePreview$ = createEffect(() => this.actions().pipe(
    ofType(BaseIssuesActions.prepareFormsToSendTemplatePreview),
    action => this.prepareFormsToSendTemplatePreview(action)
  ));

  prepareFormsToSendTemplatePreviewDone$ = createEffect(() => this.actions().pipe(
    ofType(BaseIssuesActions.prepareFormsToSendTemplatePreviewDone),
    action => this.prepareFormsToSendTemplatePreviewDone(action)
  ));

  sendTemplatePreview$ = createEffect(() => this.actions().pipe(
    ofType(BaseIssuesActions.sendTemplatePreview),
    action => this.sendTemplatePreview(action)
  ));

  sendTemplatePreviewSuccess$ = createEffect(() => this.actions().pipe(
    ofType(BaseIssuesActions.sendTemplatePreviewSuccess),
    action => this.sendTemplatePreviewSuccess(action)
  ));

  getStereotypeById$ = createEffect(() => this.actions().pipe(
    ofType(BaseIssuesActions.getStereotypeById),
    action => this.getStereotypeById(action)
  ));

  getStereotypeByIdSuccess$ = createEffect(() => this.actions().pipe(
    ofType(BaseIssuesActions.getStereotypeByIdSuccess),
    action => this.getStereotypeByIdSuccess(action)
  ));

  getStereotypeByEntityType$ = createEffect(() => this.actions().pipe(
    ofType(BaseIssuesActions.getStereotypeByEntityType),
    action => this.getStereotypeByEntityType(action)
  ));

  getStereotypeByEntityTypeSuccess$ = createEffect(() => this.actions().pipe(
    ofType(BaseIssuesActions.getStereotypeByEntityTypeSuccess),
    action => this.getStereotypeByEntityTypeSuccess(action)
  ));

  navigateToDetail$ = createEffect(() => this.actions().pipe(
    ofType(BaseIssuesActions.navigateToDetail),
    action => this.navigateToDetail(action)
  ));

  formatDetailData$ = createEffect(() => this.actions().pipe(
    ofType(BaseIssuesActions.formatReadDetailData),
    action => this.formatReadDetailData(action)
  ));

  formatReadDetailDataFinish$ = createEffect(() => this.actions().pipe(
    ofType(BaseIssuesActions.formatReadDetailDataFinish),
    action => this.formatReadDetailDataFinish(action)
  ), {dispatch: false});

  askPictureInput$ = createEffect(() => this.actions().pipe(
    ofType(BaseIssuesActions.askPictureInput),
    action => this.askPictureInput(action)
  ));

  askPictureInputSuccess$ = createEffect(() => this.actions().pipe(
    ofType(BaseIssuesActions.askPictureInputSuccess),
    action => this.askPictureInputSuccess(action)
  ));

  reducePicture$ = createEffect(() => this.actions().pipe(
    ofType(BaseIssuesActions.reducePicture),
    action => this.reducePicture(action)
  ));

  reducePictureSuccess$ = createEffect(() => this.actions().pipe(
    ofType(BaseIssuesActions.reducePictureSuccess),
    action => this.reducePictureSuccess(action)
  ));

  savePicture$ = createEffect(() => this.actions().pipe(
    ofType(BaseIssuesActions.savePicture),
    action => this.savePicture(action)
  ));

  convertDateURLToBlob$ = createEffect(() => this.actions().pipe(
    ofType(BaseIssuesActions.convertDateURLToBlob),
    action => this.convertDateURLToBlob(action)
  ));

  convertDateURLToBlobSuccess$ = createEffect(() => this.actions().pipe(
    ofType(BaseIssuesActions.convertDateURLToBlobSuccess),
    action => this.convertDateURLToBlobSuccess(action)
  ));

  saveSignature$ = createEffect(() => this.actions().pipe(
    ofType(BaseIssuesActions.saveSignature),
    action => this.saveSignature(action)
  ));

  thanksPageNavigation$ = createEffect(() => this.actions().pipe(
    ofType(BaseIssuesActions.thanksPageNavigation),
    action => this.thanksPageNavigation(action)
  ));

  thanksPageNavigationBack$ = createEffect(() => this.actions().pipe(
    ofType(BaseIssuesActions.thanksPageNavigationBack),
    action => this.thanksPageNavigationBack(action)
  ));

  getWinterhalterProductOrderCatalog$ = createEffect(() => this.actions().pipe(
    ofType(BaseIssuesActions.getWinterhalterProductOrderCatalog),
    action => this.getWinterhalterProductOrderCatalog(action)
  ));

}
