import {Injectable} from "@angular/core";
import {OrgaResponse} from "@shared/interfaces/orga-response.interface";
import {PaginationParams} from "@shared/interfaces/pagination-params.interface";
import {
  FilterDto,
  IssueListDto,
  IssueTemplateListDto,
  Orders,
  ResourceListDto,
  StereotypeDto,
  StereotypeListDto,
} from "@server-models";
import {Observable} from "rxjs";
import {LinkHttpClientBaseApiService} from "@link/services/link-http-client-base-api/link-http-client-base-api.service";
import {IssueBaseServiceInterface} from "@shared/components/issues/interfaces/issue-base-service.interface";
import {TemplatePreviewDtoType} from "@shared/components/issues/types/template-preview-dto.type";
import {IssueDtoType} from "@shared/components/issues/types/issue-dto.type";
import {SubmitIssueDtoType} from "@shared/components/issues/types/submit-issue-dto.type";

@Injectable({
  providedIn: 'root',
})
export abstract class LinkIssuesApiService extends LinkHttpClientBaseApiService implements IssueBaseServiceInterface {
  abstract getIssueById(issueId: number): Observable<IssueDtoType>;
  abstract getIssueTemplatePreviewListById(id: number): Observable<TemplatePreviewDtoType>;
  abstract sendIssueTemplatePreviewById(templateId: number, issue: SubmitIssueDtoType): Observable<void>;

  getIssueRecentList(params: PaginationParams): Observable<OrgaResponse<IssueListDto[]>> {
    const options = {
      params: {
        PageSize: params.pageSize.toString(),
        PageNumber: params.pageNumber.toString(),
        Cols: params.cols,
        SortField: "createdAt",
        Sort: Orders.Descending.toString()
      }
    }
    return super.get<OrgaResponse<IssueListDto[]>>(`/tech/issue/recent`, undefined, options);
  }

  postIssueRecentFilterList(body: FilterDto[]): Observable<OrgaResponse<IssueListDto[]>> {
    return super.post<OrgaResponse<IssueListDto[]>>(`/tech/issue/recent/filter`, undefined, body)
  }

  getIssueTemplateList(): Observable<OrgaResponse<IssueTemplateListDto[]>> {
    return super.get<OrgaResponse<IssueTemplateListDto[]>>(`/tech/issue/template`, undefined);
  }

  postIssueTemplateFilterList(body: FilterDto[]): Observable<OrgaResponse<IssueTemplateListDto[]>> {
    return super.post<OrgaResponse<IssueTemplateListDto[]>>(`/tech/issue/template/filter`, undefined, body)
  }

  requestResourceListByFilter(filter: FilterDto[]): Observable<OrgaResponse<ResourceListDto>> {
    return super.post<OrgaResponse<ResourceListDto>>(`/tech/resource/all/filter`, undefined, filter)
  }

  /**
   * @name getStereotypeById
   * @description
   * make a request to stereotype by id
   * @memberof LinkIssuesApiService
   * @param id
   * @returns {Observable<StereotypeDto>}
   */
  getStereotypeById(id: number): Observable<StereotypeDto> {
    return super.get<StereotypeDto>(`/tech/stereotype/${id}`, undefined);
  }

  /**
   * @name getStereotypeFilterByEntityType
   * @description
   * make a request to stereotype using entityType and cache-control
   * @memberof LinkIssuesApiService
   * @param entityType
   * @param cacheControl
   * @returns {Observable<OrgaResponse<StereotypeListDto>>}
   */
  getStereotypeFilterByEntityType(entityType: string, cacheControl: string): Observable<OrgaResponse<StereotypeListDto>> {
    const options = {
      params: {
        entityType: entityType.toString(),
        Cols: 'CustomPropertySets',
        'cache-control': cacheControl
      }
    }
    return super.get<OrgaResponse<StereotypeListDto>>(`/tech/stereotype`, undefined, options);
  }
}
