<form [formGroup]="parentForm">
  <ng-container *ngIf="currentTemplatePreview">
    <app-shared-custom-form-property
      (inputChange)="formInnerModified($event)"
      [customPropertySets]="currentTemplatePreview.data.issueStereotype?.customPropertySets!">

    </app-shared-custom-form-property>
  </ng-container>
</form>

