import {
  AfterViewInit, ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input, OnChanges, OnDestroy,
  Output, SimpleChanges,
  ViewChild
} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {
  ControlValueAccessor,
  FormControl,
  FormsModule, NG_VALUE_ACCESSOR,
  ReactiveFormsModule, ValidationErrors, Validators
} from "@angular/forms";
import {TranslateModule} from "@ngx-translate/core";
import SignaturePad, {PointGroup} from 'signature_pad';
import {select, Store} from "@ngrx/store";
import {Observable, of, Subject, take, takeUntil, withLatestFrom} from "rxjs";
import {map} from "rxjs/operators";
import {SharedIssueBaseSelectors} from "@shared/components/issues/store/shared-issue-base.selectors-type";
import {SharedIssueBaseActions} from "@shared/components/issues/store/shared-issue-base.action-type";
import {SharedSafePipe} from "@shared/pipes/shared-safe.pipe";

@Component({
  selector: 'app-shared-custom-property-form-input-signature',
  templateUrl: './shared-custom-property-form-input-signature.component.html',
  styleUrls: ['./shared-custom-property-form-input-signature.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, FormsModule,
    ReactiveFormsModule, TranslateModule, SharedSafePipe],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: SharedCustomPropertyFormInputSignatureComponent
    }
  ]
})
export class SharedCustomPropertyFormInputSignatureComponent implements OnChanges, AfterViewInit, OnDestroy, ControlValueAccessor {

  @Input() label: string = '';
  @Input() isRequired: boolean;
  @Output() valueChange = new EventEmitter<string>();
  @Input() formControl: FormControl;
  @Input() index: number;

  @ViewChild('canvas', { static: false }) signaturePadElement!: ElementRef<HTMLCanvasElement>;
  @ViewChild('canvasContainer', { static: false }) canvasContainer!: ElementRef<HTMLDivElement>;

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.init();
  }

  value: string = '';
  signaturePad: SignaturePad | any;
  signatureLoading$: Observable<boolean>;
  signatureLoadingId$: Observable<number>;

  private _initialWidth: number = 0;
  private _initialHeight: number = 0;
  private destroy$ = new Subject<void>();

  onChange: any = () => {
  };
  onTouch: any = () => {
  };

  constructor(
    private elementRef: ElementRef,
    private _store: Store,
    private _cd: ChangeDetectorRef
  ) {
    this.formControl = new FormControl();
    this.signatureLoading$ = of(false);
    this.signatureLoadingId$ = of(NaN);
    this.index = NaN;
    this.isRequired = false;
  }

  writeValue(value: any): void {
    if (value !== undefined) {
      this.value = value;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['formControl']) {
      this.signatureLoading$ = this._store.pipe(select(SharedIssueBaseSelectors.selectSelectedSignatureIsLoading));
      this.signatureLoadingId$ = this._store.pipe(select(SharedIssueBaseSelectors.selectSelectedSignatureIsLoadingId));
      this._store.pipe(select(SharedIssueBaseSelectors.selectSavedSignature)).subscribe(
        (signature) => {
          if (signature && signature.id) {
            if (this.index == signature.index) {
              this.formControl.setValue(
                {
                  ownValue: signature.id
                }
              )
            }
          }
        });
    }

    this.signatureLoading$.pipe(
      withLatestFrom(this.signatureLoadingId$),
      takeUntil(this.destroy$)
    ).subscribe(([loading, loadingId]) => {
      if (loading && loadingId === this.index) {
        this.formControl.addValidators(this.loadingValidator.bind(this));

        if (this.isRequired) {
          this.formControl.addValidators(Validators.required);
        }
      } else {
        this.formControl.removeValidators(this.loadingValidator.bind(this));
      }
      this.formControl.updateValueAndValidity();
    });
  }

  ngAfterViewInit(): void {
    if (!this.formControl.value.ownValue) {
      this.initSignaturePad();
    }
  }

  /**
   * @name init
   * @description
   * start the canvas and clear the pad on call
   * @memberof SharedCustomPropertyFormInputSignatureComponent
   */
  init() {
    const canvas: HTMLCanvasElement = this.signaturePadElement.nativeElement;

    if (canvas) {
      const reduceMaxWindows = window.innerWidth * 0.04;
      canvas.width = window.innerWidth - reduceMaxWindows;
      canvas.height = 200;

      if (this._initialWidth === 0 && this._initialHeight === 0) {
        this._initialWidth = canvas.width;
        this._initialHeight = canvas.height;
      }

      this.redrawSignature();
    }
  }

  initSignaturePad(): void {
    this.init();
    this.signaturePad = new SignaturePad(this.signaturePadElement.nativeElement);
    this.signaturePad.clear();
    this.signaturePad.penColor = 'rgb(56,128,255)';

    this.signaturePad.addEventListener('endStroke', () => {
      this.save();
    });
  }

  /**
   * @name redrawSignature
   * @description
   * Redraw the existing signature on the canvas after resizing, scaling it to fit the new dimensions.
   * @memberof SharedCustomPropertyFormInputSignatureComponent
   */
  redrawSignature(): void {
    if (this.signaturePad && !this.signaturePad.isEmpty()) {
      const data = this.signaturePad.toData();
      this.signaturePad.clear();

      const oldWidth = this._initialWidth;
      const oldHeight = this._initialHeight;

      const newWidth = this.signaturePadElement.nativeElement.width;
      const newHeight = this.signaturePadElement.nativeElement.height;

      const scaleX = newWidth / oldWidth;
      const scaleY = newHeight / oldHeight;

      const scaledData = data.map((pointGroup: PointGroup) => ({
        ...pointGroup,
        points: pointGroup.points.map((point) => ({
          x: point.x * scaleX,
          y: point.y * scaleY,
          time: point.time,
        })),
      }));

      this.signaturePad.fromData(scaledData);

      this._initialWidth = newWidth;
      this._initialHeight = newHeight;
    }
  }

  /**
   * @name save
   * @description
   * save and dispatch the signature draw from data url to blob
   * @memberof SharedCustomPropertyFormInputSignatureComponent
   */
  save(): void {
    if (this.signaturePad && !this.signaturePad.isEmpty()) {
      const dataUrl = this.signaturePad.toDataURL();

      this._store.dispatch(SharedIssueBaseActions.convertDateURLToBlob({
        isLoadingId: this.index,
        dataUrl
      }));
      this.formControl.markAsDirty();
      this.formControl.markAsTouched();
    } else {
      this.formControl.setValue({
        ownValue: undefined,
        onwFileValue: undefined
      });
    }
  }

  /**
   * @name isCanvasBlank
   * @description
   * check if the canvas is empty
   * @memberof SharedCustomPropertyFormInputSignatureComponent
   */
  isCanvasBlank(): boolean {
    if (this.signaturePad) {
      return this.signaturePad.isEmpty();
    }
    return true;
  }

  /**
   * @name clear
   * @description
   * clear the value of the signature pad
   * @memberof SharedCustomPropertyFormInputSignatureComponent
   */
  clear(): void {
    if (this.signaturePad) {
      this.signaturePad.clear();
      this.formControl.markAsDirty();
      this.formControl.setValue({
        ownValue: undefined,
        onwFileValue: undefined
      });
    }
  }

  clearImage(): void {
    if (this.formControl.value.ownFileValue) {
      this.formControl.setValue({
        ownValue: undefined,
        onwFileValue: undefined
      });
      this._cd.detectChanges();
      this.initSignaturePad();
    }
  }

  /**
   * @name undo
   * @description
   * undo the last draw of the signature pad
   * @memberof SharedCustomPropertyFormInputSignatureComponent
   */
  undo(): void {
    const data: PointGroup[] = this.signaturePad.toData();
    if (data) {
      data.pop();
      this.signaturePad.fromData(data);
      if (data.length === 0) {
        this.formControl.markAsDirty();
        this.formControl.setValue({
          ownValue: undefined,
          onwFileValue: undefined
        });
      }
    }
  }

  /**
   * @name checkLoading
   * @description
   * check if there is a isLoading state with a specific index
   * @memberof SharedCustomPropertyFormInputSignatureComponent
   */
  checkLoading(): Observable<boolean> {
    return this.signatureLoadingId$.pipe(
      withLatestFrom(this.signatureLoading$),
      map(([id, isLoading]) => (this.index! === id) && isLoading)
    );
  }

  loadingValidator(): ValidationErrors | null {
    let isInvalid = false;

    this.signatureLoading$.pipe(
      withLatestFrom(this.signatureLoadingId$),
      take(1)
    ).subscribe(([loading, loadingId]) => {
      isInvalid = loading && loadingId === this.index;
    });

    return isInvalid ? { 'loading': true } : null;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    this._store.dispatch(SharedIssueBaseActions.savedSignatureClear({}));
  }
}
