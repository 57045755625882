import {Component, Input, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {ControlValueAccessor, FormControl, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule} from "@angular/forms";
import {TranslateModule} from "@ngx-translate/core";
import {CustomPropertyCompleteDto, CustomPropertyType} from "@server-models";
import {
  SharedCustomPropertyFormInputTextComponent
} from "@shared/components/custom-property-form/components/input/components/text/shared-custom-property-form-input-text.component";
import {
  SharedCustomPropertyFormInputNumericComponent
} from "@shared/components/custom-property-form/components/input/components/number/shared-custom-property-form-input-numeric.component";
import {
  SharedCustomPropertyFormInputMultiTextComponent
} from "@shared/components/custom-property-form/components/input/components/multi-text/shared-custom-property-form-input-multi-text.component";
import {
  SharedCustomPropertyFormInputEmailComponent
} from "@shared/components/custom-property-form/components/input/components/email/shared-custom-property-form-input-email.component";
import {
  SharedCustomPropertyFormInputPhoneComponent
} from "@shared/components/custom-property-form/components/input/components/phone/shared-custom-property-form-input-phone.component";
import {
  SharedCustomPropertyFormInputTimeOfDayComponent
} from "@shared/components/custom-property-form/components/input/components/time-of-day/shared-custom-property-form-input-time-of-day.component";
import {
  SharedCustomPropertyFormInputDateComponent
} from "@shared/components/custom-property-form/components/input/components/date/shared-custom-property-form-input-date.component";
import {
  SharedCustomPropertyFormInputInfoComponent
} from "@shared/components/custom-property-form/components/input/components/info/shared-custom-property-form-input-info.component";
import {
  SharedCustomPropertyFormInputCheckboxComponent
} from "@shared/components/custom-property-form/components/input/components/checkbox/shared-custom-property-form-input-checkbox.component";
import {
  SharedCustomPropertyFormInputDropdownComponent
} from "@shared/components/custom-property-form/components/input/components/dropdown/shared-custom-property-form-input-dropdown.component";
import {
  SharedCustomPropertyFormInputPictureComponent
} from "@shared/components/custom-property-form/components/input/components/picture/shared-custom-property-form-input-picture.component";
import {
  SharedCustomPropertyFormInputSignatureComponent
} from "@shared/components/custom-property-form/components/input/components/signature/shared-custom-property-form-input-signature.component";

@Component({
  selector: 'app-shared-custom-property-form-input',
  templateUrl: './shared-custom-property-form-input.component.html',
  styleUrls: ['./shared-custom-property-form-input.component.scss'],
  standalone: true,
  imports: [
    IonicModule, CommonModule, FormsModule,
    ReactiveFormsModule, TranslateModule,
    SharedCustomPropertyFormInputTextComponent, SharedCustomPropertyFormInputNumericComponent,
    SharedCustomPropertyFormInputMultiTextComponent, SharedCustomPropertyFormInputEmailComponent,
    SharedCustomPropertyFormInputPhoneComponent, SharedCustomPropertyFormInputTimeOfDayComponent,
    SharedCustomPropertyFormInputDateComponent, SharedCustomPropertyFormInputInfoComponent,
    SharedCustomPropertyFormInputDropdownComponent, SharedCustomPropertyFormInputCheckboxComponent,
    SharedCustomPropertyFormInputPictureComponent, SharedCustomPropertyFormInputSignatureComponent
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: SharedCustomPropertyFormInputComponent
    }
  ]
})
export class SharedCustomPropertyFormInputComponent implements OnInit, ControlValueAccessor {

  @Input() inputProperty!: CustomPropertyCompleteDto;
  @Input() formControl: FormControl;
  @Input() index: number;

  protected readonly CustomPropertyType = CustomPropertyType;

  isDisabled: boolean;
  value: string = '';

  onChange: any = () => {
  };
  onTouch: any = () => {
  };

  constructor() {
    this.formControl = new FormControl();
    this.isDisabled = false;
    this.index = NaN;
  }

  writeValue(value: any): void {
    if (value !== undefined) {
      this.value = value;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled
  }


  ngOnInit() {
  }
}
