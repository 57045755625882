<ion-header [translucent]="true">
  <ion-toolbar color="primary">
    <div class="container">
      <ng-container>
        <ion-buttons>
          <ion-button
            *ngIf="isBackButtonEnable"
            (click)="back()">
            <ion-icon slot="start" name="arrow-back-sharp"></ion-icon>
          </ion-button>
        </ion-buttons>
        <ion-title>
          <div class="ion-text-wrap">
            {{ 'TEAM.ISSUES.PAGES.TEMPLATE_PREVIEW.PAGE_NAME' | translate }}
          </div>
        </ion-title>
      </ng-container>
      <ng-container>
        <ion-buttons>
          <ion-button size="default" (click)="submitForm()" expand="full"
                      [innerText]="'TEAM.ISSUES.PAGES.TEMPLATE_PREVIEW.BUTTON' | translate"
                      [disabled]="this.formEmitted.invalid"></ion-button>
        </ion-buttons>
      </ng-container>
    </div>
  </ion-toolbar>
</ion-header>
<ion-content *ngIf="(selectedTemplatePreview$ | async)?.templateIssueType as templateIssueType">
  <ion-spinner *ngIf="(isLoading$ | async)"></ion-spinner>
  <ng-container *ngIf="!(isLoading$ | async)">
    <ng-container [ngSwitch]="templateIssueType">
      <ng-container *ngSwitchCase="IssueContentType.Message">
        <app-base-issue-message-template-preview
          (formChange)="onChangeForm($event)"
        ></app-base-issue-message-template-preview>
      </ng-container>
      <ng-container *ngSwitchCase="IssueContentType.Mission">
        <app-base-issue-mission-template-preview
          (formChange)="onChangeForm($event)"
        ></app-base-issue-mission-template-preview>
      </ng-container>
      <ng-container *ngSwitchCase="IssueContentType.Ticket">
        <app-base-issue-ticket-template-preview
          (formChange)="onChangeForm($event)"
        ></app-base-issue-ticket-template-preview>
      </ng-container>
      <ng-container *ngSwitchCase="IssueContentType.WinterhalterServiceCall">
        <app-base-issue-winterhalter-service-call-template-preview
          (formChange)="onChangeForm($event)"
        ></app-base-issue-winterhalter-service-call-template-preview>
      </ng-container>
      <ng-container *ngSwitchCase="IssueContentType.WinterhalterProductOrder">
        <app-base-issue-winterhalter-product-order-template-preview
          (formChange)="onChangeForm($event)"
        ></app-base-issue-winterhalter-product-order-template-preview>
      </ng-container>
    </ng-container>
  </ng-container>
</ion-content>
