<ion-header [translucent]="true">
  <ion-toolbar color="primary">
    <div class="container">
      <ion-buttons *ngIf="isBackButtonEnable">
        <ion-button (click)="back()">
          <ion-icon slot="start" name="arrow-back-sharp"></ion-icon>
        </ion-button>
      </ion-buttons>
      <ion-title>{{ 'TEAM.ISSUES.PAGES.TEMPLATE.PAGE_NAME' | translate }}</ion-title>
    </div>
  </ion-toolbar>
</ion-header>
<ion-content>
  <ion-spinner *ngIf="(isLoading$ | async)"></ion-spinner>
  <ion-card *ngIf="!(isLoading$ | async)">
    <ion-card-content class="space-content">
      <ng-container *ngIf="(issuesTemplateList$ | async)!.length > 0 && issueTemplateList.length > 0; else noTemplates">
        <app-base-issue-template-card *ngFor="let template of issueTemplateList"
                                      [template]="template">
        </app-base-issue-template-card>
      </ng-container>
      <ng-template #noTemplates>
        <ion-item>
          <ion-label class="ion-text-center">
            <ion-text>{{ 'TEAM.ISSUES.PAGES.TEMPLATE.EMPTY' | translate }}</ion-text>
          </ion-label>
        </ion-item>
      </ng-template>
    </ion-card-content>
  </ion-card>
</ion-content>
