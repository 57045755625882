<ion-list class="ion-no-padding">
  <ion-item class="ion-no-padding">
    <ion-textarea
      [autoGrow]="true"
      [label]="isRequired ? label + ' *': label"
      labelPlacement="stacked"
      [maxlength]="maxLength!"
      [minlength]="minLength!"
      rows="5"
      [formControl]="formControl">
    </ion-textarea>
  </ion-item>
  <div class="input-error" *ngIf="formControl.errors && (formControl.dirty || formControl.touched)">
    <p *ngIf="formControl.errors['required']">{{ 'TEAM.ISSUES.COMPONENTS.INPUTS.ERRORS.REQUIRED' | translate }}</p>
    <p
      *ngIf="formControl.errors['minlength']"> {{ 'TEAM.ISSUES.COMPONENTS.INPUTS.ERRORS.MIN_LENGTH' | translate }} {{ minLength }}</p>
    <p
      *ngIf="formControl.errors['maxlength']"> {{ 'TEAM.ISSUES.COMPONENTS.INPUTS.ERRORS.MAX_LENGTH' | translate }} {{ maxLength }}</p>
  </div>
</ion-list>
