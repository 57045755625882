import {Injectable} from "@angular/core";
import {SharedIssueBaseService} from "@shared/components/issues/services/shared-issue-base.service";
import {
  IssueType,
  ResourceProfileDto,
  SubmitWinterhalterServiceCallIssueDto,
  WinterhalterServiceCallIssueDto, WinterhalterServiceCallIssuePreviewDto,
} from "@server-models";
import {
  ICustomPropertyStructureFormatted
} from "@shared/components/custom-property-form/interfaces/custom-property-structure-formatted.interface";
import {FormGroup} from "@angular/forms";
import {
  IAdditionalDetails
} from "@shared/components/custom-property-form/interfaces/structure-formatted-additional-details.interface";

@Injectable({
  providedIn: 'root',
})
export class SharedIssueWinterhalterServiceCallService extends SharedIssueBaseService {
  override formatReadDetailData(issueDtoType: WinterhalterServiceCallIssueDto,
                                issueType: IssueType): ICustomPropertyStructureFormatted {
    const baseResult = <ICustomPropertyStructureFormatted>{
      structureId: issueDtoType.issueId,
      headerTitle: issueDtoType.title,
      lastModified: issueDtoType.lastModified,
      type: issueType
    }

    return {
      ...baseResult,
      additionalDetails: { ...this.getAdditionalFormattedData(issueDtoType) }
    };
  }

  override prepareFormIssueDto(form: FormGroup, templatePreviewDto: WinterhalterServiceCallIssuePreviewDto,
                               resourceDto?: ResourceProfileDto,
                               tenantId?: number): SubmitWinterhalterServiceCallIssueDto {
    const formValues = form.value;
    return {
      title: templatePreviewDto.name,
      tenantId,
      problem: formValues.problem,
      category: formValues.category,
      message: formValues.message,
      contactFirstName: formValues.contactFirstName,
      contactLastName: formValues.contactLastName,
      resource: resourceDto
    };
  }

  override getAdditionalFormattedData(issueDto: WinterhalterServiceCallIssueDto): Partial<IAdditionalDetails> {
    return {
      winterhalter: {
        winterhalterOrder: issueDto?.winterhalterOrder,
        serviceCall: {
          resource: issueDto.resource,
          // review if this is a must or not
          orderId: issueDto.winterhalterOrder!?.orderId,
          formData: {
            category: issueDto.category,
            problem: issueDto.problem,
            message: issueDto.message,
            contactFirstName: issueDto.contactFirstName,
            contactLastName: issueDto.contactLastName
          }
        }
      }
    };
  }
}
