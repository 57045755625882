import {AbstractControl, ValidationErrors, ValidatorFn} from "@angular/forms";

export const signaturePictureRequiredValidator = (): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;

    if (value && value.ownValue === undefined && value.ownFileValue === undefined) {
      return { required: true };
    }

    return null;
  };
};
