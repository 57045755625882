import {Injectable} from "@angular/core";
import {Store} from "@ngrx/store";
import {Router, RouterStateSnapshot, UrlTree} from "@angular/router";
import {selectLinkId} from "@link/pages/login/store/link-login.selector";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {EAppType} from "@shared/models/AppType.enum";

@Injectable({
  providedIn: 'root'
})
export class LinkLoginGuard {

  constructor(
    private store: Store,
    private router: Router
  ) {
  }

  public canActivate(appType: EAppType, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.store.select(selectLinkId).pipe(
      map((resourceId) => {
        if (resourceId) {
          return true;
        } else {
          return this.router.createUrlTree(['link/login'], {
            queryParams: {
              redirect: `${ state.url }`
            }
          });
        }
      })
    )
  }

}
