<ion-list class="ion-no-padding">
  <ion-item class="ion-no-padding">
    <ion-grid>
      <ion-row class="ion-align-items-center">
        <ion-col>
          <ion-input
            [label]="isRequired ? label + ' *': label"
            labelPlacement="stacked"
            type="hidden"
          ></ion-input>
        </ion-col>
        <ion-col>
          <ion-datetime-button class="ion-float-right" [datetime]="'datetime' + idDate">
            <ion-icon name="calendar-outline" slot="date-target" *ngIf="getValue() === null"></ion-icon>
          </ion-datetime-button>
        </ion-col>
      </ion-row>
    </ion-grid>

    <ion-modal [keepContentsMounted]="true">
      <ng-template>
        <ion-datetime
          [id]="'datetime' + idDate"
          presentation="date"
          [showDefaultButtons]="true"
          [min]="this.min"
          [max]="this.max"
          [formControl]="formControl"
          [doneText]="('COMPONENTS.INPUTS.DATE.DONE'| translate)"
          [cancelText]="('COMPONENTS.INPUTS.DATE.CANCEL'| translate)"
        ></ion-datetime>
      </ng-template>
    </ion-modal>
  </ion-item>
  <div class="input-error" *ngIf="formControl.errors && (formControl.dirty || formControl.touched)">
    <p *ngIf="formControl.errors['required']" >{{'TEAM.ISSUES.COMPONENTS.INPUTS.ERRORS.REQUIRED' | translate}}</p>
  </div>
</ion-list>




