import {createReducer, on} from "@ngrx/store";
import {LinkState} from "./link.state";
import {LinkActions} from "@link/store/link.actions-type";

export const initialState: LinkState = <LinkState>{};

export const linkStore = createReducer(
  initialState,
  on(LinkActions.getProfileDashboard, (state) => ({
    ...state,
    hint: {
      isLoading: true,
      list: []
    },
    error: {
      isLoading: true,
      list: []
    },
    consumption: {
      isLoading: true,
      list: []
    }
  })),
  on(LinkActions.getProfileDashboardSuccess, (state, { hints, errors, consumptions }) => ({
    ...state,
    hint: {
      isLoading: false,
      list: hints
    },
    error: {
      isLoading: false,
      list: errors
    },
    consumption: {
      isLoading: false,
      list: consumptions
    }
  })),
  on(LinkActions.getKnowledgeArticle, (state, { knowledgeArticleId }) => ({
    ...state,
    knowledge: {
      ...state.knowledge,
      isLoading: true,
      detailId: knowledgeArticleId,
      detail: undefined
    }
  })),
  on(LinkActions.getKnowledge, (state, {}) => ({
    ...state,
    knowledge: {
      ...state.knowledge,
      isLoading: true,
      list: []
    }
  })),
  on(LinkActions.getKnowledgeArticleSuccess, (state, { knowledgeArticle }) => ({
    ...state,
    knowledge: {
      ...state.knowledge,
      isLoading: false,
      detail: knowledgeArticle
    }
  })),
  on(LinkActions.getKnowledgeSuccess, (state, { knowledgeArticles }) => ({
    ...state,
    knowledge: {
      ...state.knowledge,
      isLoading: false,
      list: knowledgeArticles
    }
  })),
);
