import {Injectable} from "@angular/core";
import {Actions} from "@ngrx/effects";
import {select, Store} from "@ngrx/store";
import {exhaustMap, mergeMap, Observable, withLatestFrom} from "rxjs";
import {catchError} from "rxjs/operators";
import {IOrgaResponse} from "@shared/interfaces/orga-response.interface";
import {IPagination} from "@shared/interfaces/pagination.interface";
import {
  AppEntityType,
  FileDto,
  IssueContentType,
  IssueListDto,
  IssueTemplateListDto,
  IssueType,
  StereotypeDto,
  StereotypeListDto
} from "@server-models";
import {Router} from "@angular/router";
import {SharedImageService} from "@shared/services/shared-image.service";
import {SharedCameraService} from "@shared/services/shared-camera.service";
import {SharedLoginBaseSelectors} from "@shared/stores/login-base/store/shared-login-base.selector-type";
import {Photo} from "@capacitor/camera";
import {TypedAction} from "@ngrx/store/src/models";
import {SharedModalLoaderService} from "@shared/services/shared-modal-loader.service";
import {TranslateService} from "@ngx-translate/core";
import {IIssueRequestPagination} from "@shared/components/issues/interfaces/issue-request-pagination.interface";
import {
  ICustomPropertyStructureFormatted
} from "@shared/components/custom-property-form/interfaces/custom-property-structure-formatted.interface";
import {SharedIssueBaseService} from "@shared/components/issues/services/shared-issue-base.service";
import {SharedIssueBaseEffects} from "@shared/components/issues/store/shared-issue-base.effets";
import {SharedIssueBaseActions} from "@shared/components/issues/store/shared-issue-base.action-type";
import {SharedIssueBaseSelectors} from "@shared/components/issues/store/shared-issue-base.selectors-type";
import {
  TGetIssue,
  TTemplatePreviewIssue
} from "@shared/components/issues/store/shared-issue-base.actions";

import {SharedIssueFactory} from "@shared/components/issues/services/shared-issue.factory";
import {FormGroup} from "@angular/forms";
import {TTemplatePreviewDto} from "@shared/components/issues/types/template-preview-dto.type";
import {TIssueDto} from "@shared/components/issues/types/issue-dto.type";
import {TSubmitIssueDto} from "@shared/components/issues/types/submit-issue-dto.type";
import {SharedIssueStereotypeBaseService} from "@shared/components/issues/services/stereotype/shared-issue-stereotype-base.service";
import {SharedCacheService} from "@shared/services/cache/shared-cache.service";

@Injectable({
  providedIn: 'root'
})
export abstract class SharedIssueApiBaseEffects extends SharedIssueBaseEffects {

  protected constructor(
    actions$: Actions,
    store: Store,
    protected _cameraService: SharedCameraService,
    protected _modalLoaderService: SharedModalLoaderService,
    protected _translateService: TranslateService,
    protected _imageService: SharedImageService,
    protected _issuesBaseService: SharedIssueBaseService,
    protected _issueBaseStereotype: SharedIssueStereotypeBaseService,
    protected _issuesBaseFactory: SharedIssueFactory,
    protected _cacheService: SharedCacheService,
    protected _router: Router,
  ) {
    super(store, actions$);
  }

  /**
   * @name _requestGetPaginated
   * @description
   * request to issue service the list of issues
   * @memberof SharedIssueApiBaseEffects
   * @param action
   * @protected
   * @returns {Observable<(IOrgaResponse<IssueListDto[]> | any)>}
   */
  abstract _requestGetPaginated(action: IIssueRequestPagination): Observable<(IOrgaResponse<IssueListDto[]> | any)>;

  /**
   * @name _requestGetTemplate
   * @description
   * request to get list of template
   * @memberof SharedIssueApiBaseEffects
   * @protected
   * @returns {Observable<(IOrgaResponse<IssueTemplateListDto[]> | any)>}
   */
  abstract _requestGetTemplate(templatePreviewId: string): Observable<(IOrgaResponse<IssueTemplateListDto[]> | any)>;

  abstract _requestGetTemplatePreviewByIssueType(action: TTemplatePreviewIssue): Observable<(unknown | any)>;

  abstract _requestByIdByIssueType(action: TGetIssue): Observable<TIssueDto>

  abstract requestItemsResource(action: any): Observable<any>;

  abstract _sendTemplatePreviewByIssueType(action: {
    templateId: number,
    issuePrepared: TSubmitIssueDto,
    issueContentType: IssueContentType
  }): Observable<any>;

  abstract _requestGetStereotypeById(action: { stereotypeId: number, cacheGuid: string }): Observable<{
    stereotypeId: number,
    cacheGuid: string
  } | any>;

  abstract _requestGetStereotypeByEntityType(entityType: AppEntityType,
                                             cacheControl: string): Observable<IOrgaResponse<StereotypeListDto> | any>;

  /**
   * @name _savePictureRequest
   * @description
   * make request throw file api service to upload picture
   * @memberof SharedIssueApiBaseEffects
   * @param tenantId
   * @param blob
   * @param fileType
   * @param pictureId
   * @protected
   */
  abstract _savePictureRequest(tenantId: number, blob: Blob, fileType: string, pictureId: number): Observable<{
    image: FileDto
  } | any>;

  /**
   * @name _saveSignatureRequest
   * @description
   * make request throw file api service to upload signature
   * @memberof SharedIssueApiBaseEffects
   * @param tenantId
   * @param blob
   * @param fileType
   * @param index
   * @protected
   */
  abstract _saveSignatureRequest(tenantId: number, blob: Blob, fileType: string, index: number): Observable<{
    signature: FileDto
  } | any>;

  protected _isLastPagePaginationState(action: IIssueRequestPagination, state: IPagination): boolean {
    return !state || action.params.pageNumber <= state.totalPages
  }

  getPaginated(action$: Observable<IIssueRequestPagination>): Observable<(IOrgaResponse<IssueListDto[]> | any)> {
    return action$.pipe(
      withLatestFrom(this.store.pipe(select(SharedIssueBaseSelectors.selectPagePagination))),
      mergeMap(([action, paginationState]) => {

          if (action.isRefresh) {
            return this._requestGetPaginated(action);
          } else if (this._isLastPagePaginationState(action, paginationState)) {
            return this._requestGetPaginated(action);
          } else {
            return [SharedIssueBaseActions.getItemsPaginatedCancel()];
          }
        }
      )
    );
  }


  getItemsTemplate(action$: Observable<{
    templatePreviewId?: string
  }>): Observable<(IOrgaResponse<IssueTemplateListDto[]> | any)> {
    return action$.pipe(
      mergeMap(({ templatePreviewId }) =>
        this._requestGetTemplate(templatePreviewId!))
    );
  }

  getItemsTemplateSuccess(action$: Observable<any>): Observable<any> {
    return action$.pipe(
      withLatestFrom(this.store.pipe(select(SharedIssueBaseSelectors.selectPageTemplate))),
      mergeMap(([{ templatePreviewId }, templateList]) => {
        if (templatePreviewId) {
          const selectedTemplate = templateList.find(template => template.issueTemplateId == templatePreviewId);
          if (selectedTemplate) {
            return [SharedIssueBaseActions.getItemsTemplatePreviewByIssueType({
              id: selectedTemplate!.issueTemplateId!,
              issueType: selectedTemplate!.contentType!
            })];
          } else {
            return [SharedIssueBaseActions.navigateToIssues()];
          }
        } else {
          return [SharedIssueBaseActions.getItemsTemplateDone()]
        }
      })
    )
  }

  getItemsTemplatePreviewByIssueType(action$: Observable<TTemplatePreviewIssue>): Observable<(unknown | any)> {
    return action$.pipe(
      mergeMap((action) => {
          return this._requestGetTemplatePreviewByIssueType(action)
        }
      )
    );
  }

  getItemsResource(action$: Observable<any>): Observable<any> {
    return action$.pipe(
      mergeMap((action) => {
        return this.requestItemsResource(action)
      })
    )
  }

  getItemsResourceSuccess(action$: Observable<any>): Observable<any> {
    return action$.pipe(
      withLatestFrom(this.store.pipe(select(SharedIssueBaseSelectors.selectSelectedTemplateId))),
      withLatestFrom(this.store.pipe(select(SharedIssueBaseSelectors.selectSelectedTemplateIssueType))),
      exhaustMap(([[_, issueTemplateId], templateIssueContentType]) => {
          return [SharedIssueBaseActions.navigateToTemplatePreview({
            templateId: issueTemplateId!,
            templateIssueType: templateIssueContentType!
          })]
        }
      )
    );
  }

  getItemsTemplatePreviewSuccess(action$: Observable<{
    data: TTemplatePreviewDto
  }>): Observable<(unknown | any)> {
    return action$.pipe(
      exhaustMap((action) => {
          return [SharedIssueBaseActions.getItemsResource({})]
        }
      )
    );
  }

  getById(action$: Observable<TGetIssue>): Observable<(unknown | any)> {
    return action$.pipe(
      mergeMap((action) =>
        this._requestByIdByIssueType(action))
    );
  }

  getByIdSuccess(action$: Observable<{
    data: TIssueDto
  }>): Observable<TypedAction<string>> {
    return action$.pipe(
      mergeMap(({ data }) => {
        if (this._issueBaseStereotype.isIssueDtoStereotypeType(data)) {
          return [SharedIssueBaseActions.getStereotypeById({
            issueId: data.issueId!,
            stereotypeId: data.stereotypeId!
          })]
        } else {
          return [SharedIssueBaseActions.formatReadDetailData()]
        }

      })
    )
  }

  formatReadDetailData(action$: Observable<TypedAction<string>>): Observable<{
    detailDataFormatted: ICustomPropertyStructureFormatted
  }> {
    return action$.pipe(
      withLatestFrom(this.store.pipe(select(SharedIssueBaseSelectors.selectSelectedIssueItem))),
      withLatestFrom(this.store.pipe(select(SharedIssueBaseSelectors.selectSelectedStereotype))),
      withLatestFrom(this.store.pipe(select(SharedIssueBaseSelectors.selectSelectedIssueIssueType))),
      exhaustMap(([[[_, issueDto], stereotypeDto], issueType]) => {
        const service = this._issuesBaseFactory.getServiceByIssueType(issueType);
        const detailDataFormatted = service.formatReadDetailData(issueDto, issueType, stereotypeDto);
        return [SharedIssueBaseActions.formatReadDetailDataFinish({ detailDataFormatted })]
      })
    )
  }

  formatReadDetailDataFinish(action$: Observable<{
    detailDataFormatted: ICustomPropertyStructureFormatted
  }>): Observable<any> {
    return action$;
  }

  prepareFormsToSendTemplatePreview(action$: Observable<{
    form: FormGroup,
    templatePreviewDto: TTemplatePreviewDto
  }>): Observable<any> {
    return action$.pipe(
      withLatestFrom(this.store.pipe((select(SharedIssueBaseSelectors.selectSelectedTemplateIssueType)))),
      mergeMap(([{ form, templatePreviewDto }, templateIssueType]) => {
        const service = this._issuesBaseFactory.getServiceByIssueType(templateIssueType);
        const submitReady = service.prepareFormIssueDto(form, templatePreviewDto);
        return [SharedIssueBaseActions.prepareFormsToSendTemplatePreviewDone({ submitReady })]
      })
    );
  }

  prepareFormsToSendTemplatePreviewDone(action$: Observable<{ submitReady: TSubmitIssueDto }>): Observable<any> {
    return action$.pipe(
      withLatestFrom(this.store.pipe((select(SharedIssueBaseSelectors.selectSelectedTemplateIssueType)))),
      mergeMap(([{ submitReady }, templateIssueType]) => {
        return [SharedIssueBaseActions.sendTemplatePreview({ issuePrepared: submitReady })]
      })
    );
  }

  sendTemplatePreview(action$: Observable<{ issuePrepared: TSubmitIssueDto }>): Observable<void> {
    return action$.pipe(
      withLatestFrom(this.store.pipe(select(SharedIssueBaseSelectors.selectSelectedTemplateId))),
      withLatestFrom(this.store.pipe((select(SharedIssueBaseSelectors.selectSelectedTemplateIssueType)))),
      mergeMap(([[{ issuePrepared }, templateId], issueContentType]) => {
        return this._sendTemplatePreviewByIssueType({
          templateId,
          issuePrepared,
          issueContentType
        });
      })
    );
  }

  sendTemplatePreviewSuccess(action$: Observable<any>): Observable<any> {
    return action$;
  }

  thanksPageNavigation(action$: Observable<any>): Observable<any> {
    return action$.pipe(
      exhaustMap(() => {
        return [SharedIssueBaseActions.thanksPageNavigationDone()];
      })
    );
  }

  thanksPageNavigationBack(action$: Observable<any>): Observable<any> {
    return action$.pipe(
      exhaustMap(() => {
        return [SharedIssueBaseActions.thanksPageNavigationDone()];
      })
    );
  }

  getStereotypeById(action$: Observable<{ stereotypeId: number, issueId: number, cacheGuid: string }>): Observable<{
    stereotypeId: number,
    cacheGuid: string
  } | any> {
    return action$.pipe(
      mergeMap((stereotype) =>
        this._requestGetStereotypeById(stereotype)
      )
    );
  }

  getStereotypeByIdSuccess(action$: Observable<{
    stereotype: StereotypeDto,
    cacheGuid: string
  }>): Observable<IOrgaResponse<StereotypeListDto> | any> {
    return action$.pipe(
      mergeMap((action) => {
        return [SharedIssueBaseActions.getStereotypeByEntityType({
          entityType: action.stereotype.entityType,
          cacheControl: action.cacheGuid
        })]
      })
    );
  }

  getStereotypeByEntityType(action$: Observable<{
    entityType: AppEntityType,
    cacheControl: string
  }>): Observable<IOrgaResponse<StereotypeListDto> | any> {
    return action$.pipe(
      mergeMap((data) => {
          return this._requestGetStereotypeByEntityType(data.entityType, data.cacheControl)

        }
      )
    );
  }

  getStereotypeByEntityTypeSuccess(action$: Observable<{ data: IOrgaResponse<StereotypeListDto> }>): Observable<any> {
    return action$.pipe(
      mergeMap(() => {
        return [SharedIssueBaseActions.formatReadDetailData()]
      })
    );
  }


  navigateToDetail(action$: Observable<{
    issueId: number,
    issueType: IssueType,
    cacheGuid: string
  }>): Observable<(TIssueDto) | any> {
    return action$.pipe(
      exhaustMap(data => {
        return [SharedIssueBaseActions.getIssueByIdByIssueType({ id: data.issueId, issueType: data.issueType })]
      })
    );
  }

  getIssueByIdByIssueType(action$: Observable<any>): Observable<any> {
    return action$.pipe(
      mergeMap((action) => this._requestByIdByIssueType(action))
    );
  }

  askPictureInput(action$: Observable<{ isLoadingId: number, pictureId: number }>): Observable<{
    picture: Photo
  } | any> {
    return action$.pipe(
      exhaustMap((action) => {
        return this._cameraService.getPhoto().pipe(
          mergeMap((photo) => {
            return [SharedIssueBaseActions.askPictureInputSuccess({ picture: photo!, pictureId: action.pictureId })]
          }),
          catchError((error) => [SharedIssueBaseActions.askPictureInputFail({ error })])
        )

      })
    );
  }

  askPictureInputSuccess(action$: Observable<{ picture: Photo, pictureId: number }>): Observable<{
    picture: Photo
  } | any> {
    return action$.pipe(
      exhaustMap((data) => {
        return [SharedIssueBaseActions.reducePicture({ picture: data.picture, pictureId: data.pictureId })]
      })
    )
  }

  reducePicture(action$: Observable<{ picture: Photo, pictureId: number }>): Observable<{ blob: Blob } | any> {
    return action$.pipe(
      mergeMap((data) => {
        return this._imageService.resizeBlobImageObservable(data.picture)!.pipe(
          mergeMap((newData) => {
            return [SharedIssueBaseActions.reducePictureSuccess({ blob: newData, pictureId: data.pictureId })]
          }),
          catchError((error) => [SharedIssueBaseActions.reducePictureError({ error })])
        )
      })
    )
  }

  reducePictureSuccess(action$: Observable<{ blob: Blob, pictureId: number }>): Observable<{
    blob: Blob,
    fileType: string
  }> {
    return action$.pipe(
      exhaustMap((data) => {
        return [SharedIssueBaseActions.savePicture({
          blob: data.blob,
          fileType: data.blob.type,
          pictureId: data.pictureId
        })]
      })
    )
  }

  savePicture(action$: Observable<{ blob: Blob, fileType: string, pictureId: number }>): Observable<{
    picture: FileDto
  } | any> {
    return action$.pipe(
      withLatestFrom(this.store.pipe(select(SharedIssueBaseSelectors.selectSelectedPictureAsBlob))),
      withLatestFrom(this.store.pipe(select(SharedLoginBaseSelectors.selectTenantId))),
      exhaustMap(([[action, selectedBlob], tenantId]) => {
        return this._savePictureRequest(tenantId!, selectedBlob, selectedBlob.type, action.pictureId)
      })
    );
  }

  convertDateURLToBlob(action$: Observable<{ isLoadingId: number; dataUrl: string }>): Observable<any> {
    return action$.pipe(
      mergeMap((data) => {
        return this._imageService.convertDataURLToBlobObservable(data.dataUrl).pipe(
          mergeMap((blob) => {
            return [SharedIssueBaseActions.convertDateURLToBlobSuccess({ blob: blob!, index: data.isLoadingId })]
          }),
          catchError((error) => [SharedIssueBaseActions.convertDateURLToBlobFail({ error })])
        )
      })
    );
  }

  convertDateURLToBlobSuccess(action$: Observable<{ blob: Blob; index: number }>): Observable<any> {
    return action$.pipe(
      mergeMap((data) => [SharedIssueBaseActions.saveSignature({ blob: data.blob, index: data.index })])
    )
  }

  saveSignature(action$: Observable<{ blob: Blob; index: number }>): Observable<{ image: FileDto } | any> {
    return action$.pipe(
      withLatestFrom(this.store.pipe(select(SharedIssueBaseSelectors.selectSelectedSignatureAsBlob))),
      withLatestFrom(this.store.pipe(select(SharedLoginBaseSelectors.selectTenantId))),
      mergeMap(([[action, selectedBlob], tenantId]) => {
        return this._saveSignatureRequest(tenantId!, selectedBlob, selectedBlob.type, action.index)
      })
    );
  }

  navigateToNewIssue(action$: Observable<any>): Observable<any> {
    return action$;
  }

  navigateToIssues(action$: Observable<any>): Observable<any> {
    return action$;
  }

}
