<ng-container [ngSwitch]="inputProperty.type">
  <app-shared-custom-property-form-input-text
    [label]="inputProperty.name!"
    [isRequired]="inputProperty.isRequired!"
    [maxLength]="inputProperty.maxLength!"
    [minLength]="inputProperty.minLength!"
    *ngSwitchCase="CustomPropertyType.Text"
    [formControl]="formControl">
  </app-shared-custom-property-form-input-text>
  <app-shared-custom-property-form-input-multi-text
    [label]="inputProperty.name!"
    [isRequired]="inputProperty.isRequired!"
    [maxLength]="inputProperty.maxLength!"
    [minLength]="inputProperty.minLength!"
    *ngSwitchCase="CustomPropertyType.Multiline"
    [formControl]="formControl">
  </app-shared-custom-property-form-input-multi-text>
  <app-shared-custom-property-form-input-email
    [label]="inputProperty.name!"
    [isRequired]="inputProperty.isRequired!"
    *ngSwitchCase="CustomPropertyType.Email"
    [formControl]="formControl">
  </app-shared-custom-property-form-input-email>
  <app-shared-custom-property-form-input-phone
    [label]="inputProperty.name!"
    [isRequired]="inputProperty.isRequired!"
    *ngSwitchCase="CustomPropertyType.Phone"
    [formControl]="formControl">
  </app-shared-custom-property-form-input-phone>
  <app-shared-custom-property-form-input-numeric
    [label]="inputProperty.name!"
    [isRequired]="inputProperty.isRequired!"
    [minNumber]="inputProperty.minValue!"
    [maxNumber]="inputProperty.maxValue!"
    *ngSwitchCase="CustomPropertyType.Numeric"
    [formControl]="formControl">
  </app-shared-custom-property-form-input-numeric>
  <app-shared-custom-property-form-input-date
    [label]="inputProperty.name!"
    [isRequired]="inputProperty.isRequired!"
    [minDate]="inputProperty.minDate!"
    [maxDate]="inputProperty.maxDate!"
    [idDate]="inputProperty.customPropertyId!"
    *ngSwitchCase="CustomPropertyType.Date"
    [formControl]="formControl">
  </app-shared-custom-property-form-input-date>
  <app-shared-custom-property-form-input-time-of-day
    [label]="inputProperty.name!"
    [isRequired]="inputProperty.isRequired!"
    [minMinutes]="inputProperty.minMinutes!"
    [maxMinutes]="inputProperty.maxMinutes!"
    [idTimeOfDay]="inputProperty.customPropertyId!"
    *ngSwitchCase="CustomPropertyType.TimeOfDay"
    [formControl]="formControl">
  </app-shared-custom-property-form-input-time-of-day>
  <app-shared-custom-property-form-input-info
    [label]="inputProperty.name!"
    [infoData]="{
      kind: inputProperty.infoKind!,
      userContent: inputProperty.content!
    }"
    *ngSwitchCase="CustomPropertyType.Info">
  </app-shared-custom-property-form-input-info>
  <app-shared-custom-property-form-input-dropdown
    [label]="inputProperty.name!"
    [isRequired]="inputProperty.isRequired!"
    [options]="inputProperty.defaultValues!"
    *ngSwitchCase="CustomPropertyType.Dropdown"
    [formControl]="formControl">
  </app-shared-custom-property-form-input-dropdown>
  <app-shared-custom-property-form-input-checkbox
    [label]="inputProperty.name!"
    [isRequired]="inputProperty.isRequired!"
    *ngSwitchCase="CustomPropertyType.Checkbox"
    [formControl]="formControl">
  </app-shared-custom-property-form-input-checkbox>
  <app-shared-custom-property-form-input-picture
    [label]="inputProperty.name!"
    [isRequired]="inputProperty.isRequired!"
    [index]="index"
    [propertyId]="inputProperty.customPropertyId!"
    *ngSwitchCase="CustomPropertyType.Picture"
    [formControl]="formControl">
  </app-shared-custom-property-form-input-picture>
  <app-shared-custom-property-form-input-signature
    [label]="inputProperty.name!"
    [isRequired]="inputProperty.isRequired!"
    [index]="index"
    *ngSwitchCase="CustomPropertyType.Signature"
    [formControl]="formControl">
  </app-shared-custom-property-form-input-signature>
</ng-container>
