import {Injectable} from "@angular/core";
import * as dayjs from 'dayjs';
import * as dayjsUtc from 'dayjs/plugin/utc';
import * as dayjsCustomParseFormat from 'dayjs/plugin/customParseFormat';
import * as dayjsRelativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/de';
import {TranslateService} from "@ngx-translate/core";

@Injectable({
  providedIn: 'root'
})
export class CoreClockService {

  constructor(private _translateService: TranslateService) {
    dayjs.extend(dayjsUtc);
    dayjs.extend(dayjsCustomParseFormat);
    dayjs.extend(dayjsRelativeTime);
    dayjs.locale('de');
    dayjs.locale('en');
  }

  public parse(datetime: string | null) {
    if (!datetime)
      return null;

    if (this._translateService.currentLang == 'de-DE') {
      return dayjs(datetime)
        .locale('de-DE')
        .toDate();
    } else {
      return dayjs(datetime)
        .locale('en-US')
        .toDate();
    }
  }

  public to(at: Date | null) {
    if (!at)
      return null;

    return dayjs().to(at);
  }

  format(date: Date | null, withTime = true) {
    if (withTime) {
      const format = this._translateService.currentLang == 'de-DE' ? 'DD.MM.YYYY HH:mm' : 'DD/MM/YYYY HH:mm';
      return dayjs(date).format(format);
    } else {
      const format = this._translateService.currentLang == 'de-DE' ? 'DD.MM.YYYY' : 'DD/MM/YYYY';
      return dayjs(date).format(format);
    }
  }

  private _isCurrentYear(incYear: Date): boolean {
    return dayjs().year() === dayjs(incYear).year();
  }

  formatDateWithWeekday(date: string, withComma: boolean = true, withEndMonthDot: boolean = false): string {
    const parsedDate = this.parse(date);
    if (!parsedDate) return "";

    let weekDayFormat;
    if (withComma) {
      weekDayFormat = this._translateService.currentLang == 'de-DE' ? 'dd,' : 'ddd';
    } else {
      weekDayFormat = this._translateService.currentLang == 'de-DE' ? 'dd' : 'ddd';
    }

    let formatString = `${ weekDayFormat } DD.MM`;
    if (!this._isCurrentYear(parsedDate)) {
      formatString += this._translateService.currentLang == 'de-DE' ? '.YYYY' : '/YYYY';
    } else if (withEndMonthDot) {
      formatString = `${ weekDayFormat } DD.MM.`
    }

    formatString += ' HH:mm';

    return this.formatCustom(parsedDate, formatString);
  }

  formatCustom(date: Date | null, format: string) {
    return dayjs(date).format(format);
  }
}
