<ng-container *ngIf="(detailDataFormatted$ | async) as detailDataFormatted">
  <ng-container *ngIf="detailDataFormatted.additionalDetails && detailDataFormatted.additionalDetails.winterhalter">
    <ion-card *ngIf="detailDataFormatted.additionalDetails.winterhalter.serviceCall! as serviceCall"
              class="right-padding">
      <app-shared-custom-property-read-only-input-text
        *ngIf="serviceCall.formData.contactFirstName"
        [title]="'COMPONENTS.ISSUES.SERVICES.WINTERHALTER.SERVICE_CALL.FIRST_NAME_LABEL' | translate"
        [value]="serviceCall.formData.contactFirstName">
      </app-shared-custom-property-read-only-input-text>
      <app-shared-custom-property-read-only-input-text
        *ngIf="serviceCall.formData.contactLastName"
        [title]="'COMPONENTS.ISSUES.SERVICES.WINTERHALTER.SERVICE_CALL.LAST_NAME_LABEL' | translate"
        [value]="serviceCall.formData.contactLastName">
      </app-shared-custom-property-read-only-input-text>
      <app-shared-custom-property-read-only-input-text
        *ngIf="serviceCall.formData.category"
        [title]="'COMPONENTS.ISSUES.SERVICES.WINTERHALTER.SERVICE_CALL.CATEGORY_LABEL' | translate"
        [value]="serviceCall.formData.category">
      </app-shared-custom-property-read-only-input-text>
      <app-shared-custom-property-read-only-input-text
        *ngIf="serviceCall.formData.problem"
        [title]="'COMPONENTS.ISSUES.SERVICES.WINTERHALTER.SERVICE_CALL.PROBLEM_LABEL' | translate"
        [value]="serviceCall.formData.problem">
      </app-shared-custom-property-read-only-input-text>
      <app-shared-custom-property-read-only-input-text
        *ngIf="serviceCall.formData.message"
        [title]="'COMPONENTS.ISSUES.SERVICES.WINTERHALTER.SERVICE_CALL.MESSAGE_LABEL' | translate"
        [value]="serviceCall.formData.message">
      </app-shared-custom-property-read-only-input-text>
    </ion-card>
  </ng-container>


</ng-container>
