import {Injectable} from "@angular/core";
import {SharedIssueBaseService} from "@shared/components/issues/services/shared-issue-base.service";
import {
  IssueType,
  ResourceProfileDto,
  SubmitWinterhalterProductDto,
  SubmitWinterhalterProductOrderIssueDto,
  WinterhalterProductOrderIssueDto,
  WinterhalterProductOrderIssuePreviewDto,
} from "@server-models";
import {
  ICustomPropertyStructureFormatted
} from "@shared/components/custom-property-form/interfaces/custom-property-structure-formatted.interface";
import {FormGroup} from "@angular/forms";
import {
  IAdditionalDetails
} from "@shared/components/custom-property-form/interfaces/structure-formatted-additional-details.interface";

@Injectable({
  providedIn: 'root',
})
export class SharedIssueWinterhalterProductOrderService extends SharedIssueBaseService {
  override formatReadDetailData(issueDtoType: WinterhalterProductOrderIssueDto,
                                issueType: IssueType): ICustomPropertyStructureFormatted {
    const baseResult = <ICustomPropertyStructureFormatted>{
      structureId: issueDtoType.issueId,
      headerTitle: issueDtoType.title,
      lastModified: issueDtoType.lastModified,
      type: issueType
    }

    return {
      ...baseResult,
      additionalDetails: { ...this.getAdditionalFormattedData(issueDtoType) }
    };
  }

  override prepareFormIssueDto(form: FormGroup, templatePreviewDto: WinterhalterProductOrderIssuePreviewDto,
                               resourceDto?: ResourceProfileDto,
                               tenantId?: number): SubmitWinterhalterProductOrderIssueDto {
    const formValues = form.value;
    const submitWinterhalterProductDtoList = (Object.values(formValues) as SubmitWinterhalterProductDto[]);
    const combinedProducts = submitWinterhalterProductDtoList.map(({ productId, quantity }) => ({
      productId,
      quantity
    }));
    return {
      title: templatePreviewDto.name,
      tenantId,
      products: combinedProducts,
      resource: resourceDto
    };
  }

  override getAdditionalFormattedData(issueDto: WinterhalterProductOrderIssueDto): Partial<IAdditionalDetails> {
    return {
      winterhalter: {
        winterhalterOrder: issueDto?.winterhalterOrder,
        productOrder: {
          resource: issueDto.resource,
          // review if this is a must or not
          orderId: issueDto.winterhalterOrder!?.orderId,
          formData: {
            productList: issueDto.products!
          }
        }
      }
    };
  }
}
