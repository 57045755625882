import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {
  FormBuilder, FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import {InputTextComponent} from '@shared/components/custom-property/inputs/text/input-text.component';
import {
  WinterhalterProductCatalogDto,
  WinterhalterProductDetailDto,
  WinterhalterProductOrderIssuePreviewDto,
} from '@server-models';
import {InputMultiTextComponent} from '@shared/components/custom-property/inputs/multi-text/input-multi-text.component';
import {InputEmailComponent} from '@shared/components/custom-property/inputs/email/input-email.component';
import {InputDropdownComponent} from '@shared/components/custom-property/inputs/dropdown/input-dropdown.component';
import {ReadInputTextComponent} from '@shared/components/custom-property-read/inputs/text/read-input-text.component';
import {ViewerImageComponent} from '@shared/components/viewers/viewer-image/viewer-image.component';
import {ModalControllerService} from '@shared/services/modal-controller.service';
import {FileService} from '@shared/services/file.service';
import { ViewerUrlSrc } from '@shared/components/viewers/interfaces/viewer-src.interface';

@Component({
  selector: 'app-issue-custom-form-winterhalter-product-order',
  templateUrl: './issue-custom-winterhalter-product-order-form.component.html',
  styleUrls: ['./issue-custom-winterhalter-product-order-form.component.scss'],
  standalone: true,
  imports: [
    IonicModule,
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    InputTextComponent,
    InputMultiTextComponent,
    InputEmailComponent,
    InputDropdownComponent,
    ReadInputTextComponent,
  ],
})
export class IssueCustomWinterhalterProductOrderFormComponent implements OnInit {
  @Input() productOrderData: WinterhalterProductOrderIssuePreviewDto;
  @Input() catalogData: WinterhalterProductCatalogDto;
  @Output() inputChange = new EventEmitter<FormGroup>();

  productOrderForm: FormGroup;
  innerFormGroup: FormGroup;
  products: any;

  constructor(
    private _modalControllerService: ModalControllerService,
    private _fileService: FileService,
    private _translate: TranslateService,
    private _fb: FormBuilder
  ) {
    this.productOrderData = {};
    this.catalogData = {};
    this.products = [];

    this.productOrderForm = this._fb.group({});
    this.innerFormGroup = this._fb.group({
      productId: [''],
      quantity: [''],
      containerSize: [''],
      productCode: [''],
      urlSafetyFile: [''],
      urlImage: [''],
    });
  }

  ngOnInit() {
    this.products = this.catalogData?.products || [];

    if (this.products) {
      this.products.forEach(
        (product: WinterhalterProductDetailDto, index: number) => {
          const productKey = `product_${index}`;
          this.productOrderForm.addControl(
            productKey,
            this.createProductForm(product)
          );
        }
      );
      this.formModified();
    }
  }


  getControl(productKey: string, controlName: string): FormControl {
    return this.productOrderForm.get(productKey)?.get(controlName) as FormControl;
  }

  getControlValue(productKey: string, controlName: string): string {
    return this.getControl(productKey, controlName)?.value!;
  }

  getFormGroupKeys(): string[] {
    return Object.keys(this.productOrderForm.controls);
  }

  /**
   * @name createProductForm
   * @description
   * create a formGroup base of inc product
   * @memberof IssueCustomWinterhalterProductOrderFormComponent
   * @param product
   */
  createProductForm(product: WinterhalterProductDetailDto): FormGroup {
    return this._fb.group({
      productId: [product.productId, Validators.required],
      quantity: [0],
      title: [product.title],
      containerSize: [product.containerSize],
      productCode: [product.productCode],
      urlSafetyFile: [product.urlSafetyFile],
      urlImage: [product.urlImage],
    });
  }

  openImage(url: string, name?: string) {
    this._modalControllerService.showModal(
      ViewerImageComponent,
      'modal-fullscreen',
      {
        file: {url} as ViewerUrlSrc,
        name,
      }
    );
  }

  quantitySelected(productKey: string, ev: CustomEvent ) {
    this.getControl(productKey, 'quantity').setValue(ev.detail.value);
    this.formModified();
  }

  getQuantitySelectOptions(productId: string): { label: string; value: number }[] {
    const unit = this._translate.instant('COMPONENTS.ISSUES.SERVICES.WINTERHALTER.PRODUCT_ORDER.FORM.SELECT.QUANTITY.PIECES');
    const product = this.catalogData.products?.find(
      (product) => product.productId == +productId
    );

    const options = [{value: 0, label: `${0} ${unit}`}];
    for (
      let i = product?.minimumQuantity ?? 1;
      i <= (product?.maxQuantity ?? 5);
      i = i + (product?.salesQuantity ?? 1)
    ) {
      options.push({value: i, label: `${i} ${unit}`});
    }

    return options;
  }

  formModified(): void {
    this.inputChange.emit(this.productOrderForm);
  }
}
