import {Injectable} from "@angular/core";
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {EMPTY, mergeMap, Observable, take, throwError} from "rxjs";
import {TranslateService} from "@ngx-translate/core";
import {Clients, TokenDto} from "@server-models";
import {select, Store} from "@ngrx/store";
import {catchError} from "rxjs/operators";
import {Router} from "@angular/router";
import {CoreStorageBaseService} from "@core/storage/core-storage-base.service";
import {Storage} from "@ionic/storage-angular";
import {AlertBaseControllerService} from "@shared/services/shared-alert-controller.service";
import {TokenBaseHelperService} from "@shared/services/token-base-helper.service";
import {SharedLoginBaseSelectors} from "@shared/stores/login-base/store/shared-login-base.selector-type";
import {SharedLoginBaseActions} from "@shared/stores/login-base/store/shared-login-base.actions-type";
import {CoreErrorHandlerBaseService} from "@core/services/error-handler-base/core-error-handler-base.service";
import {IRequestOptions} from "@shared/interfaces/request-options.interface";
import {EAppType} from "@shared/models/AppType.enum";
import {environment as teamEnv} from "@env-team/environment";
import {environment as techEnv} from "@env-tech/environment";
import {environment as linkEnv} from "@env-link/environment";

@Injectable({
  providedIn: 'root'
})
export abstract class CoreHttpClientApiBaseService extends CoreStorageBaseService<any> {

  // one works as proxy, identity app /identity, orga app /orga, tech app /tech
  protected _apiUrlOne: string = "";
  private _selectToken$: Observable<TokenDto | null | undefined>;
  protected _errorHandler: CoreErrorHandlerBaseService;
  protected key: string = 'login';
  protected clientType: Clients = Clients.Team;

  // private _selectLoading$: Observable<boolean>;

  protected constructor(
    public http: HttpClient,
    protected _router: Router,
    protected _store: Store,
    protected _alertControllerService: AlertBaseControllerService,
    protected _translateService: TranslateService,
    private _tokenHelperService: TokenBaseHelperService,
    storage: Storage
  ) {
    super(storage);
    this._selectToken$ = this._store.pipe(select(SharedLoginBaseSelectors.selectToken));
    this._errorHandler = new CoreErrorHandlerBaseService(this._alertControllerService, this._translateService, this._router);
    // this._selectLoading$ = this._store.pipe(select(selectIsLoading));
  }

  /**
   * @name get
   * @memberof CoreHttpClientApiBaseService
   * @description
   * public method to handle a simple get
   * @param endpoint
   * @param useAuth
   * @param options
   * @return {Observable<T>}
   */
  get<T>(endpoint: string, useAuth: boolean = true, options?: IRequestOptions): Observable<T> {
    const headers: HttpHeaders = this._getHeaders(options?.headers);
    const params: HttpParams = this._getParams(options?.params);
    const errorHandler: CoreErrorHandlerBaseService = options?.errorHandler || this._errorHandler;
    return this._handleRequest<T>(useAuth, (innerHeaders, innerParams) =>
      this._simpleGet<T>(
        endpoint,
        this._overrideDefaultHeaders(headers, innerHeaders),
        this._mergeParams(params, innerParams)
      ).pipe(
        catchError((error) => throwError(() => errorHandler?.handlerErrors(error)))
      )
    );
  }

  /**
   * @name _simpleGet
   * @memberof CoreHttpClientApiBaseService
   * @description
   * return a httpclient get
   * @param endpoint
   * @param headers
   * @param params
   * @private
   * @return {Observable<T>}
   */
  private _simpleGet<T>(endpoint: string, headers?: HttpHeaders, params?: HttpParams): Observable<T> {
    return this.http.get<T>(`${ this._apiUrlOne }${ endpoint }`, {
      headers,
      params
    });
  }

  /**
   * @name post
   * @memberof CoreHttpClientApiBaseService
   * @description
   * public method to handle a simple post
   * @param endpoint
   * @param useAuth
   * @param body
   * @param options
   * @return {Observable<T>}
   */
  post<T>(endpoint: string, useAuth: boolean = true, body?: any, options?: IRequestOptions): Observable<T> {
    const headers: HttpHeaders = this._getHeaders(options?.headers);
    const params: HttpParams = this._getParams(options?.params);
    const errorHandler: CoreErrorHandlerBaseService = options?.errorHandler || this._errorHandler;
    return this._handleRequest<T>(useAuth, (innerHeaders, innerParams) =>
      this._simplePost<T>(
        endpoint,
        body,
        this._overrideDefaultHeaders(headers, innerHeaders),
        this._mergeParams(params, innerParams)
      ).pipe(
        catchError((error) => throwError(() => errorHandler?.handlerErrors(error)))
      )
    );
  }

  /**
   * @name _simplePost
   * @memberof CoreHttpClientApiBaseService
   * @description
   * return a httpclient post
   * @param endpoint
   * @param body
   * @param headers
   * @param params
   * @private
   * @return {Observable<T>}
   */
  private _simplePost<T>(endpoint: string, body?: any, headers?: HttpHeaders, params?: HttpParams): Observable<T> {
    return this.http.post<T>(`${ this._apiUrlOne }${ endpoint }`, body, {
      headers,
      params
    });
  }

  /**
   * @name put
   * @memberof CoreHttpClientApiBaseService
   * @description
   * public method to handle a simple put
   * @param endpoint
   * @param useAuth
   * @param body
   * @param options
   * @return {Observable<T>}
   */
  put<T>(endpoint: string, useAuth: boolean = true, body?: any, options?: IRequestOptions): Observable<T> {
    const headers: HttpHeaders = this._getHeaders(options?.headers);
    const params: HttpParams = this._getParams(options?.params);
    const errorHandler: CoreErrorHandlerBaseService = options?.errorHandler || this._errorHandler;
    return this._handleRequest<T>(useAuth, (innerHeaders, innerParams) =>
      this._simplePut<T>(
        endpoint,
        body,
        this._overrideDefaultHeaders(headers, innerHeaders),
        this._mergeParams(params, innerParams)
      ).pipe(
        catchError((error) => throwError(() => errorHandler?.handlerErrors(error)))
      )
    );
  }

  /**
   * @name _simplePut
   * @memberof CoreHttpClientApiBaseService
   * @description
   * return a httpclient put
   * @param endpoint
   * @param body
   * @param headers
   * @param params
   * @private
   * @return {Observable<T>}
   */
  private _simplePut<T>(endpoint: string, body?: any, headers?: HttpHeaders, params?: HttpParams): Observable<T> {
    return this.http.put<T>(`${ this._apiUrlOne }${ endpoint }`, body, {
      headers,
      params
    });

  }

  /**
   * @name patch
   * @memberof CoreHttpClientApiBaseService
   * @description
   * public method to handle a simple patch
   * @param endpoint
   * @param useAuth
   * @param body
   * @param options
   * @return {Observable<T>}
   */
  patch<T>(endpoint: string, useAuth: boolean = true, body?: any, options?: IRequestOptions): Observable<T> {
    const headers: HttpHeaders = this._getHeaders(options?.headers);
    const params: HttpParams = this._getParams(options?.params);
    const errorHandler: CoreErrorHandlerBaseService = options?.errorHandler || this._errorHandler;
    return this._handleRequest<T>(useAuth, (innerHeaders, innerParams) =>
      this._simplePatch<T>(
        endpoint,
        body,
        this._overrideDefaultHeaders(headers, innerHeaders),
        this._mergeParams(params, innerParams)
      ).pipe(
        catchError((error) => throwError(() => errorHandler?.handlerErrors(error)))
      )
    );
  }

  /**
   * @name _simplePatch
   * @memberof CoreHttpClientApiBaseService
   * @description
   * return a httpclient patch
   * @param endpoint
   * @param body
   * @param headers
   * @param params
   * @private
   * @return {Observable<T>}
   */
  private _simplePatch<T>(endpoint: string, body?: any, headers?: HttpHeaders, params?: HttpParams): Observable<T> {
    return this.http.patch<T>(`${ this._apiUrlOne }${ endpoint }`, body, {
      headers,
      params
    });

  }

  /**
   * @name delete
   * @memberof CoreHttpClientApiBaseService
   * @description
   * public method to handle a simple delete
   * @param endpoint
   * @param useAuth
   * @param options
   * @return {Observable<T>}
   */
  delete<T>(endpoint: string, useAuth: boolean = true, options?: IRequestOptions): Observable<T> {
    const headers: HttpHeaders = this._getHeaders(options?.headers);
    const params: HttpParams = this._getParams(options?.params);
    const errorHandler: CoreErrorHandlerBaseService = options?.errorHandler || this._errorHandler;
    return this._handleRequest<T>(useAuth, (innerHeaders, innerParams) =>
      this._simpleDelete<T>(
        endpoint,
        this._overrideDefaultHeaders(headers, innerHeaders),
        this._mergeParams(params, innerParams)
      ).pipe(
        catchError((error) => throwError(() => errorHandler?.handlerErrors(error)))
      )
    );
  }

  /**
   * @name _simpleDelete
   * @memberof CoreHttpClientApiBaseService
   * @description
   * return a httpclient delete
   * @param endpoint
   * @param headers
   * @param params
   * @private
   * @return {Observable<T>}
   */
  private _simpleDelete<T>(endpoint: string, headers: HttpHeaders, params: HttpParams): Observable<T> {
    return this.http.delete<T>(`${ this._apiUrlOne }${ endpoint }`, {
      headers,
      params
    });
  }

  /**
   * @name _handleRequest
   * @memberof CoreHttpClientApiBaseService
   * @description
   * Handle all request to check if is need use authorization, if is the case check if the token is expired
   * in case that the token is expired it will hold the current request and dispatch a isRefresh token, once the steps
   * are clear it will include the auth header and retry once the previous request
   * @param useAuth
   * @param requestFn
   * @private
   * @return Observable<T>
   */
  private _handleRequest<T>(useAuth: boolean,
                            requestFn: (headers: HttpHeaders, params: HttpParams) => Observable<T>): Observable<T> {
    let headers: HttpHeaders = this._getHeaders();
    let params: HttpParams = this._getParams();

    return this._store.select(SharedLoginBaseSelectors.selectCurrentApp).pipe(
      mergeMap((appType) => {
        this._initCoreService(appType);

        if (useAuth) {
          return this._selectToken$.pipe(
            mergeMap((tokenDto) => {
              let existingToken = tokenDto!.token;
              const isExpired = this._tokenHelperService.isTokenExpiredBoth(
                tokenDto!.token
              );

              if (isExpired || existingToken === undefined) {
                this._store.dispatch(SharedLoginBaseActions.loginRefresh({ clientType: this.clientType }));
                return EMPTY;
              }
              headers = this._getAuthHeader(headers, existingToken!);

              return requestFn(headers, params);
            }),
            take(1)
          );
        } else {
          return requestFn(headers, params);
        }
      })
    );
  }

  /**
   * @name _initCoreService
   * @memberof CoreHttpClientApiBaseService
   * @description
   * Check the existing route path to know what app it is and init the client type and the environment
   * @param appType
   * @private
   */
  private _initCoreService(appType?: EAppType): void {
    // Dynamically inits itself
    // Makes core service independent
    switch (appType) {
      case EAppType.Link:
        this.clientType = Clients.Link;
        this._apiUrlOne = linkEnv.apiUrl.one;
        // add value to defaultPageSize
        break;
      case EAppType.Tech:
        this.clientType = Clients.Tech;
        this._apiUrlOne = techEnv.apiUrl.one;
        break;
      case EAppType.Team:
        this.clientType = Clients.Team;
        this._apiUrlOne = teamEnv.apiUrl.one;
        break;
      default:
        this.clientType = Clients.Team;
        this._apiUrlOne = teamEnv.apiUrl.one;
    }
  }

  /**
   * @name _getAcceptLanguageHeader
   * @memberof CoreHttpClientApiBaseService
   * @description
   * get the accept-language from the translation service currentLang
   * @private
   */
  private _getAcceptLanguageHeader(headers: HttpHeaders): HttpHeaders {
    return headers.set('Accept-Language', this._translateService.currentLang);
  }

  /**
   * @name _getAuthHeader
   * @memberof CoreHttpClientApiBaseService
   * @description
   * get the Authorization header
   * @param headers
   * @param token
   * @private
   */
  private _getAuthHeader(headers: HttpHeaders, token: string | null): HttpHeaders {
    return headers.set('Authorization', `Bearer ${ token }`);
  }

  /**
   * @name _getHeaders
   * @memberof CoreHttpClientApiBaseService
   * @description
   * get all headers given and mix with the current existence
   * @param headers
   */
  _getHeaders(headers?: { [key: string]: string }): HttpHeaders {
    let newHeaders: HttpHeaders = new HttpHeaders();

    newHeaders = this._getAcceptLanguageHeader(newHeaders);

    if (headers) {
      for (const key in headers) {
        if (headers.hasOwnProperty(key)) {
          newHeaders = newHeaders.set(key, headers[key]);
        }
      }
    }

    return newHeaders;
  }

  /**
   * @name _getParams
   * @memberof CoreHttpClientApiBaseService
   * @description
   * get all params given and mix with the current existence
   * @param params
   * @private
   */
  private _getParams(params?: { [key: string]: string }): HttpParams {
    let newParams: HttpParams = new HttpParams();

    // Set additional parameters
    if (params) {
      newParams = Object.keys(params).reduce(
        (acc, key) => acc.set(key, params[key]),
        newParams
      );
    }

    return newParams;
  }

  /**
   * @name _overrideDefaultHeaders
   * @memberof CoreHttpClientApiBaseService
   * @description
   * Override default headers
   * @param headersA
   * @param headersB
   * @private
   */
  private _overrideDefaultHeaders(headersA: HttpHeaders, headersB: HttpHeaders): HttpHeaders {
    let mergedHeaders = headersB;

    if (headersA) {
      headersA.keys().forEach(headerName => {
        const headerValueA = headersA.getAll(headerName);
        if (headerValueA) {
          mergedHeaders = mergedHeaders.set(headerName, headerValueA);
        }
      });
    }
    return mergedHeaders;
  }

  /**
   * @name _mergeParams
   * @memberof CoreHttpClientApiBaseService
   * @description
   * Merge params to return one HttpParams
   * @param paramsA
   * @param paramsB
   * @private
   */
  private _mergeParams(paramsA: HttpParams, paramsB: HttpParams): HttpParams {
    let mergedParams = paramsA;

    paramsB.keys().forEach(paramName => {
      const paramValueB = paramsB.getAll(paramName);
      if (paramValueB) {
        const paramValueA = mergedParams.getAll(paramName);
        if (paramValueA) {
          const mergedValue = [...paramValueA, ...paramValueB].join(',');
          mergedParams = mergedParams.set(paramName, mergedValue);
        } else {
          mergedParams = mergedParams.set(paramName, paramValueB.join(','));
        }
      }
    });

    return mergedParams;
  }

}
