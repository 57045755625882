<form [formGroup]="parentForm">
  <ng-container *ngIf="currentTemplatePreview">
    <app-shared-issue-custom-form-priority
      *ngIf="currentTemplatePreview.data.priorities?.length! > 1"
      (inputChange)="formInnerModified($event)"
      [priorityList]="currentTemplatePreview.data.priorities!">
    </app-shared-issue-custom-form-priority>
    <app-shared-custom-form-property
      (inputChange)="formInnerModified($event)"
      [customPropertySets]="currentTemplatePreview.data.issueStereotype?.customPropertySets!">
    </app-shared-custom-form-property>
  </ng-container>
</form>
