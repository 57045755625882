import {Injectable} from "@angular/core";
import {CoreBaseEffects} from "@core/core-base.effects";
import {Store} from "@ngrx/store";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {Observable} from "rxjs";
import {IIssueRequestPagination} from "@shared/components/issues/interfaces/issue-request-pagination.interface";
import {IOrgaResponse} from "@shared/interfaces/orga-response.interface";
import {
  AppEntityType,
  FileDto,
  IssueListDto,
  IssueTemplateListDto,
  IssueType,
  StereotypeDto,
  StereotypeListDto
} from "@server-models";
import {TypedAction} from "@ngrx/store/src/models";
import {
  ICustomPropertyStructureFormatted
} from "@shared/components/custom-property-form/interfaces/custom-property-structure-formatted.interface";
import {Photo} from "@capacitor/camera";
import {SharedIssueBaseActions} from "@shared/components/issues/store/shared-issue-base.action-type";
import {
  TGetIssue,
  TTemplatePreviewIssue
} from "@shared/components/issues/store/shared-issue-base.actions";
import {FormGroup} from "@angular/forms";
import {TTemplatePreviewDto} from "@shared/components/issues/types/template-preview-dto.type";
import {TIssueDto} from "@shared/components/issues/types/issue-dto.type";
import {TSubmitIssueDto} from "@shared/components/issues/types/submit-issue-dto.type";

@Injectable({
  providedIn: 'root'
})
export abstract class SharedIssueBaseEffects extends CoreBaseEffects {

  protected constructor(
    store: Store,
    rawActions$: Actions
  ) {
    super(store, rawActions$);
  }

  abstract getPaginated(action$: Observable<IIssueRequestPagination>): Observable<IOrgaResponse<IssueListDto[]> | any>;

  abstract getItemsTemplate(action$: Observable<{
    templatePreviewId?: string
  }>): Observable<IOrgaResponse<IssueTemplateListDto[]> | any>;

  abstract getItemsTemplateSuccess(action$: Observable<{
    templatePreviewId?: string
  }>): Observable<IOrgaResponse<IssueTemplateListDto[]> | any>;

  abstract getItemsTemplatePreviewByIssueType(action$: Observable<TTemplatePreviewIssue>): Observable<(unknown | any)>;

  abstract getItemsTemplatePreviewSuccess(action$: Observable<{
    data: unknown,
    isRefresh?: boolean
  }>): Observable<any>;

  abstract getItemsResource(action$: Observable<any>): Observable<any>;

  abstract getItemsResourceSuccess(action$: Observable<any>): Observable<any>;

  abstract getIssueByIdByIssueType(action$: Observable<TGetIssue>): Observable<unknown | any>;

  abstract getByIdSuccess(action$: Observable<{
    data: TIssueDto
  }>): Observable<TypedAction<string>>;

  abstract getStereotypeByEntityType(action$: Observable<{
    entityType: AppEntityType,
    cacheControl: string
  }>): Observable<IOrgaResponse<StereotypeListDto> | any>;

  abstract getStereotypeByEntityTypeSuccess(action$: Observable<{
    data: IOrgaResponse<StereotypeListDto>
  }>): Observable<{
    id: number
  } | any>;

  abstract getStereotypeById(action$: Observable<{
    stereotypeId: number,
    issueId: number,
    cacheGuid?: string
  }>): Observable<{
    stereotypeId: number,
    cacheGuid: string
  } | any>;

  abstract getStereotypeByIdSuccess(action$: Observable<{
    stereotype: StereotypeDto,
    cacheGuid: string
  }>): Observable<IOrgaResponse<StereotypeListDto> | any>;

  abstract prepareFormsToSendTemplatePreview(action$: Observable<{
    form: FormGroup,
    templatePreviewDto: TTemplatePreviewDto
  }>): Observable<any>;

  abstract prepareFormsToSendTemplatePreviewDone(action$: Observable<{
    submitReady: TSubmitIssueDto
  }>): Observable<any>;

  abstract sendTemplatePreview(action$: Observable<{
    issuePrepared: TSubmitIssueDto
  }>): Observable<any>;

  abstract sendTemplatePreviewSuccess(action$: Observable<any>): Observable<any>;

  abstract navigateToDetail(action$: Observable<{
    issueId: number,
    issueType: IssueType,
    cacheGuid: string
  }>): Observable<any>;

  abstract formatReadDetailData(action$: Observable<TypedAction<string>>): Observable<{
    detailDataFormatted: ICustomPropertyStructureFormatted
  } | any>;

  abstract formatReadDetailDataFinish(action$: Observable<{
    detailDataFormatted: ICustomPropertyStructureFormatted
  }>): Observable<any>;

  abstract askPictureInput(action$: Observable<{ isLoadingId: number }>): Observable<{ picture: Photo } | any>;

  abstract askPictureInputSuccess(action$: Observable<{ picture: Photo }>): Observable<{ picture: Photo } | any>;

  abstract reducePicture(action$: Observable<{ picture: Photo }>): Observable<{ blob: Blob } | any>;

  abstract reducePictureSuccess(action$: Observable<{ blob: Blob }>): Observable<{
    blob: Blob,
    fileType: string
  } | any>;

  abstract savePicture(action$: Observable<{ blob: Blob; fileType: string }>): Observable<{ picture: FileDto } | any>;

  abstract convertDateURLToBlob(action$: Observable<{ isLoadingId: number; dataUrl: string }>): Observable<{
    blob: Blob
  } | any>;

  abstract convertDateURLToBlobSuccess(action$: Observable<{ blob: Blob; index: number }>): Observable<{
    blob: Blob
  } | any>;

  abstract saveSignature(action$: Observable<{ blob: Blob; index: number }>): Observable<{ image: FileDto } | any>;

  abstract navigateToNewIssue(action$: Observable<any>): Observable<any>;

  abstract navigateToNewSpecificTemplate(action$: Observable<any>): Observable<any>;

  abstract navigateToIssues(action$: Observable<any>): Observable<any>;

  abstract navigateToTemplatePreview(action$: Observable<any>): Observable<any>;

  abstract thanksPageNavigation(action$: Observable<any>): Observable<any>;

  abstract thanksPageNavigationBack(action$: Observable<any>): Observable<any>;

  abstract getWinterhalterProductOrderCatalog(action$: Observable<any>): Observable<any>;

  navigateToNewIssue$ = createEffect(() => this.actions().pipe(
    ofType(SharedIssueBaseActions.navigateToNewIssue),
    action => this.navigateToNewIssue(action)
  ), { dispatch: false });

  navigateToNewSpecificTemplate$ = createEffect(() => this.actions().pipe(
    ofType(SharedIssueBaseActions.navigateToNewSpecificTemplate),
    action => this.navigateToNewSpecificTemplate(action)
  ), { dispatch: false });

  navigateToIssues$ = createEffect(() => this.actions().pipe(
    ofType(SharedIssueBaseActions.navigateToIssues),
    action => this.navigateToIssues(action)
  ), { dispatch: false });

  navigateToTemplatePreview$ = createEffect(() => this.actions().pipe(
    ofType(SharedIssueBaseActions.navigateToTemplatePreview),
    action => this.navigateToTemplatePreview(action)
  ), { dispatch: false });

  getPaginated$ = createEffect(() => this.actions().pipe(
    ofType(SharedIssueBaseActions.getItemsPaginated),
    action => this.getPaginated(action)
  ));

  getItemsTemplate$ = createEffect(() => this.actions().pipe(
    ofType(SharedIssueBaseActions.getItemsTemplate),
    action => this.getItemsTemplate(action)
  ));

  getItemsTemplateSuccess$ = createEffect(() => this.actions().pipe(
    ofType(SharedIssueBaseActions.getItemsTemplateSuccess),
    action => this.getItemsTemplateSuccess(action)
  ));

  getItemsTemplatePreviewByIssueType$ = createEffect(() => this.actions().pipe(
    ofType<TTemplatePreviewIssue>(SharedIssueBaseActions.getItemsTemplatePreviewByIssueType),
    action => this.getItemsTemplatePreviewByIssueType(action)
  ));

  getItemsTemplatePreviewSuccess$ = createEffect(() => this.actions().pipe(
    ofType(SharedIssueBaseActions.getItemsTemplatePreviewSuccess),
    action => this.getItemsTemplatePreviewSuccess(action)
  ));

  getItemsResource$ = createEffect(() => this.actions().pipe(
    ofType(SharedIssueBaseActions.getItemsResource),
    action => this.getItemsResource(action)
  ));

  getItemsResourceSuccess$ = createEffect(() => this.actions().pipe(
    ofType(SharedIssueBaseActions.getItemsResourceSuccess),
    action => this.getItemsResourceSuccess(action)
  ));

  getIssueByIdByIssueType$ = createEffect(() => this.actions().pipe(
    ofType<TGetIssue>(SharedIssueBaseActions.getIssueByIdByIssueType),
    action => this.getIssueByIdByIssueType(action)
  ));

  getByIdSuccess$ = createEffect(() => this.actions().pipe(
    ofType(SharedIssueBaseActions.getByIdSuccess),
    action => this.getByIdSuccess(action)
  ));

  prepareFormsToSendTemplatePreview$ = createEffect(() => this.actions().pipe(
    ofType(SharedIssueBaseActions.prepareFormsToSendTemplatePreview),
    action => this.prepareFormsToSendTemplatePreview(action)
  ));

  prepareFormsToSendTemplatePreviewDone$ = createEffect(() => this.actions().pipe(
    ofType(SharedIssueBaseActions.prepareFormsToSendTemplatePreviewDone),
    action => this.prepareFormsToSendTemplatePreviewDone(action)
  ));

  sendTemplatePreview$ = createEffect(() => this.actions().pipe(
    ofType(SharedIssueBaseActions.sendTemplatePreview),
    action => this.sendTemplatePreview(action)
  ));

  sendTemplatePreviewSuccess$ = createEffect(() => this.actions().pipe(
    ofType(SharedIssueBaseActions.sendTemplatePreviewSuccess),
    action => this.sendTemplatePreviewSuccess(action)
  ));

  getStereotypeById$ = createEffect(() => this.actions().pipe(
    ofType(SharedIssueBaseActions.getStereotypeById),
    action => this.getStereotypeById(action)
  ));

  getStereotypeByIdSuccess$ = createEffect(() => this.actions().pipe(
    ofType(SharedIssueBaseActions.getStereotypeByIdSuccess),
    action => this.getStereotypeByIdSuccess(action)
  ));

  getStereotypeByEntityType$ = createEffect(() => this.actions().pipe(
    ofType(SharedIssueBaseActions.getStereotypeByEntityType),
    action => this.getStereotypeByEntityType(action)
  ));

  getStereotypeByEntityTypeSuccess$ = createEffect(() => this.actions().pipe(
    ofType(SharedIssueBaseActions.getStereotypeByEntityTypeSuccess),
    action => this.getStereotypeByEntityTypeSuccess(action)
  ));

  navigateToDetail$ = createEffect(() => this.actions().pipe(
    ofType(SharedIssueBaseActions.navigateToDetail),
    action => this.navigateToDetail(action)
  ));

  formatDetailData$ = createEffect(() => this.actions().pipe(
    ofType(SharedIssueBaseActions.formatReadDetailData),
    action => this.formatReadDetailData(action)
  ));

  formatReadDetailDataFinish$ = createEffect(() => this.actions().pipe(
    ofType(SharedIssueBaseActions.formatReadDetailDataFinish),
    action => this.formatReadDetailDataFinish(action)
  ), { dispatch: false });

  askPictureInput$ = createEffect(() => this.actions().pipe(
    ofType(SharedIssueBaseActions.askPictureInput),
    action => this.askPictureInput(action)
  ));

  askPictureInputSuccess$ = createEffect(() => this.actions().pipe(
    ofType(SharedIssueBaseActions.askPictureInputSuccess),
    action => this.askPictureInputSuccess(action)
  ));

  reducePicture$ = createEffect(() => this.actions().pipe(
    ofType(SharedIssueBaseActions.reducePicture),
    action => this.reducePicture(action)
  ));

  reducePictureSuccess$ = createEffect(() => this.actions().pipe(
    ofType(SharedIssueBaseActions.reducePictureSuccess),
    action => this.reducePictureSuccess(action)
  ));

  savePicture$ = createEffect(() => this.actions().pipe(
    ofType(SharedIssueBaseActions.savePicture),
    action => this.savePicture(action)
  ));

  convertDateURLToBlob$ = createEffect(() => this.actions().pipe(
    ofType(SharedIssueBaseActions.convertDateURLToBlob),
    action => this.convertDateURLToBlob(action)
  ));

  convertDateURLToBlobSuccess$ = createEffect(() => this.actions().pipe(
    ofType(SharedIssueBaseActions.convertDateURLToBlobSuccess),
    action => this.convertDateURLToBlobSuccess(action)
  ));

  saveSignature$ = createEffect(() => this.actions().pipe(
    ofType(SharedIssueBaseActions.saveSignature),
    action => this.saveSignature(action)
  ));

  thanksPageNavigation$ = createEffect(() => this.actions().pipe(
    ofType(SharedIssueBaseActions.thanksPageNavigation),
    action => this.thanksPageNavigation(action)
  ));

  thanksPageNavigationBack$ = createEffect(() => this.actions().pipe(
    ofType(SharedIssueBaseActions.thanksPageNavigationBack),
    action => this.thanksPageNavigationBack(action)
  ));

  getWinterhalterProductOrderCatalog$ = createEffect(() => this.actions().pipe(
    ofType(SharedIssueBaseActions.getWinterhalterProductOrderCatalog),
    action => this.getWinterhalterProductOrderCatalog(action)
  ));

}
