<form [formGroup]="parentForm">
  <ng-container *ngIf="currentTemplatePreview">
    <app-issue-custom-form-priority
      (inputChange)="formInnerModified($event)"
      [priorityList]="currentTemplatePreview.data.priorities!">
    </app-issue-custom-form-priority>
    <app-issue-custom-form-property
      (inputChange)="formInnerModified($event)"
      [customPropertySets]="currentTemplatePreview.data.issueStereotype?.customPropertySets!">
    </app-issue-custom-form-property>
  </ng-container>
</form>
