import {createFeatureSelector, createSelector} from "@ngrx/store";
import {SharedLoginBaseState} from "./shared-login-base.state";

export const sharedLoginBaseSelect = createFeatureSelector<SharedLoginBaseState>('login');

export const selectSharedLoginBaseState = createSelector(
  sharedLoginBaseSelect,
  (state: SharedLoginBaseState) => state
);

export const selectCurrentApp = createSelector(
  sharedLoginBaseSelect,
  (state: SharedLoginBaseState) => state.currentApp
);

export const selectCurrentLogin = createSelector(
  sharedLoginBaseSelect,
  (state: SharedLoginBaseState) => state.currentLogin
);

export const selectIsLoading = createSelector(
  sharedLoginBaseSelect,
  (state: SharedLoginBaseState) => state.isLoading
);

export const selectIsLoadingId = createSelector(
  sharedLoginBaseSelect,
  (state: SharedLoginBaseState) => state.isLoadingId
);

export const selectTenantId = createSelector(
  sharedLoginBaseSelect,
  (state: SharedLoginBaseState) => state.currentTenant?.tenantId
);

export const selectTenantSettings = createSelector(
  sharedLoginBaseSelect,
  (state: SharedLoginBaseState) => state.currentTenant?.tenantSettings!
);

export const selectTenantDisplayName = createSelector(
  sharedLoginBaseSelect,
  (state: SharedLoginBaseState) => state.currentTenant?.tenantDisplayName!
);

export const selectToken = createSelector(
  sharedLoginBaseSelect,
  (state: SharedLoginBaseState) => {
    return state.app
  }
);
