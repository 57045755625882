/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum DocumentTemplateContextType {
    ExportTicket = 1,
    ExportMissionByTicket = 2,
    ActivationMissionByTicket = 3,
    ExportMissionByTask = 4,
    ActivationMissionByTask = 5,
    ExportMission = 6,
    ExportMissionByInspection = 7,
    ActivationMission = 8,
    ActivationMissionByInspection = 9,
    MissionBase = 10,
    FilledForm = 11,
    FilledFormByChat = 12,
    FilledFormByTask = 13,
    Issue = 14,
    MessageIssue = 15,
    TicketIssue = 16,
    MissionIssue = 17,
    WinterhalterIssue = 18,
}
