import {Injectable} from "@angular/core";
import {
  IssueType, MessageControlDto,
  MessageIssuePreviewDto,
  StereotypeDto,
  SubmitMessageIssueDto
} from "@server-models";
import {
  ICustomPropertyStructureFormatted
} from "@shared/components/custom-property-form/interfaces/custom-property-structure-formatted.interface";
import {FormGroup} from "@angular/forms";
import {SharedIssueStereotypeBaseService} from "@shared/components/issues/services/stereotype/shared-issue-stereotype-base.service";
import {TIssueDto} from "../types/issue-dto.type";
import {
  IAdditionalDetails
} from "@shared/components/custom-property-form/interfaces/structure-formatted-additional-details.interface";

@Injectable({
  providedIn: 'root',
})
export class SharedIssueControlMessageService extends SharedIssueStereotypeBaseService {

  override formatReadDetailData(issueDtoType: TIssueDto, issueType: IssueType,
                                stereotypeDto?: StereotypeDto | undefined): ICustomPropertyStructureFormatted {
    const issueDtoTypeControlMessage = issueDtoType as MessageControlDto;
    const baseResult = <ICustomPropertyStructureFormatted>{
      structureId: issueDtoTypeControlMessage.issueId,
      headerTitle: issueDtoTypeControlMessage.title,
      lastModified: issueDtoTypeControlMessage.lastModified,
      type: issueType
    }
    return {
      ...baseResult,
      additionalDetails: { ...this.getAdditionalFormattedData(issueDtoType) }
    };
  }

  prepareFormIssueDto(formGroup: FormGroup, templatePreviewDto: MessageIssuePreviewDto): SubmitMessageIssueDto {
    console.warn('Submit Control Message has not been implemented');
    return {} as any;
  }

  getAdditionalFormattedData(issueDto: MessageControlDto): Partial<IAdditionalDetails> {
    return { controlMessage: issueDto };
  }

}
