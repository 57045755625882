<ion-header [translucent]="true">
  <ion-toolbar color="primary">
    <ion-title>{{ 'LINK.HOME.TITLE' | translate }}</ion-title>
    <ion-buttons slot="start">
      <ion-button (click)="close()" *ngIf="isChildApp">
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content [fullscreen]="true">

  <div class="ion-text-center" *ngIf="isLoading$ | async; else content">
    <ion-spinner></ion-spinner>
  </div>
  <ng-template #content>
    <ion-grid>
      <ion-row>
        <ion-col>
          <ion-card *ngIf="resource$ | async as resource">
            <ion-card-header>
              <ion-card-title>
                {{ resource.name }}
              </ion-card-title>
            </ion-card-header>
            <ion-card-content>
              <ion-list class="properties">
                <ion-item lines="full" style="--padding-start: 0; --padding-end: 0;">
                  <app-shared-prop [title]="'LINK.HOME.TYPE' | translate">{{ resource.stereotype?.name }}
                  </app-shared-prop>
                </ion-item>
                <ion-item lines="full" style="--padding-start: 0; --padding-end: 0;">
                  <app-shared-prop
                    [title]="'LINK.HOME.INSTALL' | translate">{{ formatDatetime(parseDatetime(resource.installationDate), false) }}
                  </app-shared-prop>
                </ion-item>
                <ion-item lines="full" style="--padding-start: 0; --padding-end: 0;" *ngIf="resource.inventoryNumber">
                  <app-shared-prop [title]="'LINK.HOME.NUMBER' | translate">{{ resource.inventoryNumber }}
                  </app-shared-prop>
                </ion-item>
                <ion-item lines="full" style="--padding-start: 0; --padding-end: 0;"
                          *ngIf="resource.manufacturer?.name">
                  <app-shared-prop [title]="'LINK.HOME.MANUFACTURER' | translate">{{ resource.manufacturer?.name }}
                  </app-shared-prop>
                </ion-item>
                <ion-item lines="full" style="--padding-start: 0; --padding-end: 0;" *ngIf="resource.model?.name">
                  <app-shared-prop [title]="'LINK.HOME.MODEL' | translate">{{ resource.model?.name }}</app-shared-prop>
                </ion-item>
                <ion-item lines="none" style="--padding-start: 0; --padding-end: 0;" *ngIf="resource.location?.name">
                  <app-shared-prop [title]="'LINK.HOME.LOCATION' | translate">{{ resource.location?.name }}
                  </app-shared-prop>
                </ion-item>
              </ion-list>
            </ion-card-content>
          </ion-card>
        </ion-col>
      </ion-row>

      <div class="ion-text-center" *ngIf="hintsIsLoading$ | async">
        <ion-spinner></ion-spinner>
      </div>

      <ng-container *ngIf="((errors$ | async)?.length || 0) > 0">
        <ion-row style="margin-top: 1rem">
          <ion-col>
            <ion-card-subtitle>
              {{ (errors$ | async)?.length }}
              <span *ngIf="(errors$ | async)?.length === 1">{{ ('LINK.HOME.ERRORS.SINGLE') | translate }}</span>
              <span *ngIf="(errors$ | async)?.length !== 1">{{ ('LINK.HOME.ERRORS.MULTI') | translate }}</span>
            </ion-card-subtitle>
          </ion-col>
        </ion-row>

        <ion-row *ngFor="let error of errors$ | async">
          <ion-col>
            <ion-card>
              <ion-card-header>
                <ion-card-title>
                  {{ error.displayName }}
                </ion-card-title>
                <ion-card-subtitle>
                  <ion-badge color="danger">{{ ('LINK.HOME.ERRORS.LABEL') | translate }}</ion-badge>
                </ion-card-subtitle>
              </ion-card-header>

              <ion-card-content>
                <p>{{ error.description }}</p>
              </ion-card-content>

              <ion-button
                *ngIf="!!error.knowledgeArticle?.knowledgeArticleId"
                fill="clear"
                (click)="openKnowledgeArticle(error.knowledgeArticle!.knowledgeArticleId!)">
                {{ 'LINK.HOME.DETAILS' | translate }}
              </ion-button>
            </ion-card>
          </ion-col>
        </ion-row>
      </ng-container>

      <ng-container *ngIf="((hints$ | async)?.length || 0) > 0;">
        <ion-row style="margin-top: 1rem">
          <ion-col>
            <ion-card-subtitle>{{ (hints$ | async)?.length }}
              <span *ngIf="(hints$ | async)?.length === 1">{{ ('LINK.HOME.HINTS.SINGLE') | translate }}</span>
              <span *ngIf="(hints$ | async)?.length !== 1">{{ ('LINK.HOME.HINTS.MULTI') | translate }}</span>
            </ion-card-subtitle>
          </ion-col>
        </ion-row>

        <ion-row *ngFor="let hint of hints$ | async">
          <ion-col>
            <ion-card>
              <ion-card-header>
                <ion-card-title>
                  {{ hint.displayName }}
                </ion-card-title>
                <ion-card-subtitle>
                  <ion-badge color="medium">{{ ('LINK.HOME.HINTS.LABEL') | translate }}</ion-badge>
                </ion-card-subtitle>
              </ion-card-header>

              <ion-card-content>
                <p>{{ hint.description }}</p>
              </ion-card-content>

              <ion-button
                *ngIf="!!hint.knowledgeArticle?.knowledgeArticleId"
                fill="clear"
                (click)="openKnowledgeArticle(hint.knowledgeArticle!.knowledgeArticleId!)">
                {{ 'LINK.HOME.DETAILS' | translate }}
              </ion-button>
            </ion-card>
          </ion-col>
        </ion-row>
      </ng-container>


    </ion-grid>
  </ng-template>
</ion-content>
