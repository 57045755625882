import {AfterViewInit, Component, Input} from '@angular/core';
import {IonicModule} from "@ionic/angular";
import {NgxExtendedPdfViewerModule, NgxExtendedPdfViewerService, PagesLoadedEvent} from "ngx-extended-pdf-viewer";
import {SharedModalControllerService} from "@shared/services/shared-modal-controller.service";
import {TranslateModule} from "@ngx-translate/core";
import {CommonModule} from "@angular/common";
import {IViewerBlobSrc} from "@shared/components/viewers/interfaces/viewer-src.interface";

@Component({
  selector: 'app-viewer-pdf',
  templateUrl: './shared-viewer-pdf.component.html',
  styleUrls: ['./shared-viewer-pdf.component.scss'],
  imports: [
    CommonModule,
    IonicModule,
    NgxExtendedPdfViewerModule,
    TranslateModule
  ],
  standalone: true
})
export class SharedViewerPdfComponent implements AfterViewInit {

  @Input() file!: IViewerBlobSrc;
  @Input() name!: string;

  zoomLevel!: string;
  zoomPercentage: number;
  isSearch: boolean;
  currentPage: number;
  pagesCount: number;
  isSidebarVisible: boolean;
  historyKeyValue: string;

  constructor(
    private _modalControllerService: SharedModalControllerService,
    private _pdfService: NgxExtendedPdfViewerService,
  ) {
    this.zoomPercentage = 100;
    this.isSearch = false;
    this.currentPage = 1;
    this.pagesCount = 1;
    this.isSidebarVisible = false;
    this.historyKeyValue = 'pdfjs.history';
  }

  ngAfterViewInit(): void {
    this._removePdfHistory()
  }

  /**
   * @name _removePdfHistory
   * @description
   * remove a specific key created by library 'pdfjs.history'
   * and replace the use of setItem function to use a function that avoid to set
   * the value of 'pdfjs.history' to be stored
   * @private
   */
  private _removePdfHistory(): void {
    localStorage.removeItem(this.historyKeyValue);
    const originalSetItem = localStorage.setItem;

    localStorage.setItem = (key: string, value: string) => {
      if (key !== this.historyKeyValue) {
        originalSetItem.call(localStorage, key, value);
      }
    };
  }

  /**
   * @name exit
   * @description
   * call modal controller service to close the modal
   * @memberof SharedViewerPdfComponent
   */
  exit(): void {
    this._modalControllerService.closeModal();
  }

  /**
   * @name onSearch
   * @description
   * trigger the on search event to find in the pdf
   * @memberof SharedViewerPdfComponent
   * @param $event
   */
  onSearch($event: any): void {
    const value = $event.detail.value;
    this.isSearch = !!value;
    this._pdfService.find(value);
  }

  /**
   * @name zoomChange
   * @description
   * calculate the zoom in percentage
   * @memberof SharedViewerPdfComponent
   * @param $event
   */
  zoomChange($event: any): void {
    this.zoomPercentage = Math.round($event * 100);

    if (!this.zoomLevel) {
      this.zoomLevel = this.zoomPercentage.toString();
    }
  }

  /**
   * @name pagesLoaded
   * @description
   * trigger the pages loaded and saved into variable
   * @memberof SharedViewerPdfComponent
   * @param event
   */
  pagesLoaded(event: PagesLoadedEvent) {
    this.pagesCount = event.pagesCount;
  }

  /**
   * @name shiftPage
   * @description
   * change the current page
   * @memberof SharedViewerPdfComponent
   * @param offset
   */
  shiftPage(offset: number) {
    this.currentPage += offset;
    this.currentPage = Math.max(1, Math.min(this.currentPage, this.pagesCount));
  }

  /**
   * @name shiftZoom
   * @description
   * change the zoom
   * @memberof SharedViewerPdfComponent
   * @param offset
   */
  shiftZoom(offset: number): void {
    const increase = this.zoomPercentage * 0.10 * offset;
    this.zoomLevel = (this.zoomPercentage + increase).toString();
    this.zoomLevel = Math.max(10, Math.min(+this.zoomLevel, 1000)).toString();
  }

  /**
   * @name toggleSidebar
   * @description
   * show/hide the toggle sidebar from the pdf library
   * @memberof
   */
  toggleSidebar(): void {
    this.isSidebarVisible = !this.isSidebarVisible;
  }

  /**
   * @name searchNext
   * @description
   * on search let pdf service change next page
   * @memberof SharedViewerPdfComponent
   */
  searchNext() {
    this._pdfService.findNext();
  }

  /**
   * @name searchPrev
   * @description
   * on search let pdf service change previous page
   * @memberof SharedViewerPdfComponent
   */
  searchPrev() {
    this._pdfService.findPrevious();
  }

}
