import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {CommonModule} from "@angular/common";
import {IonicModule} from "@ionic/angular";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {SharedSafePipe} from "@shared/pipes/shared-safe.pipe";
import {SharedUserHtmlComponent} from "@shared/components/user-html/shared-user-html.component";
import {SharedAttachmentsComponent} from "@shared/components/attachments/shared-attachments.component";
import {RouterLink} from "@angular/router";
import {SharedCustomPropertyFormInputComponent} from "@shared/components/custom-property-form/components/input/shared-custom-property-form-input.component";
import {FormBuilder, FormControl, FormGroup, ReactiveFormsModule} from "@angular/forms";
import {
  CustomPropertyDto,
  CustomSetReferenceDto, type FilledCustomValueDto,
} from "@server-models";
import {Observable, Subscription} from "rxjs";
import {Store} from "@ngrx/store";
import {SharedIssueBaseService} from "@shared/components/issues/services/shared-issue-base.service";
import {SharedStereotypeService} from "@shared/services/stereotype/shared-stereotype.service";
import {
  SharedCustomPropertyFormInputTextComponent
} from "@shared/components/custom-property-form/components/input/components/text/shared-custom-property-form-input-text.component";

@Component({
  selector: 'app-shared-custom-form-property',
  templateUrl: './shared-custom-property-form.component.html',
  styleUrls: ['./shared-custom-property-form.component.scss'],
  standalone: true,
  imports: [
    IonicModule,
    CommonModule,
    TranslateModule,
    SharedSafePipe,
    SharedUserHtmlComponent,
    SharedAttachmentsComponent,
    RouterLink,
    SharedCustomPropertyFormInputTextComponent,
    ReactiveFormsModule,
    SharedCustomPropertyFormInputComponent
  ]
})
export class SharedCustomPropertyFormComponent implements OnInit, OnChanges, OnDestroy {
  @Input() customPropertySets: CustomSetReferenceDto[];
  @Input() customFilledValues: FilledCustomValueDto[];
  @Input() hasNoMargin: boolean;
  @Output() inputChange = new EventEmitter<FormGroup>();
  inputsProperties: CustomPropertyDto[];
  customValues: FormGroup;
  propertyForm: FormGroup;
  isLoading$!: Observable<boolean>;
  propertyFormValueChangesSubscription: Subscription | null;

  constructor(
    protected _store: Store,
    protected _fb: FormBuilder,
    protected _issuesBaseService: SharedIssueBaseService,
    protected _stereotypeBaseService: SharedStereotypeService,
    protected _translateService: TranslateService
  ) {
    this.customPropertySets = [];
    this.customFilledValues = []
    this.hasNoMargin = false;
    this.propertyForm = this._fb.group({});
    this.customValues = this._fb.group({
      CustomValues: this.propertyForm
    });
    this.inputsProperties = [];
    this.propertyFormValueChangesSubscription = null;
  }

  getGroupNames(): string[] {
    return Object.keys(this.propertyForm.controls);
  }

  getControlNames(groupName: string): string[] {
    const group = this.propertyForm.controls[groupName] as FormGroup;
    return Object.keys(group.controls);
  }

  getControlFromGroup(groupName: string, controlName: string): FormControl {
    const group = this.propertyForm.controls[groupName] as FormGroup;
    return group.controls[controlName] as FormControl;
  }

  getGroupNameByIndex(index: number): string {
    return this.getGroupNames()[index];
  }

  getSortedIndexes(): number[] {
    return this.customPropertySets
      .map((_, index) => index)
      .sort((a, b) => {
        const positionA = this.customPropertySets[a]?.position!;
        const positionB = this.customPropertySets[b]?.position!;
        return positionA - positionB;
      });
  }

  getProperty(groupIndex: number, controlName: string): CustomPropertyDto {
    const propertySet = this.customPropertySets[groupIndex];
    return propertySet!.customPropertySet?.properties!.find(
      (prop: any) => prop.name! + prop.customPropertyId! === controlName
    )!;
  }

  getPropertyName(groupIndex: number): string {
    const propertySet = this.customPropertySets[groupIndex];
    return propertySet!.customPropertySet?.name!;
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['customPropertySets']) {
      this.propertyForm = this._stereotypeBaseService.buildFormGroupsCustomProperties(this.customPropertySets, this.customFilledValues);
      this.customValues.setControl('CustomValues', this.propertyForm);
      // emit the first time
      this.formModified();
      // listen to form changes and emit them
      this.propertyForm.valueChanges.subscribe(() => {
        this.formModified();
      });
    }
  }

  formModified(): void {
    this.inputChange.emit(this.customValues);
  }

  ngOnDestroy() {
    if (this.propertyFormValueChangesSubscription) {
      this.propertyFormValueChangesSubscription.unsubscribe();
    }
  }
}
