<ng-container *ngIf="mappedAttachments.length > 0" [ngSwitch]="designType">
  <app-shared-attachments-row-item *ngSwitchCase="DesignTypeEnum.Row"
                                   [mappedAttachments]="mappedAttachments"
                                   [isIconFolderEnabled]="hasIconFolder"
                                   [hasHeader]="hasHeader">
  </app-shared-attachments-row-item>
  <app-shared-attachments-box-item *ngSwitchCase="DesignTypeEnum.Box"
                                   [mappedAttachments]="mappedAttachments"
  ></app-shared-attachments-box-item>
</ng-container>
