import {Component, Input, OnInit} from '@angular/core';
import {IonicModule} from '@ionic/angular';
import {NgOptimizedImage} from '@angular/common';
import {SharedModalControllerService} from '@shared/services/shared-modal-controller.service';
import {SafeUrl} from '@angular/platform-browser';
import {SharedSafePipe} from '@shared/pipes/shared-safe.pipe';
import {IViewerBlobSrc, IViewerUrlSrc} from '../interfaces/viewer-src.interface';

@Component({
  selector: 'app-viewer-image',
  templateUrl: './shared-viewer-image.component.html',
  styleUrls: ['./shared-viewer-image.component.scss'],
  imports: [IonicModule, NgOptimizedImage, SharedSafePipe],
  standalone: true,
})
export class SharedViewerImageComponent implements OnInit {
  @Input() file!: IViewerBlobSrc | IViewerUrlSrc;
  @Input() name!: string;

  image!: SafeUrl;

  constructor(private _modalCtrlService: SharedModalControllerService) {
  }

  ngOnInit() {
    this._transformBlobToImage();
  }

  /**
   * @name _transformBlobToImage
   * @description
   * Transform the blob to a valid url image
   * @memberof SharedViewerImageComponent
   * @private
   */
  private _transformBlobToImage(): void {
    if ((this.file as IViewerUrlSrc)?.url) {
      this.image = (this.file as IViewerUrlSrc).url;
    }
    if ((this.file as IViewerBlobSrc)?.file) {
      this.image = URL.createObjectURL((this.file as IViewerBlobSrc).file);
    }
  }

  /**
   * @name exit
   * @description
   * call modal controller service to close the modal
   * @memberof SharedViewerImageComponent
   */
  exit(): void {
    this._modalCtrlService.closeModal();
  }
}
