import {Component, Input, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {TranslateModule} from "@ngx-translate/core";

@Component({
  selector: 'app-shared-custom-property-read-only-input-numeric',
  templateUrl: './shared-custom-property-read-only-input-numeric.component.html',
  styleUrls: ['./shared-custom-property-read-only-input-numeric.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, TranslateModule]
})
export class SharedCustomPropertyReadOnlyInputNumericComponent implements OnInit {

  @Input() title: string;
  @Input() value: number;

  constructor() {
    this.title = "";
    this.value = NaN;
  }

  ngOnInit() {
  }
}
