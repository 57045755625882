<ion-header [translucent]="true">
  <ion-toolbar color="primary">
    <ion-title>{{ 'LINK.PROPERTIES.TITLE' | translate }}</ion-title>
    <ion-buttons slot="start">
      <ion-button (click)="close()" *ngIf="isChildApp">
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content [fullscreen]="true">
  <div class="ion-text-center" *ngIf="isLoading$ | async">
    <ion-spinner></ion-spinner>
  </div>

  <ion-card *ngFor="let set of (stereotype$ | async)?.customPropertySets">
    <ion-card-header>
      <ion-card-subtitle>{{ set.customPropertySet?.name }}</ion-card-subtitle>
    </ion-card-header>
    <ion-card-content>
      <ion-list>
        <ion-item
          [lines]="last ? 'none' : 'full'"
          style="--padding-start: 0; --padding-end: 0;"
          *ngFor="let property of set.customPropertySet?.properties; let last = last;"
        >
          <app-shared-prop [title]="property?.name">
            {{ findValue(property, property.customPropertyId) | async }}
          </app-shared-prop>
        </ion-item>
      </ion-list>
    </ion-card-content>
  </ion-card>


  <ion-card *ngIf="((consumptions$ | async)?.length || 0) > 0;">
    <ion-card-header>
      <ion-card-subtitle>{{ 'LINK.PROPERTIES.CONSUMPTIONS' | translate }}</ion-card-subtitle>
    </ion-card-header>
    <ion-card-content>
      <ion-list>
        <ion-item
          [lines]="last ? 'none' : 'full'"
          style="--padding-start: 0; --padding-end: 0;"
          *ngFor="let consumption of consumptions$ | async ; let last = last;"
        >
          <app-shared-prop [title]="consumption.displayName">
            {{ consumption.value }} {{ consumption.unit }}
          </app-shared-prop>
        </ion-item>
      </ion-list>
    </ion-card-content>
  </ion-card>

</ion-content>
